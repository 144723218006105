import React, { memo, useState, ReactNode, useMemo, useRef } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import Button from '../atoms/button/Button';
import BackIcon from '../backIcon/BackIcon';
import DropdownMenu, { MenuItem } from '../dropdownMenu/DropdownMenu';
import MenuButton from '../menuButton/MenuButton';
import BurgerIcon from '../burgerIcon/BurgerIcon';

import { backLabel } from '../../strings';

import styles from './StickyBar.module.css';
import tooltipStyle from '../../globalStyles/Tooltip.module.css';
import separatorStyles from '../../globalStyles/Separator.module.css';
import useTheme from '../../hooks/useTheme';

type Action = {
  id: string;
  title: string;
  onClick: () => void;
  icon?: ReactNode;
  className?: string;
  tooltip?: string;
};

export type Theme = typeof styles & { separatorAfter: string };

export type Props = {
  backButton?: boolean;
  actions?: Array<Action>;
  children?: ReactNode;
  theme?: Partial<Theme>;
  containerClassName?: string;
  className?: string;
};

const StickyBar = ({
  actions = [],
  theme: customTheme,
  className,
  backButton,
  children,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme<Theme>(
    { ...styles, separatorAfter: separatorStyles.separatorAfter },
    customTheme
  );
  const [isActionsMenuOpened, setIsActionsMenuOpened] = useState(false);
  const rightActionsRef = useRef<HTMLDivElement>(null);

  const actionsMenuItems: MenuItem[] = useMemo(
    () =>
      actions.map((action) => ({
        ...action,
        type: 'button',
        content: action.title,
        label: action.title,
      })),
    [actions]
  );

  return (
    <div data-testid="sticky-bar" className={cx(theme.stickyBar, className)}>
      <div className={theme.container}>
        {backButton && window.history.length > 1 && (
          <Button
            className={cx(theme.backButton, tooltipStyle.tooltip)}
            unstyled
            onClick={() => navigate(-1)}
            data-tooltip={backLabel}
            data-testid="backButton"
          >
            <BackIcon
              theme={{ icon: theme.backIcon, tail: theme.backIconTail }}
            />
          </Button>
        )}

        <div className={styles.childrenContainer}>{children}</div>

        <div ref={rightActionsRef} className={theme.actions}>
          {actionsMenuItems.length ? (
            <DropdownMenu
              button={
                <MenuButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsActionsMenuOpened((opened) => !opened);
                  }}
                  menuIsOpen={isActionsMenuOpened}
                  openIcon={<BurgerIcon />}
                  theme={{ circleIcon: theme.actionsMenuCircleIcon }}
                  aria-label="Menu items"
                />
              }
              isMenuOpened={isActionsMenuOpened}
              forceCloseMenu={() => setIsActionsMenuOpened(false)}
              menuItems={actionsMenuItems}
            />
          ) : (
            <div className={styles.actionsMenuItemDummy} />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(StickyBar);
