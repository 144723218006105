import React, { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavbarButton from '../navbarButton/NavbarButton';
import Logo from '../logo/Logo';
import SignUpHandlerContainer from '../signUpHandler/SignUpHandlerContainer';
import Notifications from '../notifications/NotificationsContainer';
import MobileMenu from '../mobileMenu/MobileMenu';
import DefaultNavMenu from '../defaultNavMenu/DefaultNavMenu';
import UserMenu from '../userMenu/UserMenu';
import styles from './PrimaryNavbar.module.css';
import {
  coursesPath,
  libraryPath,
  projectsPath,
  tutorialsPath,
  onboardingPath,
  myContentPath,
  dashboardPath,
} from '../../strings';

import RealmMenu from '../realmMenu/RealmMenu';
import { useAuth } from '../../context/Auth';

const skipMainLinksPages = [`/${tutorialsPath}`, `/${onboardingPath}`];

const getRootPath = (pathname: string) => {
  // remove leading slash and get first path segment
  return pathname.replace(/^\//, '').split('/')[0];
};

const getActiveRootPath = (pathname: string) => {
  const rootPath = getRootPath(pathname);

  // on course and project pages highlight create when editing, else explore
  if ([projectsPath, coursesPath].includes(rootPath)) {
    return pathname.endsWith('/edit') ? myContentPath : libraryPath;
  }

  return rootPath;
};

const noRealmMenuPaths = [
  'activate',
  `${libraryPath}`,
  `${onboardingPath}`,
  `${tutorialsPath}`,
];

const PrimaryNavbar = () => {
  const { pathname } = useLocation();
  const { authenticated, isReady: authIsReady } = useAuth();

  const showMainLinks = useMemo(() => {
    return !skipMainLinksPages.find((p) => pathname.startsWith(p));
  }, [pathname]);

  const activeRootPath = useMemo(() => getActiveRootPath(pathname), [pathname]);

  const rootPath = useMemo(() => getRootPath(pathname), [pathname]);

  const showRealmMenu = useMemo(() => {
    return (
      process.env.FEATURE_FLAG_REALMS === 'true' &&
      ![...noRealmMenuPaths].includes(rootPath)
    );
  }, [rootPath]);

  const mainLinks = (
    <>
      <Link
        to={`/${dashboardPath}`}
        className={styles.buttonLink}
        role="menuitem"
      >
        <NavbarButton active={activeRootPath === dashboardPath}>
          Dashboard
        </NavbarButton>
      </Link>

      <Link
        to={`/${libraryPath}`}
        className={styles.buttonLink}
        role="menuitem"
      >
        <NavbarButton active={activeRootPath === libraryPath}>
          Library
        </NavbarButton>
      </Link>

      <Link
        to={`/${myContentPath}`}
        className={styles.buttonLink}
        role="menuitem"
      >
        <NavbarButton active={activeRootPath === myContentPath}>
          My Content
        </NavbarButton>
      </Link>

      <div className={styles.spacer} />
      <MobileMenu />
    </>
  );

  const unauthenticatedNav = (
    <>
      <div style={{ flex: 1 }} />
      <DefaultNavMenu className={styles.defaultDropdown} />
      <SignUpHandlerContainer className={styles.signUpHandler} />
    </>
  );

  const authenticatedNav = (
    <div className={styles.buttonBar} role="menubar" aria-label="main menu">
      <div className={styles.spacer} />
      {showMainLinks && mainLinks}

      <DefaultNavMenu className={styles.defaultDropdown} />

      <Notifications className={styles.notifications} />

      <UserMenu />
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.realmMenu}>{showRealmMenu && <RealmMenu />}</div>
      {authenticated && authIsReady ? authenticatedNav : unauthenticatedNav}
    </header>
  );
};

export default memo(PrimaryNavbar);
