import React from 'react';
import { string } from 'prop-types';

import styles from './WarningIcon.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const WarningIcon = ({ className }) => (
  <svg viewBox="50 20 70 35" className={className}>
    <g>
      <path
        d="M78.21,13.86c-1,.61-1,2-.89,3.11q1.07,12.81,2.33,25.59a4,4,0,0,0,.61,2.07c.7.93,2,1,3.19,1.1l2.84.11a2.12,2.12,0,0,0,1.19-.17,2.25,2.25,0,0,0,.84-1.77,166.61,166.61,0,0,0,1.5-25.84,5.83,5.83,0,0,0-.54-2.79C87.75,12.63,80.54,12.38,78.21,13.86Z"
        className={styles.exclamationPoint}
      />
      <path
        d="M81.07,52.24a1.91,1.91,0,0,0-.88.16c-.6.32-.68,1.13-.68,1.81l0,4.32a1.82,1.82,0,0,0,1.21,2.27,1.75,1.75,0,0,0,.91,0l4.5.1a1.6,1.6,0,0,0,.72-.08c.61-.26.71-1.06.73-1.71l.1-3.78a3.16,3.16,0,0,0-.42-2.06C86.16,51.93,82.64,52.2,81.07,52.24Z"
        className={styles.exclamationPoint}
      />
    </g>
  </svg>
);

WarningIcon.propTypes = propTypes;
WarningIcon.defaultProps = defaultProps;
export default WarningIcon;
