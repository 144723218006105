import React, { ReactChild, useRef } from 'react';
import { Provider } from 'react-redux';
import createStore from '../../createStore';
import { AuthContextProvider } from '../../context/Auth';
import { MembershipContextProvider } from '../../context/Membership';
import { HardwarePreferencesProvider } from '../../context/HardwarePreferences';
import { FurtherLinkContextProvider } from '../../context/FurtherLink';
import { RealmContextProvider } from '../../context/Realm';
import { PersistedCacheApolloProvider } from '../../apollo/PersistedCacheApolloProvider';
import { LanguageContextProvider } from '../../context/Language';

export type Props = {
  children: ReactChild;
};
const AppProviders = ({ children }: Props) => {
  // prevent accidental recreation of store and apollo client.
  const store = useRef(createStore()).current;

  return (
    // @ts-expect-error no more implicit children in ReactNode types. Hoping to remove redux instead of upgrading.
    <Provider store={store}>
      <PersistedCacheApolloProvider>
        <LanguageContextProvider>
          <AuthContextProvider>
            <MembershipContextProvider>
              <RealmContextProvider>
                <HardwarePreferencesProvider>
                  <FurtherLinkContextProvider>
                    {children}
                  </FurtherLinkContextProvider>
                </HardwarePreferencesProvider>
              </RealmContextProvider>
            </MembershipContextProvider>
          </AuthContextProvider>
        </LanguageContextProvider>
      </PersistedCacheApolloProvider>
    </Provider>
  );
};
export default AppProviders;
