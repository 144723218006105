import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import PtProgress from '../ptProgress/PtProgress';
import PrimaryNavbar from '../primaryNavbar/PrimaryNavbar';
import Footer from '../footer/Footer';
import styles from './App.module.css';

export const mainContentWrapperId = 'mainContentWrapper';

const PageLayout = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.app}>
      <main className={styles.mainContent} id={mainContentWrapperId}>
        <PrimaryNavbar />

        <ErrorBoundary key={pathname}>
          <Suspense
            fallback={
              <div className={styles.pageLoader}>
                <PtProgress />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </main>
      <Footer />
    </div>
  );
};

export default PageLayout;
