import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import MembershipDialog from '../membershipDialog/MembershipDialog';
import {
  activateLicenseTitle,
  activateLicenseHint,
  activateLicenseInputLabel,
  activateLicenseSubmit,
  licenseActivated,
  licenseCodeErrorMessage,
  activatedContentLicenseDescription,
  activatedContentLicenseShareInfo,
  activatedContentShareExplanation,
  activatedContentShareExplanationPage,
} from '../../strings';
import { UPGRADE_MEMBERSHIP } from '../../graphql/mutations/accounts';
import logException from '../../services/logging/exception';
import {
  UpgradeMembership,
  UpgradeMembershipVariables,
} from '../../graphql/mutations/accounts/types/UpgradeMembership';
import styles from './ActivateLicenseDialog.module.css';

type Props = {
  handleClose: () => void;
  onUpgradeCompleted?: () => void;
  activationCode?: string;
};

const ActivateLicenseDialog = ({
  handleClose,
  activationCode,
  onUpgradeCompleted,
}: Props) => {
  const [activatedCode, setActivatedCode] = useState('');

  const [
    upgradeMembership,
    { loading: upgradeLoading, data, error },
  ] = useMutation<UpgradeMembership, UpgradeMembershipVariables>(
    UPGRADE_MEMBERSHIP,
    {
      onCompleted: onUpgradeCompleted,
      onError: (err) => {
        logException(err);
      },
    }
  );

  let hintText = activateLicenseHint;
  let buttonText = activateLicenseSubmit;
  let showHasMembershipCode = true;

  // presence of activatedInvite is used to determine successful license activation
  // as opposed to just activatedCode which is set on submit rather than on completed
  const activatedInvite = data?.upgradeMembership?.invites
    ? data.upgradeMembership.invites.find(
        (invite) => invite?.code === activatedCode
      )
    : null;

  if (activatedInvite) {
    hintText = licenseActivated;
    buttonText = 'Ok';
    showHasMembershipCode = false;
  }

  const licensedContentInfo = activatedInvite?.contentLicenses
    .map((license) => {
      const shares = license.maxUsers - license.accounts.length;
      if (license.realm?.name) {
        return {
          name: license.realm.name,
          to: `/explore/${license.realm.name}`,
          shares,
        };
      }
      if (license.course?.title) {
        return {
          name: license.course.title,
          to: `/courses/${license.course.id}`,
          shares,
        };
      }
      return null;
    })
    .filter((i): i is { name: string; to: string; shares: number } => !!i);

  const submit = ({ membershipCode }: { membershipCode: string }) => {
    if (!membershipCode) {
      return;
    }
    setActivatedCode(membershipCode);
    upgradeMembership({
      variables: { inviteCode: membershipCode },
    });
  };

  return (
    <MembershipDialog
      active
      handleClose={handleClose}
      membershipCode={activationCode}
      showMembershipCodeInput={showHasMembershipCode}
      title={activateLicenseTitle}
      hint={hintText}
      codeInputLabel={activateLicenseInputLabel}
      icon="teacher"
      isSubmitting={upgradeLoading}
      submitError={error ? licenseCodeErrorMessage : ''}
      onConfirm={activatedInvite ? handleClose : submit}
      buttonText={buttonText}
    >
      {activatedInvite && !!licensedContentInfo?.length && (
        <div className={styles.licenseInfo}>
          <div className={styles.contentInfo}>
            {activatedContentLicenseDescription}
            <ul>
              {licensedContentInfo.map(({ name, to, shares }) => (
                <li key={name}>
                  <Link to={to}>{name}</Link>
                  {shares > 0 && (
                    <span>{activatedContentLicenseShareInfo(shares)}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {licensedContentInfo.some(({ shares }) => shares > 0) && (
            <p>
              {activatedContentShareExplanation}{' '}
              <Link to="/student-accounts">
                {activatedContentShareExplanationPage}
              </Link>
              .
            </p>
          )}
        </div>
      )}
    </MembershipDialog>
  );
};

export default ActivateLicenseDialog;
