import React, { memo } from 'react';
import cx from 'classnames';
import styles from './CloneIcon.module.css';

export type Props = {
  className?: string;
};

const CloneIcon = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cx(styles.root, className)}
    viewBox="0 0 30.33 35.15"
  >
    <g>
      <path
        className={styles.st2}
        d="M1.59,4.74c-0.37,0.02-0.38,1.3-0.45,1.82c-0.66,4.87-0.33,9.87-0.16,14.78c0.04,1.23,0.21,2.64,1.23,3.32
		c0.54,0.36,1.22,0.43,1.87,0.48c3.69,0.29,7.42,0.21,11.1-0.23c0.49-0.06,1.01-0.14,1.36-0.47c0.47-0.44,0.51-1.16,0.52-1.81
		c0.06-5.05,0.11-10.1,0.17-15.16c0.01-0.65,0.01-1.33-0.23-1.93C16.08,3.28,1.75,4.73,1.59,4.74z"
      />
      <path
        className={styles.st2}
        d="M9.01,25.51c0.04,2.23,0.14,4.13,0.21,6.34c0.04,1.23,0.21,2.64,1.23,3.32c0.54,0.36,1.22,0.43,1.87,0.48
		c3.69,0.29,7.42,0.21,11.1-0.23c0.49-0.06,1.01-0.14,1.36-0.47c0.47-0.44,0.51-1.16,0.52-1.81c0.06-5.05,0.11-10.1,0.17-15.16
		c0.01-0.65,0.01-1.33-0.23-1.93c-0.92-2.26-15.25-0.81-15.4-0.8c-1.09,0.04-0.82,3.88-0.85,5.71"
      />
      <path
        d="M19.88,11.85c0.03-2.29,0.05-4.57,0.08-6.86c0.01-0.65,0.01-1.33-0.23-1.93C18.8,0.8,4.47,2.25,4.32,2.26
		c-0.37,0.02-0.38,1.3-0.45,1.82C3.21,8.94,3.54,13.94,3.7,18.85c0.04,1.23,0.21,2.64,1.23,3.32c0.54,0.36,1.22,0.43,1.87,0.48
		c1.63,0.13,3.27,0.18,4.91,0.17"
      />
      <path
        d="M11.73,23.03c0.04,2.23,0.14,4.13,0.21,6.34c0.04,1.23,0.21,2.64,1.23,3.32c0.54,0.36,1.22,0.43,1.87,0.48
		c3.69,0.29,7.42,0.21,11.1-0.23c0.49-0.06,1.01-0.14,1.36-0.47c0.47-0.44,0.51-1.16,0.52-1.81c0.06-5.05,0.11-10.1,0.17-15.16
		c0.01-0.65,0.01-1.33-0.23-1.93c-0.92-2.26-15.25-0.81-15.4-0.8c-1.09,0.04-0.82,3.88-0.85,5.71"
      />
      <g>
        <path d="M7.36,22.69c6.38,0.01,7.94,0.28,14.32,0.54" />
        <path d="M17.92,27.57c1.39-0.9,3.92-2.88,5.3-3.78c-1.11-1.56-3.86-3.86-5.68-5.31" />
      </g>
    </g>
  </svg>
);

export default memo(CloneIcon);
