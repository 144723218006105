import React from 'react';
import { bool, node } from 'prop-types';
import cx from 'classnames';
import MaskedDiv from '../maskedDiv/MaskedDiv';
import InputMask from '../../assets/images/navbar-button-default.svg';

import styles from './NavbarButton.module.css';

const propTypes = {
  active: bool,
  children: node.isRequired,
};

const defaultProps = {
  active: false,
};

const NavbarButton = ({ children, active }) => (
  <MaskedDiv
    className={cx(styles.navbarButton, active && styles.active)}
    mask={`url(${InputMask})`}
  >
    {children}
  </MaskedDiv>
);

NavbarButton.propTypes = propTypes;
NavbarButton.defaultProps = defaultProps;
export default NavbarButton;
