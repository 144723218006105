import React, { memo, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import DropdownMenu from '../dropdownMenu/DropdownMenu';
import { dashboardPath, libraryPath, myContentPath } from '../../strings';

import navStyles from '../primaryNavbar/PrimaryNavbar.module.css';
import styles from './MobileMenu.module.css';

import defaultMenuItems from '../defaultNavMenu/defaultNavMenuItems';
import MenuButton from '../menuButton/MenuButton';
import BurgerIcon from '../burgerIcon/BurgerIcon';

const MobileMenu = () => {
  const [mobileMenuOpened, toggleMobileMenu] = useState(false);

  return (
    <DropdownMenu
      button={
        <MenuButton
          id="mobileDropdownMenu"
          onClick={(event) => {
            event.stopPropagation();
            toggleMobileMenu((active) => !active);
          }}
          menuIsOpen={mobileMenuOpened}
          openIcon={<BurgerIcon className={styles.icon} />}
          theme={{
            root: styles.mobileMenuButton,
            content: styles.mobileMenuButtonContent,
          }}
        />
      }
      className={cx(styles.dropdownContainer, navStyles.mobileDropdown)}
      isMenuOpened={mobileMenuOpened}
      menuClassName={styles.dropdownMenu}
      menuItems={_.compact([
        {
          content: 'Dashboard',
          type: 'link',
          url: `/${dashboardPath}`,
        },
        {
          content: 'Library',
          type: 'link',
          url: `/${libraryPath}`,
        },
        {
          content: 'My Content',
          type: 'link',
          url: `/${myContentPath}`,
        },
        { type: 'separator' },
        ...defaultMenuItems,
      ])}
      forceCloseMenu={() => toggleMobileMenu(false)}
    />
  );
};

export default memo(MobileMenu);
