/* eslint import/no-mutable-exports:0 */

const prodApiBaseUrl = 'https://backend.pi-top.com/api/v3';
const stagingApiBaseUrl = 'https://backend-staging.pi-top.com/api/v3';
const testApiBaseUrl = 'https://backend-test.pi-top.com/api/v3';
const edgeApiBaseUrl = 'https://backend-edge.pi-top.com/api/v3';
const demoApiBaseUrl = 'https://further-demo.pi-top.com/api/v3';
const localApiBaseUrl = `http://${window.location.hostname}:8001/api/v3`;

const prodPdfBaseUrl = 'https://backend.pi-top.com/pdf/v1';
const stagingPdfBaseUrl = 'https://backend-staging.pi-top.com/pdf/v1';
const testPdfBaseUrl = 'https://backend-test.pi-top.com/pdf/v1';
const edgePdfBaseUrl = 'https://backend-edge.pi-top.com/pdf/v1';
const localPdfBaseUrl = `http://${window.location.hostname}:8011/pdf/v1`;

const prodUtilsBaseUrl = 'https://backend.pi-top.com/utils/v1';
const stagingUtilsBaseUrl = 'https://backend-staging.pi-top.com/utils/v1';
const testUtilsBaseUrl = 'https://backend-test.pi-top.com/utils/v1';
const edgeUtilsBaseUrl = 'https://backend-edge.pi-top.com/utils/v1';
const localUtilsBaseUrl = `http://${window.location.hostname}:8007/utils/v1`;

const prodKeycloakBaseUrl = 'https://keycloak.pi-top.com/auth';
const testKeycloakBaseUrl = 'https://keycloak-test.pi-top.com/auth';
const demoKeycloakBaseUrl = 'https://further-demo.pi-top.com/auth';
const localKeycloakBaseUrl = `http://${window.location.hostname}:8080/auth`;

const prodDeviceTrackerBaseUrl = 'https://devices.pi-top.com';
const stagingDeviceTrackerBaseUrl = 'https://devices-staging.pi-top.com';
const edgeDeviceTrackerBaseUrl = 'https://devices-edge.pi-top.com';
const testDeviceTrackerBaseUrl = 'https://devices-test.pi-top.com';
const localDeviceTrackerBaseUrl = `http://${window.location.hostname}:8019`;

let apiBaseUrl = localApiBaseUrl;
let pdfBaseUrl = testPdfBaseUrl;
let utilsBaseUrl = testUtilsBaseUrl;
let keycloakBaseUrl = localKeycloakBaseUrl;
let deviceTrackerBaseUrl = testDeviceTrackerBaseUrl;

// window.PT_SVC_ENV is copied into client code from process.env on server boot
switch ((window as any).PT_SVC_ENV) {
  case 'production':
  case 'prod':
    apiBaseUrl = prodApiBaseUrl;
    pdfBaseUrl = prodPdfBaseUrl;
    utilsBaseUrl = prodUtilsBaseUrl;
    keycloakBaseUrl = prodKeycloakBaseUrl;
    deviceTrackerBaseUrl = prodDeviceTrackerBaseUrl;
    break;
  case 'staging':
    apiBaseUrl = stagingApiBaseUrl;
    pdfBaseUrl = stagingPdfBaseUrl;
    utilsBaseUrl = stagingUtilsBaseUrl;
    keycloakBaseUrl = prodKeycloakBaseUrl;
    deviceTrackerBaseUrl = stagingDeviceTrackerBaseUrl;
    break;
  case 'test':
    apiBaseUrl = testApiBaseUrl;
    pdfBaseUrl = testPdfBaseUrl;
    utilsBaseUrl = testUtilsBaseUrl;
    keycloakBaseUrl = testKeycloakBaseUrl;
    deviceTrackerBaseUrl = testDeviceTrackerBaseUrl;
    break;
  case 'edge':
    apiBaseUrl = edgeApiBaseUrl;
    pdfBaseUrl = edgePdfBaseUrl;
    utilsBaseUrl = edgeUtilsBaseUrl;
    keycloakBaseUrl = testKeycloakBaseUrl;
    deviceTrackerBaseUrl = edgeDeviceTrackerBaseUrl;
    break;
  case 'demo':
    apiBaseUrl = demoApiBaseUrl;
    pdfBaseUrl = testPdfBaseUrl;
    utilsBaseUrl = testUtilsBaseUrl;
    keycloakBaseUrl = demoKeycloakBaseUrl;
    deviceTrackerBaseUrl = testDeviceTrackerBaseUrl;
    break;
  case 'remote':
    apiBaseUrl = localApiBaseUrl;
    pdfBaseUrl = testPdfBaseUrl;
    utilsBaseUrl = testUtilsBaseUrl;
    keycloakBaseUrl = testKeycloakBaseUrl;
    deviceTrackerBaseUrl = testDeviceTrackerBaseUrl;
    break;
  case 'local':
    apiBaseUrl = localApiBaseUrl;
    pdfBaseUrl = localPdfBaseUrl;
    utilsBaseUrl = localUtilsBaseUrl;
    keycloakBaseUrl = localKeycloakBaseUrl;
    deviceTrackerBaseUrl = localDeviceTrackerBaseUrl;
    break;
  case 'development':
  default:
    break;
}

export default apiBaseUrl;
export {
  apiBaseUrl,
  pdfBaseUrl,
  utilsBaseUrl,
  keycloakBaseUrl,
  deviceTrackerBaseUrl,
};
