import React, { memo } from 'react';
import cx from 'classnames';
import MaskedDiv from '../../maskedDiv/MaskedDiv';

import Mask from '../../../assets/images/circle-mask.svg';
import styles from './Avatar.module.css';

type Props = {
  className?: string;
  firstName?: string | null;
  lastName?: string | null;
  username?: string;
};

export const createInitials = (
  firstName?: string | null,
  lastName?: string | null,
  username?: string
) => {
  if (firstName) {
    return `${firstName[0].toUpperCase()}${
      lastName ? lastName[0].toUpperCase() : ''
    }`;
  }
  return username ? username[0].toUpperCase() : '';
};

const Avatar = ({
  className,
  firstName,
  lastName,
  username,
  ...props
}: Props) => (
  <MaskedDiv
    mask={`url(${Mask})`}
    className={cx(styles.avatar, className)}
    testId="avatar"
    {...props}
  >
    {createInitials(firstName, lastName, username)}
  </MaskedDiv>
);

export default memo(Avatar);
