import React from 'react';

import styles from './ReportCommentIcon.module.css';
import CircleIcon from '../circleIcon/CircleIcon';

const ReportCommentIcon = () => (
  <CircleIcon className={styles.circle}>
    <g>
      <path
        className={styles.white}
        d="M15.23,6.86c-0.48,0.31-0.49,0.99-0.44,1.56c0.35,4.29,0.74,8.58,1.17,12.86c0.04,0.37,0.08,0.75,0.3,1.04
        c0.36,0.47,1.02,0.53,1.61,0.55c0.48,0.02,0.95,0.04,1.43,0.06c0.2,0.01,0.42,0.01,0.6-0.09c0.3-0.17,0.38-0.55,0.43-0.89
        c0.59-4.3,0.84-8.65,0.75-12.99c-0.01-0.48-0.03-0.98-0.27-1.4C20.03,6.24,16.4,6.11,15.23,6.86z"
      />
      <path
        className={styles.white}
        d="M17.03,24.28c-0.15,0-0.31,0.01-0.44,0.08c-0.3,0.16-0.34,0.57-0.34,0.91c0,0.72-0.01,1.45-0.01,2.17
        c0,0.35,0.02,0.74,0.28,0.96c0.21,0.18,0.51,0.2,0.79,0.2c0.75,0.02,1.51,0.03,2.26,0.05c0.12,0,0.25,0,0.37-0.04
        c0.3-0.13,0.36-0.53,0.37-0.86c0.02-0.63,0.03-1.27,0.05-1.9c0.01-0.36,0.01-0.75-0.21-1.04C19.59,24.13,17.82,24.26,17.03,24.28z"
      />
      <path
        className={styles.iconGrey}
        d="M16.59,6.16l-0.25-0.39c-0.29,0.18-0.47,0.46-0.56,0.74c-0.09,0.28-0.12,0.55-0.12,0.81
         c0,0.15,0.01,0.3,0.02,0.44c0.35,4.29,0.75,8.58,1.17,12.87c0.04,0.37,0.07,0.84,0.39,1.28c0.26,0.34,0.62,0.52,0.96,0.61
         c0.35,0.09,0.69,0.11,0.99,0.13c0.48,0.02,0.95,0.04,1.43,0.06h0l0.18,0c0.18,0,0.42-0.02,0.66-0.14l0,0
         c0.27-0.15,0.43-0.4,0.51-0.61c0.09-0.22,0.12-0.44,0.15-0.62c0.51-3.76,0.77-7.56,0.77-11.36c0-0.57-0.01-1.13-0.02-1.7
         c-0.01-0.48-0.02-1.07-0.33-1.62c-0.33-0.56-0.93-0.88-1.59-1.09c-0.66-0.21-1.42-0.3-2.15-0.3c-0.96,0.01-1.86,0.15-2.47,0.53
         L16.59,6.16l0.25,0.39c0.32-0.21,1.13-0.39,1.98-0.38c0.65,0,1.33,0.09,1.87,0.26c0.55,0.17,0.94,0.44,1.07,0.68
         c0.17,0.28,0.2,0.7,0.21,1.18c0.01,0.56,0.02,1.12,0.02,1.68c0,3.76-0.26,7.51-0.76,11.24c-0.02,0.16-0.05,0.31-0.09,0.4
         c-0.04,0.1-0.08,0.13-0.1,0.15l0,0c-0.02,0.01-0.09,0.03-0.22,0.03l-0.15,0h0c-0.47-0.02-0.95-0.04-1.43-0.06
         c-0.28-0.01-0.57-0.03-0.79-0.09c-0.23-0.06-0.37-0.15-0.47-0.28c-0.12-0.15-0.18-0.45-0.21-0.81c-0.43-4.28-0.82-8.57-1.17-12.86
         c-0.01-0.12-0.02-0.25-0.02-0.36c0-0.2,0.02-0.39,0.07-0.52c0.05-0.13,0.1-0.2,0.18-0.26L16.59,6.16z"
      />
      <path
        className={styles.iconGrey}
        d="M18.39,23.58l-0.01-0.46c-0.15,0.01-0.39,0-0.65,0.14l0,0c-0.29,0.16-0.44,0.43-0.5,0.67
        c-0.07,0.24-0.07,0.46-0.08,0.65c0,0.72-0.01,1.45-0.01,2.17l0,0.04c0,0.18,0.01,0.39,0.06,0.61c0.05,0.22,0.16,0.47,0.38,0.66
        c0.19,0.16,0.4,0.23,0.58,0.27c0.19,0.04,0.36,0.04,0.5,0.04c0.75,0.02,1.51,0.03,2.26,0.05H21c0.12,0,0.28,0,0.46-0.08l0.01,0
        c0.31-0.13,0.48-0.41,0.55-0.63c0.08-0.23,0.09-0.45,0.1-0.64c0.02-0.63,0.03-1.27,0.05-1.9l0-0.19c0-0.33-0.02-0.75-0.31-1.14
        c-0.3-0.38-0.74-0.53-1.17-0.63c-0.44-0.09-0.91-0.11-1.33-0.11c-0.38,0-0.73,0.02-0.96,0.02L18.39,23.58l0.01,0.46
        c0.25-0.01,0.58-0.02,0.94-0.02c0.39,0,0.81,0.02,1.15,0.09c0.34,0.07,0.57,0.19,0.63,0.29c0.08,0.09,0.12,0.29,0.12,0.57l0,0.17
        c-0.02,0.63-0.03,1.27-0.05,1.9c0,0.14-0.02,0.29-0.05,0.37l-0.03,0.07l0,0l0,0l0,0l0,0l0,0l0,0l0.18,0.42l-0.18-0.43L21,27.5
        h-0.07c-0.75-0.02-1.5-0.03-2.26-0.05c-0.13,0-0.25-0.01-0.34-0.03c-0.09-0.02-0.13-0.04-0.16-0.06c-0.03-0.03-0.06-0.07-0.09-0.17
        c-0.02-0.1-0.03-0.25-0.03-0.4l0-0.03c0-0.73,0.01-1.45,0.01-2.17c0-0.15,0.01-0.31,0.04-0.4c0.03-0.1,0.05-0.11,0.06-0.11l0,0
        c0-0.01,0.09-0.03,0.24-0.03L18.39,23.58z"
      />
    </g>
  </CircleIcon>
);

export default ReportCommentIcon;
