import React, { memo } from 'react';
import { string } from 'prop-types';

import styles from './Underline.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const Underline = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20 20 1065 15"
    width="100%"
    preserveAspectRatio="none"
  >
    <path
      className={styles.path}
      d="M332.61475,30.5034c-103.0049,0-206.87842-0.96191-311.16992-3.62988
      c-0.27588-0.00684-0.49414-0.23633-0.4873-0.5127c0.00684-0.27539,0.24268-0.51172,0.5127-0.4873
      c241.29152,6.17285,480.36572,3.20996,711.56982,0.3418c114.17969-1.41504,232.24707-2.87988,350.36328-3.26465
      c0.00098,0,0.00195,0,0.00195,0c0.27539,0,0.49902,0.22266,0.5,0.49805c0.00098,0.27637-0.22168,0.50098-0.49805,0.50195
      c-118.1123,0.38477-236.17773,1.84961-350.35449,3.26465C601.76758,28.84324,467.94189,30.5034,332.61475,30.5034z"
    />
  </svg>
);

Underline.propTypes = propTypes;
Underline.defaultProps = defaultProps;

export default memo(Underline);
