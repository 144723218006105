import React from 'react';

import Dialog from '../dialog/Dialog';
import { useAuth } from '../../context/Auth';

export type Props = {
  active: boolean;
  handleClose: () => void;
  message: string;
  title?: string;
  confirmActionLabel?: string;
  preventClose?: boolean;
};

const LoginDialog = ({
  active,
  message,
  title = 'Do you want to login?',
  confirmActionLabel = 'Login',
  handleClose,
  preventClose,
}: Props) => {
  const keycloak = useAuth();

  const redirectToLogin = () => {
    // adds another entry for further to browser history to counteract keycloak
    // overwriting history - duplicated from signUpHandler
    window.history.pushState(null, document.title, window.location.href);
    if (keycloak) {
      keycloak.login();
    }
  };

  return (
    <Dialog
      confirmAction={{
        disabled: false,
        label: confirmActionLabel,
        onClick: () => redirectToLogin(),
      }}
      isLoading={preventClose}
      active={active}
      title={title}
      size="small"
      handleClose={() => handleClose()}
      description={message}
    />
  );
};

export default LoginDialog;
