import React, { PureComponent } from 'react';
import { func, shape, arrayOf, string } from 'prop-types';
import cx from 'classnames';

import styles from './Notifications.module.css';
import Button from '../atoms/button/Button';
import NotificationFlyout from '../notificationFlyout/NotificationFlyout';
import BellIcon from '../bellIcon/BellIcon';
import dedupeNotifications from '../../utils/dedupeNotifications';

class Notifications extends PureComponent {
  static propTypes = {
    notifications: arrayOf(shape({})),
    readNotifications: func.isRequired,
    lastRead: string,
    className: string,
  };

  static defaultProps = {
    notifications: [],
    lastRead: null,
    className: '',
  };

  state = {
    isFlyoutOpened: false,
  };

  componentDidUpdate(_, { isFlyoutOpened: wasFlyoutOpened }) {
    const { readNotifications } = this.props;
    const { isFlyoutOpened } = this.state;

    // mark read when closing to prevent merging reads with unreads while open
    if (wasFlyoutOpened && !isFlyoutOpened) {
      readNotifications();
    }
  }

  componentWillUnmount = () => {
    document.body.removeEventListener('click', this.hideNotifications);
  };

  hideNotifications = (event) => {
    document.body.removeEventListener('click', this.hideNotifications);
    this.setState({ isFlyoutOpened: false });
    if (event.target.closest('[class^="Notifications"]')) {
      event.stopPropagation();
    }
  };

  handleBellClick = (event) => {
    if (!this.state.isFlyoutOpened) {
      document.body.addEventListener('click', this.hideNotifications);
      this.setState({ isFlyoutOpened: true });
      event.stopPropagation();
    }
  };

  render() {
    const { notifications, lastRead, className } = this.props;
    const { isFlyoutOpened } = this.state;

    const lastReadDate = new Date(lastRead);
    const readMarkedNotifs = notifications.map((n) => ({
      ...n,
      isUnread: new Date(n.updatedAt) > lastReadDate,
    }));

    const dedupedNotifs = dedupeNotifications(readMarkedNotifs);
    const sortedNotifs = dedupedNotifs.sort((a, b) => {
      // unreads before reads, then sorted by updatedAt
      if (a.isUnread === b.isUnread) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      }
      return a.isUnread ? -1 : 1;
    });

    // we don't count dupe notifications multiple times
    const unreadCount = sortedNotifs.reduce(
      (count, { isUnread }) => (isUnread ? count + 1 : count),
      0
    );

    return (
      <div className={cx(styles.notificationContainer, className)}>
        <Button
          unstyled
          className={styles.bellIcon}
          role="menuitem"
          type="button"
          onClick={this.handleBellClick}
          aria-label="notifications"
          aria-haspopup
        >
          <BellIcon unreadCount={unreadCount} />
        </Button>
        {isFlyoutOpened && (
          <NotificationFlyout
            unreadCount={unreadCount}
            notifications={sortedNotifs}
            handleClose={this.handleBellClick}
          />
        )}
      </div>
    );
  }
}

export default Notifications;
