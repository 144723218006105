import React, { memo } from 'react';

import styles from './ClipIcon.module.css';

export type Props = {
  className?: string;
};

const ClipIcon = ({ className }: Props) => (
  <svg viewBox="0 0 21.25 22.07" className={className}>
    <path
      className={styles.clip}
      d="M14.46,7.982c-1.43,2.16-2.83,3.93-4.23,5.77c-0.44,0.56-0.97,1.05-1.57,1.43
		c-0.56,0.4-1.33,0.36-1.85-0.09c-1.06-1.12,0.25-2.65,0.89-3.48c1.5-1.95,2.73-3.2,4.21-5.12c1.16-1.5,3.31-4.26,5.07-3.32
		c1.21,0.59,1.71,2.05,1.12,3.27c-0.04,0.08-0.08,0.15-0.12,0.22c-0.84,1.58-6.89,9.3-7.62,10.28c-0.64,0.93-1.57,1.61-2.64,1.95
		c-1.21,0.4-2.54,0.17-3.55-0.62c-1.38-1.1-1.66-3.08-0.64-4.52c2-3.19,4.29-6.2,6.83-8.98"
    />
  </svg>
);

export default memo(ClipIcon);
