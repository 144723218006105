import React, { memo, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';

import { GetRealmMenu } from './types/GetRealmMenu';
import styles from './RealmMenu.module.css';
import Image from '../atoms/image/Image';
import Mask from '../../assets/images/multi-check-box.svg';
import MaskedDiv from '../maskedDiv/MaskedDiv';
import UpIcon from '../upIcon/UpIcon';
import PtProgress from '../ptProgress/PtProgress';
import { openRealm, useRealm } from '../../context/Realm';
import Button from '../atoms/button/Button';

const GET_REALM_MENU = gql`
  query GetRealmMenu {
    realms {
      __typename
      id
      name
      primary
      priority
      thumbnailFile {
        __typename
        id
        url
      }
    }
  }
`;

const RealmMenuItem = ({
  name,
  imageUrl,
  onClick,
}: {
  name: string;
  imageUrl: string;
  onClick?: () => void;
}) => {
  return (
    <Button
      unstyled
      className={cx(styles.item)}
      role="menuitem"
      aria-label={name}
      data-testid={`realm-menu-item-${name}`}
      onClick={onClick}
    >
      <MaskedDiv mask={`url(${Mask})`} className={styles.mask}>
        {imageUrl ? (
          <Image
            src={imageUrl}
            alt={name}
            size={null}
            className={styles.image}
          />
        ) : (
          <span>{name}</span>
        )}
      </MaskedDiv>
    </Button>
  );
};

export type Props = {
  className?: string;
};

const RealmMenu = ({ className }: Props) => {
  const {
    isRealmLoading,
    realmName,
    secondaryRealmName,
    setRealmName,
  } = useRealm();

  const currentRealm = secondaryRealmName || realmName;

  const { data: { realms: foundRealms } = {}, loading } = useQuery<
    GetRealmMenu
  >(GET_REALM_MENU);

  const realms = useMemo(() => {
    // show primary realms and the current realm
    const visibleRealms = foundRealms?.filter(
      ({ name, primary }) => primary || name === currentRealm
    );

    // if there isn't a pi-top realm we'll show the openRealm instead
    const includeOpenRealm = !visibleRealms?.some(
      ({ name }) => name === 'pi-top'
    );

    return [
      ...(includeOpenRealm ? [openRealm] : []),
      ...(visibleRealms || []),
    ].sort((a, b) => b.priority - a.priority);
  }, [foundRealms, currentRealm]);

  const realm = useMemo(() => {
    return realms?.find((r) => r.name === currentRealm) || openRealm;
  }, [currentRealm, realms]);

  return (
    <div className={cx(styles.root, className)} aria-label="realm menu">
      <div role="menubar" aria-label="Realm menu" className={cx(styles.menu)}>
        {isRealmLoading || loading ? (
          <PtProgress className={styles.loading} />
        ) : (
          <>
            <div
              data-testid="active-realm"
              className={styles.selectedContainer}
            >
              <RealmMenuItem
                name={realm.name}
                imageUrl={realm.thumbnailFile?.url || ''}
              />
              <UpIcon className={styles.arrow} />
            </div>

            <MaskedDiv mask={`url(${Mask})`}>
              {realms
                .filter(({ name }) => name !== realm.name)
                .map(({ name, thumbnailFile }) => (
                  <RealmMenuItem
                    key={name}
                    name={name}
                    imageUrl={thumbnailFile?.url || ''}
                    onClick={() => setRealmName(name)}
                  />
                ))}
            </MaskedDiv>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(RealmMenu);
