import React from 'react';
import cx from 'classnames';
import { Placement, PositioningStrategy } from '@popperjs/core';

import HelpButton from '../helpButton/HelpButton';
import styles from './HelpWidget.module.css';

import { useTooltip } from '../tooltip/Tooltip';
import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';

export type Props = {
  className?: string;
  message: React.ReactNode;
  tooltipPlacement?: Placement;
  tooltipStrategy?: PositioningStrategy;
  'aria-label'?: string;
};

const HelpWidget = ({
  className,
  message,
  tooltipPlacement,
  tooltipStrategy,
  'aria-label': ariaLabel = 'Help',
}: Props) => {
  const [tooltipActive, setTooltipActive] = React.useState(false);

  const ref = useOutsideClickHandler(() => setTooltipActive(false), {
    active: tooltipActive,
  });

  const { tooltip, setTooltipReferenceElement } = useTooltip({
    message: <div className={styles.messageWrapper}>{message}</div>,
    active: tooltipActive,
    placement: tooltipPlacement,
    strategy: tooltipStrategy,
  });

  return (
    <div ref={ref} className={cx(className)}>
      <HelpButton
        aria-label={ariaLabel}
        onClick={() => setTooltipActive(!tooltipActive)}
        buttonRef={setTooltipReferenceElement}
      />
      {tooltip}
    </div>
  );
};

export default HelpWidget;
