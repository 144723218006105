import Keycloak from 'keycloak-js';

import config from './config.json';
import { keycloakBaseUrl } from '../services/apiBaseUrl';

const keycloak = new Keycloak({
  ...config,
  url: keycloakBaseUrl,
});

export const ready = new Promise((resolve) => (keycloak.onReady = resolve));

export default keycloak;
