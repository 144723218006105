import { InMemoryCache } from '@apollo/client/cache';
import keycloak from '../keycloak';

/*
  Use type policies to specify if variables passed to queries can be used to
  fetch the data from the cache. e.g. when the block query is used, lookup the
  block from the cache using the id argument.
*/

export default () =>
  new InMemoryCache({
    typePolicies: {
      CoderConnectionEvent: {
        keyFields: ['timestamp', 'type'],
      },
      Query: {
        fields: {
          myAccount(_, { toReference }) {
            const { tokenParsed } = keycloak;
            const id = tokenParsed?.sub;

            return toReference({
              __typename: 'Account',
              id,
            });
          },
          school(_, { args, toReference }) {
            return toReference({
              __typename: 'School',
              id: args?.id,
            });
          },
          block(_, { args, toReference }) {
            return toReference({
              __typename: 'Block',
              id: args?.id,
            });
          },
          course(_, { args, toReference }) {
            return toReference({
              __typename: 'Course',
              id: args?.id,
            });
          },
          section(_, { args, toReference }) {
            return toReference({
              __typename: 'Section',
              id: args?.id,
            });
          },
          submission(_, { args, toReference }) {
            return toReference({
              __typename: 'Submission',
              id: args?.id,
            });
          },
          task(_, { args, toReference }) {
            return toReference({
              __typename: 'Task',
              id: args?.id,
            });
          },
          taskResponse(_, { args, toReference }) {
            return toReference({
              __typename: 'TaskResponse',
              id: args?.id,
            });
          },
          group(_, { args, toReference }) {
            return toReference({
              __typename: 'Group',
              id: args?.id,
            });
          },
        },
      },
      ProjectVersion: {
        fields: {
          sections: {
            merge: false,
          },
        },
      },
    },
  });
