import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { ColumnDef } from '@tanstack/react-table';
import cx from 'classnames';

import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';

import { errorDialogMessage } from '../../strings';
import styles from './AddSchoolContentAccessDialog.module.css';
import logException from '../../services/logging/exception';
import {
  AddSchoolContentAccess,
  AddSchoolContentAccessVariables,
} from './types/AddSchoolContentAccess';
import MultiSelectTable from '../multiSelectTable/MultiSelectTable';
import { Header } from '../multiSelectTable/Header';
import { TextCell } from '../multiSelectTable/TextCell';

export const ADD_SCHOOL_CONTENT_ACCESS = gql`
  mutation AddSchoolContentAccess(
    $schoolId: String!
    $schoolMemberIds: [String!]!
    $contentAccessIds: [String!]!
  ) {
    addSchoolContentAccess(
      id: $schoolId
      schoolMemberIds: $schoolMemberIds
      contentAccessIds: $contentAccessIds
    ) {
      __typename
      id
      schoolMembers {
        id
        __typename
        missingContentAccess {
          __typename
          id
        }
      }
      schoolContentAccess {
        __typename
        id
        schoolMembers {
          __typename
          id
        }
      }
    }
  }
`;

type ContentAccess = {
  id: string;
  realm: { name: string } | null;
  course: { title: string } | null;
  schoolMembers: { id: string }[];
  numBuildingLicenses: number;
  maxSeats: number;
};

type ContentAccessRow = {
  id: string;
  content: string;
  seatsAvailable: number;
  seatsNeeded: number;
  message?: string;
  errorMessage?: string;
};

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'size' | 'title' | 'description' | 'handleClose'
> & {
  schoolId: string;
  selectedMembers: { id: string; contentAccess: ContentAccess[] }[];
  schoolContentAccess: ContentAccess[];
  handleClose: () => void;
};

const AddSchoolContentAccessDialog = ({
  schoolId,
  selectedMembers,
  schoolContentAccess,
  ...dialogProps
}: Props) => {
  const [selectedContentAccesses, setSelectedContentAccesses] = React.useState<
    ContentAccessRow[]
  >([]);

  const [
    addContentAccess,
    { loading: addingContentAccess, error: addContentAccessError },
  ] = useMutation<AddSchoolContentAccess, AddSchoolContentAccessVariables>(
    ADD_SCHOOL_CONTENT_ACCESS,
    {
      onError: (err) => logException(err),
      onCompleted: () => dialogProps.handleClose(),
    }
  );

  const contentAccessRows = React.useMemo<ContentAccessRow[]>(
    () =>
      schoolContentAccess.map((contentAccess) => {
        const rowData: ContentAccessRow = {
          id: contentAccess.id,
          content: 'Unknown',
          seatsAvailable:
            contentAccess.numBuildingLicenses > 0
              ? Infinity
              : contentAccess.maxSeats - contentAccess.schoolMembers.length,
          seatsNeeded:
            contentAccess.numBuildingLicenses > 0
              ? 0
              : selectedMembers.filter(
                  (member) =>
                    !member.contentAccess.some(
                      ({ id }) => id === contentAccess.id
                    )
                ).length,
        };

        if (contentAccess.realm) {
          rowData.content = `All ${contentAccess.realm.name} content`;
        }
        if (contentAccess.course) {
          rowData.content = `${contentAccess.course.title} course`;
        }
        if (rowData.seatsNeeded === 0) {
          rowData.message = 'Access already granted';
        }

        const remainingSeats = rowData.seatsAvailable - rowData.seatsNeeded;
        if (remainingSeats < 0) {
          rowData.errorMessage = `${-remainingSeats} more ${
            remainingSeats === -1 ? 'seat' : 'seats'
          } required`;
        }

        return rowData;
      }),
    [schoolContentAccess, selectedMembers]
  );

  const columns = React.useMemo<ColumnDef<ContentAccessRow>[]>(
    () => [
      {
        accessorKey: 'content',
        header: (header) => (
          <Header
            key="content"
            name="Content"
            header={header}
            filterType="search"
            className={styles.contentColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="content"
            info={info}
            className={styles.contentColumn}
          />
        ),
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        id: 'seatsAvailable',
        accessorFn: (row) =>
          Number.isFinite(row.seatsAvailable)
            ? row.seatsAvailable.toFixed(0)
            : 'Unlimited',
        header: (header) => (
          <Header
            key="seats-available"
            name="Seats Available"
            header={header}
            className={styles.seatsRemainingColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="seats-available"
            info={info}
            className={styles.seatsRemainingColumn}
          />
        ),
        enableSorting: false,
      },
      {
        id: 'seatsNeeded',
        accessorFn: (row) => row.seatsNeeded.toFixed(0),
        header: (header) => (
          <Header
            key="seats-needed"
            name="Seats Needed"
            header={header}
            className={styles.seatsNeededColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="seats-needed"
            info={info}
            className={styles.seatsNeededColumn}
          />
        ),
        enableSorting: false,
      },
      {
        id: 'note',
        accessorFn: (row) => row.message || row.errorMessage || '',
        header: () => <th className={styles.notesColumn} />,
        cell: (info) => {
          return (
            <TextCell
              key="notes"
              info={info}
              className={cx(styles.notesColumn, {
                [styles.errorMessage]: !!info.row.original.errorMessage,
              })}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Dialog
      {...dialogProps}
      title="Grant Content Access to School Members"
      description={`This will give <strong>${selectedMembers.length} selected members</strong> access to chosen content`}
      isLoading={addingContentAccess}
      confirmAction={{
        label: 'Confirm',
        onClick: () =>
          addContentAccess({
            variables: {
              schoolId,
              schoolMemberIds: selectedMembers.map((member) => member.id),
              contentAccessIds: selectedContentAccesses.map(
                (contentAccess) => contentAccess.id
              ),
            },
          }),
        disabled: addingContentAccess || selectedContentAccesses.length === 0,
      }}
      size="large"
      theme={{
        innerContainer: styles.dialogInnerContainer,
        bodyContainer: styles.dialogBodyContainer,
        description: styles.dialogDescription,
      }}
    >
      {!schoolContentAccess.length ? (
        <Placeholder message="Your school has not purchased any premium content" />
      ) : (
        <MultiSelectTable
          columns={columns}
          data={contentAccessRows}
          getRowId={(row) => row.id}
          onSelectedRowsChange={setSelectedContentAccesses}
          enableRowSelection={(row) =>
            Number.isFinite(row.original.seatsAvailable) &&
            row.original.seatsNeeded > 0 &&
            !row.original.errorMessage &&
            !addingContentAccess
          }
          className={styles.table}
          disabled={addingContentAccess}
        />
      )}

      {addingContentAccess && (
        <Placeholder
          isLoading={addingContentAccess}
          message="Granting content access..."
          className={styles.placeholder}
        />
      )}

      {!!addContentAccessError && (
        <span className={styles.errorMessage}>
          {errorDialogMessage(
            `granting content access: ${addContentAccessError.message}`
          )}
        </span>
      )}
    </Dialog>
  );
};

export default AddSchoolContentAccessDialog;
