import { gql } from '@apollo/client';

export default gql`
  query GetProjectsAndSectionsToGetImported($searchQuery: String) {
    getMyChallenges(search: $searchQuery) {
      id
      createdAt
      readerVersion {
        id
        title
        number
        thumbnail
        sections {
          id
          number
          title
          tasks {
            id
            number
            title
            type
          }
        }
        createdAt
        updatedAt
        isCollaboratorProject
        isPublicVersion
      }
    }
  }
`;
