import { ApolloLink, Operation, FetchResult, Observable } from '@apollo/client';
import { print, GraphQLError } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';

export default class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              sink.error(err);
            } else if (err instanceof CloseEvent) {
              sink.error(
                new Error(
                  // eslint-disable-next-line prefer-template
                  `Socket closed with event ${err.code}` + err.reason
                    ? `: ${err.reason}` // reason will be available on clean closes
                    : ''
                )
              );
            } else if (
              Array.isArray(err) &&
              err.length > 0 &&
              err.every((e) => e instanceof GraphQLError)
            ) {
              sink.error(
                new Error(
                  err.map(({ message }: GraphQLError) => message).join(', ')
                )
              );
            } else {
              sink.error(new Error('Socket error'));
            }
          },
        }
      );
    });
  }
}
