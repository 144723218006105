import React, { Component, ReactNode } from 'react';
import logException from '../../services/logging/exception';
import Error from '../error/Error';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props> {
  static getDerivedStateFromError(error: Error) {
    return { hasError: !!error };
  }

  state: State = {
    hasError: false,
  };

  componentDidCatch({ message, stack }: Error) {
    logException({
      message,
      stack,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Error />;
    }

    return children;
  }
}
