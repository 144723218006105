import { gql } from '@apollo/client';

export default gql`
  fragment GroupAdminFields on GroupAdmin {
    id
    isApproved
    createdAt
    updatedAt
    __typename
  }
`;
