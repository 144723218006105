import { gql } from '@apollo/client';

export default gql`
  fragment GroupFields on Group {
    id
    name
    thumbnail
    description
    createdAt
    updatedAt
    isCollaboratorGroup
    isPublic
    joinCode
    joinCodeExpiry
    isExternal
    externalId
    externalUrl
    __typename
  }
`;
