import { gql } from '@apollo/client';

export default gql`
  fragment NotificationFields on Notification {
    __typename
    id
    type
    createdAt
    updatedAt
    data
  }
`;
