import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import {
  GetSubmission,
  GetSubmissionVariables,
} from '../../graphql/queries/submissions/types/GetSubmission';
import { GET_SUBMISSION } from '../../graphql/queries/submissions';
import { buildChallengeUrl, buildSubmissionUrl } from '../../utils/urlBuilder';
import styles from './NotificationCard.module.css';

type Props = {
  submissionId: string;
};

const SubmissionLink = ({ submissionId }: Props) => {
  const { data, loading, error } = useQuery<
    GetSubmission,
    GetSubmissionVariables
  >(GET_SUBMISSION, { variables: { id: submissionId } });
  const { submission } = data || {};

  /*
  The backend only includes the submissionId in the notification so we need to
  fetch the submission to know the challengeId.  Use the submission url as a
  fallback until we update the notification on the backend.
  */
  const fallback = (
    <Link to={buildSubmissionUrl(submissionId)}>
      &quot;
      <span className={styles.unknownValue}>challenge name</span>
      &quot;
    </Link>
  );

  if (!submission || loading || error) {
    return fallback;
  }

  const { assignment, challenge } = submission;

  const version = assignment
    ? assignment.project?.readerVersion
    : challenge?.readerVersion;

  if (!challenge || !version) {
    return fallback;
  }

  return (
    <Link to={buildChallengeUrl(challenge.id, { submissionId })}>
      &quot;{version.title}&quot;
    </Link>
  );
};

export default SubmissionLink;
