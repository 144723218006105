import { gql } from '@apollo/client';

export default gql`
  fragment TaskResponseFields on TaskResponse {
    id
    taskCrossVersionId
    content
    createdAt
    updatedAt
    __typename
  }
`;
