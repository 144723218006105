import React, { useCallback, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { useAuth } from './Auth';
import { GetRealmContext } from './types/GetRealmContext';
import { libraryPath } from '../strings';
import { GetRealmMenu_realms } from '../components/realmMenu/types/GetRealmMenu';
import usePrevious from '../hooks/usePrevious';

export type RealmContextValue = {
  isRealmLoading: boolean; // when realm list or auth are loading
  realmName: string | null; // empty string for 'openRealm'
  secondaryRealmName: string; // empty string means unset
  setRealmName: (name: string) => void; // ignored if loading or invalid, can redirect on explore
};

export const defaultRealmName = 'pi-top';

export const openRealm: GetRealmMenu_realms = {
  name: '', // the 'openRealm' name is empty string
  __typename: 'Realm',
  id: 'default-realm',
  primary: true,
  priority: 0,
  thumbnailFile: {
    url: 'https://static.pi-top.com/images/logo/pi-top_black.png',
    __typename: 'GCSFile',
    id: 'default-realm-thumbnail',
  },
};

const RealmContext = React.createContext<RealmContextValue | undefined>(
  undefined
);

const GET_REALM_CONTEXT = gql`
  query GetRealmContext {
    realms {
      __typename
      id
      name
      primary
    }
  }
`;

export const currentRealmKey = 'currentRealm';
export const unauthenticatedKey = 'unauthenticated';

const getPersistedRealms = () => {
  return JSON.parse(localStorage.getItem(currentRealmKey) || '{}');
};

const setPersistedRealms = (persistedRealms: Record<string, string>) => {
  return localStorage.setItem(currentRealmKey, JSON.stringify(persistedRealms));
};

const getPersistedRealm = (userKey: string) => {
  try {
    return getPersistedRealms()[userKey];
  } catch (e) {
    // bad data in local storage
    return null;
  }
};

const addPersistedRealm = (userKey: string, realmName: string) => {
  const persistedRealms = getPersistedRealms();
  persistedRealms[userKey] = realmName;
  setPersistedRealms(persistedRealms);
};

export type Props = {
  children: React.ReactNode;
};

function RealmContextProvider({ children }: Props) {
  const location = useLocation();

  // can't use params context is above the explore route
  const realmParam = useMemo(() => {
    const realmParamRegex = /\/library\/([^/]+)/; // /explore/ capture anything-except-slash
    const match = location.pathname.match(realmParamRegex);
    return decodeURI(match?.[1] || '');
  }, [location.pathname]);

  const {
    tokenParsed: { sub: userKey = unauthenticatedKey } = {},
    isReady: isAuthReady,
  } = useAuth();

  const { data: { realms } = {}, loading } = useQuery<GetRealmContext>(
    GET_REALM_CONTEXT
  );

  const validRealms = useMemo(() => {
    return [openRealm, ...(realms || [])];
  }, [realms]);

  const validRealmNames = useMemo(() => {
    return validRealms.map(({ name }) => name);
  }, [validRealms]);

  const [realmName, setName] = React.useState<RealmContextValue['realmName']>(
    null
  );

  // secondary (when primary bool is not true) realms can be set temporarily by
  // explore param but are not persisted and are reset on page load
  const [secondaryRealmName, setSecondaryRealmName] = React.useState<
    RealmContextValue['secondaryRealmName']
  >('');

  const isLoading = useMemo(() => {
    return !isAuthReady || loading;
  }, [isAuthReady, loading]);

  // this is used to ensure the initial realm setting effect runs first
  const [initialized, setInitialized] = React.useState<boolean>(false);

  const isRealmLoading = useMemo(() => {
    return isLoading || !initialized;
  }, [isLoading, initialized]);

  // setRealmName can only set primary realm, which will unset secondary realm
  const setRealmName = useCallback(
    (name: string) => {
      const foundRealm = validRealms?.find(({ name: n }) => n === name);
      if (!foundRealm || !foundRealm.primary) {
        return;
      }

      if (secondaryRealmName) {
        setSecondaryRealmName('');
      }

      if (name !== realmName) {
        setName(foundRealm.name);
      }
    },
    [validRealms, realmName, secondaryRealmName]
  );

  // once loading is done, set state from url param, or persisted, or default
  useEffect(() => {
    if (initialized || isLoading) {
      return;
    }

    // setInitialised should ideally come at the end of this effect but this
    // works fine and is simpler to allow early returns
    setInitialized(true);

    // if a realm name was set already by setRealmName, use that
    if (realmName) {
      return;
    }

    const foundParamRealm = validRealms?.find(
      ({ name: n }) => n === realmParam
    );
    if (realmParam && foundParamRealm) {
      if (foundParamRealm.primary) {
        return setName(foundParamRealm.name);
      }
      // if param is a secondary realm, continue to check persisted realm
      setSecondaryRealmName(foundParamRealm.name);
    }

    const persistedRealm = getPersistedRealm(userKey);
    const foundPersistedRealm = validRealms?.find(
      ({ name: n }) => n === persistedRealm
    );
    if (persistedRealm && foundPersistedRealm && foundPersistedRealm.primary) {
      return setName(persistedRealm);
    }

    if (validRealmNames.includes(defaultRealmName)) {
      return setName(defaultRealmName);
    }

    return setName(openRealm.name);
  }, [
    initialized,
    isLoading,
    realmName,
    realmParam,
    validRealms,
    validRealmNames,
    userKey,
  ]);

  // when state changes, set local storage
  const previousRealmName = usePrevious(realmName);
  useEffect(() => {
    const persistedRealm = getPersistedRealm(userKey);
    if (
      !isRealmLoading &&
      previousRealmName !== realmName &&
      persistedRealm !== realmName
    ) {
      addPersistedRealm(userKey, realmName || openRealm.name);
    }
  }, [isRealmLoading, previousRealmName, realmName, userKey]);

  const previousPathname = usePrevious(location.pathname);

  // when chaning page away from explore unset secondary realm
  useEffect(() => {
    if (
      previousPathname?.startsWith(`/${libraryPath}`) &&
      !location.pathname.startsWith(`/${libraryPath}`)
    ) {
      setSecondaryRealmName('');
    }
  }, [previousPathname, location.pathname]);

  // when navigating to explore from elsewhere, use the realm param if there is one
  useEffect(() => {
    if (
      location.pathname.startsWith(`/${libraryPath}`) &&
      typeof previousPathname !== 'undefined' &&
      previousPathname !== location.pathname
    ) {
      if (!realmParam) {
        return;
      }

      const foundParamRealm = validRealms?.find(
        ({ name: n }) => n === realmParam
      );

      if (foundParamRealm && !foundParamRealm.primary) {
        return setSecondaryRealmName(foundParamRealm.name);
      }

      if (foundParamRealm && foundParamRealm.primary) {
        setSecondaryRealmName('');
        return setName(foundParamRealm.name);
      }
    }
  }, [
    userKey,
    setRealmName,
    location.pathname,
    previousPathname,
    realmParam,
    realmName,
    validRealms,
    location,
  ]);

  // when local storage changes in background, set state
  useEffect(() => {
    const loadLocalStorage = () => {
      const persistedRealm = getPersistedRealm(userKey);
      // ignore if tab active
      if (document.visibilityState !== 'hidden') {
        return;
      }
      if (
        persistedRealm !== realmName &&
        validRealmNames.includes(persistedRealm)
      ) {
        setName(persistedRealm);
      }
    };

    window.addEventListener('storage', loadLocalStorage);
    return () => window.removeEventListener('storage', loadLocalStorage);
  }, [userKey, realmName, validRealmNames]);

  return (
    <>
      <RealmContext.Provider
        value={{
          isRealmLoading,
          realmName,
          secondaryRealmName,
          setRealmName,
        }}
      >
        {children}
      </RealmContext.Provider>
    </>
  );
}

function useRealm() {
  const context = React.useContext(RealmContext);
  if (context === undefined) {
    throw new Error('useRealm must be used within a RealmProvider');
  }
  return context;
}

type CallableChild = {
  children: (context: RealmContextValue) => React.ReactNode;
};

function RealmContextConsumer({ children }: CallableChild) {
  return (
    <RealmContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error('RealmConsumer must be used within a RealmProvider!');
        }
        return children(context);
      }}
    </RealmContext.Consumer>
  );
}

export { RealmContextProvider, RealmContextConsumer, useRealm };
