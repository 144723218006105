import React, { memo } from 'react';

import styles from './PreviewIcon.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  theme?: Partial<Theme>;
};

const PublishIcon = ({ theme: customTheme }: Props) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      viewBox="0 0 45 36"
      xmlns="http://www.w3.org/2000/svg"
      className={theme.root}
    >
      <g transform="translate(0 -5)">
        <path
          className={theme.icon}
          d="M 32.455 25.856 C 36.345 30.456 37.035 29.886 40.875 34.116"
        />
        <path
          className={theme.icon}
          d="M 18.805 15.256 C 17.815 17.076 17.375 19.156 17.545 21.226 C 17.705 24.056 18.935 25.736 22.315 27.166 C 23.865 27.646 25.485 27.886 27.115 27.876 C 29.115 27.986 36.005 25.846 35.185 19.696 C 34.965 18.056 35.015 16.316 34.045 14.976 C 32.765 13.456 30.985 12.436 29.025 12.096 C 25.395 11.236 20.915 11.656 18.805 15.256 Z"
        />
      </g>
    </svg>
  );
};

export default memo(PublishIcon);
