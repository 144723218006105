import React, { memo } from 'react';

import styles from './CrossIcon.module.css';

export type Props = {
  className?: string;
};

const CrossIcon = ({ className }: Props) => (
  <svg viewBox="0 0 15.62 19.89" className={className} data-testid="cross-icon">
    <g transform="translate(13.462 54.586) rotate(90)">
      <path
        className={styles.path}
        d="M-50.46,11.68c4.39-3.5,8.3-7.55,11.64-12.06"
      />
      <path
        className={styles.path}
        d="M-50.67-0.17c3.7,4.2,7.73,8.09,12.06,11.65"
      />
    </g>
  </svg>
);

export default memo(CrossIcon);
