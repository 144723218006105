import { isEqual } from 'lodash';

export const removeFromArray = <Value>(array: Value[], index: number) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const insertIntoArray = <Value>(
  array: Value[],
  index: number,
  item: Value
) => [...array.slice(0, index), item, ...array.slice(index)];

export const moveInArray = <Value>(
  array: Value[],
  sourceIndex: number,
  targetIndex: number
) => {
  const item = array[sourceIndex];
  const delta = sourceIndex < targetIndex ? -1 : 0;

  return insertIntoArray(
    removeFromArray(array, sourceIndex),
    targetIndex + delta,
    item
  );
};

export const isSameSet = (
  first: Iterable<unknown>,
  second: Iterable<unknown>
) => isEqual(new Set(first), new Set(second));
