import React, { memo, useState } from 'react';
import Dialog, { Props as DialogProps } from '../dialog/Dialog';

import styles from './InputDialog.module.css';
import PtProgress from '../ptProgress/PtProgress';

import Input from '../atoms/input/Input';
import { errorDialogMessage } from '../../strings';

export type Props = Omit<DialogProps, 'confirmAction' | 'size'> & {
  confirmAction?: Omit<DialogProps['confirmAction'], 'form' | 'type'>;
  inputProps?: Omit<
    React.ComponentProps<typeof Input>,
    | 'onChange'
    | 'controlled'
    | 'controlledValue'
    | 'bordered'
    | 'disabled'
    | 'className'
  >;
  onConfirm: (value: string) => void;
  formId?: string;
  initialValue?: string;
  errorMessage?: string;
};

const InputDialog = ({
  onConfirm,
  formId = 'input-dialog-form',
  initialValue = '',
  errorMessage,
  inputProps = {},
  ...dialogProps
}: Props) => {
  const [value, setValue] = useState(initialValue);

  return (
    <Dialog
      {...dialogProps}
      confirmAction={{
        label: 'Confirm',
        disabled: dialogProps.isLoading,
        ...dialogProps.confirmAction,
        type: 'submit',
        form: formId,
      }}
      size="small"
    >
      <form
        id={formId}
        onSubmit={(event) => {
          event.preventDefault();
          onConfirm(value);
        }}
      >
        <Input
          minLength={1}
          maxLength={128}
          required
          autoFocus
          {...inputProps}
          bordered
          controlled
          controlledValue={value}
          onChange={setValue}
          className={styles.renameInput}
          disabled={dialogProps.isLoading}
        />

        {dialogProps.isLoading && <PtProgress />}

        {!!errorMessage && (
          <span className={styles.errorMessage}>
            {errorDialogMessage(errorMessage)}
          </span>
        )}
      </form>
    </Dialog>
  );
};

export default memo(InputDialog);
