import * as types from '../../actions/commonStandards/actionTypes';

export default (state = {}, action) => {
  if (action.type === types.FETCH_STANDARDS_SUCCESS) {
    const { data, page } = action.payload;

    return {
      ...state,
      [page]: data,
    };
  }

  return state;
};
