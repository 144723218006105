import React, { memo } from 'react';
import styles from './CloseIcon.module.css';

const CloseIcon = () => (
  <g>
    <g>
      <path
        className={styles.path}
        d="M19.46035,18.58733c-0.20459,0-0.40967-0.07812-0.56543-0.23389
			c-0.3125-0.3125-0.3125-0.81934-0.00049-1.13135l5.60449-5.60449c0.3125-0.3125,0.81836-0.31201,1.13086-0.00049
			c0.3125,0.3125,0.3125,0.81934,0,1.13135l-5.60352,5.60449C19.87002,18.5092,19.66494,18.58733,19.46035,18.58733z"
      />
      <path
        className={styles.path}
        d="M19.46035,18.58733c-0.20459,0-0.40967-0.07812-0.56592-0.23389l-5.60449-5.60449
			c-0.31201-0.3125-0.31201-0.81934,0-1.13184c0.3125-0.31152,0.81934-0.31152,1.13184,0l5.60449,5.60449
			c0.31201,0.3125,0.31201,0.81934,0,1.13184C19.87002,18.5092,19.66494,18.58733,19.46035,18.58733z"
      />
    </g>
    <g>
      <path
        className={styles.path}
        d="M13.85586,24.19133c-0.20459,0-0.40967-0.07812-0.56592-0.23438
			c-0.31201-0.3125-0.31201-0.81836,0.00049-1.13086l5.60449-5.60449c0.31152-0.31152,0.81885-0.31201,1.13135,0.00049
			c0.31201,0.31201,0.31201,0.81885-0.00049,1.13135l-5.60449,5.60352C14.26553,24.11321,14.06045,24.19133,13.85586,24.19133z"
      />
      <path
        className={styles.path}
        d="M25.06435,24.19133c-0.20508,0-0.40918-0.07812-0.56543-0.23438l-5.60449-5.60352
			c-0.31201-0.3125-0.31201-0.81934,0-1.13184c0.3125-0.31152,0.81934-0.31152,1.13184,0l5.60352,5.60449
			c0.3125,0.3125,0.3125,0.81836,0,1.13086C25.47353,24.11321,25.26943,24.19133,25.06435,24.19133z"
      />
    </g>
  </g>
);

export default memo(CloseIcon);
