/* eslint react/jsx-filename-extension:0 global-require: 0 */
import React from 'react';
import { createRoot } from 'react-dom/client';

import Root from './components/root/Root';
import './index.css';

const container = document.getElementById('root');
if (!container) {
  throw Error('Root element with id "root" could not be found');
}

createRoot(container).render(<Root />);
