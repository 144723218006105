import { gql } from '@apollo/client';
import DetailContentFrag from './detailContent';

export default gql`
  fragment TaskFields on Task {
    id
    title
    description
    detailContent {
      ...DetailContentFields
    }
    type
    createdAt
    updatedAt
    crossVersionId
    number
    __typename
  }

  ${DetailContentFrag}
`;
