import React, { memo } from 'react';

export type Props = {
  className?: string;
  title?: string;
};

const StarIcon = ({ className, title }: Props) => (
  <svg viewBox="0 0 36 36" className={className} data-testid="starIcon">
    {title && <title>{title}</title>}
    <path
      d="M34.8,14c-.07-.36-4.31-.13-9.74.34a1.76,1.76,0,0,1-1-.31C21.23,7.84,18.65,2.6,18.4,2.6S15.76,8.7,13.26,15a1.58,1.58,0,0,1-.71.61c-6,.69-11,1.37-11,1.6s3.64,2.33,8.32,5a.7.7,0,0,1,.24.52C9.24,25,8.46,26.84,8,27.8c-.8,1.8-3.4,8.4.8,5.4,2.73-2.25,5.59-4.37,8.47-6.46a1.21,1.21,0,0,1,.73,0c7.15,3.92,14,7.48,14.4,7.1s-2.32-6.59-5.34-13.31a.94.94,0,0,1,.14-.74C31.19,17.05,34.8,14.59,34.8,14Z"
      fill="transparent"
      stroke="#00ad9f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default memo(StarIcon);
