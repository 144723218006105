import React, { DetailedHTMLProps, memo } from 'react';
import type { HTMLProps } from 'react';
import cx from 'classnames';

import styles from './SearchBar.module.css';

type Props = DetailedHTMLProps<HTMLProps<HTMLInputElement>, HTMLInputElement>;

const SearchBar = ({ className, ...props }: Props) => (
  <input {...props} className={cx(styles.root, className)} />
);

export default memo(SearchBar);
