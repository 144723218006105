import React, { memo } from 'react';
import cx from 'classnames';

import Button, { Props as ButtonProps } from '../atoms/button/Button';
import UpIcon from '../upIcon/UpIcon';
import styles from './CollapseButton.module.css';

export type Props = Omit<ButtonProps, 'ref' | 'children'> & {
  collapsed: boolean;
};

const CollapseButton = ({ collapsed, className, ...props }: Props) => (
  <Button
    {...props}
    unstyled
    className={cx(styles.root, className, {
      [styles.collapsed]: collapsed,
    })}
  >
    <UpIcon className={cx(styles.arrow, { [styles.rotated]: collapsed })} />
  </Button>
);

export default memo(CollapseButton);
