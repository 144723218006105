import createService from '../createService';
import { apiBaseUrl } from '../apiBaseUrl';

export const createCheckout = createService({
  method: 'POST',
  baseUrl: apiBaseUrl,
  pathTemplate: '/stripe/create-checkout-session',
});

export const createPortal = createService({
  method: 'POST',
  baseUrl: apiBaseUrl,
  pathTemplate: '/stripe/create-portal-session',
});
