/* eslint babel/new-cap: 0 */

if (window && window.PT_ENV) {
  (() => {
    const appId = ['staging', 'prod'].includes(window.PT_ENV)
      ? 'x7tavu6w'
      : 'c8a0h0kb';
    window.intercomSettings = {
      app_id: appId,
    };

    if (typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator');
      window.Intercom('update', window.intercomSettings);
    } else {
      const intercom = (...args) => intercom.c(args);
      intercom.q = [];
      intercom.c = (args) => intercom.q.push(args);
      window.Intercom = intercom;

      const load = () => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(s, firstScript);
      };

      load();
    }
  })();
}
