export const reqTimeout = async (
  url: RequestInfo,
  timeoutms: number,
  options?: RequestInit
) => {
  return new Promise<Response>((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
      reject(new Error('Connect timeout'));
    }, timeoutms);

    fetch(url, { signal: controller.signal, ...options })
      .then((res) => {
        clearTimeout(timeout);
        if (!res.ok) {
          throw new Error(`HTTP error, status = ${res.status}`);
        }
        return resolve(res);
      })
      .catch(reject);
  });
};

export const checkStatus = async (url: RequestInfo, timeoutms: number) => {
  const res = await reqTimeout(url, timeoutms);
  return res.status === 200;
}
