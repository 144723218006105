import { gql } from '@apollo/client';

export default gql`
  mutation ActivateSchoolLicense($id: String!, $code: String!) {
    activateSchoolLicense(id: $id, code: $code) {
      __typename
      id
    }
  }
`;
