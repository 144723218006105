import React, { memo } from 'react';

import { string } from 'prop-types';
import cx from 'classnames';

import styles from './Logo.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const Logo = ({ className }) => <div className={cx(className, styles.logo)} />;

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default memo(Logo);
