import { gql } from '@apollo/client';

export default gql`
  fragment CommentFields on Comment {
    id
    content
    createdAt
    updatedAt
    canDelete
    pinningId
    __typename
  }
`;
