import React, { memo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import styles from './SchoolClasses.module.css';
import { Header } from '../multiSelectTable/Header';
import { TextCell } from '../multiSelectTable/TextCell';
import { TagsCell } from '../multiSelectTable/TagsCell';
import { groupsPath, schoolsPath } from '../../strings';
import { LinkCell } from '../multiSelectTable/LinkCell';
import MultiSelectTable from '../multiSelectTable/MultiSelectTable';
import ImportSchoolClassesDialog from '../importSchoolClassesDialog/ImportSchoolClassesDialog';
import AddSchoolClassDialog from '../addSchoolClassDialog/AddSchoolClassDialog';
import Button from '../atoms/button/Button';
import { AddIcon } from '../icons';
import DropdownMenu from '../dropdownMenu/DropdownMenu';

export type SchoolClass = {
  id: string;
  name: string;
  teachers: string[];
  numStudents: number;
  numAssignments: number;
};

export type Props = {
  school: { id: string; name: string };
  isSchoolEditor: boolean;
  classes: SchoolClass[];
  className?: string;
};

const SchoolClasses = ({
  school,
  isSchoolEditor,
  classes = [],
  className,
}: Props) => {
  const [isAddClassesDropdownOpen, setIsAddClassesDropdownOpen] = useState(
    false
  );
  const [
    isImportSchoolClassesDialogOpen,
    setIsImportSchoolClassesDialogOpen,
  ] = useState(false);
  const [isAddClassByNameDialogOpen, setIsAddClassByNameDialogOpen] = useState(
    false
  );

  const columns = React.useMemo<ColumnDef<SchoolClass>[]>(
    () => [
      {
        id: 'name',
        accessorFn: (schoolClass) => ({
          label: schoolClass.name,
          to: `/${schoolsPath}/${school.id}/${groupsPath}/${schoolClass.id}`,
        }),
        header: (header) => (
          <Header key="name" name="Name" header={header} filterType="search" />
        ),
        cell: (info) => <LinkCell key="name" info={info} />,
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        id: 'teachers',
        accessorFn: (schoolClass) =>
          schoolClass.teachers.map((t) => ({
            id: t,
            label: t,
            title: t,
          })),
        header: (header) => (
          <Header
            key="teachers"
            name="Teachers"
            header={header}
            filterType="filter"
            filterOptions={Array.from(
              new Set(classes.flatMap((c) => c.teachers))
            )}
          />
        ),
        cell: (info) => <TagsCell key="teachers" info={info} />,
        filterFn: 'tags',
        enableSorting: false,
      },
      {
        id: 'students',
        accessorFn: (schoolClass) => schoolClass.numStudents.toFixed(0),
        header: (header) => (
          <Header key="num-students" name="Students" header={header} />
        ),
        cell: (info) => <TextCell key="email" info={info} />,
        sortingFn: 'number',
      },
      {
        id: 'assignments',
        accessorFn: (schoolClass) => schoolClass.numAssignments.toFixed(0),
        header: (header) => (
          <Header key="num-assignments" name="Assignments" header={header} />
        ),
        cell: (info) => <TextCell key="role" info={info} />,
      },
      {
        id: 'view',
        accessorFn: (schoolClass) => ({
          label: 'View',
          to: `/${schoolsPath}/${school.id}/${groupsPath}/${schoolClass.id}`,
        }),
        header: () => <th className={styles.viewColumn} />,
        cell: (info) => (
          <LinkCell key="role" info={info} className={styles.viewColumn} />
        ),
      },
    ],
    [classes, school.id]
  );

  return (
    <div className={className}>
      {isSchoolEditor && (
        <div className={styles.tableActions}>
          <DropdownMenu
            isMenuOpened={isAddClassesDropdownOpen}
            button={
              <label className={styles.addClassesButton}>
                <Button
                  unstyled
                  onClick={() =>
                    setIsAddClassesDropdownOpen(!isAddClassesDropdownOpen)
                  }
                >
                  <AddIcon />
                </Button>
                Add Classes
              </label>
            }
            forceCloseMenu={() => setIsAddClassesDropdownOpen(false)}
            menuItems={[
              {
                type: 'button',
                content: 'Create Class',
                onClick: () => setIsAddClassByNameDialogOpen(true),
              },
              {
                type: 'button',
                content: 'Import Classes through SSO',
                onClick: () => setIsImportSchoolClassesDialogOpen(true),
              },
            ]}
            className={styles.addClassesDropdown}
          />
        </div>
      )}

      <MultiSelectTable
        columns={columns}
        data={classes}
        getRowId={(schoolClass) => schoolClass.id}
        className={className}
        disabled
      />

      {isImportSchoolClassesDialogOpen && (
        <ImportSchoolClassesDialog
          schools={[school]}
          handleClose={() => setIsImportSchoolClassesDialogOpen(false)}
        />
      )}

      {isAddClassByNameDialogOpen && (
        <AddSchoolClassDialog
          schools={[school]}
          handleClose={() => setIsAddClassByNameDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default memo(SchoolClasses);
