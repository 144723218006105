import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { string } from 'prop-types';

import { metaTitle } from '../../strings';

const propTypes = {
  title: string,
  description: string,
  thumbnail: string,
};

const defaultProps = {
  title: '',
  description: '',
  thumbnail: '',
};

const Meta = ({ title, description, thumbnail }) => (
  <Helmet>
    <title>
      {title ? `${title} | ` : ''}
      {metaTitle}
    </title>
    {description && <meta name="description" content={description} />}

    {title && <meta itemProp="name" content={title} />}
    {description && <meta itemProp="description" content={description} />}
    {thumbnail && <meta itemProp="image" content={thumbnail} />}

    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {thumbnail && <meta name="twitter:image:src" content={thumbnail} />}

    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {thumbnail && <meta property="og:image" content={thumbnail} />}
  </Helmet>
);

Meta.propTypes = propTypes;
Meta.defaultProps = defaultProps;
export default memo(Meta);
