/* eslint react-hooks/exhaustive-deps: warn */

import React, { memo, ReactElement, useRef } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import uuid from 'uuid-v4';

import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';
import Button, { Props as ButtonProps } from '../atoms/button/Button';
import MaskedDiv from '../maskedDiv/MaskedDiv';
import Mask from '../../assets/images/multi-check-box.svg';

import styles from './DropdownMenu.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type MenuItem = {
  content?: string | JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  type: 'button' | 'href' | 'link' | 'separator' | 'custom';
  url?: string;
  label?: string;
  key?: string;
  disabled?: boolean;
  className?: string;
};

type Props = {
  button: ReactElement<ButtonProps>;
  className?: string;
  isMenuOpened: boolean;
  menuClassName?: string;
  menuItems: MenuItem[];
  forceCloseMenu: () => void;
  dropDownLabel?: string;
  theme?: Partial<Theme>;
};

const DropdownMenu = ({
  button,
  className,
  isMenuOpened = false,
  menuClassName,
  menuItems,
  forceCloseMenu,
  dropDownLabel,
  theme: customTheme = {},
}: Props) => {
  const theme = useTheme(styles, customTheme);
  const buttonId = useRef(button.props.id || uuid()).current;

  const dropDownContainerRef = useOutsideClickHandler(() => {
    if (!isMenuOpened) return;
    forceCloseMenu();
  });

  const renderMenuItem = ({
    content,
    onClick,
    selected,
    type,
    url,
    label,
    key = undefined,
    className: menuItemClassName = '',
    disabled,
  }: MenuItem): JSX.Element | undefined => {
    if (type === 'separator') {
      return <hr key={`dropdown_separator_${uuid()}`} />;
    }

    const contentElement = (
      <MaskedDiv
        mask={`url(${Mask})`}
        className={cx(theme.mask, { [menuItemClassName]: type === 'custom' })}
        key={key}
      >
        {content}
      </MaskedDiv>
    );

    switch (type) {
      case 'button':
        return onClick ? (
          <Button
            className={cx(theme.item, theme.button, menuItemClassName, {
              [theme.selected]: selected,
            })}
            key={`menu_item_${type}_${content}`}
            onMouseUp={() => forceCloseMenu()}
            onClick={onClick}
            unstyled
            role="menuitem"
            aria-label={label}
            disabled={disabled}
          >
            {contentElement}
          </Button>
        ) : undefined;
        break;
      case 'href':
        return url ? (
          <a
            className={cx(theme.item, menuItemClassName, {
              [theme.selected]: selected,
            })}
            href={url}
            key={`menu_item_${type}_${url}`}
            rel="noopener noreferrer"
            target="_blank"
            onMouseUp={() => forceCloseMenu()}
          >
            {contentElement}
          </a>
        ) : undefined;
        break;
      case 'link':
        return url ? (
          <Link
            className={cx(theme.item, menuItemClassName, {
              [theme.selected]: selected,
            })}
            key={`menu_item_${type}_${url}`}
            to={url}
            onMouseUp={() => forceCloseMenu()}
          >
            {contentElement}
          </Link>
        ) : undefined;
        break;
      case 'custom':
        return contentElement;
      default:
        return undefined;
    }
  };

  return (
    <div
      data-testid="drop down menu"
      className={cx(theme.container, className)}
      ref={dropDownContainerRef}
    >
      <>
        {React.cloneElement(button, { id: buttonId })}

        <div
          role="menubar"
          aria-label={dropDownLabel}
          className={cx(
            {
              [theme.opened]: isMenuOpened,
            },
            theme.menu,
            menuClassName
          )}
        >
          {menuItems.map(renderMenuItem)}
        </div>
      </>
    </div>
  );
};

export default memo(DropdownMenu);
