import React, { useState } from 'react';
import { ColumnDef, Table } from '@tanstack/react-table';

import styles from './Roster.module.css';
import { AddIcon } from '../icons';
import Button from '../atoms/button/Button';
import PrimaryButton from '../primaryButton/PrimaryButton';
import DropdownMenu, { MenuItem } from '../dropdownMenu/DropdownMenu';
import MultiSelectTable from '../multiSelectTable/MultiSelectTable';
import HelpWidget from '../helpWidget/HelpWidget';

export type Props<Member extends Record<string, any>> = {
  tableRef?: React.Ref<Table<Member> | null>;
  data: Member[];
  columns: ColumnDef<Member>[];
  onAddMembersClick: () => void;
  addMembersHelp?: React.ReactNode;
  selectedMemberIds: string[];
  onSelectedMembersChange: (selectedMemberIds: string[]) => void;
  multiSelectActionMenuItems: MenuItem[];
  disabled?: boolean;
  className?: string;
};

const Roster = <Member extends Record<string, any>>({
  tableRef,
  data,
  columns,
  onAddMembersClick,
  addMembersHelp,
  selectedMemberIds,
  onSelectedMembersChange,
  multiSelectActionMenuItems,
  disabled,
  className,
}: Props<Member>) => {
  const [isMultiSelectDropdownOpen, setIsMultiSelectDropdownOpen] = useState(
    false
  );

  return (
    <div className={className}>
      <div className={styles.tableActions}>
        {!disabled && (
          <div className={styles.tableLeftActions}>
            <label className={styles.addMembersButton}>
              <Button unstyled onClick={() => onAddMembersClick()}>
                <AddIcon />
              </Button>
              Add Members
            </label>
            {addMembersHelp}
          </div>
        )}

        {!disabled && (
          <DropdownMenu
            button={
              <>
                {selectedMemberIds.length === 0 && (
                  <HelpWidget
                    aria-label="Actions Help"
                    message="Actions are enabled after selecting members using the left checkboxes"
                    tooltipPlacement="bottom-end"
                    className={styles.multiSelectActionsHelpWidget}
                  />
                )}

                <label>
                  {selectedMemberIds.length > 0 &&
                    `${selectedMemberIds.length} members selected`}

                  <PrimaryButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsMultiSelectDropdownOpen(!isMultiSelectDropdownOpen);
                    }}
                    ariaLabel="Multiselect actions menu toggle"
                    className={styles.multiSelectActionsMenuButton}
                    disabled={selectedMemberIds.length === 0}
                    aria-haspopup
                  >
                    Actions...
                  </PrimaryButton>
                </label>
              </>
            }
            menuItems={multiSelectActionMenuItems}
            isMenuOpened={isMultiSelectDropdownOpen}
            forceCloseMenu={() => setIsMultiSelectDropdownOpen(false)}
            menuClassName={styles.multiSelectActionsDropdownMenu}
          />
        )}
      </div>

      <MultiSelectTable
        tableRef={tableRef}
        columns={columns}
        data={data}
        getRowId={(member) => member.id}
        disabled={disabled}
        onSelectedRowsChange={(members) =>
          onSelectedMembersChange(members.map((member) => member.id))
        }
      />
    </div>
  );
};

export default Roster;
