import React, { memo } from 'react';
import cx from 'classnames';

import styles from './GlobeIcon.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  className?: string;
  theme: Partial<Theme>;
};

const GlobeIcon = ({ className, theme: customTheme }: Props) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-1.5 -1 69 70"
      className={cx(className, theme.root)}
      data-testid="public-icon"
    >
      <clipPath id="crustClip">
        <path
          transform="translate(1 1) scale(0.98 0.98)"
          d="
            M4.1,20.38
            c-.08.27-.16.54-.23.82
            A34.81,34.81,0,0,0,4.1,39.32
            c2.29,8.21,8.52,16.38,16.3,19.17
            a40.55,40.55,0,0,0,11.9,1.77
            c10.31.33,17.36-2.46,22.14-6
            h0
            a27.29,27.29,0,0,0,8.94-11.42
            c1.25-3.29,1.78-12-.61-20.65-.32-1.14-.68-2.28-1.11-3.41
            A28.71,28.71,0,0,0,53.14,6.35,23.22,23.22,0,0,0,48,3.14
            C43.41,1.08,38.3.93,33.36.8
            A35.15,35.15,0,0,0,14.49,6.89,25.07,25.07,0,0,0,4.1,20.38"
        />
      </clipPath>

      <g className={styles.group}>
        <path
          className={styles.background}
          d="
            M1.32,23.87
            c-.08.27-.16.54-.23.81
            A34.81,34.81,0,0,0,1.32,42.8
            C3.61,51,9.84,59.18,17.62,62
            a40.21,40.21,0,0,0,11.9,1.76
            c10.31.34,17.37-2.45,22.14-6
            h0
            a27.14,27.14,0,0,0,8.94-11.42
            c1.26-3.29,1.78-12-.61-20.65-.32-1.14-.68-2.28-1.1-3.41
            A28.79,28.79,0,0,0,50.36,9.84
            a22.86,22.86,0,0,0-5.17-3.21
            c-4.56-2.07-9.67-2.21-14.61-2.35
            a35.16,35.16,0,0,0-18.87,6.1
            C6.93,13.63,3,18.15,1.32,23.87"
        />
        <path
          className={styles.line}
          d="
            M4.1,20.38
            c-.08.27-.16.54-.23.82
            A34.81,34.81,0,0,0,4.1,39.32
            c2.29,8.21,8.52,16.38,16.3,19.17
            a40.55,40.55,0,0,0,11.9,1.77
            c10.31.33,17.36-2.46,22.14-6
            h0
            a27.29,27.29,0,0,0,8.94-11.42
            c1.25-3.29,1.78-12-.61-20.65-.32-1.14-.68-2.28-1.11-3.41
            A28.71,28.71,0,0,0,53.14,6.35,23.22,23.22,0,0,0,48,3.14
            C43.41,1.08,38.3.93,33.36.8
            A35.15,35.15,0,0,0,14.49,6.89,25.07,25.07,0,0,0,4.1,20.38"
        />
        <g clipPath="url(#crustClip)">
          <g className={styles.atlantic}>
            <path
              className={styles.line}
              d="
              M63.71,41.84
              A14,14,0,0,1,61,47.42
              c-.8,1-1.91,2.27-3.12,2.1-.49-.07-1.15-.81-2.46-2.3
              a13.6,13.6,0,0,1-1.85-2.52
              A10.09,10.09,0,0,1,53,43.61
              c-.5-1.21-.83-3.34-1.37-7.56
              a2.44,2.44,0,0,0-.83-1.73,2.58,2.58,0,0,0-1.3-.37,10.17,10.17,0,0,1-4.58-1.05
              c-2.13-1.34-2.75-4.22-2.43-6.72
              a4.24,4.24,0,0,1,.63-2,4.28,4.28,0,0,1,1.58-1.31
              A8.19,8.19,0,0,1,52,22.22
              c1.2.55,2.38,1.54,3.68,1.27.92-.18,1.61-1,2.49-1.27
              a5.23,5.23,0,0,1,2.23-.11
              c.7.07,1.55.07,2.43.1
              C63,23.11,65.25,35.06,63.71,41.84
              Z"
            />
            <path
              className={styles.line}
              d="
              M61.67,18.8
              a9.43,9.43,0,0,1-2.65,0
              c-1.39-.18-2.86-.66-4.12-.06-.49-1.22-2.25-1.34-3.43-.76
              s-2.12,1.6-3.33,2.13-3,.22-3.31-1.06
              c-.29-1.09.66-2.11,1.6-2.73
              s2.06-1.2,2.42-2.28
              a7,7,0,0,0,.2-1.47
              A7.9,7.9,0,0,1,50.71,9
              a15,15,0,0,1,2.43-2.66
              A28.71,28.71,0,0,1,61.67,18.8
              Z"
            />
            <path
              className={styles.line}
              d="
              M18.1,28.64
              a7.45,7.45,0,0,0-1.88-.49,7.58,7.58,0,0,1-1.71-.31,10.16,10.16,0,0,1-1.68-1,7.44,7.44,0,0,0-2.41-1,2.83,2.83,0,0,1-1.15-.41
              c-.48-.39-.57-1.07-.82-1.64
              C7.71,22,5.65,21.47,4.1,20.38
              A25.07,25.07,0,0,1,14.49,6.89
              c.52.84.94,1.75,1.22,2.28
              l3.35-1.73
              C19.89,7,21,6.6,21.69,7.21
              c.27.23.43.58.75.74
              a1.59,1.59,0,0,0,1.17-.07,20.62,20.62,0,0,1,10-.54
              c.14,1.37-2.14,1.87-2.29,3.24-.08.75.56,1.41.66,2.16
              a2.25,2.25,0,0,1-.83,1.87,11.73,11.73,0,0,1-1.77,1.16
              C26.71,17.36,24.87,20,23,22.37
              a8,8,0,0,1-1.78,1.81
              c-1.49,1-3.69,1.16-4.51,2.76
              A4,4,0,0,0,18.1,28.64
              Z"
            />
            <path
              className={styles.line}
              d="
              M37.82,38.89
              a4.64,4.64,0,0,1-1.3,3
              c-.57.63-1.24,1.14-1.85,1.73
              a20.27,20.27,0,0,0-2.87,3.86,13.1,13.1,0,0,0-1.93,4.09,9.32,9.32,0,0,1-.75,3,.78.78,0,0,1-.63.46.81.81,0,0,1-.45-.18
              c-2-1.37-2.54-4.07-2.59-6.49
              s.1-4.93-.93-7.12
              l-1-1.09
              c-1.31-1.29-2.69-2.75-2.86-4.58-.13-1.37.46-2.75.16-4.1
              a3.61,3.61,0,0,0-1.57-2.19,8.6,8.6,0,0,0,2.39.57
              c2,.21,4.09,0,6,.52
              a2.63,2.63,0,0,1,1,.5,3.17,3.17,0,0,1,.75,1.28
              c.59,1.48,1.45,3.06,3,3.53,1.22.38,2.62,0,3.79.48
              A3,3,0,0,1,37.82,38.89
              Z"
            />
            <path
              className={styles.line}
              d="
              M47,10.85
              a1.33,1.33,0,0,0-.8-1.59.67.67,0,0,0-.72.13.9.9,0,0,0-.13.42,3.85,3.85,0,0,0,.27,2.56
              A1.94,1.94,0,0,0,47,10.29"
            />
          </g>
          <g className={styles.pacific}>
            <path
              className={styles.line}
              d="
              M33.43,42.53
              c-1.25,2.1.37,3.89-.66,6.4
              a6.31,6.31,0,0,1-2.74,3,5.51,5.51,0,0,1-4.11-.54
              c-.7-.45-1.09-1-2.2-2.87
              C22.2,46,21.44,44.7,21.29,44.3
              a41.35,41.35,0,0,1-1.65-8.25,2.46,2.46,0,0,0-.83-1.73,2.58,2.58,0,0,0-1.3-.37,10.17,10.17,0,0,1-4.58-1.05
              c-2.13-1.34-2.75-4.22-2.43-6.72
              a4.24,4.24,0,0,1,.63-2,4.28,4.28,0,0,1,1.58-1.31
              A8.19,8.19,0,0,1,20,22.22
              c1.2.55,2.38,1.54,3.68,1.27.92-.18,1.61-1,2.49-1.27
              a5.23,5.23,0,0,1,2.23-.11
              c.7.07,1.55.07,2.43.1,1.27,4.67,3.1,6.49,4.86,7.07
              s3.44,0,3.76.72
              c.42,1-2.42,2-3.1,4.8-.54,2.26.88,3.27,0,5.08
              C35.55,41.44,34.36,41,33.43,42.53
              Z"
            />
            <path
              className={styles.line}
              d="
              M15,10.85
              a1.33,1.33,0,0,0-.8-1.59.67.67,0,0,0-.72.13.9.9,0,0,0-.13.42,3.85,3.85,0,0,0,.27,2.56
              A1.94,1.94,0,0,0,15,10.29"
            />
            <path
              className={styles.line}
              d="
              M38.29,47.17c-.83-.11-1.62-2.32-.88-3.54.48-.78,1.22-.54,2.43-1.54s1.55-2.29,1.77-2.21-.13,2.54-1.33,4.86C39.67,45.92,39,47.26,38.29,47.17Z"
            />
            <path
              className={styles.line}
              d="
              M21.14,6.35
              A15,15,0,0,0,18.71,9
              a7.9,7.9,0,0,0-1.66,3.58,7.13,7.13,0,0,1-.2,1.47
              c-.36,1.08-1.48,1.66-2.42,2.28
              s-1.89,1.64-1.6,2.73
              c.33,1.28,2.1,1.58,3.31,1.06
              s2.14-1.55,3.33-2.13,2.94-.46,3.43.76
              c1.26-.6,2.74-.12,4.12.06.84.11,1.75.12-.75-6.77.47-.39,2.14,2.23,5.18,2.65,3.92.55,6.76-3,8-2,.6.55.5,2.07-.22,2.88-1.45,1.62-4.75-.34-6.19,1.32
              a3.23,3.23,0,0,0-.44,2.65
              c.3,1.26,1.21,1.56,2.43,2.87,2.36,2.53,1.77,4.4,3.31,4.86.29.09.61.11,4-1.32,3.1-1.33,3.56-1.66,3.53-2
              s-.9-.67-2.65-1.33
              a11.91,11.91,0,0,1-2.21-.88
              c-.95-.6-2-1.69-1.76-2.21
              s2.24-.25,2.43-.22
              c2.16.41,2.51,1.56,5.07,2.43,1.47.5,1.52.18,2.43.66,1.15.6,1.8,1.58,3.1,3.54
              a23.37,23.37,0,0,1,2.38,4
              c.12.3.33.88.49.85
              s-.19-2.06.66-4.19
              c.17-.43.89-2.24,1.77-2.21,1.12,0,1.19,3.22,3.09,3.75
              A1.9,1.9,0,0,0,62.13,28
              c2.1-1.62-1.42-16.64-11.75-21.59
              C45.38,4,36.22,2.77,31,6.31
              c-.91.62-3.12,2.35-5.85,2
              A7.17,7.17,0,0,1,21.14,6.35
              Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(GlobeIcon);
