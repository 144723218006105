import { gql } from '@apollo/client';

export default gql`
  fragment GCSFileFields on GCSFile {
    id
    name
    url
    mimeType
    __typename
  }
`;
