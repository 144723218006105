import * as types from '../../actions/notification/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_NOTIFICATION:
      return { ...action.payload };

    case types.CANCEL_NOTIFICATION:
      return state.code === action.code ? {} : state;

    default:
      return state;
  }
};

export const getNotificationCode = (state) => state.code;
export const getNotificationMessage = (state) => state.message;
