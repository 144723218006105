import { gql } from '@apollo/client';

export default gql`
  fragment CoderFileFields on CoderFile {
    __typename
    id
    code
    name
    crossVersionId
    sourceCrossVersionId
  }
`;
