import { setContext } from '@apollo/client/link/context';
import uuid from 'uuid-v4';

export default setContext((_, previousContext) => {
  const { headers } = previousContext;

  return {
    ...previousContext,
    headers: {
      ...headers,
      'pt-request-id': uuid(),
    },
  };
});
