import { apiBaseUrl } from './apiBaseUrl';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getApiRequestUrl = ({
  baseUrl = apiBaseUrl,
  pathTemplate,
  baseQuery = '',
  args,
}) => {
  const { params = {}, query = {} } = args || {};

  const path = pathTemplate.replace(
    /:(\w+)/g,
    (_, paramName) => params[paramName]
  );

  const queryString = Object.entries(query).reduce(
    (acc, [key, value], i) =>
      `${acc}${i || baseQuery ? '&' : ''}${key}=${value}`,
    baseQuery
  );
  return `${baseUrl}${path}${queryString ? '?' : ''}${queryString}`;
};

export default ({
  method, // string; http method e.g. 'GET'
  baseUrl = apiBaseUrl, // string; base url of the api being used
  pathTemplate, // string; prefix params with a colon e.g. '/Model/:modelId'
  headers: createHeaders = {}, // object; e.g. { 'Content-Type': 'text/html' }
  baseQuery = '', // string, prepended to the query e.g. 'include=user',
}) => {
  return async (args = {}) => {
    const { body, headers: requestHeaders = {} } = args;
    const headers = {
      ...defaultHeaders,
      ...createHeaders,
      ...requestHeaders,
    };

    let requestBody;
    if (body) {
      if (body instanceof FormData) {
        requestBody = body;
        if (headers['Content-Type'] === 'application/json') {
          delete headers['Content-Type'];
        }
      } else {
        requestBody = body && JSON.stringify(body);
      }
    }

    const url = getApiRequestUrl({ baseUrl, pathTemplate, baseQuery, args });
    const res = await fetch(url, { method, headers, body: requestBody });

    if (res.status !== 204) {
      const resBody = await res.json();

      const { error } = resBody;
      if (error) throw error;

      return resBody;
    }
  };
};
