import React, { memo, ReactNode } from 'react';
import cx from 'classnames';
import Button, { Props as ButtonProps } from '../atoms/button/Button';
import CircleIcon from '../circleIcon/CircleIcon';

import styles from './MenuButton.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = Omit<
  ButtonProps,
  'role' | 'aria-haspopup' | 'unstyled' | 'children'
> & {
  menuIsOpen: boolean;
  theme?: Partial<Theme>;
  openIcon?: ReactNode;
  closeIcon?: ReactNode;
};

const MenuButton = ({ theme: customTheme, ...props }: Props) => {
  const theme = useTheme(styles, customTheme);
  const {
    menuIsOpen,
    className,
    openIcon = <div className={theme.threeDots} />,
    closeIcon = <div className={theme.closeIcon} />,
    ...buttonProps
  } = props;

  return (
    <Button
      className={cx(theme.root, className)}
      role="menuitem"
      aria-haspopup="true"
      unstyled
      {...buttonProps}
    >
      <CircleIcon className={theme.circleIcon} />
      <div className={theme.content}>{menuIsOpen ? closeIcon : openIcon}</div>
    </Button>
  );
};

export default memo(MenuButton);
