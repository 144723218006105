import { gql } from '@apollo/client';

export default gql`
  query GetSubmissionProgress($challengeId: String!) {
    project(id: $challengeId) {
      __typename
      id
      submissions {
        __typename
        id
        isSubmissionCollaborator
        isDraftSubmission
        completedSectionIds
      }
      readerVersion {
        __typename
        id
        sections {
          __typename
          id
          crossVersionId
        }
      }
      draftVersion {
        __typename
        id
        sections {
          __typename
          id
          crossVersionId
        }
      }
    }
  }
`;
