import uuidV4 from 'uuid-v4';

import EventEmitter from 'events';
import { CodeDirectory } from '../../context/CodeDirectory';
import { CodeRunKeyEventType } from '../../types/graphql-types';

import { reqTimeout } from '../requestHelpers';
import { updateQueryString } from '../queryString';
import { createFurtherLinkDNS } from '../../services/furtherLink';
import { ChunkedMessage, Chunk } from './chunkMessages';
import * as timeouts from './timeouts';

export const PT_SERVICE_UUID = '12341000-1234-1234-1234-123456789aaa';
// export const PT_STATUS_CHARACTERISTIC_UUID = '12341000-1234-1234-1234-123456789aba';
export const PT_VERSION_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789aca';
export const PT_WRITE_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789aab';
export const PT_APT_VERSION_READ_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789ada';
export const PT_APT_VERSION_WRITE_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789adb';
export const PT_UPLOAD_READ_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789bba';
export const PT_UPLOAD_WRITE_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789bca';
export const PT_RUN_READ_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789cba';
export const PT_RUN_WRITE_CHARACTERISTIC_UUID =
  '12341000-1234-1234-1234-123456789cca';

export const SUCCESS_EXIT_CODE = 0;
export const STOPPED_EXIT_CODE = -15;
export const subdomain = 'further-link.pi-top.com';
export const defaultPort = 8028;
export const statusUrl = 'status';
export const runUrl = 'run?pty=true';
export const versionUrl = 'version';
export const aptVersionUrl = 'version/apt';
export const uploadUrl = 'upload';

export const sdkPackageName = 'python3-pitop';
export const sdkMinVersion = '0.32.0';
export const linkMinVersion = '5.9.1';
export const linkUploadVersion = '4.4.0';

export type FurtherLinkMessage = {
  client: string;
  process: string;
  type: string;
  data: unknown;
};

export type OnKeyListenType = (key: string) => void;
export type OnKeyEventType = (key: string, event: CodeRunKeyEventType) => void;

export type NovncOptions = {
  enabled?: boolean;
  height?: number;
  width?: number;
};

export type StartOptions = {
  processId: string;
  runner: string;
  novncOptions?: NovncOptions;
  directoryName?: string;
  onStdout: (content: string) => void;
  onStderr: (content: string) => void;
  onVideo: (src: string) => void;
  onNovnc?: (port: number, path: string) => void;
  onStop: (data: { exitCode: number }) => void;
  code?: string;
  fileName?: string;
};

export type PtySize = { rows: number; cols: number };

export type UrlOptions = {
  protocol?: string;
  port?: number | null;
};

export class FurtherLinkServer {
  static ipBaseUrl(
    ip: string,
    { protocol = 'https', port = defaultPort } = {}
  ) {
    return FurtherLinkServer.hostBaseUrl(
      `${FurtherLinkServer.normaliseIp(ip).replace(/\./g, '-')}.${subdomain}`,
      { protocol, port }
    );
  }

  private static hostBaseUrl(
    host: string,
    { protocol = 'https', port = defaultPort }: UrlOptions
  ) {
    return `${protocol}://${host}${port ? `:${port}` : ''}`;
  }

  private static normaliseIp(ip: string) {
    if (ip === 'localhost') {
      return '127.0.0.1';
    }

    return ip;
  }

  private static furtherLinkDNSHost(ip: string) {
    return `${ip.replace(/\./g, '-')}.${subdomain}`;
  }

  userInput: string;

  isLocal: boolean;

  ip: string;

  host: string;

  port: number | null;

  httpBaseUrl: string;

  wsBaseUrl: string;

  needsDNS: boolean;

  constructor(userInput: string, useSSL: boolean, useDNS: boolean) {
    this.userInput = userInput;

    const directConnections = ['cloud-demo', 'cloud'];
    const isDirectConnection = directConnections.includes(userInput);
    this.isLocal = !isDirectConnection;
    this.needsDNS = useDNS && !isDirectConnection;

    this.ip = FurtherLinkServer.normaliseIp(userInput);
    this.host = this.ip;
    if (!this.isLocal) {
      this.host = `${this.ip}.${subdomain}`;
    }
    if (this.needsDNS) {
      this.host = FurtherLinkServer.furtherLinkDNSHost(this.ip);
    }

    this.port = isDirectConnection ? null : defaultPort;

    this.httpBaseUrl = `${FurtherLinkServer.hostBaseUrl(this.host, {
      protocol: useSSL ? 'https' : 'http',
      port: this.port,
    })}`;

    this.wsBaseUrl = `${FurtherLinkServer.hostBaseUrl(this.host, {
      protocol: useSSL ? 'wss' : 'ws',
      port: this.port,
    })}`;
  }

  novncUrl(port: number, path: string) {
    if (this.isLocal) {
      return `${FurtherLinkServer.ipBaseUrl(this.userInput, {
        port,
      })}/${path}`;
    }

    // on cloud servers novnc ports are mapped as part of the path, and an
    // extra query param is required to point the novnc FE to that path also
    const [mainPath, query] = path.split('?');
    const fullQuery = updateQueryString(query, { path: `${port}/websockify` });
    return `${this.httpBaseUrl}/${port}/${mainPath}?${fullQuery}`;
  }

  async configureDNS(): Promise<boolean> {
    if (!this.needsDNS) return false;

    try {
      const { isNew } = await createFurtherLinkDNS({
        body: { ip: this.ip },
      });

      if (isNew) {
        // give a few seconds for dns propagation
        return new Promise((resolve) => {
          setTimeout(() => resolve(true), 5000);
        });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {} // if DNS creation fails, still try to connect

    return false;
  }
}

export enum FurtherLinkError {
  CONNECTION_TIMEOUT = 'CONNECTION_TIMEOUT',
  START_TIMEOUT = 'START_TIMEOUT',
  STOP_TIMEOUT = 'STOP_TIMEOUT',
  CONNECTION_CLOSED = 'CONNECTION_CLOSED', // 'The connection was unexpectedly closed'
  UNKNOWN_SERVER = 'UNKNOWN_SERVER',
  NO_BLUETOOTH_DEVICE = 'NO_BLUETOOTH_DEVICE',
  BLUETOOTH_SERVER_ERROR = 'BLUETOOTH_SERVER_ERROR',
  BLUETOOTH_PAIRING_TIMEOUT = 'BLUETOOTH_PAIRING_TIMEOUT',
  CANT_DETERMINE_MTU = 'CANT_DETERMINE_MTU',
  NO_PONG = 'NO_PONG',
}

export enum LinkType {
  Ip = 'ip',
  Bluetooth = 'bluetooth',
  Remote = 'remote',
}

export class BackendConnection {
  type: LinkType;

  constructor(linkType: LinkType) {
    this.type = linkType;
  }
}

export class RemoteConnection extends BackendConnection {
  constructor() {
    super(LinkType.Remote);
  }
}

export class BluetoothConnection extends BackendConnection {
  constructor() {
    super(LinkType.Bluetooth);
  }
}

export class IpConnection extends BackendConnection {
  ip: string;

  constructor(ipAddress: string) {
    super(LinkType.Ip);
    this.ip = ipAddress;
  }
}

export class FurtherLinkProcess {
  id: string;

  onStdout?: (content: string) => void;

  onStderr?: (content: string) => void;

  onStop?: (data: { exitCode: number }) => void;

  onVideo?: (src: string) => void;

  onNovnc?: (port: number, path: string) => void;

  keyListens: string[];

  onKeyListen?: OnKeyListenType;

  onKeyEvent?: OnKeyEventType;

  constructor(id: string) {
    this.id = id;
    this.keyListens = [];
  }

  resetKeyListens() {
    this.keyListens = [];
  }

  keyListen(key: string) {
    this.keyListens.push(key);
  }
}

export abstract class FurtherLinkBase {
  protected createMessage(type: string, data = {}, process = '') {
    return JSON.stringify({
      client: this.clientId,
      process,
      type,
      data,
    });
  }

  protected static parseMessage(message: string): FurtherLinkMessage {
    try {
      let { client, process, type, data } = JSON.parse(message);
      if (typeof client !== 'string') client = '';
      if (typeof process !== 'string') process = '';
      if (typeof type !== 'string') type = '';
      if (typeof data !== 'object') data = {};
      return { client, process, type, data };
    } catch (e) {
      return { client: '', process: '', type: '', data: {} };
    }
  }

  serverName: string;

  clientId: string;

  timeout: number;

  noPingPong: boolean;

  emitter: EventEmitter.EventEmitter;

  processes: Record<string, FurtherLinkProcess | undefined>;

  onError?: (e: Error) => void;

  onDisconnect?: () => void;

  pingTimeoutTimer?: number;

  pongTimeoutTimer?: number;

  missedPongsCounter: number;

  readonly MAX_UNPONGED_PINGS = 2;

  backend: IpConnection | BluetoothConnection | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  server: any;

  constructor(
    backend: IpConnection | BluetoothConnection,
    {
      noPingPong = false,
      timeout = timeouts.defaultTimeout,
      clientUuid = '',
    } = {}
  ) {
    this.missedPongsCounter = 0;
    this.serverName = '';
    this.backend = backend;
    this.timeout = timeout;
    this.noPingPong = noPingPong;
    this.clientId = clientUuid || uuidV4().toString();

    this.emitter = new EventEmitter.EventEmitter();
    this.processes = {};

    this.listenKeyEvents();
  }

  async connect(
    onError: (e: Error) => void,
    onDisconnect: () => void
  ): Promise<boolean> {
    this.missedPongsCounter = 0;
    this.onError = onError;
    this.onDisconnect = onDisconnect;
    return Promise.resolve(true);
  }

  addProcess(processId: string) {
    const process = new FurtherLinkProcess(processId);
    this.processes[processId] = process;
    return process;
  }

  abstract disconnect(): void;

  cancelConnect() {
    this.emitter.emit('cancelConnect');
  }

  abstract checkVersion(): Promise<{ version: string }>;

  abstract checkPackageVersion(pkg: string): Promise<{ version: string }>;

  async start(options: StartOptions): Promise<FurtherLinkProcess> {
    if (!this.isReady()) {
      return Promise.reject();
    }

    const { runner, directoryName, code, fileName, novncOptions } = options;

    const process = this.addProcess(options.processId);
    process.onStdout = options.onStdout;
    process.onStderr = options.onStderr;
    process.onVideo = options.onVideo;
    process.onNovnc = options.onNovnc;
    process.onStop = options.onStop;
    process.onKeyEvent = (key, event) =>
      this.send(this.createMessage('keyevent', { key, event }, process.id));

    return new Promise((resolve, reject) => {
      const timeout = window.setTimeout(
        () => reject(new Error(FurtherLinkError.START_TIMEOUT)),
        this.timeout
      );

      this.emitter.once('started', () => {
        clearTimeout(timeout);
        resolve(process);
      });

      this.send(
        this.createMessage(
          'start',
          {
            runner,
            code,
            path: fileName ? `${directoryName}/${fileName}` : directoryName,
            novncOptions,
          },
          process.id
        )
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract upload(directory: CodeDirectory, username?: string): Promise<any>;

  async stop(processId: string) {
    if (!this.isReady()) {
      return Promise.reject();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise<any | Error>((resolve, reject) => {
      const timeout = window.setTimeout(
        () => reject(new Error(FurtherLinkError.STOP_TIMEOUT)),
        this.timeout
      );

      this.emitter.once('stopped', (data) => {
        clearTimeout(timeout);
        resolve(data);
      });

      this.send(this.createMessage('stop', {}, processId));
    });
  }

  abstract isReady(): boolean;

  protected closePingPong() {
    if (this.pingTimeoutTimer) {
      clearTimeout(this.pingTimeoutTimer);
    }
    if (this.pongTimeoutTimer) {
      clearTimeout(this.pongTimeoutTimer);
    }
  }

  protected ping() {
    this.send(this.createMessage('ping'));
    this.missedPongsCounter += 1;
    this.pongTimeoutTimer = window.setTimeout(() => {
      // disconnect if missed pongs exceed the limit
      if (this.missedPongsCounter >= this.MAX_UNPONGED_PINGS) {
        this.onError && this.onError(new Error(FurtherLinkError.NO_PONG));
        this.disconnect();
        this.onDisconnect && this.onDisconnect();
        return;
      }
      this.ping();
    }, timeouts.missedPongTimeout);
  }

  protected startPingPong() {
    this.emitter.on('pong', () => {
      clearTimeout(this.pongTimeoutTimer);
      clearTimeout(this.pingTimeoutTimer);
      this.pingTimeoutTimer = window.setTimeout(
        () => this.ping(),
        timeouts.pingInterval
      );
    });

    return this.ping();
  }

  protected abstract send(msg: string): void;

  sendInput(input: string, processId: string) {
    this.send(
      this.createMessage(
        'stdin',
        {
          input,
        },
        processId
      )
    );
  }

  sendResize(size: PtySize, processId: string) {
    this.send(this.createMessage('resize', size, processId));
  }

  protected listenKeyEvents() {
    const handleKeyEvent = (event: CodeRunKeyEventType) => ({
      key,
    }: {
      key: string;
    }) => {
      Object.values(this.processes).forEach((process) => {
        if (
          process?.keyListens.includes(key) &&
          typeof process.onKeyEvent === 'function'
        ) {
          process.onKeyEvent(key, CodeRunKeyEventType[event]);
        }
      });
    };

    document.addEventListener(
      'keydown',
      handleKeyEvent(CodeRunKeyEventType.keydown)
    );
    document.addEventListener(
      'keyup',
      handleKeyEvent(CodeRunKeyEventType.keyup)
    );
  }

  protected handleMessage(message: { data: string }) {
    const {
      client,
      process: processId,
      type,
      data,
    } = FurtherLinkBase.parseMessage(message.data);

    // we only really need clientId for bluetooth
    if (this.backend?.type === LinkType.Bluetooth && client !== this.clientId) {
      return;
    }

    const process = this.processes[processId];

    const { output, port, path } = (data || {}) as {
      output?: string;
      port?: number;
      path?: string;
    };

    switch (type) {
      case 'error':
        if (typeof this.onError === 'function') {
          this.onError(data as Error);
        }
        break;
      case 'started':
        this.emitter.emit('started', { processId });
        break;
      case 'pong':
        this.emitter.emit('pong');
        break;
      // the following callbacks are bound in start() method
      case 'stdout':
        if (process?.onStdout && output) {
          process.onStdout(output);
        }
        break;
      case 'stderr':
        if (process?.onStderr && output) {
          process.onStderr(output);
        }
        break;
      case 'video':
        if (process?.onVideo && output) {
          process.onVideo(output);
        }
        break;
      case 'novnc':
        if (process?.onNovnc && port && path) {
          process.onNovnc(port, path);
        }
        break;
      case 'keylisten':
        if (typeof output !== 'string') {
          break;
        }
        if (process?.onKeyListen) {
          process.onKeyListen(output);
        }
        process?.keyListen(output);
        break;
      case 'stopped':
        this.emitter.emit('stopped', { processId, ...(data || {}) });
        process?.resetKeyListens();
        if (process?.onStop) {
          process.onStop({ exitCode: 0, ...(data || {}) });
        }
        break;
      default:
        break;
    }
  }
}

export class FurtherLinkRemote extends FurtherLinkBase {
  constructor(
    backend: RemoteConnection,
    {
      noPingPong = false,
      timeout = timeouts.defaultTimeout,
      clientUuid = '',
    } = {}
  ) {
    super(backend, { noPingPong, timeout, clientUuid });
  }

  /* eslint-disable class-methods-use-this */
  disconnect() {
    throw new Error('Not implemented');
  }

  checkVersion(): Promise<{ version: string }> {
    throw new Error('Not implemented');
  }

  checkPackageVersion(): Promise<{ version: string }> {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  upload(): Promise<any> {
    throw new Error('Not implemented');
  }

  isReady(): boolean {
    throw new Error('Not implemented');
  }

  send(): void {
    throw new Error('Not implemented');
  }
  /* eslint-enable class-methods-use-this */
}

export class FurtherLinkBluetooth extends FurtherLinkBase {
  device: BluetoothDevice | null;

  server: BluetoothRemoteGATTServer | null;

  service: BluetoothRemoteGATTService | null;

  characteristics: BluetoothRemoteGATTCharacteristic[] | null;

  readPartials: Record<string, ChunkedMessage>;

  writePartials: Record<string, ChunkedMessage>;

  notificationPartials: Record<string, ChunkedMessage>;

  notificationEmitter: EventEmitter.EventEmitter;

  mtu: number;

  constructor(
    backend: BluetoothConnection,
    {
      noPingPong = false,
      timeout = timeouts.defaultTimeout,
      clientUuid = '',
    } = {}
  ) {
    super(backend, { noPingPong, timeout, clientUuid });
    this.device = null;
    this.server = null;
    this.serverName = '';
    this.service = null;
    this.characteristics = null;
    this.readPartials = {};
    this.writePartials = {};
    this.notificationPartials = {};
    this.notificationEmitter = new EventEmitter.EventEmitter();
    this.mtu = 0;
  }

  private cleanup() {
    this.device = null;
    this.server = null;
    this.serverName = '';
    this.service = null;
    this.characteristics = null;
    this.readPartials = {};
    this.writePartials = {};
    this.notificationPartials = {};

    this.closePingPong();
    this.emitter.removeAllListeners();
    this.notificationEmitter.removeAllListeners();

    this.processes = {};
    this.onError = undefined;

    if (typeof this.onDisconnect === 'function') {
      this.onDisconnect();
      this.onDisconnect = undefined;
    }
  }

  private async onBluetoothDisconnect() {
    // eslint-disable-next-line no-restricted-syntax
    for (const characteristic of this.characteristics || []) {
      if (characteristic?.properties?.notify) {
        // eslint-disable-next-line no-await-in-loop
        await characteristic.stopNotifications();
      }
    }
    this.cleanup && this.cleanup();
  }

  async connect(
    onError: (e: Error) => void,
    onDisconnect: () => void
  ): Promise<boolean> {
    if (this.isReady()) {
      return Promise.resolve(true);
    }

    super.connect(onError, onDisconnect);

    let hasCancelled = false;

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        this.emitter.once('cancelConnect', () => {
          hasCancelled = true;
          this.disconnect();
          resolve(false); // not an error but not connected
        });

        try {
          this.device = await navigator.bluetooth.requestDevice({
            filters: [{ name: 'pi-top-' }, { services: [PT_SERVICE_UUID] }],
          });
        } catch (error) {
          const message =
            error instanceof Error ? error.message : String(error);
          if (message === 'User cancelled the requestDevice() chooser.') {
            return resolve(false); // not an error but not connected
          }
        }

        if (!this.device) {
          throw new Error(FurtherLinkError.NO_BLUETOOTH_DEVICE);
        }
        if (!this.device.gatt) {
          throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);
        }

        const connectionTimeout = window.setTimeout(() => {
          if (hasCancelled) return;
          this.disconnect();
          reject(new Error(FurtherLinkError.BLUETOOTH_PAIRING_TIMEOUT));
        }, timeouts.bluetoothConnectTimeout);

        this.server = await this.device.gatt.connect();
        clearTimeout(connectionTimeout);

        if (!this.server)
          throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);
        this.serverName = this.server?.device?.name || '';

        this.device.addEventListener(
          'gattserverdisconnected',
          this.onBluetoothDisconnect
        );

        if (hasCancelled) {
          return;
        }

        this.service = await this.server.getPrimaryService(PT_SERVICE_UUID);
        if (!this.service)
          throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);

        this.mtu = await this.determineMtu();

        await new Promise((r) => setTimeout(r, 1000));

        this.characteristics = await this.service.getCharacteristics();
        if (this.characteristics.length === 0)
          throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);

        this.subscribeToNotifications(
          PT_RUN_READ_CHARACTERISTIC_UUID,
          this.handleRunNotification
        );

        this.startPingPong();
        resolve(true);
      } catch (e) {
        this.device = null;
        this.server = null;
        this.serverName = '';
        this.service = null;
        this.characteristics = null;
        reject(e);
      }
    });
  }

  private async subscribeToNotifications(
    characteristicUUID: string,
    handler: (event: Event) => void
  ) {
    const characteristic = this.getCharacteristicByUUID(characteristicUUID);
    if (!characteristic) {
      throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);
    }
    await characteristic.startNotifications();
    characteristic.oncharacteristicvaluechanged = handler;
  }

  private handleNotification = (event: Event) => {
    const { value } = event.target as BluetoothRemoteGATTCharacteristic;
    let response = '';
    if (!value) {
      return response;
    }

    const chunk = Chunk.parse(value);
    if (!(chunk.id in this.notificationPartials)) {
      this.notificationPartials[chunk.id] = new ChunkedMessage(
        chunk.finalIndex + 1,
        chunk.data.length
      );
    }

    this.notificationPartials[chunk.id].append(chunk);

    if (this.notificationPartials[chunk.id].isComplete()) {
      response = this.notificationPartials[chunk.id].decode();
      delete this.notificationPartials[chunk.id];
      // console.debug('notification:', response);
    }
    return response;
  };

  private handleRunNotification = async (event: Event) => {
    const message = this.handleNotification(event);
    if (message) {
      this.handleMessage({ data: message });
    }
  };

  private handleUploadNotification = (event: Event) => {
    const message = this.handleNotification(event);
    if (message) {
      this.notificationEmitter.emit('uploadResponse', message);
    }
  };

  private handleAptVersionNotification = (event: Event) => {
    const message = this.handleNotification(event);
    if (message) {
      this.notificationEmitter.emit('aptVersionResponse', message);
    }
  };

  disconnect() {
    if (this.device?.gatt?.disconnect) {
      this.device.gatt?.disconnect();
    }
    this.cleanup();
  }

  private async write(characteristicUUID: string, value: string) {
    const characteristic = this.getCharacteristicByUUID(characteristicUUID);
    if (!characteristic)
      throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);

    // console.debug('write:', value);

    const chunkedMessage = await ChunkedMessage.fromString(value, this.mtu);
    for (let i = 0; i < chunkedMessage.totalChunks; i += 1) {
      try {
        const chunk = chunkedMessage.chunk(i);
        // eslint-disable-next-line no-await-in-loop
        await characteristic.writeValueWithResponse(chunk.asBytes());
      } catch (error) {
        if (!this.isReady()) break;
        // console.error('Write failed!', error);
        i -= 1;
      }
    }
  }

  private async read(characteristicUUID: string): Promise<string> {
    const characteristic = this.getCharacteristicByUUID(characteristicUUID);
    if (!characteristic)
      throw new Error(FurtherLinkError.BLUETOOTH_SERVER_ERROR);

    const value = await characteristic.readValue();
    const chunk = Chunk.parse(value);

    if (!(chunk.id in this.readPartials)) {
      this.readPartials[chunk.id] = new ChunkedMessage(
        chunk.finalIndex + 1,
        chunk.data.length
      );
    }

    this.readPartials[chunk.id].append(chunk);

    // continue reading until message is complete
    if (!this.readPartials[chunk.id].isComplete()) {
      return this.read(characteristicUUID);
    }

    const message = this.readPartials[chunk.id].decode();
    // console.debug('read:', message);

    delete this.readPartials[chunk.id];

    return message;
  }

  private async determineMtu(): Promise<number> {
    const maxMtu = 512;
    const minMtu = 20;
    const deltaMtu = 20;

    const char = await this.service?.getCharacteristic(
      PT_WRITE_CHARACTERISTIC_UUID
    );
    for (let i = maxMtu; i >= minMtu; i -= deltaMtu) {
      if (!this.isReady()) {
        break;
      }
      try {
        // eslint-disable-next-line no-await-in-loop
        await char?.writeValueWithResponse(new Uint8Array(i));
        if (this.isReady()) {
          return i;
        }
      } catch (error) {
        if (!this.isReady()) {
          // eslint-disable-next-line no-await-in-loop
          this.server = (await this.device?.gatt?.connect()) || null;
        }
      }
    }
    throw new Error(FurtherLinkError.CANT_DETERMINE_MTU);
  }

  protected send(msg: string) {
    if (this.isReady()) {
      // always a RUN message
      this.write(PT_RUN_WRITE_CHARACTERISTIC_UUID, msg);
    }
  }

  async sendMessageAndWaitForNotification(
    message: string,
    writeCharacteristicUUID: string,
    notificationCharacteristicUUID: string,
    eventName: string,
    handler: (event: Event) => void
  ) {
    return new Promise(async (resolve) => {
      // subscribe to changes in notificationCharacteristicUUID
      this.subscribeToNotifications(notificationCharacteristicUUID, handler);

      // sleep for a bit
      await new Promise((r) => setTimeout(r, 50));

      // listen for notification
      this.notificationEmitter.once(eventName, async (response) => {
        this.notificationEmitter.removeAllListeners(eventName);

        // unsubscribe from notifications
        const characteristic = await this.service?.getCharacteristic(
          notificationCharacteristicUUID
        );
        await characteristic?.stopNotifications();
        resolve(response);
      });

      // write to server
      await this.write(writeCharacteristicUUID, message);
    });
  }

  async upload(directory: CodeDirectory, username?: string) {
    return this.sendMessageAndWaitForNotification(
      JSON.stringify({ ...directory, username }),
      PT_UPLOAD_WRITE_CHARACTERISTIC_UUID,
      PT_UPLOAD_READ_CHARACTERISTIC_UUID,
      'uploadResponse',
      this.handleUploadNotification
    );
  }

  async checkPackageVersion(pkg: string): Promise<{ version: string }> {
    const response = await this.sendMessageAndWaitForNotification(
      pkg,
      PT_APT_VERSION_WRITE_CHARACTERISTIC_UUID,
      PT_APT_VERSION_READ_CHARACTERISTIC_UUID,
      'aptVersionResponse',
      this.handleAptVersionNotification
    );
    return JSON.parse((response as unknown) as string);
  }

  async checkVersion(): Promise<{ version: string }> {
    return JSON.parse(await this.read(PT_VERSION_CHARACTERISTIC_UUID));
  }

  isReady(): boolean {
    return this.device?.gatt?.connected || false;
  }

  protected startPingPong() {
    if (!this.isReady() || this.noPingPong) {
      return false;
    }
    return super.startPingPong();
  }

  private getCharacteristicByUUID(uuid: string) {
    return this.characteristics?.find((c) => c.uuid === uuid);
  }
}

export default class FurtherLink extends FurtherLinkBase {
  socket: WebSocket | null;

  server: FurtherLinkServer | null;

  ip: string;

  useSSL: boolean;

  useDNS: boolean;

  constructor(
    backend: IpConnection,
    {
      noPingPong = false,
      timeout = timeouts.defaultTimeout,
      clientUuid = '',
      useSSL = (window as any).PT_SVC_ENV !== 'development',
      useDNS = (window as any).NODE_ENV !== 'test',
    } = {}
  ) {
    super(backend, { noPingPong, timeout, clientUuid });

    this.server = null;
    this.serverName = '';
    this.socket = null;
    this.ip = backend.ip;
    this.useSSL = useSSL;
    this.useDNS = useDNS;
  }

  async connect(
    onError: (e: Error) => void,
    onDisconnect: () => void
  ): Promise<boolean> {
    if (this.isReady()) {
      return Promise.resolve(true);
    }

    super.connect(onError, onDisconnect);

    this.server = new FurtherLinkServer(this.ip, this.useSSL, this.useDNS);
    this.serverName = this.ip;

    let timeout: number;
    let hasCancelled = false;

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        this.emitter.once('cancelConnect', () => {
          if (timeout) clearTimeout(timeout);
          hasCancelled = true;
          this.onSocketDisconnect();
          resolve(false); // not an error but not connected
        });

        if (!this.server)
          return reject(new Error(FurtherLinkError.UNKNOWN_SERVER));

        await this.server.configureDNS();

        if (hasCancelled) {
          return;
        }

        const wsUri = new URL(`${this.server.wsBaseUrl}/${runUrl}`);
        wsUri.searchParams.append('client', this.clientId);
        this.socket = new WebSocket(wsUri.toString());

        // reject if there is an error before connection established
        this.socket.onerror = reject;

        this.socket.onmessage = (m) => this.handleMessage(m);
        this.socket.onclose = () => this.onSocketDisconnect();

        timeout = window.setTimeout(
          () => reject(new Error(FurtherLinkError.CONNECTION_TIMEOUT)),
          this.timeout
        );

        this.socket.onopen = () => {
          this.onSocketOpen();
          clearTimeout(timeout);
          resolve(true);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.onerror = null;
      this.socket.close(1000); // this should trigger onSocketDisconnect
      this.socket = null;
    }
  }

  async checkVersion(): Promise<{ version: string }> {
    if (!this.server) throw new Error(FurtherLinkError.UNKNOWN_SERVER);

    const url = `${this.server.httpBaseUrl}/${versionUrl}`;
    const res = await reqTimeout(url, this.timeout);
    return res.json();
  }

  async checkPackageVersion(pkg: string): Promise<{ version: string }> {
    if (!this.server) throw new Error(FurtherLinkError.UNKNOWN_SERVER);

    const url = `${this.server.httpBaseUrl}/${aptVersionUrl}/${pkg}`;
    const res = await reqTimeout(url, this.timeout);
    return res.json();
  }

  async upload(directory: CodeDirectory, username?: string) {
    if (!this.server) throw new Error(FurtherLinkError.UNKNOWN_SERVER);

    const url = `${this.server.httpBaseUrl}/${uploadUrl}`;

    return reqTimeout(url, timeouts.uploadTimeout, {
      method: 'POST',
      body: JSON.stringify({ ...directory, username }),
    });
  }

  isReady(): boolean {
    return this.socket?.readyState === 1;
  }

  private onSocketOpen() {
    if (this.socket) {
      // once socket is opened, errors should go to the callback
      this.socket.onerror = () => {
        if (typeof this.onError === 'function') {
          // websocket 'error' is an event so we need to create our own Error
          this.onError(new Error(FurtherLinkError.CONNECTION_CLOSED));
        }
        this.onSocketDisconnect();
      };
    }

    this.startPingPong();
  }

  private onSocketDisconnect() {
    if (this.socket) {
      this.socket = null;
    }
    this.closePingPong();
    this.emitter.removeAllListeners();
    this.processes = {};
    this.server = null;
    this.serverName = '';
    this.onError = undefined;

    if (typeof this.onDisconnect === 'function') {
      this.onDisconnect();
      this.onDisconnect = undefined;
    }
  }

  protected startPingPong() {
    if (!this.isReady() || this.noPingPong) {
      return false;
    }
    return super.startPingPong();
  }

  protected send(msg: string) {
    if (this.isReady()) this.socket?.send(msg);
  }
}

export function createFurtherLink(
  connection: IpConnection | BluetoothConnection | RemoteConnection
) {
  if (connection instanceof IpConnection) {
    return new FurtherLink(connection);
  }
  if (connection instanceof BluetoothConnection) {
    return new FurtherLinkBluetooth(connection);
  }
  if (connection instanceof RemoteConnection) {
    return new FurtherLinkRemote(connection);
  }
  throw Error('Unknown connection type');
}
