import React, { memo } from 'react';

import CircleIcon from '../circleIcon/CircleIcon';
import styles from './SearchIcon.module.css';

type Props = {
  noCircle: boolean;
  className?: string;
};

const SearchIcon = ({ noCircle, className }: Props) => {
  const lookupIcon = (
    <g transform="translate(-3570.465 -1901.014)">
      <g>
        <path
          className={styles.st1}
          d="M3602.92,1926.87c3.89,4.6,4.58,4.03,8.42,8.26"
        />
        <path
          className={styles.st1}
          d="M3589.27,1916.27c-0.99,1.82-1.43,3.9-1.26,5.97c0.16,2.83,1.39,4.51,4.77,5.94
			c1.55,0.48,3.17,0.72,4.8,0.71c2,0.11,8.89-2.03,8.07-8.18c-0.22-1.64-0.17-3.38-1.14-4.72c-1.28-1.52-3.06-2.54-5.02-2.88
			C3595.86,1912.25,3591.38,1912.67,3589.27,1916.27z"
        />
      </g>
    </g>
  );

  if (!noCircle) return <CircleIcon>{lookupIcon}</CircleIcon>;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58.38 45.83"
      className={className}
    >
      {lookupIcon}
    </svg>
  );
};

export default memo(SearchIcon);
