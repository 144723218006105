type LabelledValue = {
  label: string;
};

export function isLabelledValue(value: any): value is LabelledValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    'label' in value &&
    typeof value.label === 'string'
  );
}
