import React, { memo, useState } from 'react';

import DropdownMenu, { MenuItem } from '../dropdownMenu/DropdownMenu';
import MenuButton from '../menuButton/MenuButton';
import defaultMenuItems from './defaultNavMenuItems';

import styles from './DefaultNavMenu.module.css';
import { useRealm } from '../../context/Realm';

export type Props = {
  className?: string;
};

const menuItemsByRealm: Record<string, Array<MenuItem>> = {
  makerbot: [
    {
      content: 'makerbot.com',
      type: 'link',
      url: 'https://www.makerbot.com',
    },
    {
      content: 'MakerBot Support',
      type: 'link',
      url: 'https://support.makerbot.com',
    },
    {
      content: 'Further Support',
      type: 'link',
      url: 'https://www.pi-top.com/resources/product-support',
    },
  ],
};

const DefaultNavMenu = ({ className }: Props) => {
  const [defaultNavMenuOpened, toggleDefaultNavMenu] = useState(false);
  const { realmName } = useRealm();
  const realmMenuItems =
    realmName && menuItemsByRealm[realmName]
      ? menuItemsByRealm[realmName]
      : defaultMenuItems;

  return (
    <DropdownMenu
      button={
        <MenuButton
          id="defaultDropdownMenu"
          data-testid="defaultDropdownMenu"
          aria-label="default nav menu"
          aria-haspopup="true"
          onClick={(event) => {
            event.stopPropagation();
            toggleDefaultNavMenu((active) => !active);
          }}
          menuIsOpen={defaultNavMenuOpened}
        />
      }
      className={className}
      isMenuOpened={defaultNavMenuOpened}
      menuClassName={styles.dropdownMenu}
      menuItems={realmMenuItems}
      forceCloseMenu={() => toggleDefaultNavMenu(false)}
      dropDownLabel="default nav menu drop down"
    />
  );
};

export default memo(DefaultNavMenu);
