import React, { useState, useEffect } from 'react';

import Dialog from '../dialog/Dialog';
import PtProgress from '../ptProgress/PtProgress';
import styles from './UpdateFurtherLinkDialog.module.css';
import {
  updateFurtherLinkTitle,
  updateFurtherLinkCallToAction,
  updateFurtherLinkComplete,
  updateFurtherLinkWaitForUpdate,
  updateFurtherLinkMoreInformation,
} from '../../strings';

import iconImage from '../../assets/images/update-character.png';

export type Props = {
  updateReasonMessage: string;
  active: boolean;
  handleClose: () => void;
  checkUpdated: () => Promise<boolean | undefined>;
  ip: string;
};

enum DialogState {
  Inactive = 'INACTIVE',
  Updating = 'UPDATING',
  Updated = 'UPDATED',
}

const UpdateFurtherLinkDialog = ({
  updateReasonMessage,
  active,
  handleClose,
  checkUpdated,
  ip,
}: Props) => {
  const [state, setState] = useState(DialogState.Inactive);

  useEffect(() => {
    if (!active) {
      return setState(DialogState.Inactive);
    }
  }, [active]);

  useEffect(() => {
    if (state === DialogState.Updating) {
      const interval = window.setInterval(async () => {
        if (await checkUpdated()) {
          setState(DialogState.Updated);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
    return undefined; // ts-conform: Not all code paths return a value
  }, [state, checkUpdated]);

  const confirmAction = () => {
    if (state === DialogState.Updated) {
      handleClose();
      return;
    }

    window.open(`http://${ip}/updater`, '_blank')?.focus();
    setState(DialogState.Updating);
  };

  return (
    <Dialog
      active={active}
      title={updateFurtherLinkTitle}
      handleClose={handleClose}
      confirmAction={{
        label: state === DialogState.Inactive ? 'Open Updater' : 'Ok',
        onClick: confirmAction,
        disabled: state === DialogState.Updating,
      }}
      headerIcon={iconImage}
    >
      <div className={styles.wrap}>
        {
          {
            [DialogState.Inactive]: (
              <p className={styles.text}>
                {updateReasonMessage}
                <br />
                <br />
                {updateFurtherLinkCallToAction}
              </p>
            ),

            [DialogState.Updating]: (
              <>
                <p className={styles.text}>
                  {updateFurtherLinkWaitForUpdate}
                  <br />
                  <br />
                  {updateFurtherLinkMoreInformation}
                </p>

                <br />
                <PtProgress className={styles.progressBar} />
              </>
            ),

            [DialogState.Updated]: (
              <p className={styles.text}>{updateFurtherLinkComplete}</p>
            ),
          }[state]
        }
      </div>
    </Dialog>
  );
};

export default UpdateFurtherLinkDialog;
