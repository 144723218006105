import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n, { ready } from '../i18n/i18n';

export const defaultLanguage = 'en-US';

export type LanguageContextValue = {
  isReady: boolean;
  setLanguage: (language: string) => void;
  language: string;
};

const LanguageContext = React.createContext<undefined | LanguageContextValue>(
  undefined
);

export type Props = {
  children: ReactNode;
};

function LanguageContextProvider({ children }: Props) {
  const [isReady, setIsReady] = useState(false);
  const [language, setLanguageState] = useState<string>(defaultLanguage);

  useEffect(() => {
    ready.then(() => {
      setIsReady(true);
      setLanguageState(i18n.language || defaultLanguage);
    });
  }, []);

  const value = useMemo<LanguageContextValue>(
    () => ({
      isReady,
      language,
      setLanguage: (nextLanguage) => {
        i18n.changeLanguage(nextLanguage);
        setLanguageState(nextLanguage);
      },
    }),
    [language, isReady]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

function useLanguage() {
  const auth = useContext(LanguageContext);
  if (auth === undefined) {
    throw new Error(
      'useLanguage must be used within an LanguageContextProvider'
    );
  }

  return auth;
}

export { LanguageContextProvider, useLanguage, LanguageContext };
