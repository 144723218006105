import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { exclude } from 'query-string';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from '../../context/Auth';

import MembershipDialog from '../membershipDialog/MembershipDialog';

import {
  activateMembershipTitle,
  activateMembershipHintText,
  membershipCodeUrlParamName,
  membershipActivated,
  membershipCodeErrorMessage,
  membershipLoginMessage,
} from '../../strings';

import { MembershipType } from '../../types/graphql-types';
import { useMembershipContext } from '../../context/Membership';
import logException from '../../services/logging/exception';
import {
  ActivateMembershipDialogConfirm,
  ActivateMembershipDialogConfirmVariables,
} from './types/ActivateMembershipDialogConfirm';

const ACTIVATE_MEMBERSHIP = gql`
  mutation ActivateMembershipDialogConfirm($inviteCode: String!) {
    upgradeMembership(inviteCode: $inviteCode) {
      __typename
      id
      membershipType
    }

    updateAccount(userType: TEACHER) {
      __typename
      id
      userType
    }
  }
`;

const ActivateMembershipDialog = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { membershipType } = useMembershipContext();

  const [licenseConfirmation, setLicenseConfirmation] = useState('');
  const [mutationError, setMutationError] = useState('');
  const [hideThisDialog, setHideThisDialog] = useState(false); // this state used to handle when login dialog showing

  const { authenticated, isReady, openLoginDialog } = useAuth();

  const [upgradeMembership, { loading: upgradeLoading }] = useMutation<
    ActivateMembershipDialogConfirm,
    ActivateMembershipDialogConfirmVariables
  >(ACTIVATE_MEMBERSHIP, {
    onCompleted: () => {
      setLicenseConfirmation(membershipActivated);
    },
    onError: (err) => {
      logException(err);
      setMutationError(membershipCodeErrorMessage);
    },
  });

  const membershipCodeUrlParam =
    new URLSearchParams(location.search).get(membershipCodeUrlParamName) || '';

  let hintText;
  let buttonText;
  let showHasMembershipCode;

  if (!licenseConfirmation) {
    switch (membershipType) {
      case MembershipType.FULL:
        hintText = 'You already have full membership';
        buttonText = 'Ok';
        showHasMembershipCode = false;
        break;
      default:
        hintText = activateMembershipHintText;
        buttonText = activateMembershipTitle;
        showHasMembershipCode = true;
    }
  } else {
    hintText = licenseConfirmation;
    buttonText = 'Ok';
    showHasMembershipCode = false;
  }

  const handleClose = useCallback(() => {
    // Remove UrlParam from querystring to avoid
    // opening dialog again when going back
    // or refreshing the page
    if (membershipCodeUrlParam) {
      const urlWithoutParam = exclude(
        window.location.pathname + window.location.search,
        [membershipCodeUrlParamName]
      );
      navigate(urlWithoutParam, {
        replace: true,
      });
    }
  }, [navigate, membershipCodeUrlParam]);

  useEffect(() => {
    if (membershipCodeUrlParam && isReady && !authenticated) {
      if (!hideThisDialog) {
        openLoginDialog(membershipLoginMessage);
        setHideThisDialog(true); // don't show this dialog again if you dismissed the login
      }
    }
  }, [
    isReady,
    authenticated,
    hideThisDialog,
    membershipCodeUrlParam,
    openLoginDialog,
  ]);

  return (
    <MembershipDialog
      active={!hideThisDialog && !!membershipCodeUrlParam && isReady}
      showMembershipCodeInput={showHasMembershipCode}
      membershipCode={membershipCodeUrlParam}
      handleClose={handleClose}
      title={activateMembershipTitle}
      hint={hintText}
      icon="teacher"
      isSubmitting={upgradeLoading}
      submitError={mutationError}
      onConfirm={
        membershipType === MembershipType.FULL || licenseConfirmation
          ? handleClose
          : ({ membershipCode }) => {
              membershipCode &&
                upgradeMembership({
                  variables: { inviteCode: membershipCode },
                });
            }
      }
      buttonText={buttonText}
    />
  );
};

export default ActivateMembershipDialog;
