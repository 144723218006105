import {
  ADD_COMMENT_ON_PROJECT,
  ADD_COMMENT_ON_SUBMISSION,
  ADD_COMMENT_REPLY,
  EDIT_PROJECT,
  EDIT_SUBMISSION,
} from './notificationTypes';

const mostRecent = (notifs) =>
  notifs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];

// this combines notifications which are duplicates under certain rules
//
// for example, project comment notifs are deduped if they have matching
// creatorId and projectId, but unread ones are not combined with read ones
//
// to do this, we create a 'dupeKey' for notifs which serialises the data that
// would make them considered duplicates, such as type, creatorId, projectId
// notifs are collected together in an object by their dupe key and then the
// most recent ones and a count are returned to represent the groups
//
// input notifs are expected to have inUnread set, and output ones are
// annotated with a dupe count which can be used to render custom messages
//
// arrangement depends on if they are read, and we don't want to rearrange while
// the notifs flyout if open, so best to updat the lastRead date when it closes

export default (notifications) => {
  const notifsByDupeData = {};

  notifications.forEach((notif) => {
    switch (notif.type) {
      case ADD_COMMENT_ON_PROJECT:
      case ADD_COMMENT_ON_SUBMISSION:
      case ADD_COMMENT_REPLY: {
        // read & unread not combined, comment content & pinningId are ignored
        const {
          type,
          isUnread,
          data: { creatorId, projectId, submissionId, repliedToId },
        } = notif;

        const dupeKey = JSON.stringify({
          type,
          isUnread,
          creatorId,
          projectId,
          submissionId,
          repliedToId,
        });

        notifsByDupeData[dupeKey] = [
          ...(notifsByDupeData[dupeKey] || []),
          notif,
        ];
        break;
      }
      case EDIT_PROJECT:
      case EDIT_SUBMISSION: {
        // don't want to spam these, so show as unread dupe only if matching read ones are >3 hours old
        // in other words, 1 bucket for read, & less than 3 hours since recent read
        const {
          type,
          isUnread,
          updatedAt,
          data: { creatorId, projectId, submissionId },
        } = notif;

        const baseDupeKey = { type, creatorId, projectId, submissionId };

        // if read skip this and just put into read bucket
        if (isUnread) {
          const allReadMatches = notifications.filter(
            (n) =>
              n.type === type &&
              !n.isUnread &&
              n.data.creatorId === creatorId &&
              (!projectId || n.data.projectId === projectId) &&
              (!submissionId || n.data.submissionId === submissionId)
          );
          const mostRecentReadMatch = mostRecent(allReadMatches);
          // const THREE_HOURS = 3 * 60 * 60 * 1000;
          // TODO temporarily disable this behaviour without breaking
          // tests/removing code by just using 3s over 3h
          const THREE_SECONDS = 3 * 1000;
          const closeToRead =
            mostRecentReadMatch &&
            new Date(updatedAt) - new Date(mostRecentReadMatch.updatedAt) <
              THREE_SECONDS;

          // if not <3h since recent read, this goes into the unread bucket
          if (!closeToRead) {
            const dupeKey = JSON.stringify({ ...baseDupeKey, isUnread: true });
            notifsByDupeData[dupeKey] = [
              ...(notifsByDupeData[dupeKey] || []),
              notif,
            ];
            break;
          }
        }
        // read or closeToRead - treat as read
        const dupeKey = JSON.stringify({ ...baseDupeKey, isUnread: false });
        notifsByDupeData[dupeKey] = [
          ...(notifsByDupeData[dupeKey] || []),
          { ...notif, isUnread: false },
        ];
        break;
      }
      default: {
        // mark dupe if data, type and isUnread is the same
        const { type, isUnread, data } = notif;
        const dupeKey = JSON.stringify({ type, isUnread, ...data });
        notifsByDupeData[dupeKey] = [
          ...(notifsByDupeData[dupeKey] || []),
          notif,
        ];
        break;
      }
    }
  });

  return Object.values(notifsByDupeData).map((dupedNotifications) => {
    const latest = mostRecent(dupedNotifications);
    return {
      ...latest,
      count: dupedNotifications.length,
    };
  });
};
