import { createCheckout, createPortal } from '../services/stripe';

export const manageLicenses = async ({
  schoolId,
  token,
  returnUrl,
}: {
  schoolId: string;
  token: string;
  returnUrl: string;
}) => {
  const { url } = await createPortal({
    body: {
      schoolId,
      returnUrl,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  window.location.assign(url);
};

export const buyLicense = async ({
  schoolId,
  token,
  productId,
  promoCode,
  successUrl,
  cancelUrl,
}: {
  schoolId: string;
  token: string;
  productId?: string;
  promoCode?: string;
  successUrl: string;
  cancelUrl: string;
}) => {
  const { url } = await createCheckout({
    body: {
      schoolId,
      successUrl,
      cancelUrl,
      productId,
      promoCode,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  window.location.assign(url);
};
