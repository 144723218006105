import React, { memo } from 'react';
import cx from 'classnames';

import PtProgress from '../ptProgress/PtProgress';
import PrimaryButton from '../primaryButton/PrimaryButton';

import styles from './Placeholder.module.css';

export type Props = {
  className?: string;
  isLoading?: boolean;
  message: string;
  retryAction?: () => void;
  showRetryAction?: boolean;
  short?: boolean;
  buttonLabel?: string;
};

const Placeholder = ({
  className,
  isLoading,
  message,
  retryAction,
  showRetryAction = true,
  short,
  buttonLabel,
}: Props) => (
  <div
    className={cx(styles.wrapper, className, {
      [styles.short]: short,
    })}
  >
    <h3>{message}</h3>

    {isLoading && (
      <div className={styles.progressWrapper}>
        <PtProgress type="linear" mode="indeterminate" />
      </div>
    )}

    {retryAction && showRetryAction && (
      <PrimaryButton
        ariaLabel={buttonLabel}
        className={styles.button}
        onClick={retryAction}
      >
        Retry
      </PrimaryButton>
    )}
  </div>
);

export default memo(Placeholder);
