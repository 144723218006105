import React, { memo } from 'react';

import styles from './BlockCreatorIcon.module.css';

type Props = {
  className?: string;
};

const BlockCreatorIcon = ({ className }: Props) => (
  <svg viewBox="0 0 55.2 31.59" className={className}>
    <g transform="translate(-133.594 -3035)">
      <g transform="matrix(0.998, 0.07, -0.07, 0.998, 533.261, 3049.566)">
        <path
          className={styles.st3}
          d="M-378.18,34.19c-1.15-0.69-6.87-6.44-6.87-6.44c6.94,0,15.72-0.74,28.3-1.44"
        />
        <line
          className={styles.st3}
          x1="-379.33"
          y1="20.3"
          x2="-385.05"
          y2="27.75"
        />
      </g>
    </g>
  </svg>
);

export default memo(BlockCreatorIcon);
