import { gql } from '@apollo/client';

export default gql`
  mutation UpdateLastRead {
    updateLastRead {
      status
      lastRead
    }
  }
`;
