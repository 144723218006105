import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import {
  GetProject,
  GetProjectVariables,
} from '../../graphql/queries/projects/types/GetProject';
import { GET_PROJECT } from '../../graphql/queries/projects';
import { buildChallengeUrl } from '../../utils/urlBuilder';
import styles from './NotificationCard.module.css';

type Props = {
  projectId: string;
  projectVersionId?: string;
};

const ProjectLink = ({ projectId, projectVersionId }: Props) => {
  const { data, loading, error } = useQuery<GetProject, GetProjectVariables>(
    GET_PROJECT,
    { variables: { id: projectId } }
  );
  const { project } = data || {};

  const link = buildChallengeUrl(projectId, { edit: true });

  const fallback = (
    <Link to={link}>
      &quot;
      <span className={styles.unknownValue}>challenge name</span>
      &quot;
    </Link>
  );

  if (!project || loading || error) {
    return fallback;
  }

  const { versions } = project;
  const version = projectVersionId
    ? versions.find(({ id }) => id === projectVersionId)
    : versions[versions.length - 1];

  if (!version) {
    return fallback;
  }

  return (
    <Link to={link}>
      &quot;
      {version.title}
      &quot;
    </Link>
  );
};

export default ProjectLink;
