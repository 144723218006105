import { gql } from '@apollo/client';
import { AccountFrag } from '../../fragments';

export default gql`
  mutation UpdateAccount($tutorialsCompleted: [String!]) {
    updateAccount(tutorialsCompleted: $tutorialsCompleted) {
      ...AccountFields
      tutorialsCompleted
    }
  }

  ${AccountFrag}
`;
