import React, { memo } from 'react';
import cx from 'classnames';
import { string, shape } from 'prop-types';

import styles from './PencilIcon.module.css';
import useTheme from '../../hooks/useTheme';

const propTypes = { className: string, theme: shape({}) };

const defaultProps = {
  className: '',
  theme: {},
};

const PencilIcon = ({ className, theme: customTheme }) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(theme.root, className)}
      viewBox="0 0 54.28 34.65"
    >
      <g transform="translate(915 1220.563)">
        <g transform="translate(-3724.924 -2485.804)">
          <path
            className={theme.background}
            d="M2852.28,1274.13c-7.38,6.23-16.21,17.09-22.98,23.25c-2.04,0.41-5.84,2.5-7.91,2.33
			c-0.18-2.07,1.97-5.82,2.38-7.86c6.77-6.16,15.52-17.11,22.9-23.34"
          />
          <path
            className={theme.background}
            d="M2846.67,1268.52c0.79,0.28,4.69,4.53,5.62,5.62"
          />
          <path
            className={theme.background}
            d="M2823.77,1291.85c0,0,3.87,2.49,5.53,5.53"
          />
        </g>
        <g transform="translate(-3724.924 -2485.804)">
          <path
            className={theme.outline}
            d="M2854.75,1273.93c-7.38,6.23-16.21,17.09-22.98,23.25c-2.04,0.41-5.84,2.51-7.91,2.33
			c-0.18-2.07,1.97-5.82,2.38-7.86c6.77-6.16,15.52-17.11,22.9-23.33"
          />
          <path
            className={theme.outline}
            d="M2849.13,1268.31c0.79,0.28,4.69,4.53,5.62,5.62"
          />
          <path
            className={theme.outline}
            d="M2826.24,1291.65c0,0,3.87,2.49,5.53,5.53"
          />
        </g>
      </g>
    </svg>
  );
};

PencilIcon.propTypes = propTypes;
PencilIcon.defaultProps = defaultProps;

export default memo(PencilIcon);
