import React from 'react';
import cx from 'classnames';

import { logInLabel, signUpLabel } from '../../strings';
import styles from './SignUpHandler.module.css';
import separatorStyles from '../../globalStyles/Separator.module.css';
import Button from '../atoms/button/Button';

export type Props = {
  login?: () => void;
  register?: () => void;
  className?: string;
};

export default ({ login, register, className }: Props) => (
  <div className={cx(styles.wrapper, className)}>
    {login && (
      <Button
        className={cx(
          styles.button,
          styles.login,
          separatorStyles.separatorAfter
        )}
        onClick={() => login()}
        unstyled
        aria-label={logInLabel}
        role="menuitem"
      >
        {logInLabel}
      </Button>
    )}

    {register && (
      <Button
        className={styles.button}
        onClick={() => register()}
        unstyled
        aria-label={signUpLabel}
        role="menuitem"
      >
        {signUpLabel}
      </Button>
    )}
  </div>
);
