import React, { memo } from 'react';
import { bool } from 'prop-types';

const propTypes = {
  isVisible: bool,
};

const defaultProps = {
  isVisible: false,
};

const PasswordIcon = ({ isVisible }) =>
  isVisible ? (
    <svg
      data-testid="PasswordIcon-Hide"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.004 24.004"
    >
      <path d="M23.694 10.887c-.04-.08-.09-.159-.149-.229a14.814 14.814 0 0 0-5.019-4.101c-.079-.04-.169-.08-.249-.119l4.79-4.788c.06-.07.1-.159.1-.249 0-.1-.04-.189-.1-.249l-.358-.358a.35.35 0 0 0-.498 0L17.072 5.93a14.485 14.485 0 0 0-4.889-.906h-.369a14.56 14.56 0 0 0-6.343 1.533 14.8 14.8 0 0 0-5.009 4.101c-.06.07-.11.149-.149.229a1.916 1.916 0 0 0 0 1.931c.05.07.1.149.149.219a14.78 14.78 0 0 0 5.009 4.101c.09.05.179.09.269.119L.941 22.055a.35.35 0 0 0 0 .498l.359.349c.06.07.149.11.249.11.09 0 .179-.04.249-.11l5.128-5.126c1.563.577 3.206.876 4.889.896h.369c2.23-.03 4.361-.538 6.343-1.533a14.835 14.835 0 0 0 5.019-4.101c.05-.07.1-.149.149-.219.169-.299.259-.637.259-.966a1.994 1.994 0 0 0-.26-.966zM6.647 16.352a5.836 5.836 0 0 1-.627-.289A13.503 13.503 0 0 1 1.41 12.3a.747.747 0 0 1 0-.896 13.755 13.755 0 0 1 4.61-3.773 18.1 18.1 0 0 1 1.006-.448 6.803 6.803 0 0 0-1.852 4.669 6.76 6.76 0 0 0 1.603 4.37l-.13.13zm-.269-4.499a5.625 5.625 0 0 1 9.151-4.38l-2.181 2.18a2.58 2.58 0 0 0-3.545 3.544l-2.181 2.18a5.61 5.61 0 0 1-1.244-3.524zm6.921-.438c.05.139.08.289.08.438 0 .757-.617 1.374-1.374 1.374-.149 0-.299-.03-.438-.08l1.732-1.732zm-2.589.876a1.306 1.306 0 0 1-.08-.438c0-.757.617-1.374 1.374-1.374.149 0 .299.03.438.08l-1.732 1.732zm1.294 5.176a5.553 5.553 0 0 1-3.525-1.244l2.181-2.17c.388.239.846.378 1.344.378a2.586 2.586 0 0 0 2.579-2.578c0-.498-.139-.956-.378-1.344l2.181-2.18a5.623 5.623 0 0 1-4.382 9.138zm10.585-5.166a13.478 13.478 0 0 1-5.616 4.221 6.84 6.84 0 0 0 1.852-4.669 6.766 6.766 0 0 0-1.593-4.37l.129-.129c.209.08.418.179.627.279 1.782.896 3.376 2.2 4.6 3.773.11.129.159.289.159.448a.684.684 0 0 1-.158.447z" />
    </svg>
  ) : (
    <svg
      data-testid="PasswordIcon-Show"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.004 24.004"
    >
      <path d="M23.69 10.888c-.04-.08-.09-.159-.149-.229a14.64 14.64 0 0 0-5.019-4.102 14.513 14.513 0 0 0-6.333-1.533h-.369c-2.23.03-4.361.548-6.343 1.533a14.838 14.838 0 0 0-5.019 4.102c-.06.07-.11.149-.149.229a1.916 1.916 0 0 0 0 1.931c.05.07.1.149.149.219a14.829 14.829 0 0 0 5.019 4.102 14.405 14.405 0 0 0 6.333 1.533h.379c2.22-.03 4.361-.538 6.333-1.533a14.649 14.649 0 0 0 5.019-4.102c.05-.07.1-.149.149-.219.169-.299.259-.637.259-.966a1.97 1.97 0 0 0-.259-.965zM6.016 16.065a13.468 13.468 0 0 1-4.6-3.763.719.719 0 0 1 0-.896 13.622 13.622 0 0 1 4.6-3.773 18.1 18.1 0 0 1 1.006-.448 6.84 6.84 0 0 0-1.842 4.669c0 1.732.667 3.405 1.842 4.669a12.593 12.593 0 0 1-1.006-.458zM12 17.469a5.622 5.622 0 0 1-5.616-5.615c0-3.106 2.519-5.625 5.616-5.625s5.626 2.519 5.626 5.625c0 3.096-2.529 5.615-5.626 5.615zm10.595-5.167a13.607 13.607 0 0 1-4.61 3.763 18.23 18.23 0 0 1-1.006.458 6.84 6.84 0 0 0 1.852-4.669 6.803 6.803 0 0 0-1.852-4.669 18.1 18.1 0 0 1 1.006.448c1.782.896 3.376 2.2 4.61 3.773.1.129.149.289.149.448a.732.732 0 0 1-.149.448z" />
      <path d="M12 9.275a2.58 2.58 0 0 0-2.579 2.579c0 1.414 1.155 2.579 2.579 2.579s2.579-1.165 2.579-2.579A2.58 2.58 0 0 0 12 9.275zm0 3.953c-.757 0-1.374-.617-1.374-1.374S11.244 10.48 12 10.48s1.374.617 1.374 1.374-.617 1.374-1.374 1.374z" />
    </svg>
  );
PasswordIcon.propTypes = propTypes;
PasswordIcon.defaultProps = defaultProps;
export default memo(PasswordIcon);
