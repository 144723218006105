import React, { memo, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Tag.module.css';
import Button from '../atoms/button/Button';

export type Props = {
  label: string;
  highlighted?: boolean;
  canRemove?: boolean;
  onRemove?: () => void;
  leftAction?: ReactNode;
  className?: string;
  onTagClick?: (label: string) => void;
  selected?: boolean;
  title?: string;
};

const Tag = ({
  label,
  highlighted,
  canRemove,
  onRemove,
  leftAction,
  className,
  onTagClick,
  selected,
  title,
}: Props) => {
  return (
    <div
      title={title}
      className={cx(styles.root, className, {
        [styles.selected]: selected,
      })}
    >
      <div className={styles.body}>
        {leftAction}

        {onTagClick ? (
          <Button
            unstyled
            onClick={() => onTagClick(label)}
            className={cx(styles.label, { [styles.highlighted]: highlighted })}
          >
            {label}
          </Button>
        ) : (
          <div
            className={cx(styles.label, { [styles.highlighted]: highlighted })}
          >
            {label}
          </div>
        )}

        {canRemove && onRemove && (
          <Button
            unstyled
            title="remove"
            aria-label={`Remove ${label}`}
            onClick={onRemove}
            className={styles.removeButton}
          >
            <div className={styles.cross} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(Tag);
