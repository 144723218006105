import { gql } from '@apollo/client';

import { NotificationFrag } from '../../fragments';

export default gql`
  query GetNotifications {
    getNotifications {
      ...NotificationFields
    }
  }
  ${NotificationFrag}
`;
