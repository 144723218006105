import React from 'react';

import styles from './AudioIcon.module.css';

const AudioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <rect className={styles.rect} />
      <g>
        <g>
          <path
            className={styles.path}
            d="M15.8,0.5c-0.5-0.3-1.1-0.3-1.6,0c0,0-0.1,0-0.1,0.1L7.1,7C7,7,7,7,6.9,7H2.5C1.6,7,0.9,7.8,0.9,8.7v6.7
            c0,0.9,0.7,1.6,1.6,1.6h4.3C7,17,7,17,7.1,17l6.9,6.4c0,0,0.1,0,0.1,0.1c0.3,0.2,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2
            c0.5-0.3,0.8-0.8,0.8-1.4V1.9C16.6,1.3,16.3,0.8,15.8,0.5z M15.5,22.1c0,0.3-0.2,0.5-0.3,0.5c-0.1,0-0.3,0.1-0.5,0l-6.9-6.4
            c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.2-0.8-0.2H2.5c-0.3,0-0.6-0.3-0.6-0.6V8.7c0-0.3,0.3-0.6,0.6-0.6h4.3c0.3,0,0.6-0.1,0.8-0.2
            c0,0,0.1,0,0.1-0.1l6.9-6.4c0.2-0.1,0.5,0,0.5,0c0.1,0.1,0.3,0.2,0.3,0.5V22.1z"
          />
          <path
            className={styles.path}
            d="M14.9,24c-0.3,0-0.7-0.1-0.9-0.3c0,0-0.1-0.1-0.1-0.1L7,17.2c0,0-0.1,0-0.1,0H2.5c-1,0-1.9-0.8-1.9-1.9V8.7
            c0-1,0.8-1.9,1.9-1.9h4.3c0,0,0.1,0,0.1,0l6.9-6.4c0,0,0.1-0.1,0.1-0.1c0.6-0.3,1.3-0.3,1.9,0c0.6,0.3,0.9,0.9,0.9,1.6v20.3
            c0,0.7-0.4,1.3-0.9,1.6C15.6,23.9,15.3,24,14.9,24z M2.5,7.3c-0.8,0-1.4,0.6-1.4,1.4v6.7c0,0.8,0.6,1.4,1.4,1.4h4.3
            c0.1,0,0.2,0,0.4,0.1l0.1,0l6.9,6.4c0,0,0,0,0.1,0c0.4,0.2,0.9,0.2,1.4,0c0.4-0.2,0.7-0.7,0.7-1.2V1.9c0-0.5-0.3-0.9-0.7-1.2l0,0
            c-0.4-0.2-0.9-0.2-1.4,0l-7,6.5C7.1,7.3,7,7.3,6.9,7.3H2.5z M14.9,23c-0.1,0-0.2,0-0.4-0.1l-0.1,0l-6.9-6.4
            c-0.3-0.2-0.5-0.2-0.7-0.2H2.5c-0.5,0-0.8-0.4-0.8-0.8V8.7c0-0.5,0.4-0.8,0.8-0.8h4.3c0.2,0,0.5-0.1,0.7-0.2l7-6.5
            c0.4-0.2,0.7,0,0.8,0c0.1,0.1,0.4,0.3,0.4,0.7v20.3c0,0.3-0.2,0.6-0.4,0.7C15.3,22.9,15.1,23,14.9,23z M14.8,22.4
            c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.3V1.9c0-0.2-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.3,0L8,8c0,0-0.1,0.1-0.1,0.1
            C7.5,8.2,7.2,8.3,6.9,8.3H2.5c-0.2,0-0.3,0.1-0.3,0.3v6.7c0,0.2,0.1,0.3,0.3,0.3h4.3c0.3,0,0.7,0.1,0.9,0.3C7.9,16,7.9,16,8,16
            L14.8,22.4z"
          />
        </g>
        <g>
          <path
            className={styles.path}
            d="M19.6,5.6c-0.2,0.2-0.2,0.5,0,0.7c3.3,3.3,3.3,8.6,0,11.8c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2
            c0.1,0,0.3-0.1,0.4-0.2c3.7-3.7,3.7-9.6,0-13.3C20.1,5.4,19.8,5.4,19.6,5.6z"
          />
          <path
            className={styles.path}
            d="M20,19.3c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1c3.2-3.2,3.2-8.3,0-11.5c-0.3-0.3-0.3-0.8,0-1.1
            c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.2c3.8,3.8,3.8,9.9,0,13.6C20.4,19.2,20.2,19.3,20,19.3z M20,5.7
            c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.3,0,0.4c3.4,3.4,3.4,8.8,0,12.2c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0
            c3.6-3.6,3.6-9.4,0-12.9C20.1,5.8,20.1,5.7,20,5.7L20,5.7z"
          />
        </g>
        <g>
          <path
            className={styles.path}
            d="M18.5,7.2C18.3,7,18,7,17.8,7.2c-0.2,0.2-0.2,0.5,0,0.7c2.2,2.2,2.2,5.9,0,8.1c-0.2,0.2-0.2,0.5,0,0.7
            c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2C21.2,14.2,21.2,9.8,18.5,7.2z"
          />
          <path
            className={styles.path}
            d="M18.1,17.2c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1c2.1-2.1,2.1-5.6,0-7.8c-0.3-0.3-0.3-0.8,0-1.1
            s0.8-0.3,1.1,0c2.7,2.7,2.7,7.2,0,10C18.5,17.1,18.3,17.2,18.1,17.2z M18.1,7.3c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.3,0,0.4
            c2.3,2.3,2.3,6.1,0,8.5c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0c2.6-2.6,2.6-6.7,0-9.3C18.3,7.3,18.2,7.3,18.1,7.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AudioIcon;
