import React, { memo, ReactNode } from 'react';
import cx from 'classnames';
import shortid from 'shortid';

import styles from './LabelledCheckbox.module.css';
import radioBg from '../../../assets/images/radio.svg';
import checkboxBg from '../../../assets/images/checkbox.svg';

export type Props = {
  id?: string;
  className?: string;
  disabled?: boolean;
  name?: string;
  label?: ReactNode;
  textLeft?: boolean;
  checked?: boolean;
  value?: string;
  errorMessage?: string;
  onChange: (value: string) => void;
  inline?: boolean;
  icon?: React.ReactNode;
  noCheckIcon?: boolean;
  monoIcon?: boolean;
  singleSelectUi?: boolean;
  square?: boolean;
  ariaLabel?: string;
};

const LabelledCheckbox = ({
  checked,
  className,
  disabled,
  errorMessage,
  icon,
  id,
  inline,
  label,
  name,
  noCheckIcon,
  monoIcon,
  onChange,
  singleSelectUi = false,
  textLeft,
  value,
  square,
  ariaLabel,
}: Props) => {
  // need a unique id to link label user clicks to the hidden checkbox input
  // use id prop or name-value if non empty, or generate one
  const identifier: string =
    id || (name && value && `${name}-${value}`) || shortid.generate();
  const background = square ? checkboxBg : radioBg;

  return (
    <div className={cx(className, { [styles.inline]: inline })}>
      <input
        type="checkbox"
        name={name}
        value={value}
        id={identifier}
        checked={checked}
        disabled={disabled}
        className={styles.checkbox}
        onChange={() => disabled || onChange(value || '')}
        aria-label={ariaLabel}
        data-testid={identifier}
      />
      <label
        className={cx(styles.label, {
          [styles.inline]: inline,
          [styles.noCheckIcon]: noCheckIcon,
          [styles.singleSelectUi]: singleSelectUi,
          [styles.isSelected]: checked,
        })}
        htmlFor={identifier}
      >
        {textLeft && (
          <span className={cx(styles.labelText, styles.leftLabel)}>
            {label}
            {icon}
            {errorMessage && (
              <span className={styles.errorMsg}>{errorMessage}</span>
            )}
          </span>
        )}

        <span
          className={cx(styles.input, className)}
          style={{ backgroundImage: `url(${background})` }}
        >
          <span
            className={cx(styles.checked, {
              [styles.hidden]: !checked,
              [styles.squareCheck]: square,
              [styles.monoTick]: monoIcon,
            })}
          />
        </span>

        {!textLeft && (
          <span className={cx(styles.labelText, styles.rightLabel)}>
            {label}
            {icon}
            <span className={styles.errorMsg}>{errorMessage}</span>
          </span>
        )}
      </label>
    </div>
  );
};

export default memo(LabelledCheckbox);
