import { gql } from '@apollo/client';
import {
  SubmissionFrag,
  AssignmentFrag,
  GroupFrag,
  GroupAdminFrag,
  GroupMemberFrag,
  ProjectFrag,
  ProjectCollaboratorGroupFrag,
  ProjectVersionFrag,
  BlockFrag,
  TaskResponseFrag,
  CoderFileFrag,
} from '../../fragments';

export const GET_SUBMISSION_FRAGMENT = gql`
  fragment GetSubmissionFragment on Submission {
    ...SubmissionFields
    isSubmissionCollaborator
    isDraftSubmission
    coderFiles {
      ...CoderFileFields
    }
    challenge {
      ...ProjectFields
      hasUnpublishedChanges
      readerVersion {
        ...ProjectVersionFields
        isCollaboratorProject
      }
      draftVersion {
        ...ProjectVersionFields
        isCollaboratorProject
      }
      collaboratorGroups {
        ...ProjectCollaboratorGroupFields
        group {
          ...GroupFields
          groupAdmins {
            ...GroupAdminFields
            admin {
              id
              username
            }
          }
        }
      }
      assignments {
        id
        group {
          id
        }
      }
      studentAssignments {
        id
        group {
          id
          name
        }
      }
      studentForkedAssignments {
        id
        group {
          id
          name
        }
      }
    }
    assignment {
      ...AssignmentFields
      project {
        ...ProjectFields
        hasUnpublishedChanges
        readerVersion {
          ...ProjectVersionFields
          isCollaboratorProject
        }
        draftVersion {
          ...ProjectVersionFields
          isCollaboratorProject
        }
        collaboratorGroups {
          ...ProjectCollaboratorGroupFields
          group {
            ...GroupFields
            groupAdmins {
              ...GroupAdminFields
              admin {
                id
                username
              }
            }
          }
        }
      }
    }
    blocks {
      ...BlockFields
    }
    taskResponses {
      ...TaskResponseFields
    }
    collaboratorGroups {
      ...ProjectCollaboratorGroupFields
      group {
        ...GroupFields
        groupAdmins {
          ...GroupAdminFields
          admin {
            id
            username
          }
        }
        groupMembers {
          ...GroupMemberFields
          member {
            id
            username
          }
        }
      }
    }
  }

  ${CoderFileFrag}
  ${GroupFrag}
  ${GroupAdminFrag}
  ${GroupMemberFrag}
  ${ProjectFrag}
  ${ProjectCollaboratorGroupFrag}
  ${ProjectVersionFrag}
  ${SubmissionFrag}
  ${BlockFrag}
  ${TaskResponseFrag}
  ${AssignmentFrag}
`;

export default gql`
  query GetSubmission($id: String!) {
    submission(id: $id) {
      ...GetSubmissionFragment
    }
  }

  ${GET_SUBMISSION_FRAGMENT}
`;
