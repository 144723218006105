import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MY_ACCOUNT } from '../graphql/queries/accounts';
import { GetMyAccount } from '../graphql/queries/accounts/types/GetMyAccount';
import { MembershipType } from '../types/graphql-types';

type Props = {
  children: ReactNode;
};

type MembershipContext = {
  membershipType: MembershipType;
  membershipExpiry?: IsoDate;
  hasActivatedTrial: boolean;
  membershipIsLoading: boolean;
};

const MembershipContext = React.createContext<MembershipContext | undefined>(
  undefined
);

function MembershipContextProvider({ children }: Props) {
  const {
    data: { myAccount } = {
      myAccount: null,
    },
    loading,
  } = useQuery<GetMyAccount>(GET_MY_ACCOUNT);

  const membershipType = myAccount?.membershipType || MembershipType.NONE;

  return (
    <>
      <MembershipContext.Provider
        value={{
          membershipType,
          membershipExpiry: myAccount?.membershipExpiry || undefined,
          hasActivatedTrial: myAccount?.hasActivatedTrial || false,
          membershipIsLoading: loading,
        }}
      >
        {children}
      </MembershipContext.Provider>
    </>
  );
}

function useMembershipContext() {
  const context = React.useContext(MembershipContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipContext must be used within a MembershipContextProvider'
    );
  }
  return context;
}

export { MembershipContextProvider, useMembershipContext };
