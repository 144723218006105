import { gql, OnSubscriptionDataOptions } from '@apollo/client';

import { GET_NOTIFICATIONS } from '../../queries/notifications';
import { GetNotifications } from '../../queries/notifications/types/GetNotifications';

import { NotificationFrag } from '../../fragments';
import { OnNotification } from './types/OnNotification';

export default gql`
  subscription OnNotification {
    onNotification {
      ...NotificationFields
    }
  }
  ${NotificationFrag}
`;

export const onSubscriptionData = ({
  client,
  subscriptionData: { data },
}: OnSubscriptionDataOptions<OnNotification>) => {
  const { getNotifications = [] } =
    client.readQuery<GetNotifications>({
      query: GET_NOTIFICATIONS,
    }) || {};

  if (!getNotifications || !data || !data.onNotification) {
    return;
  }

  const mergeIndex = getNotifications.findIndex(
    ({ id }) => id === data.onNotification?.id
  );

  const notifications =
    mergeIndex >= 0
      ? getNotifications.slice().splice(mergeIndex, 1, data.onNotification)
      : [...getNotifications, data.onNotification];

  client.writeQuery<GetNotifications>({
    query: GET_NOTIFICATIONS,
    data: {
      getNotifications: notifications,
    },
  });
};
