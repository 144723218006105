import { gql } from '@apollo/client';

export default gql`
  fragment GroupMemberFields on GroupMember {
    id
    isApproved
    createdAt
    updatedAt
    __typename
  }
`;
