import React, { Fragment } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.css';

export type Props = {
  crumbs: { label: string; to: string }[];
  className?: string;
};

const Breadcrumbs = ({ crumbs, className }: Props) => {
  return (
    <div className={cx(className, styles.breadcrumbs)}>
      {crumbs.map((crumb, index) => {
        const isLastCrumb = index === crumbs.length - 1;
        return (
          <Fragment key={crumb.to}>
            <span className={styles.breadcrumb}>
              <Link to={crumb.to}>{crumb.label}</Link>
            </span>
            {!isLastCrumb && (
              <span className={styles.breadcrumbSeparator}>&gt;</span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
