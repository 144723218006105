import React, { memo } from 'react';

import styles from './FurtherSquare.module.css';

const FurtherSquare = () => (
  <div className={styles.wrapper}>
    <svg
      version="1.1"
      viewBox="0 0 21.488 21.227"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={styles.f}
          d="M 11.079 1.677 L 11.079 1.987 C 11.079 2.487 11.039 3.007 10.709 3.007 L 3.569 3.007 L 3.569 9.687 L 9.519 9.687 C 9.839 9.687 9.889 10.187 9.889 10.677 L 9.889 10.987 C 9.889 11.487 9.849 11.997 9.519 11.997 L 3.569 11.997 L 3.569 20.027 C 3.569 20.397 2.929 20.447 2.369 20.447 L 2.079 20.447 C 1.459 20.447 0.829 20.397 0.829 20.027 L 0.829 0.687 L 10.709 0.687 C 11.039 0.687 11.079 1.187 11.079 1.677 Z"
        />
        <polygon
          className={styles.triangle}
          points="20.609 20.482 20.609 12.452 10.679 20.482"
        />
      </g>
    </svg>
  </div>
);

export default memo(FurtherSquare);
