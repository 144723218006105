import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Keycloak, {
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
} from 'keycloak-js';
import { useDispatch } from 'react-redux';
import { gql, useApolloClient, useQuery } from '@apollo/client';

import keycloak, { ready } from '../keycloak';
import { LOG_OUT } from '../actions/root/actionTypes';
import LoginDialog from '../components/loginDialog/LoginDialog';
import { usePersistedCacheContext } from '../apollo/PersistedCacheApolloProvider';
import logException from '../services/logging/exception';
import {
  GetAuthContext,
  GetAuthContext_myAccount,
} from './types/GetAuthContext';

// adds extra Further history entry because keycloak overwrites history
const pushLocationToHistory = (location = window.location.href) =>
  window.history.pushState(null, document.title, location);

const addRedirectUriParams = (options: KeycloakLoginOptions | undefined) => {
  const redirectUri = new URL(options?.redirectUri || window.location.href);
  redirectUri.searchParams.append('justLoggedIn', 'true');

  return {
    ...options,
    redirectUri: redirectUri.toString(),
  };
};

const GET_AUTH_CONTEXT = gql`
  query GetAuthContext {
    myAccount {
      __typename
      id
      username
      firstName
      lastName
      email
      emailVerified
      userType
      isStudent
      membershipType
      membershipExpiry
      hasActivatedTrial
      restricted
      tutorialsCompleted
      lastRead
      hardwarePreferences
    }
  }
`;

type KeycloakSnapshot = Omit<
  Keycloak,
  | 'init'
  | 'login'
  | 'logout'
  | 'register'
  | 'accountManagement'
  | 'createLoginUrl'
  | 'createLogoutUrl'
  | 'createRegisterUrl'
  | 'createAccountUrl'
  | 'loadUserProfile'
  | 'loadUserInfo'
  | 'updateToken'
  | 'clearToken'
  | 'hasRealmRole'
  | 'hasResourceRole'
  | 'isTokenExpired'
  | 'profile' // use myAccount instead of profile
>;

function createKeycloakSnapshot(): KeycloakSnapshot {
  return {
    ...keycloak,
  };
}

export type AuthContextValue = KeycloakSnapshot & {
  isReady: boolean;
  login: (options?: KeycloakLoginOptions) => Promise<void>;
  logout: (options?: KeycloakLogoutOptions) => Promise<void>;
  register: (options?: KeycloakLoginOptions) => Promise<void>;
  createLoginUrl: (options?: KeycloakLoginOptions) => string;
  openLoginDialog: (message: string, options?: Record<string, boolean>) => void;
  myAccount?: GetAuthContext_myAccount | null;
};

const AuthContext = React.createContext<undefined | AuthContextValue>(
  undefined
);

export type Props = {
  children: ReactNode;
};

function AuthContextProvider({ children }: Props) {
  const { isCacheReady } = usePersistedCacheContext();
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [keycloakSnapshot, setKeycloakSnapshot] = useState<KeycloakSnapshot>(
    createKeycloakSnapshot
  );
  const [keycloakIsReady, setKeycloakIsReady] = useState(false);
  const [
    {
      active: loginDialogActive,
      message: loginDialogMessage,
      options: loginOptions,
    },
    setLoginDialog,
  ] = useState({
    active: false,
    message: '',
    options: {},
  });

  const { loading: myAccountLoading, data: { myAccount } = {} } = useQuery<
    GetAuthContext
  >(GET_AUTH_CONTEXT);

  // keep onAuthRefreshSuccess event handler up-to-date
  keycloak.onAuthRefreshSuccess = async () => {
    // rerender consumers with new keycloak profile state
    setKeycloakSnapshot(createKeycloakSnapshot());
  };

  // keep onAuthRefreshError event handler up-to-date
  keycloak.onAuthRefreshError = async () => {
    // reset redux state
    dispatch({ type: LOG_OUT });

    try {
      // reset graphql cache to prevent user specific data being retained
      await client.resetStore();
    } catch (e) {
      logException(e as Error); // log error from refetched query
    }

    // reset i18n to default browser locale
    localStorage.removeItem('i18nextLng');

    // rerender consumers with new keycloak state
    setKeycloakSnapshot(createKeycloakSnapshot());
  };

  // init keycloak
  useEffect(() => {
    keycloak
      .init({
        // check keycloak tokens to check user session
        onLoad: 'check-sso',
        // silently check sso if possible
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      })
      .then(async () => {
        // rerender consumers with new authenticated state
        setKeycloakSnapshot(createKeycloakSnapshot());

        // handle partially completed logins (eg initiated externally)
        if (window.location.hash.includes('session_state=')) {
          return keycloak.login();
        }
      })
      .catch((e) => logException(e));
  }, []);

  useEffect(() => {
    ready.then(() => setKeycloakIsReady(true));
  }, []);

  // keycloak.authenticated is undefined when keycloak first becomes ready
  // but auth context should not be marked isReady until it's set
  const isReady = useMemo(
    () =>
      keycloakIsReady &&
      typeof keycloakSnapshot.authenticated !== 'undefined' &&
      (!!myAccount || !myAccountLoading),
    [
      keycloakIsReady,
      keycloakSnapshot.authenticated,
      myAccount,
      myAccountLoading,
    ]
  );

  // refresh accessToken if it's within two minutes of expiring
  useEffect(() => {
    const checkAccessTokenInterval = setInterval(async () => {
      if (keycloak.authenticated) {
        try {
          await keycloak.updateToken(120);
        } catch (e) {
          // error handled by onAuthRefreshError event handler
        }
      }
    }, 60000);

    return () => clearInterval(checkAccessTokenInterval);
  }, []);

  // reset apollo cache if user is not authenticated
  useEffect(() => {
    if (
      isCacheReady &&
      keycloakIsReady &&
      typeof keycloakSnapshot.authenticated !== 'undefined' &&
      !keycloakSnapshot.authenticated
    ) {
      client.resetStore().catch(logException);
    }
  }, [keycloakIsReady, isCacheReady, keycloakSnapshot.authenticated, client]);

  const initialValue = useMemo<AuthContextValue>(
    () => ({
      ...keycloak,
      isReady: false,
      myAccount: null,
      login: (options) => {
        pushLocationToHistory();
        return keycloak.login(addRedirectUriParams(options));
      },
      logout: async (options) => {
        dispatch({ type: LOG_OUT }); // reset redux state

        try {
          // reset graphql cache to prevent user specific data being retained
          await client.resetStore();
        } catch (e) {
          logException(e as Error); // log error from refetched query
        }

        // reset i18n to default browser locale
        localStorage.removeItem('i18nextLng');

        pushLocationToHistory();
        return keycloak.logout({
          redirectUri: window.location.origin,
          ...options,
        });
      },
      register: (options) => {
        pushLocationToHistory();
        return keycloak.register(addRedirectUriParams(options));
      },
      createLoginUrl: keycloak.createLoginUrl.bind(keycloak),
      openLoginDialog: (
        message: string,
        options: Record<string, boolean> = {}
      ) =>
        setLoginDialog({
          active: true,
          message,
          options,
        }),
    }),
    [client, dispatch]
  );

  // memoise context value to avoid rerendering consumers unnecessarily
  const value = useMemo<AuthContextValue>(
    () =>
      isReady
        ? {
            ...keycloakSnapshot,
            isReady: true,
            myAccount,
            login: initialValue.login,
            logout: initialValue.logout,
            register: initialValue.register,
            createLoginUrl: initialValue.createLoginUrl,
            openLoginDialog: initialValue.openLoginDialog,
          }
        : initialValue,
    [isReady, initialValue, keycloakSnapshot, myAccount]
  );

  return (
    <>
      <AuthContext.Provider value={value}>
        {children}

        <LoginDialog
          active={loginDialogActive}
          message={loginDialogMessage}
          {...loginOptions}
          handleClose={() =>
            setLoginDialog({
              active: false,
              message: '',
              options: {},
            })
          }
        />
      </AuthContext.Provider>
    </>
  );
}

function useAuth() {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }

  return auth;
}

export { AuthContextProvider, useAuth, AuthContext };
