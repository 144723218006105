import React, { memo } from 'react';

import CircleIcon from '../circleIcon/CircleIcon';
import styles from './AddIcon.module.css';

type Props = {
  className?: string;
  noCircle?: boolean;
};

const AddIcon = ({ className = '', noCircle = false, ...restProps }: Props) => {
  const addIcon = (
    <g>
      <path
        className={styles.line}
        d="m 10.085674,17.500755 c 6.23706,0.652729 12.545484,0.547592 18.749242,-0.30227"
      />
      <path
        className={styles.line}
        d="m 19.30092,8.717381 c -0.390114,5.756287 -0.285449,11.530094 0.323508,17.268855"
      />
    </g>
  );

  if (noCircle)
    return (
      <g {...restProps} className={className}>
        {addIcon}
      </g>
    );

  return (
    <CircleIcon {...restProps} className={className}>
      {addIcon}
    </CircleIcon>
  );
};

export default memo(AddIcon);
