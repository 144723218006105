import { combineReducers } from 'redux';

import notification from './reducers/notification';
import commonStandards from './reducers/commonStandards';
import * as types from './actions/root/actionTypes';

const appReducer = combineReducers({
  notification,
  commonStandards,
});

export default (state, action) => {
  let nextState = state;

  if (action.type === types.LOG_OUT) {
    nextState = {};
  }

  return appReducer(nextState, action);
};
