import React, { memo } from 'react';

import { getFileUrl } from '../../utils/getAssetUrl';

import styles from './DownloadTag.module.css';
import Tag, { Props as TagProps } from '../tag/Tag';

const DownloadIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.041 20"
    className={className}
  >
    <g className={styles.arrow}>
      <path
        d="M19.082,12.227c-.329.548-3.067,3.271-3.067,3.271,0-3.3-.413-8.709-.75-14.7"
        transform="translate(-6.255 -0.328)"
        fill="none"
        stroke="#505050"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
      <path
        d="M0,0L3.544,2.723"
        transform="translate(6.215 12.446)"
        fill="none"
        stroke="#505050"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </g>
    <path
      d="M.8,32.976c.156.008.313.009.469.011,3.449.023,6.9.067,10.349.014,1.468-.023,6.275-.064,7.28-.063"
      transform="translate(-0.328 -13.498)"
      fill="none"
      stroke="#505050"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
  </svg>
);

export type Props = TagProps & {
  url: string;
  filename?: string;
};

const DownloadTag = ({ label, filename = label, url, ...tagProps }: Props) => {
  return (
    <Tag
      {...tagProps}
      label={label}
      leftAction={
        <a
          href={`${getFileUrl(url)}?name=${filename}`}
          download={filename}
          target="_blank"
          rel="noreferrer"
          className={styles.downloadButton}
          aria-label={`Download ${label}`}
        >
          <DownloadIcon className={styles.downloadIcon} />
        </a>
      }
    />
  );
};

export default memo(DownloadTag);
