import React, { memo, useState } from 'react';
import { ColumnDef, Table } from '@tanstack/react-table';

import styles from './SchoolRoster.module.css';
import AddSchoolMembersDialog from '../addSchoolMembersDialog/AddSchoolMembersDialog';
import RemoveSchoolMembersDialog from '../removeSchoolMembersDialog/RemoveSchoolMembersDialog';
import ResetSchoolMemberPasswordsDialog from '../resetSchoolMemberPasswordsDialog/ResetSchoolMemberPasswordsDialog';
import { Header } from '../multiSelectTable/Header';
import { TextCell } from '../multiSelectTable/TextCell';
import { TagsCell } from '../multiSelectTable/TagsCell';
import Roster from '../roster/Roster';
import AddSchoolContentAccessDialog from '../addSchoolContentAccessDialog/AddSchoolContentAccessDialog';
import { WarningIcon } from '../icons';
import { missingContentAccessWarning } from '../../strings';
import HelpWidget from '../helpWidget/HelpWidget';
import UpdateSchoolMembersRoleDialog from '../updateSchoolMembersRoleDialog/UpdateSchoolMembersRoleDialog';
import getAccountName from '../../utils/getAccountName';

type ContentAccessPartial = {
  id: string;
  realm: { name: string } | null;
  course: { title: string } | null;
};

type ContentAccess = ContentAccessPartial & {
  schoolMembers: { id: string }[];
  numBuildingLicenses: number;
  maxSeats: number;
};

export type Member = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username: string;
  role: 'admin' | 'teacher' | 'student';
  passwordManaged: boolean;
  classes: { id: string; name: string }[];
  contentAccess: ContentAccess[];
  missingContentAccess: ContentAccessPartial[];
  isCurrentUser: boolean;
};

function nameAccessor(member: Member) {
  if (member.isCurrentUser) {
    return `${getAccountName(member)} (You)`;
  }
  return getAccountName(member);
}

export type Props = {
  schoolId: string;
  userIsSchoolAdmin: boolean;
  isSchoolEditor: boolean;
  members: Member[];
  schoolContentAccess: ContentAccess[];
  className?: string;
};

const SchoolRoster = ({
  schoolId,
  userIsSchoolAdmin,
  isSchoolEditor,
  members = [],
  schoolContentAccess = [],
  className,
}: Props) => {
  const [tableRef, setTableRef] = useState<Table<Member> | null>(null);
  const [showAddMembersDialog, setShowAddMembersDialog] = useState(false);
  const [showRemoveMembersDialog, setShowRemoveMembersDialog] = useState(false);
  const [showAddContentAccessDialog, setShowAddContentAccessDialog] = useState(
    false
  );
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false);
  const [
    showUpdateSchoolMembersRoleDialog,
    setShowUpdateSchoolMembersRoleDialog,
  ] = useState(false);

  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const selectedMembers = selectedMemberIds
    .map((id) => members.find((member) => member.id === id))
    .filter((member): member is Member => !!member);

  const columns = React.useMemo<ColumnDef<Member>[]>(
    () => [
      {
        id: 'name',
        accessorFn: nameAccessor,
        header: (header) => (
          <Header
            key="name"
            name="Name"
            header={header}
            filterType="search"
            className={styles.nameColumn}
          />
        ),
        cell: (info) => (
          <TextCell key="name" info={info} className={styles.nameColumn} />
        ),
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        accessorKey: 'username',
        header: (header) => (
          <Header
            key="username"
            name="Username"
            header={header}
            filterType="search"
            className={styles.usernameColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="username"
            info={info}
            className={styles.usernameColumn}
          />
        ),
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        accessorKey: 'email',
        header: (header) => (
          <Header
            key="email"
            name="Email"
            header={header}
            filterType="search"
            className={styles.emailColumn}
          />
        ),
        cell: (info) => (
          <TextCell key="email" info={info} className={styles.emailColumn} />
        ),
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        id: 'role',
        accessorFn: (member) => [
          {
            id: member.role,
            label: member.role,
          },
        ],
        header: (header) => (
          <Header
            key="role"
            name="Role"
            header={header}
            filterType="filter"
            filterOptions={['admin', 'teacher', 'student']}
            className={styles.roleColumn}
          />
        ),
        cell: (info) => (
          <TagsCell key="role" info={info} className={styles.roleColumn} />
        ),
        filterFn: 'tags',
        sortingFn: (rowA, rowB, columnId) => {
          const aValue = rowA.getValue(columnId);
          const bValue = rowB.getValue(columnId);
          if (!Array.isArray(aValue) || !Array.isArray(bValue)) return 0;
          const roleOrder = ['student', 'teacher', 'admin'];
          return (
            roleOrder.indexOf(aValue[0].label) -
            roleOrder.indexOf(bValue[0].label)
          );
        },
      },
      {
        id: 'content-access',
        accessorFn: (member) => [
          ...member.missingContentAccess.map(({ id, course, realm }) => ({
            id,
            label: realm?.name || course?.title || 'Unknown',
            leftAction: (
              <WarningIcon className={styles.missingContentAccessWarningIcon} />
            ),
            title: missingContentAccessWarning,
            className: styles.missingContentAccessTag,
          })),
          ...member.contentAccess.map(({ id, course, realm }) => {
            const label = realm?.name || course?.title || 'Unknown';
            return {
              id,
              label,
              title: label,
            };
          }),
        ],
        header: (header) => (
          <Header
            key="content-access"
            name="Content Access"
            header={header}
            filterType="filter"
            filterOptions={Array.from(
              new Set(
                members
                  .map((member) =>
                    member.contentAccess
                      .map(({ realm, course }) => realm?.name || course?.title)
                      .filter((name): name is string => !!name)
                  )
                  .flat()
              )
            ).sort()}
            className={styles.contentAccessColumn}
          />
        ),
        cell: (info) => (
          <TagsCell
            key="content-access"
            info={info}
            className={styles.contentAccessColumn}
          />
        ),
        filterFn: 'tags',
        enableSorting: false,
      },
      {
        id: 'classes',
        accessorFn: (member) =>
          member.classes.map(({ id, name }) => ({
            id,
            label: name,
            title: name,
          })),
        header: (header) => (
          <Header
            key="classes"
            name="Classes"
            header={header}
            className={styles.classesColumn}
            filterType="filter"
            filterOptions={Array.from(
              new Set(
                members
                  .map((member) => member.classes.map(({ name }) => name))
                  .flat()
              )
            ).sort()}
          />
        ),
        cell: (info) => (
          <TagsCell
            key="classes"
            info={info}
            className={styles.classesColumn}
          />
        ),
        filterFn: 'tags',
        enableSorting: false,
      },
    ],
    [members]
  );

  return (
    <>
      <Roster
        tableRef={setTableRef}
        columns={columns}
        data={members}
        className={className}
        selectedMemberIds={selectedMemberIds}
        onSelectedMembersChange={setSelectedMemberIds}
        onAddMembersClick={() => setShowAddMembersDialog(true)}
        disabled={!isSchoolEditor}
        multiSelectActionMenuItems={[
          {
            type: 'button',
            content: 'Remove Members',
            onClick: () => setShowRemoveMembersDialog(true),
          },
          {
            type: 'button',
            content: 'Grant Content Access',
            onClick: () => setShowAddContentAccessDialog(true),
          },
          {
            type: 'button',
            content: 'Reset Passwords',
            onClick: () => setShowPasswordResetDialog(true),
          },
          {
            type: 'button',
            content: 'Update Role',
            onClick: () => setShowUpdateSchoolMembersRoleDialog(true),
          },
        ]}
        addMembersHelp={
          <HelpWidget
            tooltipPlacement="bottom-end"
            message={
              <>
                Use the Add Members button to add new students, teachers and
                admins to your school.
                <br />
                <br />
                <a
                  href="https://knowledgebase.pi-top.com/knowledge/how-do-i-roster-my-students"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about rostering students
                </a>
              </>
            }
          />
        }
      />

      <AddSchoolMembersDialog
        schoolId={schoolId}
        userIsSchoolAdmin={userIsSchoolAdmin}
        handleClose={() => setShowAddMembersDialog(false)}
        active={showAddMembersDialog}
      />

      {showRemoveMembersDialog && (
        <RemoveSchoolMembersDialog
          schoolId={schoolId}
          userIsSchoolAdmin={userIsSchoolAdmin}
          selectedMembers={selectedMembers}
          handleClose={() => setShowRemoveMembersDialog(false)}
          onCompleted={() => tableRef?.resetRowSelection()}
          active
        />
      )}

      {showAddContentAccessDialog && (
        <AddSchoolContentAccessDialog
          schoolId={schoolId}
          selectedMembers={selectedMembers}
          schoolContentAccess={schoolContentAccess}
          handleClose={() => setShowAddContentAccessDialog(false)}
          active
        />
      )}

      {showPasswordResetDialog && (
        <ResetSchoolMemberPasswordsDialog
          schoolId={schoolId}
          selectedMembers={selectedMembers}
          handleClose={() => setShowPasswordResetDialog(false)}
          active
        />
      )}

      {showUpdateSchoolMembersRoleDialog && (
        <UpdateSchoolMembersRoleDialog
          schoolId={schoolId}
          selectedMembers={selectedMembers}
          userIsSchoolAdmin={userIsSchoolAdmin}
          handleClose={() => setShowUpdateSchoolMembersRoleDialog(false)}
          active
        />
      )}
    </>
  );
};

export default memo(SchoolRoster);
