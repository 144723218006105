import { gql } from '@apollo/client';

export default gql`
  fragment AccountFields on Account {
    id
    firstName
    lastName
    username
    email
    emailVerified
    __typename
  }
`;
