import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ColumnDef, Table } from '@tanstack/react-table';

import styles from './ImportSchoolClassesDialog.module.css';
import logException from '../../services/logging/exception';
import Select from '../atoms/select/Select';
import {
  ImportSchoolClasses,
  ImportSchoolClassesVariables,
} from './types/ImportSchoolClasses';
import { GetImportSchoolClassesDialog } from './types/GetImportSchoolClassesDialog';
import { useAuth } from '../../context/Auth';
import { MyAccountClasses } from './types/MyAccountClasses';
import Placeholder from '../placeholder/Placeholder';
import MultiSelectTable from '../multiSelectTable/MultiSelectTable';
import {
  errorDialogMessage,
  youMustLoginThroughGoogleClassroomOrClever,
} from '../../strings';
import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import { Header } from '../multiSelectTable/Header';
import { TextCell } from '../multiSelectTable/TextCell';
import usePrevious from '../../hooks/usePrevious';

type ExternalClass = {
  id: string;
  name: string;
  source: string;
  students: { id: string; email: string }[];
  otherTeachers: { id: string; email: string }[];
};

const GET_IMPORT_SCHOOL_CLASSES_DIALOG = gql`
  query GetImportSchoolClassesDialog($schoolId: String!) {
    externalClasses

    school(id: $schoolId) {
      __typename
      id
      schoolMembers {
        __typename
        id
        role
        account {
          __typename
          id
          email
        }
      }
      schoolClasses {
        __typename
        id
        externalId
      }
    }
  }
`;

// schoolClasses should match DashboardClassFragment
const IMPORT_SCHOOL_CLASSES = gql`
  mutation ImportSchoolClasses(
    $schoolId: String!
    $classes: [UnstructuredObject!]!
  ) {
    importSchoolClasses(id: $schoolId, classes: $classes) {
      __typename
      id
      schoolClasses {
        __typename
        id
        name
        externalId
        groupAdmins {
          __typename
          id
        }
        groupMembers {
          __typename
          id
        }
        school {
          __typename
          id
          name
        }
        assignments {
          id
          submissions {
            __typename
            grade
            completedAt
          }
        }
        schoolClassMembers {
          __typename
          id
          schoolMember {
            __typename
            id
            role
            account {
              __typename
              id
              email
            }
          }
        }
      }
    }
  }
`;

const schoolToOption = (school: { id: string; name: string }) => ({
  value: school.id,
  label: school.name,
});

const optionToSchool = (option: { value: string; label: string }) => ({
  id: option.value,
  name: option.label,
});

type Props = {
  schools: { id: string; name: string }[];
  handleClose: () => void;
} & Omit<
  DialogProps,
  | 'title'
  | 'confirmAction'
  | 'isLoading'
  | 'active'
  | 'size'
  | 'theme'
  | 'handleClose'
>;

const ImportSchoolClassesDialog = ({ schools, ...dialogProps }: Props) => {
  const { myAccount } = useAuth();
  const [tableRef, setTableRef] = useState<Table<ExternalClass> | null>(null);
  const [chosenSchool, setChosenSchool] = useState<{
    id: string;
    name: string;
  } | null>(schools.length === 1 ? schools[0] : null);
  const [selectedClasses, setSelectedClasses] = useState<ExternalClass[]>([]);

  const {
    loading: getClassesLoading,
    error: getClassesError,
    data: { externalClasses = [], school } = {},
  } = useQuery<GetImportSchoolClassesDialog>(GET_IMPORT_SCHOOL_CLASSES_DIALOG, {
    onError: (err) => logException(err),
    variables: { schoolId: chosenSchool?.id },
    skip: !chosenSchool,
    notifyOnNetworkStatusChange: true,
  });

  const [
    importSchoolClasses,
    { loading: importingSchoolClasses, error: importSchoolClassesError },
  ] = useMutation<ImportSchoolClasses, ImportSchoolClassesVariables>(
    IMPORT_SCHOOL_CLASSES,
    {
      onError: (err) => logException(err),
      onCompleted: () => {
        dialogProps.handleClose();
        tableRef?.resetRowSelection();
      },
      update: (cache, { data }) => {
        if (!myAccount || !data) return;
        const myAccountClassesFragment = gql`
          fragment MyAccountClasses on Account {
            classes {
              __typename
              id
            }
          }
        `;

        const myAccountClasses = cache.readFragment<MyAccountClasses>({
          id: cache.identify({ __typename: 'Account', id: myAccount.id }),
          fragment: myAccountClassesFragment,
        });

        cache.writeFragment<MyAccountClasses>({
          id: cache.identify({ __typename: 'Account', id: myAccount.id }),
          fragment: myAccountClassesFragment,
          data: {
            ...myAccount,
            classes: [
              ...(myAccountClasses?.classes || []),
              ...data.importSchoolClasses.schoolClasses.filter(
                (schoolClass) =>
                  !myAccountClasses?.classes?.some(
                    (existingClass) => existingClass.id === schoolClass.id
                  ) &&
                  schoolClass.schoolClassMembers.some(
                    ({ schoolMember }) =>
                      schoolMember?.account &&
                      schoolMember.account.id === myAccount.id
                  )
              ),
            ],
          },
        });
      },
    }
  );

  const previousSchool = usePrevious(school);
  useEffect(() => {
    if (previousSchool !== school) {
      setSelectedClasses(
        externalClasses.filter(
          (ext) => !!school?.schoolClasses?.some((c) => c.externalId === ext.id)
        ) as ExternalClass[]
      );
    }
  }, [externalClasses, previousSchool, school]);

  const columns = React.useMemo<ColumnDef<ExternalClass>[]>(
    () => [
      {
        accessorKey: 'name',
        header: (header) => (
          <Header
            key="name"
            name="Name"
            header={header}
            filterType="search"
            className={styles.nameColumn}
          />
        ),
        cell: (info) => (
          <TextCell key="name" info={info} className={styles.nameColumn} />
        ),
        filterFn: 'fuzzy',
        sortingFn: 'fuzzy',
      },
      {
        accessorKey: 'source',
        header: (header) => (
          <Header
            key="source"
            name="Source"
            header={header}
            filterType="filter"
            filterOptions={['clever', 'google']}
            className={styles.sourceColumn}
          />
        ),
        cell: (info) => (
          <TextCell key="source" info={info} className={styles.sourceColumn} />
        ),
        filterFn: 'tags',
        sortingFn: 'count',
      },
      {
        id: 'imported',
        accessorFn: (externalClass) =>
          Boolean(
            school?.schoolClasses?.some(
              (c) => c.externalId === externalClass.id
            )
          ).toString(),
        header: (header) => (
          <Header
            key="imported"
            name="Imported"
            header={header}
            className={styles.importedColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="imported"
            info={info}
            className={styles.importedColumn}
          />
        ),
      },
      {
        id: 'students',
        accessorFn: (externalClass) =>
          (externalClass.students?.length || 0).toFixed(0),
        header: (header) => (
          <Header
            key="students"
            name="Students"
            header={header}
            className={styles.studentsColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="students"
            info={info}
            className={styles.studentsColumn}
          />
        ),
        enableSorting: false,
      },
      {
        id: 'teachers',
        accessorFn: (externalClass) =>
          ((externalClass.otherTeachers?.length || 0) + 1).toFixed(0),
        header: (header) => (
          <Header
            key="teachers"
            name="Teachers"
            header={header}
            className={styles.teachersColumn}
          />
        ),
        cell: (info) => (
          <TextCell
            key="teachers"
            info={info}
            className={styles.teachersColumn}
          />
        ),
        enableSorting: false,
      },
    ],
    [school?.schoolClasses]
  );

  const placeholderMessage = React.useMemo(() => {
    if (!chosenSchool) {
      return 'Select a school to import classes to...';
    }

    if (getClassesLoading) {
      return 'Loading external classes...';
    }

    if (
      getClassesError &&
      getClassesError.graphQLErrors.some(
        (e) => e.extensions?.exception?.statusCode === 400
      )
    ) {
      return youMustLoginThroughGoogleClassroomOrClever;
    }

    if (getClassesError) {
      return errorDialogMessage('loading external classes');
    }

    return 'No external classes found.';
  }, [chosenSchool, getClassesLoading, getClassesError]);

  return (
    <Dialog
      {...dialogProps}
      active
      title="Import Classes from Clever or Google Classroom"
      isLoading={importingSchoolClasses}
      confirmAction={{
        label: 'Import Classes',
        onClick: () => {
          if (!school) return;
          importSchoolClasses({
            variables: {
              schoolId: school.id,
              classes: selectedClasses.map((externalClass) => ({
                id: externalClass.id,
                name: externalClass.name,
                source: externalClass.source,
              })),
            },
          });
        },
        disabled: !selectedClasses.length || importingSchoolClasses,
      }}
      size="large"
      theme={{
        bodyContainer: styles.dialogBodyContainer,
        innerContainer: styles.dialogInnerContainer,
      }}
    >
      <div className={styles.schoolSelectContainer}>
        <div
          id="import-classes-school-select-label"
          className={styles.schoolSelectLabel}
        >
          School
        </div>

        <Select
          value={chosenSchool && schoolToOption(chosenSchool)}
          isMulti={false}
          options={schools.map(schoolToOption)}
          onChange={(option) =>
            setChosenSchool(option && optionToSchool(option))
          }
          aria-labelledby="import-classes-school-select-label"
          placeholder="Select school to import classes to..."
          isDisabled={importingSchoolClasses || schools.length === 1}
          aria-invalid={!school}
        />

        {!schools.length && (
          <span className={styles.errorMessage}>
            You must have a school to add the class to, ask your school admin to
            add you or create your own school
          </span>
        )}
      </div>

      <div className={styles.list}>
        {!externalClasses.length || getClassesLoading || getClassesError ? (
          <Placeholder
            isLoading={getClassesLoading}
            message={placeholderMessage}
            className={styles.placeholder}
          />
        ) : (
          <MultiSelectTable
            key={school?.id}
            tableRef={setTableRef}
            columns={columns}
            data={externalClasses as ExternalClass[]}
            getRowId={(externalClass) => externalClass.id}
            initialRowSelection={externalClasses.reduce((acc, ext) => {
              acc[ext.id] = !!school?.schoolClasses?.some(
                (c) => c.externalId === ext.id
              );
              return acc;
            }, {})}
            onSelectedRowsChange={setSelectedClasses}
            className={styles.table}
            disabled={importingSchoolClasses}
          />
        )}
      </div>

      {importingSchoolClasses && (
        <Placeholder
          isLoading={importingSchoolClasses}
          message="Importing classes..."
          className={styles.placeholder}
        />
      )}

      {!!importSchoolClassesError && (
        <span className={styles.errorMessage}>
          {errorDialogMessage(
            `importing classes: ${importSchoolClassesError.message}`
          )}
        </span>
      )}
    </Dialog>
  );
};

export default ImportSchoolClassesDialog;
