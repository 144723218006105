import * as types from '../../../actions/commonStandards/actionTypes';
import serialiseError from '../../../utils/error';

export default (state = null, action) => {
  switch (action.type) {
    case types.FETCH_STANDARDS_FAILURE:
      return serialiseError(action.error);

    case types.FETCH_STANDARDS_REQUEST:
    case types.RESET_FETCH_STANDARDS_ERRORS:
      return null;

    default:
      return state;
  }
};
