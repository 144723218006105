import React from 'react';
import cx from 'classnames';

import Button, { Props as ButtonProps } from '../atoms/button/Button';

import styles from './PrimaryButton.module.css';

export type Props = ButtonProps;

const PrimaryButton = ({
  ariaLabel,
  children,
  className,
  disabled = false,
  onClick,
  type,
  ...props
}: Props) => {
  return (
    <Button
      {...props}
      ariaLabel={ariaLabel}
      className={cx(className, styles.btn, styles.border, styles.buttonText)}
      disabled={disabled}
      onClick={onClick}
      type={type}
      unstyled
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
