/* eslint react-hooks/exhaustive-deps: warn */

import React, {
  useState,
  useEffect,
  useRef,
  ReactChild,
  useCallback,
  useContext,
} from 'react';
import semver, { SemVer } from 'semver';
import { useQuery } from '@apollo/client';

import onPitop from '../utils/onPitop';
import FurtherLink, {
  sdkPackageName,
  sdkMinVersion,
  linkMinVersion,
  linkUploadVersion,
  OnKeyListenType,
  OnKeyEventType,
  StartOptions,
  NovncOptions,
  PtySize,
  FurtherLinkProcess,
  FurtherLinkBluetooth,
  BackendConnection,
  IpConnection,
  BluetoothConnection,
  FurtherLinkServer,
  createFurtherLink,
  FurtherLinkRemote,
  RemoteConnection,
  LinkType,
  FurtherLinkError,
} from '../utils/furtherLink';
import usePrevious from '../hooks/usePrevious';
import { CodeRunKeyEventType } from '../types/graphql-types';
import UpdateFurtherLinkDialog from '../components/updateFurtherLinkDialog/UpdateFurtherLinkDialog';
import Dialog from '../components/dialog/Dialog';
import {
  furtherLinkRunError,
  furtherLinkConnectError,
  furtherLinkBluetoothConnectError,
  furtherLinkNoBluetoothAdapterError,
  furtherLinkUploadError,
  systemSoftwareBehindMajor,
  systemSoftwareBehindMinor,
  systemSoftwareBehindPatch,
  systemSoftwareNotFound,
  furtherLinkName,
  sdkName,
} from '../strings';
import { CodeDirectory } from './CodeDirectory';
import { AuthContext } from './Auth';
import logInfo from '../services/logging/info';
import { GET_MY_ACCOUNT } from '../graphql/queries/accounts';
import { GetMyAccount } from '../graphql/queries/accounts/types/GetMyAccount';

const mainProcessId = 'main';
const defaultRunner = 'python3';

export const novncWindowHeight = 1240;
export const novncWindowWidth = 1560;

async function fetchDeviceVersions(
  link: FurtherLink | FurtherLinkBluetooth | FurtherLinkRemote | null
) {
  const furtherLinkRes = await link?.checkVersion().catch(() => null);
  const sdkRes = await link
    ?.checkPackageVersion(sdkPackageName)
    .catch(() => null);

  return {
    furtherLink: furtherLinkRes && semver.coerce(furtherLinkRes.version),
    sdk: sdkRes && semver.coerce(sdkRes.version),
  };
}

function getUpdateReason({
  furtherLink,
  sdk,
}: {
  furtherLink: SemVer | null | undefined;
  sdk: SemVer | null | undefined;
}) {
  const minVersions = {
    furtherLink: semver.coerce(linkMinVersion),
    sdk: semver.coerce(sdkMinVersion),
  };

  if (!minVersions.furtherLink || !minVersions.sdk)
    throw new Error('Invalid minimum further link or sdk version');

  if (!furtherLink) return systemSoftwareNotFound(furtherLinkName);

  if (furtherLink.major < minVersions.furtherLink.major)
    return systemSoftwareBehindMajor(furtherLinkName);

  if (!sdk) return systemSoftwareNotFound(sdkName);

  if (sdk.major < minVersions.sdk.major)
    return systemSoftwareBehindMajor(sdkName);

  if (furtherLink.minor < minVersions.furtherLink.minor)
    return systemSoftwareBehindMinor(furtherLinkName);

  if (sdk.minor < minVersions.sdk.minor)
    return systemSoftwareBehindMinor(sdkName);

  if (furtherLink.patch < minVersions.furtherLink.patch)
    return systemSoftwareBehindPatch(furtherLinkName);

  if (sdk.patch < minVersions.sdk.patch)
    return systemSoftwareBehindPatch(sdkName);

  return 'Software Update Needed';
}

enum ConnectionState {
  Connecting = 'CONNECTING',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
}

type Props = {
  autoConnect?: boolean;
  children: ReactChild;
};

type RunOptions = Omit<StartOptions, 'processId' | 'runner' | 'novnc'> & {
  processId?: string;
  runner?: string;
  novncOptions?: NovncOptions;
};

type Link =
  | {
      connectLink: (connection: BackendConnection) => Promise<void>;
      disconnectLink: () => void;
      cancelConnectLink: () => void;
      linkBackend: () => BackendConnection | null;
      sendInput: (content: string, processId?: string) => void;
      sendResize: (size: PtySize, processId?: string) => void;
      upload: (directory: CodeDirectory) => Promise<void>;
      run: (options: RunOptions) => Promise<FurtherLinkProcess>;
      stop: (processId?: string) => Promise<void>;
      addProcess: (processId?: string) => FurtherLinkProcess;
      keyListen: (key: string, processId?: string) => void;
      resetKeyListens: (processId?: string) => void;
      setOnKeyListen: (
        onKeyListen: OnKeyListenType,
        processId?: string
      ) => void;
      setOnKeyEvent: (onKeyEvent: OnKeyEventType, processId?: string) => void;
      onKeyEvent: (
        key: string,
        event: CodeRunKeyEventType,
        processId?: string
      ) => void;
      novncUrl: (port: number, path: string) => string | undefined;
      serverName: string;
    }
  | undefined;

type Context = {
  ip: string;
  link: Link;
  serverName: string;
  isConnected: boolean;
  isConnecting: boolean;
  connectError: boolean;
};

type CallableChild = { children: (Context: Context) => React.ReactNode };

type IpRecord = {
  ip: string;
};

type IpLocalStorageState = { [userKey: string]: IpRecord } | undefined;

const FurtherLinkContext = React.createContext<Context | undefined>(undefined);

export const unauthenticatedKey = 'unauthenticated';

function FurtherLinkContextProvider({ autoConnect, children }: Props) {
  const { tokenParsed: { sub: userKey = 'unauthenticated' } = {} } =
    useContext(AuthContext) || {};
  const [storedIps, setStoredIps] = useState<IpLocalStorageState>(undefined); // ipaddress from localstorage
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.Disconnected
  );
  const [connectError, setConnectError] = useState<
    { connectError: string } | boolean
  >(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [runError, setRunError] = useState<{ runError: Error } | boolean>(
    false
  );
  const [requestedIp, setRequestedIp] = useState<string>(''); // ipaddress from coderunner (to be stored)
  const linkRef = useRef<
    FurtherLink | FurtherLinkBluetooth | FurtherLinkRemote | null
  >(null); // reference to furtherlink
  const [activeProcesses, setActiveProcesses] = useState<
    Record<string, boolean>
  >({});
  const [deviceVersions, setDeviceVersions] = useState<{
    furtherLink: semver.SemVer | null | undefined;
    sdk: semver.SemVer | null | undefined;
  }>({ furtherLink: null, sdk: null });
  const [updateDialogActive, setUpdateDialogActive] = useState(false);

  const { data: { myAccount } = {} } = useQuery<GetMyAccount>(GET_MY_ACCOUNT);

  // retrieve local storage state or if not possible create new default state
  const loadLocalStorage = useCallback(() => {
    const defaultIpState = { ip: '' };

    if (onPitop()) {
      defaultIpState.ip = 'localhost';
    }

    try {
      const serializedState = localStorage.getItem('furtherLink');

      if (serializedState === null || typeof serializedState === 'undefined') {
        setStoredIps({
          [userKey]: defaultIpState,
        });
      } else {
        const localStorageState = JSON.parse(serializedState);
        setStoredIps({
          ...localStorageState,
          [userKey]:
            localStorageState[userKey] ||
            localStorageState[unauthenticatedKey] ||
            defaultIpState,
        });
      }
    } catch (error) {
      setStoredIps({
        [userKey]: defaultIpState,
      });
    }
  }, [userKey]);
  useEffect(loadLocalStorage, [loadLocalStorage]);

  useEffect(() => {
    // write to localstorage and update state
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const writeToLocalStorage = (newState: any) => {
      try {
        localStorage.setItem('furtherLink', JSON.stringify(newState));
      } catch (error) {
        return undefined;
      }
      return true;
    };

    if (connectionState === ConnectionState.Connected) {
      setStoredIps((previousState) => {
        const newState = {
          ...previousState,
          [userKey]: { ip: requestedIp },
        };
        writeToLocalStorage(newState);
        return newState;
      });
    }
  }, [requestedIp, connectionState, userKey]);

  const onError = useCallback((e: Error) => {
    switch (e.message) {
      case 'Bad upload':
        setUploadError(true);
        break;
      default:
        setRunError({ runError: e });
    }
  }, []);

  const stop = useCallback(async (processId = mainProcessId) => {
    try {
      await linkRef.current?.stop(processId);
    } catch (e) {
      setRunError({ runError: e as Error });
    }
  }, []);

  const disconnectLink = useCallback(() => {
    setActiveProcesses({});
    if (linkRef.current) {
      linkRef.current.disconnect();
    }
  }, []);

  const cancelConnectLink = useCallback(() => {
    if (linkRef.current) {
      linkRef.current.cancelConnect();
    }
  }, []);

  const linkBackend = useCallback(() => {
    return linkRef.current ? linkRef.current.backend : null;
  }, []);

  const connectLink = useCallback(
    async (
      connection: IpConnection | BluetoothConnection | RemoteConnection,
      options?: { hideConnectError?: boolean }
    ) => {
      linkRef.current = createFurtherLink(connection);
      if (connection instanceof RemoteConnection) {
        return;
      }

      setDeviceVersions({ furtherLink: null, sdk: null });
      setConnectionState(ConnectionState.Connecting);

      if (connection instanceof IpConnection) {
        setRequestedIp(connection.ip);
      }

      const onDisconnect = () => {
        setActiveProcesses({});
        setConnectionState(ConnectionState.Disconnected);
      };

      let connected: boolean | void | undefined = false;
      try {
        connected = await linkRef.current?.connect(onError, onDisconnect);

        // connecting was cancelled
        if (!connected) {
          return onDisconnect();
        }

        // don't check versions if not connected to local device
        if (
          linkRef.current?.server instanceof FurtherLinkServer &&
          !linkRef.current?.server?.isLocal
        ) {
          return;
        }

        // check device versions if connected
        const { furtherLink, sdk } = await fetchDeviceVersions(linkRef.current);
        setDeviceVersions({ furtherLink, sdk });

        // show update dialog if versions are invalid
        if (
          !furtherLink ||
          !sdk ||
          semver.lt(furtherLink, linkMinVersion) ||
          semver.lt(sdk, sdkMinVersion)
        ) {
          setUpdateDialogActive(true);
        }
      } catch (e) {
        let message: string | undefined;
        if (e instanceof Error) {
          ({ message } = e);
        } else if (e instanceof Event) {
          message = `Connection failed. Event type: ${e.type}, target: ${e.target}`;
        } else {
          message = String(e);
        }

        // show connect error dialog if connection failed
        if (!options?.hideConnectError && message) {
          setConnectError({ connectError: message });
          logInfo({
            type: 'codeConnectError',
            message,
            connection,
            user: userKey,
          });
        }
      } finally {
        setConnectionState(
          connected ? ConnectionState.Connected : ConnectionState.Disconnected
        );
      }
    },
    [onError, userKey]
  );

  // autoconnect when there is a new localstorage ip address
  const previousIpDetails = usePrevious(storedIps);
  const previousUserKey = usePrevious(userKey);
  useEffect(() => {
    const ip = storedIps && storedIps[userKey] && storedIps[userKey].ip;
    const previousIp =
      previousIpDetails &&
      previousIpDetails[previousUserKey || userKey] &&
      previousIpDetails[previousUserKey || userKey].ip;
    const shouldAutoConnect =
      autoConnect &&
      ip !== previousIp &&
      connectionState === ConnectionState.Disconnected;
    if (ip && shouldAutoConnect) {
      connectLink(new IpConnection(ip), { hideConnectError: true });
    }
  }, [
    autoConnect,
    storedIps,
    previousIpDetails,
    userKey,
    previousUserKey,
    connectionState,
    connectLink,
  ]);

  // when not connected, sync stored ip changes from other pages
  const onStorageChange = useCallback(() => {
    if (connectionState === ConnectionState.Disconnected) {
      loadLocalStorage();
    }
  }, [connectionState, loadLocalStorage]);

  useEffect(() => {
    window.addEventListener('storage', onStorageChange);
    return () => window.removeEventListener('storage', onStorageChange);
  }, [onStorageChange]);

  const sendInput = useCallback(
    (content: string, processId = mainProcessId) => {
      if (linkRef.current) {
        linkRef.current.sendInput(content, processId);
      }
    },
    []
  );

  const sendResize = useCallback((size: PtySize, processId = mainProcessId) => {
    if (linkRef.current) {
      linkRef.current.sendResize(size, processId);
    }
  }, []);

  const upload = useCallback(
    async (directory: CodeDirectory) => {
      if (!linkRef.current) {
        throw new Error('No active link');
      }
      try {
        await linkRef.current.upload(directory, myAccount?.username);
      } catch (e) {
        // show update dialog if local device does not have min upload version
        if (
          linkRef.current?.server instanceof FurtherLinkServer &&
          linkRef.current?.server?.isLocal &&
          !(
            deviceVersions.furtherLink &&
            semver.gte(deviceVersions.furtherLink, linkUploadVersion)
          )
        ) {
          setUpdateDialogActive(true);
        } else {
          setUploadError(true);
        }
      }
    },
    [deviceVersions.furtherLink, myAccount?.username]
  );

  const run = useCallback(
    async (options: RunOptions) => {
      if (!linkRef.current) {
        throw new Error('No active link');
      }

      const processId = options.processId || mainProcessId;
      const runner = options.runner || defaultRunner;
      const novncOptions = options.novncOptions || {
        enabled:
          processId === mainProcessId &&
          linkRef.current.backend?.type !== LinkType.Bluetooth,
        height: novncWindowHeight,
        width: novncWindowWidth,
      };

      const isActiveProcess = activeProcesses[processId];

      if (isActiveProcess) {
        await stop(processId);
      }

      setActiveProcesses({ ...activeProcesses, [processId]: true });

      const onStop = (data: { exitCode: number }) => {
        setActiveProcesses({ ...activeProcesses, [processId]: false });
        options.onStop(data);
      };

      try {
        return await linkRef.current.start({
          ...options,
          processId,
          runner,
          onStop,
          novncOptions,
        });
      } catch (e) {
        setRunError({ runError: e as Error });
        onStop({ exitCode: 126 });
        throw new Error('Run Error');
      }
    },
    [activeProcesses, stop]
  );

  const resetError = useCallback(() => {
    setConnectError(false);
    setRunError(false);
    setUploadError(false);
  }, []);

  const addProcess = useCallback((processId = mainProcessId) => {
    if (!linkRef.current) {
      throw new Error('No active link');
    }
    return linkRef.current.addProcess(processId);
  }, []);

  const keyListen = useCallback((key: string, processId = mainProcessId) => {
    linkRef.current?.processes[processId]?.keyListen(key);
  }, []);

  const resetKeyListens = useCallback((processId = mainProcessId) => {
    linkRef.current?.processes[processId]?.resetKeyListens();
  }, []);

  const setOnKeyListen = useCallback(
    (onKeyListen: OnKeyListenType, processId = mainProcessId) => {
      const processes = linkRef.current?.processes;
      const process = processes ? processes[processId] : undefined;
      if (process) {
        process.onKeyListen = onKeyListen;
      }
    },
    []
  );

  const setOnKeyEvent = useCallback(
    (onKeyEvent: OnKeyEventType, processId = mainProcessId) => {
      const processes = linkRef.current?.processes;
      const process = processes ? processes[processId] : undefined;
      if (process) {
        process.onKeyEvent = onKeyEvent;
      }
    },
    []
  );

  const onKeyEvent: OnKeyEventType = useCallback(
    (key, event, processId = mainProcessId) => {
      const process = linkRef.current?.processes[processId];
      if (typeof process?.onKeyEvent === 'function') {
        process.onKeyEvent(key, event);
      }
    },
    []
  );

  const novncUrl = useCallback((port: number, path: string) => {
    return linkRef.current?.server instanceof FurtherLinkServer
      ? linkRef.current?.server?.novncUrl(port, path)
      : undefined;
  }, []);

  let ip = '';
  if (requestedIp) {
    // don't change what the user has typed in
    ip = requestedIp;
  } else if (storedIps) {
    // the last successful connection is stored and should be the default
    ip = storedIps[userKey] ? storedIps[userKey].ip : '';
  }

  const serverName = linkRef.current?.serverName || '';

  const link: Link = {
    connectLink,
    disconnectLink,
    cancelConnectLink,
    sendInput,
    sendResize,
    upload,
    run,
    stop,
    addProcess,
    keyListen,
    resetKeyListens,
    setOnKeyListen,
    setOnKeyEvent,
    onKeyEvent,
    novncUrl,
    linkBackend,
    serverName,
  };

  const dialogText = useCallback(() => {
    if (connectError) {
      const error =
        typeof connectError !== 'boolean' && connectError?.connectError;
      switch (error) {
        case FurtherLinkError.NO_BLUETOOTH_DEVICE:
          return furtherLinkNoBluetoothAdapterError;
        case FurtherLinkError.BLUETOOTH_SERVER_ERROR:
        case FurtherLinkError.BLUETOOTH_PAIRING_TIMEOUT:
          return furtherLinkBluetoothConnectError;
        default:
          return furtherLinkConnectError({
            ipAddress: requestedIp,
            linkType: linkRef.current?.backend?.type,
          });
      }
    }
    return uploadError ? furtherLinkUploadError : furtherLinkRunError;
  }, [requestedIp, connectError, uploadError]);

  return (
    <>
      <FurtherLinkContext.Provider
        value={{
          ip,
          serverName,
          link,
          isConnected: connectionState === ConnectionState.Connected,
          isConnecting: connectionState === ConnectionState.Connecting,
          connectError: !!connectError,
        }}
      >
        {children}
      </FurtherLinkContext.Provider>

      <UpdateFurtherLinkDialog
        updateReasonMessage={getUpdateReason(deviceVersions)}
        active={updateDialogActive}
        handleClose={() => setUpdateDialogActive(false)}
        checkUpdated={async () => {
          const versions = await fetchDeviceVersions(linkRef.current);
          return !!(
            versions.furtherLink &&
            versions.sdk &&
            semver.gte(versions.furtherLink, linkMinVersion) &&
            semver.gte(versions.sdk, sdkMinVersion)
          );
        }}
        ip={requestedIp}
      />

      <Dialog
        active={!!(connectError || runError || uploadError)}
        confirmAction={{
          label: 'OK',
          onClick: resetError,
        }}
        style={{ zIndex: 501 }}
        handleClose={resetError}
        title={!connectError ? 'Error' : 'Connection Error'}
        size="medium"
      >
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: dialogText(),
          }}
        />
      </Dialog>
    </>
  );
}

function useFurtherLinkContext() {
  const context = React.useContext(FurtherLinkContext);
  if (context === undefined) {
    throw new Error(
      'useFurtherLinkContext must be used within a FurtherLinkContextProvider'
    );
  }
  return context;
}

function FurtherLinkContextConsumer({ children }: CallableChild) {
  return (
    <FurtherLinkContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'FurtherLinkContextConsumer must be used within a FurtherLinkContextProvider!'
          );
        }
        return children(context);
      }}
    </FurtherLinkContext.Consumer>
  );
}

export {
  FurtherLinkContext,
  FurtherLinkContextProvider,
  FurtherLinkContextConsumer,
  useFurtherLinkContext,
};
