import React, {
  ReactChild,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useQuery, useMutation } from '@apollo/client';

import usePrevious from '../hooks/usePrevious';
import { HardwarePreference } from '../types/graphql-types';
import { getDevices, setDevices } from '../utils/localStorage';
import { GET_MY_ACCOUNT } from '../graphql/queries/accounts';
import { GetMyAccount } from '../graphql/queries/accounts/types/GetMyAccount';
import { UPDATE_HARDWARE_PREFERENCES } from '../graphql/mutations/accounts';
import {
  UpdateHardwarePreferences,
  UpdateHardwarePreferencesVariables,
} from '../graphql/mutations/accounts/types/UpdateHardwarePreferences';
import exception from '../services/logging/exception';
import { isSameSet } from '../utils/array';
import { useAuth } from './Auth';

export type HardwarePreferencesContextValue = {
  isReady: boolean;
  hardwarePreferences: HardwarePreference[];
  setHardwarePreferences: React.Dispatch<
    React.SetStateAction<HardwarePreference[]>
  >;
};

type Props = {
  children: ReactChild;
};

const HardwarePreferencesContext = React.createContext<
  HardwarePreferencesContextValue | undefined
>(undefined);

const HardwarePreferencesProvider = ({ children }: Props) => {
  const { isReady: isAccountReady, authenticated } = useAuth();
  const { data: { myAccount } = {}, loading: accountLoading } = useQuery<
    GetMyAccount
  >(GET_MY_ACCOUNT);
  const previousMyAccount = usePrevious(myAccount);
  const { hardwarePreferences: accountHardwarePreferences } = myAccount || {};
  const previousAccountHardwarePreferences = usePrevious(
    accountHardwarePreferences
  );

  const [updateHardwarePreferences] = useMutation<
    UpdateHardwarePreferences,
    UpdateHardwarePreferencesVariables
  >(UPDATE_HARDWARE_PREFERENCES, { onError: (err) => exception(err) });

  const combineHardwarePreferences = useCallback(() => {
    return Array.from(
      new Set([...getDevices(), ...(accountHardwarePreferences || [])])
    );
  }, [accountHardwarePreferences]);

  const [hardwarePreferences, setHardwarePreferences] = useState(
    combineHardwarePreferences
  );
  const previousHardwarePreferences = usePrevious(hardwarePreferences);

  const value = useMemo(() => {
    return {
      isReady: !accountLoading,
      hardwarePreferences,
      setHardwarePreferences,
    };
  }, [accountLoading, hardwarePreferences]);

  // persist to account when hardwarePreferences change
  useEffect(() => {
    if (
      isAccountReady &&
      authenticated &&
      !isSameSet(previousHardwarePreferences || [], hardwarePreferences) &&
      !isSameSet(hardwarePreferences, accountHardwarePreferences || [])
    ) {
      updateHardwarePreferences({ variables: { hardwarePreferences } });
    }
  }, [
    hardwarePreferences,
    previousHardwarePreferences,
    accountHardwarePreferences,
    isAccountReady,
    authenticated,
    updateHardwarePreferences,
  ]);

  // persist hardwarePreferences when localstorage changed
  useEffect(() => {
    if (!isSameSet(previousHardwarePreferences || [], hardwarePreferences)) {
      setDevices(hardwarePreferences);
    }
  }, [hardwarePreferences, previousHardwarePreferences]);

  // devices from localstorage should be moved to account
  useEffect(() => {
    const newLogin = !previousMyAccount && myAccount;
    if (!newLogin) {
      return;
    }

    // if some devices in local state aren't on the account, update the account
    const someDevicesMissing =
      !accountHardwarePreferences ||
      hardwarePreferences.some((d) => !accountHardwarePreferences.includes(d));

    if (someDevicesMissing) {
      updateHardwarePreferences({
        variables: { hardwarePreferences },
      }).then(() => setDevices([]));
    }
  }, [
    myAccount,
    previousMyAccount,
    hardwarePreferences,
    accountHardwarePreferences,
    updateHardwarePreferences,
  ]);

  // if the accountHardwarePreferences changed externally, update local state
  useEffect(() => {
    if (previousAccountHardwarePreferences !== accountHardwarePreferences) {
      setHardwarePreferences(combineHardwarePreferences());
    }
  }, [
    accountHardwarePreferences,
    previousAccountHardwarePreferences,
    combineHardwarePreferences,
  ]);

  return (
    <HardwarePreferencesContext.Provider value={value}>
      {children}
    </HardwarePreferencesContext.Provider>
  );
};

const useHardwarePreferences = () => {
  const context = React.useContext(HardwarePreferencesContext);
  if (context === undefined) {
    throw new Error(
      'useHardwarePreferences must be used within a HardwarePreferencesProvider'
    );
  }
  return context;
};

export { HardwarePreferencesProvider, useHardwarePreferences };
