import React from 'react';
import cx from 'classnames';

import styles from './BurgerIcon.module.css';

export type Props = {
  className?: string;
};

const BurgerIcon = ({ className }: Props) => (
  <svg className={cx(styles.root, className)}>
    <path
      id="Path_8"
      className={styles.path}
      d="M13.83,18.59c9.27,1.05,18.64,0.89,25.87-0.49"
    />
    <path
      id="Path_79713"
      className={styles.path}
      d="M13.83,25.59c9.27,1.05,18.64,0.89,25.87-0.49"
    />
    <path
      id="Path_79714"
      className={styles.path}
      d="M13.83,32.53c9.27,1.05,18.64,0.89,25.87-0.49"
    />
  </svg>
);

export default BurgerIcon;
