/* eslint no-underscore-dangle: 'off', @typescript-eslint/no-explicit-any: 0 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import rootReducer from './rootReducer';
import { loadState, saveState } from './localStorage';

export default () => {
  const persistedState = loadState();

  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middlewares = [thunk];

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.subscribe(throttle(() => saveState(store.getState()), 1000));

  return store;
};
