import React from 'react';
import { Link } from 'react-router-dom';

import FurtherSquare from '../furtherSquare/FurtherSquare';
import logo from '../../assets/images/pitop-logo.png';
import styles from './Footer.module.css';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer} data-testid="footer">
      <a
        href="https://further.pi-top.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        <FurtherSquare />
      </a>
      <span className={styles.copy}>
        <Link className={styles.policyLink} to="/privacy-policy">
          Privacy Policy
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&copy;
        pi-top {year}
      </span>
      <a
        href="https://www.instagram.com/getpitop/"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          className={styles.icon}
        >
          <path
            d="M8 1.422a24.53 24.53 0 0 1 3.2.089 4.123 4.123 0 0 1 1.511.267 3.122 3.122 0 0 1 1.511 1.511 4.123 4.123 0 0 1 .267 1.511c0 .8.089 1.067.089 3.2a24.53 24.53 0 0 1-.089 3.2 4.123 4.123 0 0 1-.267 1.511 3.122 3.122 0 0 1-1.511 1.511 4.123 4.123 0 0 1-1.511.267c-.8 0-1.067.089-3.2.089a24.53 24.53 0 0 1-3.2-.089 4.123 4.123 0 0 1-1.511-.267 3.122 3.122 0 0 1-1.511-1.511 4.123 4.123 0 0 1-.267-1.511c0-.8-.089-1.067-.089-3.2a24.53 24.53 0 0 1 .089-3.2 4.123 4.123 0 0 1 .267-1.511A3.192 3.192 0 0 1 2.4 2.4a1.5 1.5 0 0 1 .889-.622A4.123 4.123 0 0 1 4.8 1.511 24.53 24.53 0 0 1 8 1.422M8 0a26.264 26.264 0 0 0-3.289.089 5.488 5.488 0 0 0-1.955.355 3.48 3.48 0 0 0-1.422.889 3.48 3.48 0 0 0-.89 1.423 4.05 4.05 0 0 0-.355 1.955A26.264 26.264 0 0 0 0 8a26.264 26.264 0 0 0 .089 3.289 5.488 5.488 0 0 0 .356 1.956 3.48 3.48 0 0 0 .889 1.422 3.48 3.48 0 0 0 1.422.889 5.488 5.488 0 0 0 1.956.356A26.264 26.264 0 0 0 8 16a26.264 26.264 0 0 0 3.289-.089 5.488 5.488 0 0 0 1.956-.356 3.729 3.729 0 0 0 2.311-2.311 5.488 5.488 0 0 0 .356-1.956C15.911 10.4 16 10.133 16 8a26.264 26.264 0 0 0-.089-3.289 5.488 5.488 0 0 0-.356-1.956 3.48 3.48 0 0 0-.889-1.422 3.48 3.48 0 0 0-1.422-.889 5.488 5.488 0 0 0-1.955-.355A26.264 26.264 0 0 0 8 0m0 3.911A4.023 4.023 0 0 0 3.911 8 4.089 4.089 0 1 0 8 3.911m0 6.756A2.619 2.619 0 0 1 5.333 8 2.619 2.619 0 0 1 8 5.333 2.619 2.619 0 0 1 10.667 8 2.619 2.619 0 0 1 8 10.667m4.267-7.911a.978.978 0 1 0 .978.978.986.986 0 0 0-.978-.978"
            fill="#46d3c6"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <a
        href="https://twitter.com/getpitop"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 12.979"
          className={styles.icon}
        >
          <path
            d="M5.067 12.978A9.25 9.25 0 0 0 14.4 3.644V3.2A7.225 7.225 0 0 0 16 1.511a7.379 7.379 0 0 1-1.867.533A3.458 3.458 0 0 0 15.555.266a8.153 8.153 0 0 1-2.044.8A3.175 3.175 0 0 0 11.111 0a3.34 3.34 0 0 0-3.289 3.289A1.733 1.733 0 0 0 7.911 4 9.19 9.19 0 0 1 1.155.533a3.4 3.4 0 0 0-.444 1.689 3.532 3.532 0 0 0 1.422 2.756 3 3 0 0 1-1.511-.444 3.249 3.249 0 0 0 2.667 3.2 2.74 2.74 0 0 1-.889.089 1.513 1.513 0 0 1-.622-.089 3.367 3.367 0 0 0 3.111 2.311A6.711 6.711 0 0 1 .8 11.467a2.461 2.461 0 0 1-.8-.089 8.39 8.39 0 0 0 5.067 1.6"
            fill="#46d3c6"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <a
        href="https://www.facebook.com/GetPiTop/"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 8.355 16"
          className={styles.icon}
        >
          <path
            d="M5.422 16V8.711h2.489l.356-2.844H5.422V4.089c0-.8.267-1.422 1.422-1.422h1.511V.089C8 .089 7.111 0 6.133 0a3.431 3.431 0 0 0-3.644 3.733v2.134H0v2.844h2.489V16z"
            fill="#46d3c6"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <a
        href="https://www.pi-top.com/"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          className={styles.icon}
        >
          <circle id="Ellipse_8" fill="#093a3e" cx="7.987" cy="8" r="5.33" />
          <path
            id="Path_2692"
            fill="#00AD9F"
            d="M 5.793 4.871 C 5.208 4.364 5.182 5.183 4.74 5.365 C 4.389 5.456 4.025 5.404 3.713 5.235 L 2.244 4.481 L 2.075 4.403 C 0.866 6.366 0.723 8.809 1.685 10.902 C 2.465 10.798 3.375 10.59 3.375 10.59 C 4.116 10.408 3.843 10.096 3.765 9.81 C 3.687 9.524 3.375 8.926 5.312 8.575 C 6.027 8.445 6.586 7.406 7.106 6.704 C 7.626 6.002 6.378 5.365 5.793 4.871 Z"
          />
          <path
            id="Path_2693"
            fill="#00AD9F"
            d="M 7.977 14.958 C 9.861 14.958 11.655 14.191 12.955 12.839 C 12.019 12.696 11.122 12.397 10.303 11.929 C 8.6 11.006 9.016 10.135 7.886 10.304 C 6.755 10.473 6.417 11.617 6.69 11.981 C 7.275 12.839 7.496 13.905 7.275 14.932 C 7.522 14.945 7.756 14.958 7.977 14.958 Z"
          />
          <path
            id="Path_2694"
            fill="#00AD9F"
            d="M 8.717 1.14 C 8.6 1.686 8.691 2.245 8.964 2.726 C 9.419 3.259 10.199 4.65 10.914 4.455 C 11.629 4.26 12.487 4.286 12.071 5.079 C 11.655 5.872 11.343 6.613 11.343 6.613 C 11.343 6.613 10.888 7.276 11.148 7.783 C 11.408 8.29 11.473 10.239 12.37 10.044 C 13.267 9.849 13.891 9.94 14.086 10.161 C 14.164 10.239 14.294 10.382 14.437 10.512 C 14.749 9.719 14.905 8.874 14.905 8.017 C 14.905 4.494 12.24 1.517 8.717 1.14 Z"
          />
        </svg>
      </a>
      <a
        className={styles.pitopLink}
        href="https://www.pi-top.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        <img className={styles.logo} src={logo} alt="pi-top logo" />
      </a>
    </footer>
  );
};

export default Footer;
