import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';

import MembershipDialog from '../membershipDialog/MembershipDialog';

import {
  membershipActivated,
  membershipCodeErrorMessage,
  toContinueUsingAllTheFeaturesHintText,
  getRemainingMembershipTitlePart,
  getExpiredAgoMembershipTitlePart,
  fullMembershipStatusTitle,
  expiredMembershipStatusTitle,
  membershipCancelled,
  cancelMembershipErrorMessage,
  trialMembershipStatusTitle,
  noneMembershipStatusTitle,
} from '../../strings';

import {
  UPGRADE_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
} from '../../graphql/mutations/accounts';
import { MembershipType } from '../../types/graphql-types';
import logException from '../../services/logging/exception';
import { useMembershipContext } from '../../context/Membership';

type Props = {
  handleClose: () => void;
};

const MembershipStatusDialog = ({ handleClose }: Props) => {
  const { membershipType, membershipExpiry } = useMembershipContext();
  const [licenseConfirmation, setLicenseConfirmation] = useState('');
  const [mutationError, setMutationError] = useState('');

  const until = moment().to(moment(membershipExpiry), true);

  const [upgradeMembership, { loading: upgradeLoading }] = useMutation(
    UPGRADE_MEMBERSHIP,
    {
      onCompleted: () => {
        setLicenseConfirmation(membershipActivated);
        setMutationError('');
      },
      onError: (err) => {
        logException(err);
        setMutationError(membershipCodeErrorMessage);
      },
    }
  );

  const [cancelMembership, { loading: cancelLoading }] = useMutation(
    CANCEL_MEMBERSHIP,
    {
      onCompleted: () => {
        setLicenseConfirmation(membershipCancelled);
        setMutationError('');
      },
      onError: (err) => {
        logException(err);
        setMutationError(cancelMembershipErrorMessage);
      },
    }
  );

  let useHaveMembershipCodeCheckBox = false;
  let showCancelMembershipCheckbox = false;
  let hintText = '';
  let titleText = '';
  const buttonText = 'Ok';

  switch (membershipType) {
    case MembershipType.TRIAL:
      titleText =
        trialMembershipStatusTitle + getRemainingMembershipTitlePart(until);
      hintText =
        'You can upgrade to full membership if you have a membership code.';
      useHaveMembershipCodeCheckBox = true;
      showCancelMembershipCheckbox = true;
      break;
    case MembershipType.FULL:
      titleText =
        fullMembershipStatusTitle + getRemainingMembershipTitlePart(until);
      hintText = 'You have access to the full membership features.';
      showCancelMembershipCheckbox = true;
      break;
    case MembershipType.EXPIRED:
      titleText =
        expiredMembershipStatusTitle + getExpiredAgoMembershipTitlePart(until);
      hintText = toContinueUsingAllTheFeaturesHintText;
      useHaveMembershipCodeCheckBox = true;
      break;
    default:
      titleText = noneMembershipStatusTitle;
      hintText = membershipCancelled; // use only sees this if they just cancelled their membership
  }

  const isSubmitting = upgradeLoading || cancelLoading;

  return (
    <MembershipDialog
      active
      handleClose={handleClose}
      useHaveMembershipCodeCheckBox={useHaveMembershipCodeCheckBox}
      showCancelMembershipCheckbox={showCancelMembershipCheckbox}
      title={titleText}
      hint={hintText}
      icon="calendar"
      isSubmitting={isSubmitting}
      submitError={mutationError}
      onConfirm={
        licenseConfirmation
          ? handleClose
          : ({ membershipCode, cancelChecked, closeAction }) => {
              if (membershipCode) {
                upgradeMembership({
                  variables: {
                    inviteCode: membershipCode,
                  },
                });
              } else if (closeAction) {
                handleClose();
              }
              if (cancelChecked) {
                cancelMembership();
              }
            }
      }
      buttonText={buttonText}
      enableButtonAsClose
    />
  );
};

export default MembershipStatusDialog;
