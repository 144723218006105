import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './MultiSelectTable.module.css';

export type LinkCellValue = {
  label: string;
  to: string;
};

function isLinkCellValue(value: any): value is LinkCellValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    'label' in value &&
    'to' in value &&
    typeof value.label === 'string' &&
    typeof value.to === 'string'
  );
}

export type Props<RowData> = {
  info: CellContext<RowData, unknown>;
  className?: string;
};

export const LinkCell = <RowData extends Record<string, any>>({
  info,
  className,
}: Props<RowData>) => {
  const value = info.getValue();

  if (isLinkCellValue(value)) {
    return (
      <td className={cx(className, styles.linkCell)}>
        <Link to={value.to}>{value.label}</Link>
      </td>
    );
  }

  return <td className={className} />;
};
