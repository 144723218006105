/* provides UI for various membership related dialogs, never rendered without one of its wrapper components */
import React, { useState, ReactNode } from 'react';
import cx from 'classnames';

import Input from '../atoms/input/Input';
import LabelledCheckbox from '../atoms/labelledCheckbox/LabelledCheckbox';
import PtProgress from '../ptProgress/PtProgress';
import styles from './MembershipDialog.module.css';

import {
  membershipCodeLabel,
  hasAMembershipCode,
  cancelMembership,
} from '../../strings';

import Dialog from '../dialog/Dialog';

import trialFormIcon from '../../assets/images/calendar.png';
import becomeTeacherIcon from '../../assets/images/teacher-character-dialog.png';

export enum Checked {
  haveMembershipCode = 'haveMembershipCode',
  trial = 'trial',
  cancelFeatures = 'cancelFeatures',
}

export type Props = {
  handleClose: () => void;
  title?: string;
  hint?: string;
  codeInputLabel?: string;
  isSubmitting?: boolean;
  submitError?: string;
  membershipCode?: string;
  useHaveMembershipCodeCheckBox?: boolean;
  showMembershipCodeInput?: boolean;
  showCancelMembershipCheckbox?: boolean;
  icon?: 'calendar' | 'teacher';
  setChecked?: Checked | undefined;
  active?: boolean;
  buttonText?: string;
  onConfirm: ({
    membershipCode,
    cancelChecked,
    closeAction,
  }: {
    membershipCode: string;
    cancelChecked: boolean;
    closeAction: boolean;
  }) => void;
  size?: 'large' | 'medium' | 'small' | undefined;
  enableButtonAsClose?: boolean;
  children?: ReactNode;
  className?: string;
};

const MembershipDialog = ({
  handleClose,
  title = '',
  hint,
  codeInputLabel,
  isSubmitting = false,
  submitError = '',
  setChecked,
  membershipCode = '',
  useHaveMembershipCodeCheckBox = false,
  showMembershipCodeInput = false,
  showCancelMembershipCheckbox = false,
  icon = 'teacher',
  active = false,
  buttonText = 'Done',
  onConfirm,
  size,
  enableButtonAsClose = false,
  children,
  className,
}: Props) => {
  const [checked, changeChecked] = useState<Checked | undefined>(setChecked);

  const [membershipCodeFieldValue, setMembershipCodeFieldValue] = useState(
    membershipCode
  );

  const isMembershipFieldShowing =
    (useHaveMembershipCodeCheckBox && checked === Checked.haveMembershipCode) ||
    (!useHaveMembershipCodeCheckBox && showMembershipCodeInput);

  const isButtonEnabled =
    (!isMembershipFieldShowing && !showCancelMembershipCheckbox) || // nothing to select so button just closes
    (isMembershipFieldShowing && membershipCodeFieldValue) ||
    checked === Checked.cancelFeatures ||
    checked === Checked.trial ||
    enableButtonAsClose; // can use button to just close dialog even if there are options, used on membership status

  const inputLabel = codeInputLabel || membershipCodeLabel;

  return (
    <Dialog
      active={active}
      handleClose={handleClose}
      isLoading={isSubmitting}
      title={(!isSubmitting && title) || ''}
      description={(!isSubmitting && hint) || ''}
      headerIcon={icon === 'teacher' ? becomeTeacherIcon : trialFormIcon}
      confirmAction={{
        label:
          checked === Checked.cancelFeatures ? 'Cancel Membership' : buttonText,

        disabled: isSubmitting || !isButtonEnabled,

        onClick: () => {
          let cancelChecked = false;
          let closeAction = false;

          let membershipCodeNumber = '';

          switch (checked) {
            case Checked.cancelFeatures:
              cancelChecked = true;
              break;
            default:
              membershipCodeNumber = membershipCodeFieldValue;
              closeAction = enableButtonAsClose;
          }

          onConfirm({
            membershipCode: membershipCodeNumber,
            cancelChecked,
            closeAction,
          });

          changeChecked(undefined);
        },
      }}
      ariaLabel="Membership dialog"
      size={size}
      className={className}
    >
      <div className={styles.wrap} data-testid="trial-wrap">
        {/* error  */}
        {submitError && (
          <div className={styles.errorMessage}>{submitError}</div>
        )}
        {/* loading */}
        {isSubmitting ? (
          <div className={styles.loadingContainer}>
            <PtProgress className={styles.loading} />
          </div>
        ) : (
          <form className={styles.formWrap}>
            {/* have a membership check box */}
            {useHaveMembershipCodeCheckBox && (
              <div className={styles.checkBoxRow}>
                <LabelledCheckbox
                  id="hasMembershipCodeCheckbox"
                  label={
                    <p className={styles.checkBoxText}>{hasAMembershipCode}</p>
                  }
                  value="have a membership code?"
                  checked={checked === Checked.haveMembershipCode}
                  onChange={() =>
                    changeChecked(
                      checked !== Checked.haveMembershipCode
                        ? Checked.haveMembershipCode
                        : undefined
                    )
                  }
                  ariaLabel="have a membership code?"
                />
              </div>
            )}
            {/* membership code input  */}
            {isMembershipFieldShowing && (
              <div className={styles.inputContainer}>
                <Input
                  ariaLabel={inputLabel}
                  className={cx({
                    [styles.fade]:
                      !showMembershipCodeInput &&
                      checked !== Checked.haveMembershipCode,
                    [styles.error]: submitError,
                  })}
                  bordered
                  disabled={
                    checked === Checked.cancelFeatures ||
                    checked === Checked.trial
                  }
                  label={inputLabel}
                  id="membershipCodeInput"
                  maxLength={128}
                  onChange={(value) =>
                    setMembershipCodeFieldValue(value.trim())
                  }
                  onPaste={(val: string) => val.replace(/\t/g, ',').trim()}
                  placeholder={inputLabel}
                  controlled
                  controlledValue={membershipCodeFieldValue || ''}
                  required
                />
              </div>
            )}
            {/* cancel membership checkbox */}
            {showCancelMembershipCheckbox && (
              <div className={styles.checkBoxRow}>
                <LabelledCheckbox
                  id="cancelMembershipCheckbox"
                  label={
                    <p className={styles.checkBoxText}>{cancelMembership}</p>
                  }
                  textLeft
                  value="cancelMembership"
                  checked={checked === Checked.cancelFeatures}
                  onChange={() => {
                    changeChecked(
                      checked !== Checked.cancelFeatures
                        ? Checked.cancelFeatures
                        : undefined
                    );
                  }}
                  square
                  ariaLabel={cancelMembership}
                />
              </div>
            )}
          </form>
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default MembershipDialog;
