import { gql, useMutation } from '@apollo/client';
import React from 'react';
import cx from 'classnames';

import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';

import { errorDialogMessage } from '../../strings';
import styles from './ResetSchoolMemberPasswordsDialog.module.css';
import { SchoolMemberResetPasswordInput } from '../../types/graphql-types';
import logException from '../../services/logging/exception';
import {
  ResetSchoolMemberPasswords,
  ResetSchoolMemberPasswordsVariables,
} from './types/ResetSchoolMemberPasswords';
import { makePasswordPin } from '../../utils/makeReadableCode';
import Tag from '../tag/Tag';
import { TickIcon, WarningIcon } from '../icons';

export const RESET_SCHOOL_MEMBER_PASSWORDS = gql`
  mutation ResetSchoolMemberPasswords(
    $schoolId: String!
    $passwordResetInput: [SchoolMemberResetPasswordInput!]!
  ) {
    resetSchoolMemberPasswords(
      id: $schoolId
      passwordResetInput: $passwordResetInput
    ) {
      schoolMemberId
      success
      message
    }
  }
`;

const ValidMemberPasswordResetRow = ({
  disabled,
  member,
  success,
  errorMessage,
}: {
  disabled: boolean;
  member: {
    id: string;
    username: string;
  };
  success?: boolean;
  errorMessage?: string | null;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <tr>
      <td className={styles.nameCell}>
        <span className={styles.memberName}>{member.username}</span>
      </td>

      <td className={styles.passwordCell}>
        <input
          required
          ref={inputRef}
          name={`password-${member.id}`}
          minLength={8}
          placeholder="Type password here..."
          className={cx(styles.input, { [styles.hidden]: success })}
          disabled={disabled}
          defaultValue={makePasswordPin(8).toString()}
        />

        {!!success && (
          <span className={styles.message}>{inputRef.current?.value}</span>
        )}
      </td>

      <td className={styles.messageCell}>
        {!!success && (
          <Tag
            label="Password reset successfully"
            className={styles.successTag}
            leftAction={<TickIcon className={styles.tagIcon} />}
          />
        )}

        {!!errorMessage && (
          <Tag
            label={errorMessage}
            className={styles.warningTag}
            leftAction={<WarningIcon className={styles.tagIcon} />}
          />
        )}
      </td>
    </tr>
  );
};

const InvalidMemberPasswordResetRow = ({
  member,
  message,
}: {
  member: {
    id: string;
    username: string;
  };
  message?: string;
}) => {
  return (
    <tr>
      <td className={styles.nameCell}>
        <span className={styles.memberName}>{member.username}</span>
      </td>

      <td className={styles.passwordCell}>
        <span className={styles.message}>*******</span>
      </td>

      <td className={styles.messageCell}>
        {message && (
          <Tag
            label={message}
            className={styles.warningTag}
            leftAction={<WarningIcon className={styles.tagIcon} />}
          />
        )}
      </td>
    </tr>
  );
};

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'size' | 'title' | 'description' | 'handleClose'
> & {
  schoolId: string;
  selectedMembers: {
    id: string;
    username: string;
    email?: string | null;
    passwordManaged: boolean;
  }[];
  handleClose: () => void;
};

const ResetSchoolMemberPasswordsDialog = ({
  schoolId,
  selectedMembers,
  ...props
}: Props) => {
  const [
    resetSchoolMemberPasswords,
    { data, loading: resettingPasswords, error: resetPasswordsError },
  ] = useMutation<
    ResetSchoolMemberPasswords,
    ResetSchoolMemberPasswordsVariables
  >(RESET_SCHOOL_MEMBER_PASSWORDS, {
    onError: (err) => logException(err),
  });

  const validOptions = selectedMembers.filter((m) => m.passwordManaged);
  const invalidOptions = selectedMembers.filter((m) => !m.passwordManaged);

  return (
    <Dialog
      {...props}
      title="Reset School Member Passwords"
      description="We have pre-filled the password inputs with random values, but you can change them if you like."
      isLoading={resettingPasswords}
      confirmAction={{
        label: 'Reset Passwords',
        form: 'reset-school-member-passwords-form',
        type: 'submit',
        onClick: () => data && props.handleClose(),
        disabled: !!data || !validOptions.length || resettingPasswords,
      }}
      size="large"
      theme={{
        innerContainer: styles.dialogInnerContainer,
      }}
    >
      <form
        id="reset-school-member-passwords-form"
        onSubmit={(e) => {
          e.preventDefault();

          const form = (e.currentTarget as unknown) as HTMLFormElement;
          const passwordResetInput: SchoolMemberResetPasswordInput[] = [];

          validOptions.forEach((m) => {
            const passwordInput = form.elements.namedItem(
              `password-${m.id}`
            ) as HTMLInputElement;

            passwordResetInput.push({
              schoolMemberId: m.id,
              password: passwordInput.value,
            });
          });

          resetSchoolMemberPasswords({
            variables: {
              schoolId,
              passwordResetInput,
            },
          });
        }}
        className={styles.form}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Username</th>
              <th>Password</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!validOptions.length && !invalidOptions.length && (
              <tr>
                <td colSpan={4}>
                  <span className={styles.noMembersText}>
                    No members selected
                  </span>
                </td>
              </tr>
            )}

            {(validOptions || []).map((member) => {
              const result = data?.resetSchoolMemberPasswords.find(
                (r) => r.schoolMemberId === member.id
              );
              return (
                <ValidMemberPasswordResetRow
                  disabled={resettingPasswords}
                  member={member}
                  success={result?.success}
                  errorMessage={result?.message}
                />
              );
            })}

            {(invalidOptions || []).map((member) => (
              <InvalidMemberPasswordResetRow
                member={member}
                message={
                  member.email
                    ? 'User has an email, please use password reset flow'
                    : 'User was not generated by this school'
                }
              />
            ))}
          </tbody>
        </table>
      </form>

      {resettingPasswords && (
        <Placeholder
          isLoading={resettingPasswords}
          message="Resetting passwords..."
          className={styles.placeholder}
        />
      )}

      {!!resetPasswordsError && (
        <span className={styles.errorMessage}>
          {errorDialogMessage(
            `resetting passwords: ${resetPasswordsError?.message || 'error'}`
          )}
        </span>
      )}
    </Dialog>
  );
};

export default ResetSchoolMemberPasswordsDialog;
