import React, { memo } from 'react';

import styles from './Separator.module.css';

const Separator = ({
  vertical = false,
  className,
  testId,
}: {
  vertical?: boolean;
  className?: string;
  testId?: string;
}) => {
  const attributes = {
    viewBox: vertical ? '0 0 3.1 357.792' : '0 0 359.5 3.1',
    d: vertical
      ? 'M 2.251 0.017 C 1.651 123.343 1.589 237.917 1.151 357.792'
      : 'M1.463,1.004 c122.026 0.6,236.6 0.662,356.475 1.1',
  };

  return (
    <svg
      data-testid={testId}
      className={className}
      viewBox={attributes.viewBox}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      style={{
        overflow: 'visible',
      }}
    >
      <path
        className={styles.path}
        d={attributes.d}
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default memo(Separator);
