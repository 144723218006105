import React, { Ref, useState } from 'react';
import cx from 'classnames';
import { getImageUrl } from '../../../utils/getAssetUrl';

import PtProgress from '../../ptProgress/PtProgress';
import styles from './Image.module.css';
import useTheme from '../../../hooks/useTheme';

export type Theme = typeof styles;

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  size?: '250' | '1080' | null;
  loadingClassName?: string;
  theme?: Partial<Theme>;
  imageRef?: Ref<HTMLImageElement>;
};

const Image = ({
  src = '',
  alt = '',
  imageRef: imageRefProp,
  onLoad = () => null,
  onError = () => null,
  className = '',
  size = '1080',
  theme: customTheme = {},
  ...props
}: Props) => {
  const theme = useTheme(styles, customTheme);
  const [isLoading, setIsLoading] = useState(true);

  let url = src;
  if (size) {
    const urlParts = src.split('.');
    const ext = urlParts.pop();
    url = `${urlParts.join('.')}x${size}.${ext}`;
  }
  // fix for old /download endpoint hard coded into content
  url = url.replace(/download\//, 'downloadCDN/');

  // handle new bucket/filename image url format
  url = getImageUrl(url);

  return (
    <>
      <img
        {...props}
        ref={(imageRef) => {
          // set loading false if image is cached
          if (imageRef?.complete && isLoading) {
            setIsLoading(false);
          }
          if (typeof imageRefProp === 'function') return imageRefProp(imageRef);
          if (imageRefProp) {
            // @ts-expect-error current is readonly
            // eslint-disable-next-line no-param-reassign
            imageRefProp.current = imageRef;
          }
        }}
        src={url}
        alt={alt}
        onLoad={(e) => {
          onLoad(e);
          setIsLoading(false);
        }}
        onError={(e) => {
          onError(e);
          setIsLoading(false);
        }}
        className={cx(theme.image, className, {
          [theme.hidden]: isLoading,
        })}
      />

      <PtProgress
        type="linear"
        mode="indeterminate"
        className={cx(theme.loading, {
          [theme.hidden]: !isLoading,
        })}
      />
    </>
  );
};

// reset state when src changes
export default ({ src, ...restProps }: Props) => (
  <Image key={src} src={src} {...restProps} />
);
