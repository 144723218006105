import React from 'react';
import { Props as SelectProps } from 'react-select';

import Select from '../atoms/select/Select';

function tagToOption(tag: string) {
  return { label: tag, value: tag };
}

function optionToTag(option: { label: string; value: string }) {
  return option.value;
}

export type Props = {
  tags: string[];
  initialSelectedTags?: string[];
  onSelectedTagsChange: (tags: readonly string[]) => void;
  className?: string;
} & Omit<
  SelectProps<{ label: string; value: string }, true>,
  | 'value'
  | 'options'
  | 'defaultValue'
  | 'getOptionLabel'
  | 'getOptionValue'
  | 'onChange'
  | 'isMulti'
>;

const TagsSelect = ({
  tags,
  initialSelectedTags = [],
  onSelectedTagsChange,
  placeholder = 'Select tags...',
  'aria-label': ariaLabel = 'Tags Select',
  ...props
}: Props) => {
  return (
    <Select
      {...props}
      isMulti
      defaultValue={initialSelectedTags.map(tagToOption)}
      options={tags.map(tagToOption)}
      onChange={(options) => onSelectedTagsChange(options.map(optionToTag))}
      aria-label={ariaLabel}
      placeholder={placeholder}
    />
  );
};

export default TagsSelect;
