import React, { memo } from 'react';
import cx from 'classnames';

import styles from './BackIcon.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  className?: string;
  theme?: Partial<Theme>;
};

const BackIcon = ({ className, theme: customTheme = {} }: Props) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(className, theme.icon)}
      viewBox="0 0 56.28 26.13"
    >
      <g transform="translate(45, 12.5) rotate(90)">
        <path
          className={cx(theme.arrow, theme.tail)}
          d="M0.54,0.41c0.08,10.56-0.06,25.77-0.02,35.81"
        />
        <path
          className={theme.arrow}
          d="M0.56,37.37c-3.18-2.95-5.92-6.34-8.15-10.06"
        />
        <path
          className={theme.arrow}
          d="M0.56,37.37c3.18-2.95,5.92-6.34,8.15-10.06"
        />
      </g>
    </svg>
  );
};

export default memo(BackIcon);
