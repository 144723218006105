import React from 'react';
import ReactSelect from 'react-select';
import cx from 'classnames';

import styles from './Select.module.css';

const Select: ReactSelect = ({ className, ...props }) => {
  return (
    <ReactSelect
      {...props}
      classNamePrefix="select"
      className={cx(styles.root, className)}
    />
  );
};

export default Select;
