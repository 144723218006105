import React from 'react';

import styles from './LearningIcon.module.css';

type Props = {
  className?: string;
};

const LearningIcon = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.56 84.67"
  >
    <g id="Group_9976" transform="translate(15.464 23.341)">
      <g id="Group_9973" transform="translate(0 0.516)">
        <g id="Group_9971">
          <path
            id="Path_78371"
            className={styles.background}
            d="M13.82,19.16c5.75,2.23,11.79,4.16,17.8,3.25c3.33-0.66,6.56-1.73,9.63-3.2
				c6.53-2.8,13.06-5.61,19.59-8.43C53.57,7.8,46.28,4.95,38.94,2.21c-5.25-1.96-9.48-1.77-14.88-0.04
				C17.14,4.38,8.91,6.05,3.1,10.07c-2.97,2.06-3.2,2.34,0.11,4.58C6.31,16.76,10.42,17.84,13.82,19.16z"
          />
          <path
            id="Path_78372"
            className={styles.background}
            d="M28.47,23.44c-5.29,0-10.43-1.79-14.94-3.53l0,0c-0.87-0.34-1.78-0.66-2.72-0.98
				c-2.83-0.86-5.53-2.07-8.05-3.61c-1.71-1.16-2.75-1.94-2.76-3s1.05-1.8,2.64-2.91c4.57-3.17,10.5-4.88,16.24-6.53
				c1.69-0.49,3.35-0.97,4.94-1.48c5.91-1.89,10.23-1.87,15.4,0.06c7.3,2.72,14.67,5.6,21.91,8.57c0.41,0.17,0.61,0.63,0.44,1.04
				c-0.08,0.2-0.23,0.35-0.43,0.44c-6.53,2.82-13.06,5.63-19.59,8.43c-3.12,1.5-6.42,2.59-9.82,3.25
				C30.65,23.36,29.56,23.44,28.47,23.44z M31.72,1.62c-2.53,0.05-5.03,0.49-7.42,1.32c-1.61,0.51-3.28,1-4.99,1.49
				C13.71,6.04,7.91,7.72,3.56,10.73c-0.73,0.43-1.39,0.97-1.96,1.6c0.6,0.65,1.3,1.21,2.06,1.66c2.41,1.46,4.99,2.61,7.68,3.42
				c0.95,0.33,1.88,0.66,2.76,1l0,0c5.25,2.03,11.34,4.12,17.39,3.2c3.26-0.65,6.43-1.7,9.43-3.14c5.95-2.55,11.89-5.1,17.84-7.67
				c-6.66-2.71-13.41-5.34-20.1-7.83C36.45,2.1,34.1,1.64,31.72,1.62L31.72,1.62z"
          />
        </g>
        <g id="Group_9972" transform="translate(9.025 17.594)">
          <path
            id="Path_78373"
            className={styles.background}
            d="M0.8,2.13c0.8,5.45,0.92,10.99,0.35,16.47c-0.08,0.79-0.5,1.86,0.13,2.35
				c11.59,9.06,31.56,3.09,33.26,1.65c1.9-1.62,2.12-4.41,2.2-6.9l0.48-14.89"
          />
          <path
            id="Path_78374"
            className={styles.background}
            d="M18.05,26.63c-5.96,0-12.38-1.22-17.26-5.04c-0.86-0.68-0.65-1.8-0.52-2.54
				c0.03-0.18,0.07-0.36,0.08-0.52C0.92,13.11,0.8,7.64,0.01,2.25c-0.06-0.44,0.24-0.84,0.68-0.91s0.84,0.24,0.91,0.68l0,0
				C2.4,7.54,2.52,13.14,1.95,18.69c-0.02,0.2-0.06,0.43-0.1,0.65C1.77,19.66,1.74,20,1.78,20.33c11.24,8.79,30.94,2.79,32.25,1.67
				c1.63-1.39,1.84-3.88,1.92-6.32l0.48-14.89c0.01-0.44,0.38-0.79,0.83-0.77c0.44,0.01,0.79,0.38,0.77,0.83l-0.48,14.89
				c-0.08,2.67-0.35,5.68-2.48,7.49C33.86,24.24,26.39,26.63,18.05,26.63z"
          />
        </g>
      </g>
      <g id="Group_9974" transform="translate(0.926)">
        <path
          id="Path_78375"
          className={styles.path}
          d="M28.94,21.16c-5.34,0-10.51-1.53-15.05-3.02l0,0c-0.86-0.29-1.77-0.56-2.71-0.83
			c-2.82-0.72-5.55-1.77-8.14-3.11c-1.63-0.94-3.04-1.76-3.05-3.1C-0.01,9.8,1.16,9.11,2.93,8.07c4.62-2.73,10.58-4.19,16.34-5.61
			c1.68-0.41,3.34-0.82,4.93-1.25c5.99-1.63,10.36-1.61,15.6,0.05c7.3,2.32,14.68,4.78,21.92,7.3c0.67,0.23,1.02,0.96,0.79,1.63
			c-0.13,0.36-0.41,0.65-0.77,0.78c-6.53,2.4-13.06,4.8-19.59,7.18c-3.19,1.29-6.51,2.23-9.9,2.79
			C31.16,21.09,30.05,21.16,28.94,21.16z M14.7,15.7c5.21,1.72,11.27,3.48,17.24,2.71c3.21-0.54,6.34-1.43,9.35-2.66
			c5.41-1.97,10.81-3.95,16.22-5.94c-6.13-2.11-12.32-4.16-18.46-6.11c-4.72-1.5-8.69-1.51-14.16-0.02c-1.61,0.44-3.29,0.85-5,1.27
			C14.31,6.33,8.53,7.75,4.23,10.28c-0.38,0.23-0.93,0.55-1.32,0.81c0.32,0.22,0.79,0.51,1.42,0.87c2.42,1.24,4.96,2.2,7.59,2.87
			C12.88,15.13,13.81,15.41,14.7,15.7L14.7,15.7z"
        />
        <path
          id="Path_78376"
          className={styles.path}
          d="M27.37,42.01c-5.93,0-12.31-1.26-17.36-5.21c-1.09-0.85-0.84-2.21-0.69-3.01
			c0.03-0.17,0.06-0.33,0.08-0.48c0.55-5.38,0.44-10.8-0.35-16.15c-0.1-0.7,0.38-1.35,1.08-1.45s1.35,0.38,1.45,1.08
			c0.81,5.56,0.94,11.2,0.36,16.79c-0.02,0.22-0.06,0.45-0.11,0.69c-0.05,0.21-0.08,0.43-0.1,0.64c10.91,8.34,29.9,2.76,31.5,1.55
			c1.47-1.25,1.66-3.63,1.74-5.96l0.48-14.89c0.02-0.71,0.61-1.26,1.32-1.24s1.26,0.61,1.24,1.32l-0.48,14.89
			c-0.09,2.78-0.37,5.9-2.65,7.84C43.88,39.27,36.12,42.01,27.37,42.01z"
        />
      </g>
      <path
        id="Path_78377"
        className={styles.path}
        d="M56.67,32.2c-0.71,0-1.28-0.57-1.28-1.28V12.44c0-0.71,0.57-1.28,1.28-1.28
		s1.28,0.57,1.28,1.28v18.48C57.95,31.62,57.38,32.2,56.67,32.2C56.67,32.2,56.67,32.2,56.67,32.2z"
      />
      <g id="Group_9975" transform="translate(52.775 29.993)">
        <path
          id="Path_78378"
          className={styles.dot}
          d="M3.89,6.35C4.52,6.4,5.14,6.24,5.67,5.9c0.72-0.63,1.02-1.61,0.77-2.54
			C6.35,2.84,6.12,2.34,5.77,1.94C5.28,1.44,4.59,1.2,3.89,1.29l0,0C3.2,1.2,2.5,1.44,2.01,1.94c-0.35,0.4-0.58,0.9-0.67,1.42
			C1.1,4.29,1.4,5.27,2.11,5.9C2.64,6.24,3.27,6.4,3.89,6.35"
        />
        <path
          id="Path_78379"
          className={styles.path}
          d="M3.89,7.63H3.84C2.97,7.68,2.1,7.43,1.37,6.94C0.24,6.02-0.26,4.53,0.09,3.12
			c0.13-0.76,0.47-1.46,0.98-2.04c1.61-1.43,4.04-1.43,5.65,0C7.22,1.66,7.56,2.36,7.7,3.12c0.35,1.42-0.15,2.91-1.28,3.83
			c-0.73,0.49-1.6,0.73-2.48,0.69L3.89,7.63z M3.78,2.57c-0.61,0-0.73,0.13-0.82,0.24C2.77,3.04,2.65,3.31,2.6,3.61
			c-0.13,0.43-0.03,0.9,0.26,1.25c0.66,0.28,1.4,0.28,2.05,0c0.29-0.35,0.39-0.82,0.26-1.25c-0.05-0.29-0.17-0.57-0.36-0.8
			c-0.09-0.1-0.23-0.24-0.89-0.23c-0.02,0-0.05,0-0.07,0L3.78,2.57z"
        />
      </g>
    </g>
  </svg>
);

export default LearningIcon;
