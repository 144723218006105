import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../atoms/avatar/Avatar';
import Button from '../atoms/button/Button';
import styles from './UserMenu.module.css';
import Underline from '../../assets/images/underline_small.svg';
import {
  manageAccountLink,
  navbarSignOutButton,
  activateLicenseTitle,
} from '../../strings';
import DropdownMenu, { MenuItem } from '../dropdownMenu/DropdownMenu';
import MembershipStatusDialog from '../membershipStatusDialog/MembershipStatusDialog';
import ActivateLicenseDialog from '../activateLicenseDialog/ActivateLicenseDialog';
import { keycloakBaseUrl } from '../../services/apiBaseUrl';
import { MembershipType, UserType } from '../../types/graphql-types';
import { useAuth } from '../../context/Auth';

const UserMenu = () => {
  const { logout, myAccount } = useAuth();
  const { firstName, lastName, username, membershipType, userType } =
    myAccount || {};

  const [userMenuOpened, toggleUserMenu] = useState(false);
  const [showMembershipStatusDialog, setShowMembershipStatusDialog] = useState(
    false
  );
  const [showActivateLicenseDialog, setShowActivateLicenseDialog] = useState(
    false
  );

  const userMenuButton = (
    <Button
      className={styles.userMenuButton}
      id="userMenu"
      onClick={(event) => {
        event.stopPropagation();
        toggleUserMenu((active) => !active);
      }}
      role="menuitem"
      aria-haspopup="true"
      aria-label="user menu"
      unstyled
    >
      <Avatar firstName={firstName} lastName={lastName} username={username} />
    </Button>
  );

  const menuItems: MenuItem[] = [
    {
      type: 'custom',
      key: 'trial_link',
      content: (
        <>
          <div className={styles.customRow}>
            <span>{firstName}</span>
            {membershipType !== MembershipType.NONE && (
              <Button
                className={styles.trialButton}
                unstyled
                onClick={() => setShowMembershipStatusDialog(true)}
              >
                {`${membershipType?.toString().toLowerCase()} membership`}
              </Button>
            )}
          </div>
          <img
            src={Underline}
            alt="horizontal line"
            className={styles.divider}
          />
        </>
      ),
    },
    ...(userType === UserType.STUDENT
      ? []
      : [
          {
            type: 'custom' as MenuItem['type'],
            key: 'manage_account_link',
            className: styles.linkDropdownItem,
            content: (
              <a href={`${keycloakBaseUrl}/realms/pi-top/account`}>
                {manageAccountLink}
              </a>
            ),
          },
          {
            type: 'custom' as MenuItem['type'],
            key: 'activate_license_link',
            className: styles.linkDropdownItem,
            content: <Link to="/activateSchool">{activateLicenseTitle}</Link>,
          },
        ]),
    {
      content: navbarSignOutButton,
      onClick: logout,
      type: 'button',
      label: navbarSignOutButton,
    },
  ];

  return (
    <>
      <DropdownMenu
        button={userMenuButton}
        isMenuOpened={userMenuOpened}
        menuItems={menuItems}
        menuClassName={styles.dropdownMenu}
        forceCloseMenu={() => toggleUserMenu(false)}
        dropDownLabel="user menu drop down"
      />
      {showMembershipStatusDialog && (
        <MembershipStatusDialog
          handleClose={() => setShowMembershipStatusDialog(false)}
        />
      )}
      {showActivateLicenseDialog && (
        <ActivateLicenseDialog
          handleClose={() => setShowActivateLicenseDialog(false)}
        />
      )}
    </>
  );
};

export default memo(UserMenu);
