import { gql } from '@apollo/client';

export default gql`
  fragment DetailContentFields on DetailContent {
    url
    youtubeId
    wistiaId
    height
    sampleCode
    coderFileId
    choices
    correctChoice
    correctChoices
    initialWhiteboard
    noBackgroundRender
    aspectRatio
    fullscreenDisabled
  }
`;
