import React, { memo, useState } from 'react';
import cx from 'classnames';

import styles from './SchoolLicenses.module.css';
import Button from '../atoms/button/Button';
import { useAuth } from '../../context/Auth';
import CollapseButton from '../collapseButton/CollapseButton';
import { AddIcon, SettingsIcon } from '../icons';
import { manageLicenses } from '../../utils/schoolLicense';
import ActivateSchoolLicenseDialog from '../activateSchoolLicenseDialog/ActivateSchoolLicenseDialog';
import HelpWidget from '../helpWidget/HelpWidget';

import {
  schoolLicensesSummaryTitle,
  schoolLicenseListTitle,
  schoolLicensesListStatusColumn,
  schoolLicensesListNameColumn,
  schoolLicensesListDescriptionColumn,
  schoolLicensesListCodeColumn,
  schoolLicensesListExpiresColumn,
  schoolLicensesListRenewColumn,
  schoolNoLicensesMessage,
  schoolActivateLicenseButtonLabel,
  schoolManageLicensesButtonLabel,
  schoolLicensesMustBeAdmin,
} from '../../strings';

type Realm = {
  id: string;
  name: string;
};

type Course = {
  id: string;
  title: string;
};

type ContentGrant = {
  realm: Realm | null;
  course: Course | null;
  maxSeats: number;
  numBuildingLicenses: number;
};

type Product = {
  id: string;
  images: string[];
};

type License = {
  id: string;
  valid: boolean;
  status: string;
  name: string;
  description: string | null;
  code: string | null;
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
  products: Product[];
  contentGrants: ContentGrant[];
};

const GrantList = ({ contentGrants }: { contentGrants: ContentGrant[] }) => (
  <div className={styles.grantList}>
    <div className={styles.grantListHeaders}>
      <span>Description</span>
      <span>Available Seats</span>
    </div>

    {contentGrants.map((contentGrant) => (
      <div
        key={contentGrant.realm?.id || contentGrant.course?.id}
        className={styles.grantListItem}
        role="row"
      >
        {contentGrant.realm && (
          <span>
            All&nbsp;
            <span className={styles.highlighted}>
              {contentGrant.realm.name}
            </span>
            &nbsp;content
          </span>
        )}

        {contentGrant.course && (
          <span>
            <span className={styles.highlighted}>
              {contentGrant.course.title}
            </span>
            &nbsp;course
          </span>
        )}

        <span>
          {contentGrant.numBuildingLicenses > 0
            ? 'Unlimited'
            : contentGrant.maxSeats}
        </span>
      </div>
    ))}
  </div>
);

const LicenseListItem = ({ license }: { license: License }) => {
  const [isViewingGrants, setIsViewingGrants] = useState(false);

  const hasGrants = license.contentGrants.length > 0;

  return (
    <>
      <div key={license.id} className={styles.licenseListItem} role="row">
        <div>{license.status}</div>
        <div title={license.name} className={styles.longTextCell}>
          {license.name}
        </div>
        <div title={license.description || ''} className={styles.longTextCell}>
          {license.description}
        </div>
        <div>{license.code || '-'}</div>
        <div>{new Date(license.currentPeriodEnd).toLocaleDateString()}</div>
        <div>{license.cancelAtPeriodEnd ? 'No' : 'Yes'}</div>
        {hasGrants && (
          <CollapseButton
            collapsed={!isViewingGrants}
            onClick={() => setIsViewingGrants(!isViewingGrants)}
            aria-label="Toggle grants list"
          />
        )}
      </div>

      {isViewingGrants && <GrantList contentGrants={license.contentGrants} />}
    </>
  );
};

export type Props = {
  schoolId: string;
  licenses: License[];
  schoolContentAccess: ContentGrant[];
  userIsSchoolAdmin: boolean;
  className?: string;
};

const SchoolLicenses = ({
  schoolId,
  licenses = [],
  schoolContentAccess,
  userIsSchoolAdmin,
  className,
}: Props) => {
  const { token } = useAuth();
  const [showActivateCodeDialog, setShowActivateCodeDialog] = useState(false);

  if (!userIsSchoolAdmin) {
    return (
      <div className={cx(styles.root, className)}>
        <p className={styles.warning}>{schoolLicensesMustBeAdmin}</p>
      </div>
    );
  }

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.actions}>
        <label className={styles.actionButton}>
          <Button unstyled onClick={() => setShowActivateCodeDialog(true)}>
            <AddIcon />
          </Button>
          {schoolActivateLicenseButtonLabel}
        </label>
        <label className={styles.actionButton}>
          <Button
            unstyled
            onClick={() =>
              manageLicenses({
                schoolId,
                token: token || '',
                returnUrl: window.location.href,
              })
            }
          >
            <SettingsIcon circle />
          </Button>
          {schoolManageLicensesButtonLabel}
        </label>
        <HelpWidget
          tooltipPlacement="bottom-end"
          message={
            <>
              Use the Activate License Code button to add a license to your
              school.
              <br />
              <a
                href="https://knowledgebase.pi-top.com/knowledge/how-do-i-add-a-license-to-my-school"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about adding Licenses
              </a>
              <br />
              <br />
              Use the Manage Licenses button to manage your billing settings.
            </>
          }
        />
      </div>

      {schoolContentAccess.length > 0 && (
        <div className={styles.listSection} data-testid="licenses-summary">
          <h3>{schoolLicensesSummaryTitle}</h3>
          <GrantList contentGrants={schoolContentAccess} />
        </div>
      )}

      <div className={styles.listSection}>
        <h3>{schoolLicenseListTitle}</h3>
        {licenses.length <= 0 && (
          <div className={styles.noLicenses}>{schoolNoLicensesMessage}</div>
        )}
        {licenses.length > 0 && (
          <div className={styles.licenseList}>
            <div className={styles.licenseListHeaders}>
              <span>{schoolLicensesListStatusColumn}</span>
              <span>{schoolLicensesListNameColumn}</span>
              <span>{schoolLicensesListDescriptionColumn}</span>
              <span>{schoolLicensesListCodeColumn}</span>
              <span>{schoolLicensesListExpiresColumn}</span>
              <span>{schoolLicensesListRenewColumn}</span>
              <span />
            </div>

            <div>
              {licenses.map((license) => (
                <LicenseListItem key={license.id} license={license} />
              ))}
            </div>
          </div>
        )}
      </div>
      <ActivateSchoolLicenseDialog
        active={showActivateCodeDialog}
        schoolId={schoolId}
        handleClose={() => setShowActivateCodeDialog(false)}
      />
    </div>
  );
};

export default memo(SchoolLicenses);
