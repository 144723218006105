import React from 'react';
import PrimaryButton from '../primaryButton/PrimaryButton';
import ErrorImage from '../../assets/images/error.png';
import {
  errorTitle,
  errorRetryMessage,
  errorRetryButtonTitle,
  errorRefreshMessage,
  errorRefreshButtonTitle,
  errorFooter,
} from '../../strings';
import styles from './Error.module.css';

type Props = {
  retryAction?: () => void;
  resourceName?: string;
};

const Error = ({ retryAction, resourceName = 'page' }: Props) => {
  let errorMessage;
  let errorButtonTitle;
  let onClickAction;
  if (retryAction) {
    errorMessage = errorRetryMessage(resourceName.toLowerCase());
    errorButtonTitle = errorRetryButtonTitle;
    onClickAction = retryAction;
  } else {
    errorMessage = errorRefreshMessage;
    errorButtonTitle = errorRefreshButtonTitle;
    onClickAction = () => window.location.reload();
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h3 data-testid="error-title">{errorTitle}</h3>
        <img src={ErrorImage} alt="Error" data-testid="error-image" />
        <div className={styles.message} data-testid="error-message">
          {errorMessage}
        </div>
        <PrimaryButton
          onClick={onClickAction}
          data-testid="error-action-button"
        >
          {errorButtonTitle}
        </PrimaryButton>
        <div className={styles.footer}>
          <div data-testid="error-footer">
            <span>{errorFooter(0)}</span>
            <a
              href="https://www.pi-top.com/contact-support-team"
              target="_blank"
              rel="noopener noreferrer"
            >
              {errorFooter(1)}
            </a>
            <span>{errorFooter(2)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
