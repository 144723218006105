import React, { memo } from 'react';

import styles from './SettingsIcon.module.css';
import CircleIcon from '../circleIcon/CircleIcon';

export type Props = {
  className?: string;
  circle?: boolean;
};

const SettingsIcon = ({ circle, className, ...restProps }: Props) => {
  const settingsIcon = (
    <>
      <g>
        <path
          className={styles.green}
          d="M36.28,6.45c-2-.83-6.52-3.48-8.52-4.31a34.93,34.93,0,0,0-2.22,7.41,18.49,18.49,0,0,0-7.85,1.76,19.91,19.91,0,0,0-6.07-6.77l-6.39,6,6.52,4.23-2.7,7.55A35,35,0,0,0,1.5,21.24a32.11,32.11,0,0,1,.62,9.92l6.78-.45a18.91,18.91,0,0,0,6,6.41A19.34,19.34,0,0,0,9.72,41.6a80.94,80.94,0,0,0,9.83,3.94A12.06,12.06,0,0,0,22,39.59a32,32,0,0,0,6.86-1.2,30.84,30.84,0,0,0,4.68,6.86,26.79,26.79,0,0,1,7.65-5.66,37.82,37.82,0,0,0-6.44-6,14.89,14.89,0,0,0,1.49-7.19l7.36.87A2,2,0,0,0,45.16,27a1.94,1.94,0,0,0,.5-1.56,18.93,18.93,0,0,0-.72-5.07c-.16-.57-.88-3-1.36-3.35-.68-.51-5.81,2.28-7.37,1.7s-3.75-4.07-4.92-5.23C31.29,13.52,35.82,10.44,36.28,6.45Z"
        />
        <g>
          <path
            className={styles.green}
            d="M23.66,17.28a5.69,5.69,0,0,0-1.73-.42,6.18,6.18,0,0,0-2.92.71,10.89,10.89,0,0,0-2.92,2,8.11,8.11,0,0,0-2.46,4.57A7.92,7.92,0,0,0,15.7,30.8a10,10,0,0,0,2.9,1.85l.11,0a13.21,13.21,0,0,0,6.37.94,6.24,6.24,0,0,0,3.58-1.54,9.47,9.47,0,0,0,2.28-4.84,7.66,7.66,0,0,0,.25-3.83,6.07,6.07,0,0,0-2-2.89,16.84,16.84,0,0,0-3.54-2.31c-.57-.29-1.18-.62-1.81-.88h0l-.14-.06"
          />
        </g>
      </g>
      <g>
        <path
          className={styles.line}
          d="M38.58,5.31C36.49,4.44,31.76,1.67,29.67.8a36.81,36.81,0,0,0-2.33,7.75,19.25,19.25,0,0,0-8.2,1.84A20.62,20.62,0,0,0,12.8,3.31L6.12,9.58,12.94,14l-2.82,7.89a36.27,36.27,0,0,0-7.89-1.12,33.39,33.39,0,0,1,.64,10.36L10,30.66A5.72,5.72,0,1,1,11.36,42l-.54,0a84.25,84.25,0,0,0,10.27,4.11A12.34,12.34,0,0,0,23.68,40a34.28,34.28,0,0,0,7.18-1.25,32.3,32.3,0,0,0,4.89,7.16,28,28,0,0,1,8-5.91A39.27,39.27,0,0,0,37,33.67a15.52,15.52,0,0,0,1.55-7.52l7.7.91a2.12,2.12,0,0,0,1.61-.24,2,2,0,0,0,.51-1.62,19.72,19.72,0,0,0-.75-5.3c-.17-.6-.92-3.13-1.42-3.51-.71-.53-6.07,2.38-7.7,1.77s-3.92-4.25-5.15-5.47C33.36,12.7,38.09,9.47,38.58,5.31Z"
        />
        <g>
          <path
            className={styles.line}
            d="M25.38,16.62a6.15,6.15,0,0,0-1.8-.43,6.38,6.38,0,0,0-3.06.74A11.4,11.4,0,0,0,17.47,19a8.47,8.47,0,0,0-2.57,4.78,8.25,8.25,0,0,0,2.16,6.93,10.52,10.52,0,0,0,3,1.94l.12.05a13.81,13.81,0,0,0,6.66,1,6.48,6.48,0,0,0,3.75-1.61A9.88,9.88,0,0,0,33,27.05a7.92,7.92,0,0,0,.26-4,6.37,6.37,0,0,0-2.13-3,17.73,17.73,0,0,0-3.7-2.42c-.6-.3-1.23-.64-1.89-.91h0l-.15-.07"
          />
        </g>
      </g>
    </>
  );

  if (circle) {
    return (
      <CircleIcon {...restProps} className={className}>
        <g className={styles.circle}>{settingsIcon}</g>
      </CircleIcon>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49.21 47.04"
      className={className}
    >
      <g>{settingsIcon}</g>
    </svg>
  );
};

export default memo(SettingsIcon);
