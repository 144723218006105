import React, { memo } from 'react';

import CircleIcon from '../circleIcon/CircleIcon';
import styles from './CollapseIcon.module.css';

type Props = {
  isCollapsed: boolean;
  className?: string;
  lineStyle?: string;
};

const CollapseIcon = ({
  className,
  isCollapsed,
  lineStyle = styles.line,
}: Props) => (
  <CircleIcon className={className}>
    {isCollapsed ? (
      <g>
        <line
          className={lineStyle}
          x1="13.85604"
          y1="15.20151"
          x2="19.46026"
          y2="20.80573"
        />
        <line
          className={lineStyle}
          x1="19.46026"
          y1="20.80573"
          x2="25.06449"
          y2="15.20151"
        />
      </g>
    ) : (
      <g>
        <line
          className={lineStyle}
          x1="13.85604"
          y1="20.80573"
          x2="19.46026"
          y2="15.20151"
        />
        <line
          className={lineStyle}
          x1="19.46026"
          y1="15.20151"
          x2="25.06449"
          y2="20.80573"
        />
      </g>
    )}
  </CircleIcon>
);

export default memo(CollapseIcon);
