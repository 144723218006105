import React, { memo, Fragment } from 'react';
import { number } from 'prop-types';

import styles from './BellIcon.module.css';

const propTypes = {
  unreadCount: number.isRequired,
};

const BellIcon = ({ unreadCount }) => (
  <Fragment>
    <svg
      className={styles.bell}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74 71.44992"
    >
      <path
        opacity="0.1"
        fill="#505050"
        fillOpacity="0.29"
        d="M58.62039,54.16268c-0.25,0.57001-0.77002,0.98004-1.28979,1.31c-4.01001,2.56-9.1001,2.25-13.8501,1.76001
	l0.05005,0.37c0.52002,1.74005-0.59009,3.67004-2.18018,4.53003c-1.18994,0.64996-2.56982,0.81-3.92993,0.72998
	c-1.35986,0.08002-2.73999-0.08002-3.92993-0.72998c-1.6001-0.85999-2.70996-2.78998-2.17993-4.53003l0.05005-0.37
	c-4.75,0.48999-9.8501,0.79999-13.86011-1.76001c-0.52002-0.32996-1.04004-0.73999-1.29004-1.31
	c-0.40991-0.91998-0.01001-2.00995,0.62012-2.78998c0.62988-0.77997,1.47998-1.35999,2.16992-2.09003
	c2.80005-2.97998,2.3999-7.60999,2.16992-11.68994c-0.27002-4.71002-0.01001-9.71002,2.54004-13.68005
	c1.17993-1.82996,2.79004-3.34998,4.52002-4.69c2.21997-1.70996,4.93994-3.19,7.66992-3.04999
	c-0.97998-0.45001-1.71997-1.47998-1.6499-2.57001c0.09985-1.5,1.70996-2.70996,3.16992-2.41998
	c1.46997-0.27997,3.06006,0.91998,3.16016,2.41998c0.07983,1.09003-0.66016,2.12-1.64014,2.57001
	c2.71997-0.14001,5.44995,1.34003,7.65991,3.04999c1.73022,1.34003,3.34009,2.86005,4.52002,4.69
	c2.55005,3.97003,2.81006,8.97003,2.54004,13.68005c-0.22998,4.07996-0.61987,8.70996,2.17017,11.68994
	c0.68994,0.73004,1.53979,1.31006,2.16992,2.09003C58.63064,52.15273,59.03054,53.24269,58.62039,54.16268z"
      />
      <path
        fill="none"
        stroke="#505050"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M57.22932,55.04955c-0.25,0.57001-0.77002,0.98004-1.28979,1.31c-4.01001,2.56-9.1001,2.25-13.8501,1.76001
	l0.05005,0.37c0.52002,1.74005-0.59009,3.67004-2.18018,4.53003c-1.18994,0.64996-1.66354,1.2472-3.57565,1.24068
	c-1.6627,0.00652-3.09427-0.59072-4.28421-1.24068c-1.6001-0.85999-2.70996-2.78998-2.17993-4.53003l0.05005-0.37
	c-4.75,0.48999-9.8501,0.79999-13.86011-1.76001c-0.52002-0.32996-1.04004-0.73999-1.29004-1.31
	c-0.40991-0.91998-0.01001-2.00995,0.62012-2.78998c0.62988-0.77997,1.47998-1.35999,2.16992-2.09003
	c2.80005-2.97998,2.3999-7.60999,2.16992-11.68994c-0.27002-4.71002-0.01001-9.71002,2.54004-13.68005
	c1.17993-1.82996,2.79004-3.34998,4.52002-4.69c2.21997-1.70996,4.93994-3.19,7.66992-3.04999
	c-0.97998-0.45001-1.71997-1.47998-1.6499-2.57001c0.09985-1.5,1.59131-2.75149,3.16499-2.71535
	c1.60624-0.03404,3.06499,1.21535,3.16508,2.71535c0.07983,1.09003-0.66016,2.12-1.64014,2.57001
	c2.71997-0.14001,5.44995,1.34003,7.65991,3.04999c1.73022,1.34003,3.34009,2.86005,4.52002,4.69
	c2.55005,3.97003,2.81006,8.97003,2.54004,13.68005c-0.22998,4.07996-0.61987,8.70996,2.17017,11.68994
	c0.68994,0.73004,1.53979,1.31006,2.16992,2.09003C57.23957,53.0396,57.63947,54.12956,57.22932,55.04955z"
      />
    </svg>

    <svg
      className={styles.bellBG}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="18 8 83 82"
    >
      <g>
        <path
          fill="#f4f4f4"
          d="M61.97552,11.5503c-10.33789,0-21.06635,2.79383-29.3924,7.43736
		c-5.43182,3.02881-10.22369,7.48785-12.77545,13.16033c-3.49231,7.75641-2.46674,16.71638-1.16119,25.1231
		c0.93451,6.02718,2.09564,12.31277,5.84662,17.12494c3.46283,4.4403,8.74603,7.08839,14.08698,8.86811
		c6.31555,2.10343,12.92487,3.17974,19.55188,3.6557c2.99603,0.21443,6.23926,0.52983,9.44214,0.52983
		c3.12018,0,6.20233-0.29926,8.98053-1.28501c4.49628-1.59495,8.23157-4.82623,11.42279-8.37211
		c3.71118-4.11866,6.82922-8.91079,9.02271-14.03757c3.30652-7.72726,4.50659-16.21777,2.45398-24.31742
		c-2.67004-10.52265-10.65613-21.17723-20.97351-25.09369C73.42877,12.42572,67.76276,11.5503,61.97552,11.5503"
        />
      </g>
    </svg>

    {!!unreadCount && (
      <div className={styles.unreadCount}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="15 10 15 15">
          <path
            fill="#FF6E63"
            d="M23.45843,25.15736c0.54241,0,1.07816-0.05206,1.56106-0.22344c0.78163-0.2772,1.4308-0.83894,1.98564-1.45529
		c0.645-0.71596,1.18712-1.54893,1.56834-2.44013c0.57473-1.34318,0.78348-2.81903,0.42653-4.22697
		c-0.46398-1.82908-1.85224-3.68115-3.64576-4.36194c-0.87814-0.33339-1.863-0.48564-2.86896-0.48564
		c-1.79704,0-3.66186,0.48571-5.10917,1.29288c-0.94416,0.52644-1.77711,1.30159-2.22075,2.28761
		c-0.60709,1.34822-0.42884,2.90573-0.20182,4.36705c0.16252,1.0477,0.36435,2.14027,1.01628,2.97672
		c0.602,0.77188,1.52026,1.23218,2.44869,1.54153c1.09789,0.36567,2.24664,0.5527,3.39862,0.63547
		C22.33789,25.10248,22.90166,25.15736,23.45843,25.15736"
          />
        </svg>
        <span data-testid="unread-count">{unreadCount}</span>
      </div>
    )}
  </Fragment>
);

BellIcon.propTypes = propTypes;
export default memo(BellIcon);
