import localeCode from 'locale-code';
import getCountryISO3 from 'country-iso-2-to-3';

import { apiBaseUrl } from '../services/apiBaseUrl';

const isFullUrl = (url: string): boolean => /^(http:\/\/|https:\/\/)/.test(url);

const getAssetUrl = (downloadMethod: string, url: string): string => {
  if (url && !isFullUrl(url)) {
    const [container, name] = url.split('/');

    return `${apiBaseUrl}/Containers/${container}/${downloadMethod}/${name}`;
  }
  return url;
};

export const getYoutubeUrl = (
  youtubeId: string,
  { language, showCaptions }: { language?: string; showCaptions?: boolean } = {}
): string => {
  if (!youtubeId) return '';

  const baseUrl = `https://www.youtube-nocookie.com/embed/${youtubeId}`;
  const languageCode = localeCode.getLanguageCode(language || '');

  // https://developers.google.com/youtube/player_parameters#Parameters
  const searchParams = new URLSearchParams({
    cc_load_policy: showCaptions ? '1' : '0',
  });
  if (languageCode) {
    searchParams.append('cc_lang_pref', languageCode);
    searchParams.append('hl', languageCode);
  }

  return `${baseUrl}?${searchParams.toString()}`;
};

// Code get from: https://gist.github.com/takien/4077195
export const getYoutubeId = (youtubeValue: string): string => {
  let youtubeId: string;

  const parsedYoutubeValue = youtubeValue
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/\/?)/);
  if (parsedYoutubeValue[2] !== undefined) {
    [youtubeId] = parsedYoutubeValue[2].split(/[^0-9a-z_-]/i);
  } else {
    youtubeId = youtubeValue;
  }

  return youtubeId;
};

export const getIsYoutubeIdValid = (youtubeId: string): boolean => {
  // Regex taken from: https://stackoverflow.com/a/4084332
  return !!youtubeId && /^[a-zA-Z0-9_-]{11}$/.test(youtubeId);
};

export const getWistiaUrl = (
  wistiaId: string,
  {
    language,
    showCaptions = false,
  }: { language?: string; showCaptions?: boolean } = {}
): string => {
  if (!wistiaId) return '';

  const baseUrl = `https://fast.wistia.net/embed/iframe/${wistiaId}`;

  const searchParams = new URLSearchParams({
    'plugin[captions-v1][onByDefault]': showCaptions.toString(),
  });

  if (language) {
    const rawCountryCode = localeCode.getCountryCode(language || '');
    const iso3CountryCode = getCountryISO3(rawCountryCode);

    searchParams.append(
      'plugin[captions-v1][language]',
      (iso3CountryCode || rawCountryCode).toLowerCase()
    );
  }

  return `${baseUrl}?${searchParams.toString()}`;
};

export const getWistiaId = (value: string): string => {
  // capture wistia id from: https://fast.wistia.net/embed/iframe/hto1lacefi
  const iframeEmbedValue = value.match(/\/embed\/iframe\/([a-zA-Z0-9]*)/);
  if (iframeEmbedValue) return iframeEmbedValue[1];

  // capture wistia id from: https://*.wistia.net/medias/hto1lacefi
  const wistiaMediasValue = value.match(/wistia.*\/medias\/([a-zA-Z0-9]*)/);
  if (wistiaMediasValue) return wistiaMediasValue[1];

  return value;
};

export const getIsWistiaIdValid = (wistiaId: string): boolean => {
  return /^[a-zA-Z0-9]{10}$/.test(wistiaId);
};

export const getImageUrl = (url: string): string =>
  getAssetUrl('downloadCDN', url);
export const getFileUrl = (url: string): string =>
  getAssetUrl('downloadCDN', url);
export const getVideoUrl = (url: string): string =>
  getAssetUrl('downloadCDN', url);
