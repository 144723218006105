import { useMemo } from 'react';
import cx from 'classnames';
import mergeWith from 'lodash/mergeWith';

export type ParentTheme<
  Styles,
  Children extends Record<string, unknown>
> = Styles & {
  children: Partial<Record<keyof Children, Partial<Children[keyof Children]>>>;
};

export function mergeThemes<T, U>(defaultTheme: T, customTheme: U): T & U {
  return mergeWith(
    {},
    defaultTheme,
    customTheme,
    (defaultValue, customValue) => {
      if (typeof defaultValue === 'string' && typeof customValue === 'string')
        return cx(defaultValue, customValue);

      return undefined;
    }
  );
}

export default function useTheme<T, U = Partial<T> | undefined>(
  defaultTheme: T,
  customTheme: U
): T & U {
  return useMemo(() => mergeThemes(defaultTheme, customTheme), [
    defaultTheme,
    customTheme,
  ]);
}
