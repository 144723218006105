import React, { memo } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import styles from './UserIcon.module.css';
import CircleIcon from '../circleIcon/CircleIcon';

const propTypes = {
  className: string,
};
const defaultProps = {
  className: '',
};

const UserIcon = ({ className }) => (
  <CircleIcon className={cx(styles.circle, className)}>
    <path
      className={styles.user}
      d="M27.67857,21.84131c-1.65596,2.83744-4.71013,4.75831-8.2183,4.81834
      c-3.50817-0.06003-6.56235-1.98089-8.21831-4.81834c0.01079-0.0665,0.03415-0.13551,0.0701-0.20776
      c0.84217-1.6937,2.17498-2.77526,4.00851-3.23858c0.14485-0.03666,0.29366-0.05715,0.43995-0.08807
      c0.28899-0.06038,0.53773,0.02013,0.77568,0.18799c0.88243,0.62184,1.86084,0.92413,2.92407,0.9227
      c1.06324,0.00143,2.04164-0.30086,2.92407-0.9227c0.23795-0.16786,0.48668-0.24837,0.77567-0.18799
      c0.1463,0.03092,0.2951,0.0514,0.43996,0.08807c1.83352,0.46332,3.16634,1.54489,4.00851,3.23858
      C27.64442,21.7058,27.66779,21.77481,27.67857,21.84131z"
      transform="scale(1.5) translate(-6 -6)"
    />
    <circle
      className={styles.user}
      cx="19.46027"
      cy="13.15747"
      r="4.24221"
      transform="scale(1.5) translate(-6 -6)"
    />
  </CircleIcon>
);

UserIcon.propTypes = propTypes;
UserIcon.defaultProps = defaultProps;

export default memo(UserIcon);
