import React from 'react';
import cx from 'classnames';

import Button, { Props as ButtonProps } from '../atoms/button/Button';
import Help from '../help/Help';

import styles from './HelpButton.module.css';

export type Props = ButtonProps;

const HelpButton = ({ className, ...props }: Props) => {
  return (
    <Button
      {...props}
      className={cx(className, styles.btn, styles.border, styles.buttonText)}
      unstyled
    >
      <Help />
    </Button>
  );
};

export default HelpButton;
