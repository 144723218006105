import React from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';

import styles from './Help.module.css';

const propTypes = {
  className: string,
  text: string,
  hover: bool,
  active: bool,
};

const defaultProps = {
  className: null,
  text: null,
  hover: false,
  active: false,
};

const Help = ({ className, text, hover, active }) => (
  <span
    className={cx(className, {
      [styles.hover]: hover,
      [styles.active]: active,
    })}
    title={text}
  >
    <svg
      className={styles.help}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 71.4 65.3"
    >
      <g>
        <path
          className={styles.outerPath}
          d="M29.2,5.9c-2,0-3.9,0.3-5.8,0.9c-3.1,1.3-5.8,3.3-8,5.8c-2.6,2.9-4.7,6.2-6.3,9.8c-2.4,5.3-3,11.2-1.7,16.9
		c1.9,7.3,7.4,14.7,14.6,17.5c3.6,1.3,7.3,1.9,11.1,1.9c0.1,0,0.3,0,0.4,0c7.1,0,14.2-1.8,20.5-5.2c3.9-2,7-5.2,8.9-9.2
		c2.4-5.4,1.7-11.6,0.8-17.5c-0.7-4.2-1.5-8.6-4.1-11.9c-2.4-3.1-6.1-4.9-9.8-6.2c-4.4-1.4-9-2.3-13.6-2.5C34.2,6.2,31.9,6,29.7,6
		l0,0C29.5,5.9,29.4,5.9,29.2,5.9"
        />
        <g>
          <path
            className={styles.innerPath}
            d="M29.1,21.9c1.5-3.8,6.2-5.9,10-4.6c0.8,0.3,1.6,0.7,2.2,1.3c1.7,1.8,1.1,4.8-0.2,6.9c-1.3,2.1-2.9,4-4.5,5.9
			c-3.8,4.7-1.1,12.1-1.1,12.1"
          />
          <path
            className={styles.innerPath}
            d="M35.5,48c0.1,0.1,0.1,0.1,0.2,0.2"
          />
        </g>
      </g>
    </svg>
  </span>
);

Help.propTypes = propTypes;
Help.defaultProps = defaultProps;
export default Help;
