import { gql } from '@apollo/client';

export default gql`
  query GetProjectCourses($id: String!) {
    project(id: $id) {
      id
      courses {
        title
        visibility
      }
      __typename
    }
  }
`;
