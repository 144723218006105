import { gql, useMutation } from '@apollo/client';
import React, { useCallback, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { useDropzone } from 'react-dropzone';

import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';

import { errorDialogMessage, uploadMembersCsv } from '../../strings';
import styles from './AddSchoolMembersDialog.module.css';
import {
  SCHOOL_MEMBER_ROLE,
  SchoolMemberInput,
} from '../../types/graphql-types';
import {
  AddSchoolMembers,
  AddSchoolMembersVariables,
} from './types/AddSchoolMembers';
import Select from '../atoms/select/Select';
import Button from '../atoms/button/Button';
import { AddIcon, CrossIcon } from '../icons';
import logException from '../../services/logging/exception';
import Tabs from '../atoms/tabs/Tabs';
import parseSchoolMembersCsv from '../../utils/parseSchoolMembersCsv';
import HelpWidget from '../helpWidget/HelpWidget';

type SchoolMember = SchoolMemberInput & { id: string };

function schoolMemberInputFromMember(member: SchoolMember): SchoolMemberInput {
  const { id: _, ...rest } = member;
  // if only one name is provided, ensure it's sent as the first name
  if (rest.lastName && !rest.firstName) {
    rest.firstName = rest.lastName;
  }
  return rest;
}

export const ADD_SCHOOL_MEMBERS = gql`
  mutation AddSchoolMembers(
    $schoolId: String!
    $schoolMembers: [SchoolMemberInput!]!
  ) {
    addSchoolMembers(id: $schoolId, schoolMembers: $schoolMembers) {
      __typename
      id
      schoolMembers {
        __typename
        id
        role
        account {
          __typename
          id
          firstName
          lastName
          email
          username
        }
      }
    }
  }
`;

function inferAddByType(schoolMember: SchoolMember) {
  if (schoolMember.email) return 'email';
  if (schoolMember.username) return 'username';
  if (schoolMember.firstName) return 'name';
  if (schoolMember.lastName) return 'name';
  return null;
}

const MemberTableRow = ({
  userIsSchoolAdmin,
  defaultType,
  disabled,
  member,
  onMemberChange,
  onRoleChange,
  onTypeChange,
  removeMember,
}: {
  userIsSchoolAdmin: boolean;
  defaultType: 'email' | 'username' | 'name';
  disabled: boolean;
  member: SchoolMember;
  onMemberChange: (nextMember: SchoolMember) => void;
  onRoleChange: (nextRole: SCHOOL_MEMBER_ROLE) => void;
  onTypeChange: (nextType: 'email' | 'username' | 'name') => void;
  removeMember: () => void;
}) => {
  const [type, setType] = useState<'email' | 'username' | 'name'>(
    inferAddByType(member) || defaultType
  );

  let value = '';
  switch (type) {
    case 'email':
      value = member.email || '';
      break;

    case 'username':
      value = member.username || '';
      break;

    case 'name':
      value =
        member.firstName && member.lastName
          ? `${member.firstName} ${member.lastName}`
          : member.firstName || member.lastName || '';
      break;

    default:
      break;
  }

  const roleToLabel = {
    [SCHOOL_MEMBER_ROLE.student]: 'Student',
    [SCHOOL_MEMBER_ROLE.teacher]: 'Teacher',
    [SCHOOL_MEMBER_ROLE.admin]: 'Admin',
  };

  const typeToLabel = {
    email: 'Email',
    username: 'Username',
    name: 'Name',
  };

  const roleToOption = (r: SCHOOL_MEMBER_ROLE) => {
    return {
      label: roleToLabel[r],
      value: r,
    };
  };

  const typeToOption = (t: string) => {
    return {
      label: typeToLabel[t as keyof typeof typeToLabel],
      value: t,
    };
  };

  const availableRoles = [
    SCHOOL_MEMBER_ROLE.student,
    SCHOOL_MEMBER_ROLE.teacher,
  ];

  if (userIsSchoolAdmin) {
    availableRoles.push(SCHOOL_MEMBER_ROLE.admin);
  }

  return (
    <tr>
      <td>
        <Select
          menuPosition="fixed"
          value={roleToOption(member.role)}
          isMulti={false}
          options={availableRoles.map(roleToOption)}
          onChange={(newOption) => {
            if (newOption) {
              onRoleChange(newOption.value);
            }
          }}
          aria-label="Role Select"
          isDisabled={disabled}
        />
      </td>

      <td>
        <Select
          menuPosition="fixed"
          value={typeToOption(type)}
          isMulti={false}
          options={['email', 'username', 'name'].map(typeToOption)}
          onChange={(newOption) => {
            if (newOption) {
              const nextType = newOption.value as 'email' | 'username' | 'name';
              setType(nextType);
              onTypeChange(nextType);
            }
          }}
          aria-label="Add By Select"
          isDisabled={disabled}
        />
      </td>

      <td>
        <input
          name="member-info"
          required
          defaultValue={value}
          placeholder={`Type member ${typeToLabel[type].toLowerCase()} here...`}
          className={styles.input}
          onChange={(e) => {
            const nextValue = e.currentTarget.value;
            const nextMember: SchoolMember = {
              id: member.id,
              role: member.role,
            };

            if (type === 'email') {
              nextMember.email = nextValue;
            } else if (type === 'username') {
              nextMember.username = nextValue;
            } else if (type === 'name') {
              const [nextFirstName, ...otherNames] = nextValue.split(' ');
              nextMember.firstName = nextFirstName;
              nextMember.lastName = otherNames.join(' ');
            }

            onMemberChange(nextMember);
          }}
          disabled={disabled}
        />
      </td>

      <td>
        <Button
          unstyled
          onClick={() => removeMember()}
          className={styles.removeMemberButton}
          disabled={disabled}
        >
          <CrossIcon />
        </Button>
      </td>
    </tr>
  );
};

export const AddSchoolMemberTable = React.forwardRef<
  HTMLTableElement,
  {
    schoolMembers: SchoolMember[];
    onSchoolMembersChange: (nextSchoolMembers: SchoolMember[]) => void;
    defaultType: 'email' | 'username' | 'name';
    onRoleChange: (nextRole: SCHOOL_MEMBER_ROLE) => void;
    onTypeChange: (nextType: 'email' | 'username' | 'name') => void;
    userIsSchoolAdmin: boolean;
    disabled: boolean;
  }
>(
  (
    {
      schoolMembers,
      onSchoolMembersChange,
      defaultType,
      onRoleChange,
      onTypeChange,
      userIsSchoolAdmin,
      disabled,
    },
    ref
  ) => {
    return (
      <table ref={ref} className={styles.table}>
        <colgroup>
          <col width="150px" />
          <col width="145px" />
          <col width="initial" />
          <col width="50px" />
        </colgroup>

        <thead>
          <tr>
            <th>Role</th>
            <th>Add By</th>
            <th>Member Info</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!schoolMembers.length && (
            <tr>
              <td colSpan={4}>
                <span className={styles.noMembersText}>No members added</span>
              </td>
            </tr>
          )}

          {(schoolMembers || []).map((member) => (
            <MemberTableRow
              key={member.id}
              userIsSchoolAdmin={userIsSchoolAdmin}
              disabled={disabled}
              member={member}
              onMemberChange={(nextMember) => {
                onSchoolMembersChange(
                  schoolMembers.map((m) => {
                    if (m !== member) return m;
                    return nextMember;
                  })
                );
              }}
              defaultType={defaultType}
              onTypeChange={(nextType) => {
                onTypeChange(nextType);
                onSchoolMembersChange(
                  schoolMembers.map((m) => {
                    if (m !== member) return m;

                    const nextTypeField =
                      nextType === 'name' ? 'firstName' : nextType;

                    if (m.email) {
                      return {
                        ...m,
                        email: undefined,
                        [nextTypeField]: m.email,
                      };
                    }
                    if (m.username) {
                      return {
                        ...m,
                        username: undefined,
                        [nextTypeField]: m.username,
                      };
                    }
                    if (m.firstName) {
                      return {
                        ...m,
                        firstName: undefined,
                        lastName: undefined,
                        [nextTypeField]: m.lastName
                          ? `${m.firstName} ${m.lastName}`
                          : m.firstName,
                      };
                    }
                    if (m.lastName) {
                      return {
                        ...m,
                        lastName: undefined,
                        [nextTypeField]: m.lastName,
                      };
                    }
                    return m;
                  })
                );
              }}
              onRoleChange={(nextRole) => {
                onRoleChange(nextRole);
                onSchoolMembersChange(
                  schoolMembers.map((m) => {
                    if (m !== member) return m;
                    return {
                      ...m,
                      role: nextRole,
                    };
                  })
                );
              }}
              removeMember={() =>
                onSchoolMembersChange(schoolMembers.filter((m) => m !== member))
              }
            />
          ))}
        </tbody>
      </table>
    );
  }
);

const SchoolMembersCsvDropzone = ({
  onSuccess,
}: {
  onSuccess: (members: SchoolMemberInput[]) => void;
}) => {
  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length > 1) {
        setError('You can only upload one file at a time.');
        return;
      }

      try {
        const schoolMembers = await parseSchoolMembersCsv(acceptedFiles[0]);
        onSuccess(schoolMembers);
      } catch (e) {
        setError((e as Error)?.message);
      }
    },
    [onSuccess]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} data-testid="dropzone-input" />
        <AddIcon />
        <p>{uploadMembersCsv}</p>
      </div>

      {!!error && (
        <span className={styles.csvErrorMessage}>
          {errorDialogMessage(`reading CSV: ${error}`)}
        </span>
      )}

      <div className={styles.csvTemplatesWrapper}>
        <h3 className={styles.csvTemplatesHeading}>
          CSV Templates
          <HelpWidget
            tooltipPlacement="bottom"
            tooltipStrategy="fixed"
            message={
              <div className={styles.helpWidgetText}>
                You can upload a CSV to add multiple <br />
                members to your school at once.
                <br />
                <br />
                <a
                  href="https://knowledgebase.pi-top.com/knowledge/how-do-i-roster-my-students#adding-students-by-csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about adding using CSV
                </a>
              </div>
            }
          />
        </h3>

        <div className={styles.csvTemplates}>
          <a
            className={styles.csvTemplateLink}
            href="https://static.pi-top.com/further/import-students-by-email-template.csv"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Import students by email
          </a>
          <a
            className={styles.csvTemplateLink}
            href="https://static.pi-top.com/further/import-students-by-name-template.csv"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Import students by name
          </a>
          <a
            className={styles.csvTemplateLink}
            href="https://static.pi-top.com/further/import-teachers-and-students-by-email-template.csv"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Import teachers and students by email
          </a>
          <a
            className={styles.csvTemplateLink}
            href="https://static.pi-top.com/further/import-using-all-options-template.csv"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Import using all options
          </a>
        </div>
      </div>
    </>
  );
};

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'size' | 'title' | 'description' | 'handleClose'
> & {
  schoolId: string;
  userIsSchoolAdmin: boolean;
  handleClose: () => void;
};

const AddSchoolMembersDialog = ({
  schoolId,
  userIsSchoolAdmin,
  ...props
}: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [schoolMembers, setSchoolMembers] = useState<SchoolMember[]>([]);
  const [defaultType, setDefaultType] = useState<'email' | 'username' | 'name'>(
    'email'
  );
  const [defaultRole, setDefaultRole] = useState<SCHOOL_MEMBER_ROLE>(
    SCHOOL_MEMBER_ROLE.student
  );

  const [
    addSchoolMembers,
    { loading: addingSchoolMembers, error: addSchoolMembersError },
  ] = useMutation<AddSchoolMembers, AddSchoolMembersVariables>(
    ADD_SCHOOL_MEMBERS,
    {
      variables: {
        schoolId,
        schoolMembers: schoolMembers.map(schoolMemberInputFromMember),
      },
      onError: (err) => logException(err),
      onCompleted: () => {
        props.handleClose();
        setSchoolMembers([]);
      },
    }
  );

  const onCsvParseSuccess = useCallback(
    (members: SchoolMemberInput[]) => {
      if (userIsSchoolAdmin) {
        return setSchoolMembers(
          members.map((mem) => ({ ...mem, id: Math.random().toString() }))
        );
      }

      const sanitisedMembers = members.map((mem) =>
        mem.role === SCHOOL_MEMBER_ROLE.admin
          ? { ...mem, role: SCHOOL_MEMBER_ROLE.teacher }
          : mem
      );

      setSchoolMembers(
        sanitisedMembers.map((m) => ({
          ...m,
          id: Math.random().toString(),
        }))
      );
    },
    [userIsSchoolAdmin, setSchoolMembers]
  );

  const addSchoolMembersForm = (
    <form
      id="add-school-members-form"
      onSubmit={(e) => {
        e.preventDefault();
        addSchoolMembers();
      }}
      className={styles.form}
    >
      <AddSchoolMemberTable
        ref={tableRef}
        schoolMembers={schoolMembers}
        onSchoolMembersChange={setSchoolMembers}
        defaultType={defaultType}
        onTypeChange={setDefaultType}
        onRoleChange={setDefaultRole}
        userIsSchoolAdmin={userIsSchoolAdmin}
        disabled={addingSchoolMembers}
      />
    </form>
  );

  return (
    <Dialog
      {...props}
      title="Add School Members"
      isLoading={addingSchoolMembers}
      confirmAction={{
        label: 'Confirm',
        form: 'add-school-members-form',
        type: 'submit',
        disabled: !schoolMembers.length || addingSchoolMembers,
      }}
      size="large"
      theme={{
        innerContainer: styles.dialogInnerContainer,
        bodyContainer: styles.dialogBodyContainer,
      }}
    >
      <Tabs
        initialActiveTab="manual"
        onTabChange={() => setSchoolMembers([])}
        className={styles.tabs}
      >
        <Tabs.TabBar>
          <Tabs.Tab name="manual">Manual</Tabs.Tab>
          <Tabs.Tab name="csv">CSV</Tabs.Tab>
        </Tabs.TabBar>

        <Tabs.Content name="manual">
          {addSchoolMembersForm}

          <label className={styles.addMemberButton}>
            <Button
              unstyled
              disabled={addingSchoolMembers}
              onClick={() => {
                ReactDom.flushSync(() => {
                  setSchoolMembers([
                    ...schoolMembers,
                    { id: Math.random().toString(), role: defaultRole },
                  ]);
                });
                if (
                  tableRef.current &&
                  typeof tableRef.current.scrollIntoView === 'function'
                ) {
                  tableRef.current.scrollIntoView(false);
                }
              }}
            >
              <AddIcon />
            </Button>
            Add Member
          </label>
        </Tabs.Content>

        <Tabs.Content name="csv">
          {schoolMembers.length ? (
            addSchoolMembersForm
          ) : (
            <SchoolMembersCsvDropzone onSuccess={onCsvParseSuccess} />
          )}
        </Tabs.Content>
      </Tabs>

      {addingSchoolMembers && (
        <Placeholder
          isLoading={addingSchoolMembers}
          message="Adding school members..."
          className={styles.placeholder}
        />
      )}

      {!!addSchoolMembersError && (
        <span className={styles.errorMessage}>
          {errorDialogMessage(
            `adding school members: ${addSchoolMembersError.message}`
          )}
        </span>
      )}
    </Dialog>
  );
};

export default AddSchoolMembersDialog;
