import React from 'react';
import { string } from 'prop-types';

import styles from './ReportIcon.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const ReportIcon = ({ className }) => (
  <svg viewBox="0 0 130.36 90.65" className={className}>
    <g transform="translate(-15, -15)">
      <path
        d="M118.74,70.83a23.81,23.81,0,0,1-10.91,9.9,59,59,0,0,1-25.1,5.6c-1.31,0-4.09-.2-5.38-.3C73.17,89.78,65.74,96.6,64.88,97.55c-4.2,4.63-4.12,1.52-4,.81A142,142,0,0,0,62.6,82h0c-7-3.68-9.92-9.91-11.88-16.68A25.58,25.58,0,0,1,52.81,47a38.52,38.52,0,0,1,7.71-10.56,24.78,24.78,0,0,1,9.75-6.29,25.8,25.8,0,0,1,7.67-1c2.73,0,5.5.23,8.06.4a76.86,76.86,0,0,1,16.7,2.75c4.56,1.33,9.07,3.34,12,6.67,3.21,3.61,4.2,8.35,5,12.88C120.84,58.25,121.73,65,118.74,70.83Z"
        className={styles.comment}
      />
      <path
        d="M78.21,13.86c-1,.61-1,2-.89,3.11q1.07,12.81,2.33,25.59a4,4,0,0,0,.61,2.07c.7.93,2,1,3.19,1.1l2.84.11a2.12,2.12,0,0,0,1.19-.17,2.25,2.25,0,0,0,.84-1.77,166.61,166.61,0,0,0,1.5-25.84,5.83,5.83,0,0,0-.54-2.79C87.75,12.63,80.54,12.38,78.21,13.86Z"
        className={styles.exclamationPoint}
      />
      <path
        d="M81.07,52.24a1.91,1.91,0,0,0-.88.16c-.6.32-.68,1.13-.68,1.81l0,4.32a1.82,1.82,0,0,0,1.21,2.27,1.75,1.75,0,0,0,.91,0l4.5.1a1.6,1.6,0,0,0,.72-.08c.61-.26.71-1.06.73-1.71l.1-3.78a3.16,3.16,0,0,0-.42-2.06C86.16,51.93,82.64,52.2,81.07,52.24Z"
        className={styles.exclamationPoint}
      />
    </g>
  </svg>
);

ReportIcon.propTypes = propTypes;
ReportIcon.defaultProps = defaultProps;
export default ReportIcon;
