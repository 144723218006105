import { useRef, useEffect } from 'react';

const useOutsideClickHandler = <Element extends HTMLElement = HTMLDivElement>(
  outsideClickHandler: () => void,
  { active = true } = {}
) => {
  const containerRef = useRef<Element | null>(null);

  useEffect(() => {
    if (!active) return;

    const onDocumentClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;
      if (
        target !== containerRef.current &&
        !containerRef.current?.contains(target)
      ) {
        outsideClickHandler();
      }
    };

    document.body.addEventListener('pointerdown', onDocumentClick);
    return () =>
      document.body.removeEventListener('pointerdown', onDocumentClick);
  }, [outsideClickHandler, active]);

  return containerRef;
};

export default useOutsideClickHandler;
