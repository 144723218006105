import { combineReducers } from 'redux';

import dataByPages from './dataByPages';
import totalPages from './totalPages';
import isLoadings from './isLoadings';
import errors from './errors';

export default combineReducers({
  dataByPages,
  totalPages,
  isLoadings,
  errors,
});

export const getDataByPages = (state) => state.dataByPages;
export const getTotalPages = (state) => state.totalPages;
export const getFetchStandardsError = (state) => state.errors.fetchStandards;
export const getFetchStandardsIsLoading = (state) =>
  state.isLoadings.fetchStandards;
