import React from 'react';
import { tutorialsPath } from '../../strings';
import { MenuItem } from '../dropdownMenu/DropdownMenu';

const defaultMenuItems: MenuItem[] = [
  {
    content: 'Foundation Tutorials',
    type: 'link',
    url: `/${tutorialsPath}`,
  },
  {
    content: 'pi-top.com',
    type: 'href',
    url: 'https://www.pi-top.com/',
  },
  {
    content: (
      <>
        Knowledge Base <small>(pi-top.com)</small>
      </>
    ),
    type: 'href',
    url: 'https://knowledgebase.pi-top.com/knowledge',
  },
];

export default defaultMenuItems;
