import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import App from '../app/App';
import AppProviders from '../appProviders/AppProviders';

export default () => {
  useEffect(() => {
    // eslint-disable-next-line
    require('../../vendorScripts');
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AppProviders>
          <App />
        </AppProviders>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
