import React from 'react';
import { CellContext } from '@tanstack/react-table';

import TagsList, { Props as TagsListProps } from '../tagsList/TagsList';
import styles from './MultiSelectTable.module.css';

export type Props<RowData> = {
  info: CellContext<RowData, TagsListProps['options']>;
  className?: string;
};

export const TagsCell = <RowData extends Record<string, any>>({
  info,
  className,
}: Props<RowData>) => {
  let options = info.getValue();
  const filterValue = info.column.getFilterValue();

  if (!Array.isArray(options) || !options.length) {
    return <td className={className} />;
  }

  // sort array to have tags that match the filterValue first and highlight them
  if (Array.isArray(filterValue)) {
    options = options
      .map((option) => ({
        ...option,
        highlighted: filterValue.includes(option.label),
      }))
      .sort((a, b) => {
        if (a.highlighted && !b.highlighted) return -1;
        if (!a.highlighted && b.highlighted) return 1;
        return 0;
      });
  }

  return (
    <td className={className}>
      <TagsList options={options} className={styles.tagsList} />
    </td>
  );
};
