import { gql } from '@apollo/client';

export default gql`
  fragment SubmissionFields on Submission {
    id
    createdAt
    updatedAt
    completedSectionIds
    __typename
  }
`;
