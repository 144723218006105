import React, { memo } from 'react';
import cx from 'classnames';

import styles from './PrivateIcon.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  className?: string;
  theme?: Partial<Theme>;
};

const PrivateIcon = ({ className, theme: customTheme }: Props) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(theme.root, className)}
      viewBox="7 0 45 26"
      data-testid="private-icon"
    >
      <g transform="translate(814 12.23)">
        <g>
          <path
            className={theme.background}
            d="
            M-788 -7.8
            c4.28-0.32,8.59,0.11,12.73,1.27
            c4.09,1.27,7.93,5.11,10.29,8.69
            c-3.96,6.14-10.74,8.97-18,9.84
            c-3.82,0.42-7.69,0.06-11.37-1.06
            c-5.17-1.53-11.86-5.44-11.86-8.78
            C-807.3-1.28-794.94-7.06-789.09-7.33
            z"
          />
          <path
            className={theme.eyeLidsPath}
            d="M-787.43-9.48c4.28-0.32,8.59,0.11,12.73,1.27c4.09,1.27,7.93,5.11,10.29,8.69
   c-3.96,6.14-10.74,8.97-18,9.84c-3.82,0.42-7.69,0.06-11.37-1.06c-5.17-1.53-11.86-5.44-11.86-8.78
   C-805.64-3.43-793.27-9.21-787.43-9.48z"
          />
        </g>
        <path
          className={theme.pupil}
          d="M-786.1-3.88c-0.32,0-0.64,0.05-0.95,0.15c-0.51,0.21-0.96,0.54-1.31,0.96
   c-0.43,0.48-0.78,1.02-1.04,1.61c-0.4,0.88-0.5,1.86-0.28,2.79c0.31,1.28,1.2,2.35,2.41,2.89c0.59,0.21,1.21,0.32,1.83,0.32h0.06
   c1.18,0,2.34-0.3,3.38-0.85c0.64-0.33,1.15-0.86,1.47-1.51c0.32-0.93,0.37-1.93,0.13-2.89c-0.05-0.7-0.29-1.38-0.67-1.97
   c-0.43-0.49-0.99-0.85-1.62-1.02c-0.73-0.23-1.48-0.37-2.25-0.42c-0.34-0.02-0.72-0.06-1.09-0.06H-786.1"
        />
      </g>
      <path
        d="M 48.783 3.239 C 33.284 9.564 19.487 16.014 7.395 22.558"
        className={theme.strikeThrough}
      />
    </svg>
  );
};

export default memo(PrivateIcon);
