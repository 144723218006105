import { gql } from '@apollo/client';

export default gql`
  fragment CourseFields on Course {
    id
    createdAt
    updatedAt
    title
    thumbnail
    description
    visibility
  }
`;
