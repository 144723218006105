import { gql } from '@apollo/client';

import { AccountFrag } from '../../fragments';

export default gql`
  query GetMyAccount {
    myAccount {
      ...AccountFields
      membershipType
      membershipExpiry
      hasActivatedTrial
      restricted
      tutorialsCompleted
      lastRead
      hardwarePreferences
      isStudent
    }
  }
  ${AccountFrag}
`;
