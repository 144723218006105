import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const ready = i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
  });

export default i18n;
