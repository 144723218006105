import React, { memo } from 'react';

import styles from './UpIcon.module.css';

export type Props = {
  className?: string;
};

const UpIcon = ({ className }: Props) => (
  <svg
    width="24.429"
    height="14.469"
    viewBox="0 0 24.429 14.469"
    className={className}
  >
    <path
      d="M0.304,14.126c3.435-4.208,8.241-9.623,10.949-12.543a56.508,56.508,0,0,1,11.242,12.058"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={styles.path}
    />
  </svg>
);

export default memo(UpIcon);
