import React, { memo } from 'react';

import styles from './TickIcon.module.css';

export type Props = {
  className?: string;
};

const TickIcon = ({ className }: Props) => (
  <svg viewBox="0 0 15.62 19.89" className={className} data-testid="tick-icon">
    <path
      className={styles.path}
      d="M3.29,8.95c0.88,2.46,2.04,4.82,3.44,7.03c2.2-4.43,3.7-7.5,5.59-12.06"
    />
  </svg>
);

export default memo(TickIcon);
