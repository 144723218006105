import React, { memo } from 'react';

import styles from './AddSectionIcon.module.css';

export type Props = {
  className?: string;
};

const AddSectionIcon = ({ className }: Props) => (
  <svg viewBox="0 0 47.12 30.75" className={className}>
    <g id="Green_Button">
      <path
        className={styles.st0}
        d="M35.89,30.3c-1.13,0.38-2.32,0.52-3.5,0.42c-9.18-0.1-18.36-0.2-27.53-0.31
		c-1.18,0.14-2.36-0.2-3.28-0.94c-0.53-0.72-0.83-1.59-0.85-2.48C0.05,21.33-0.15,15.62,0.12,9.93C-0.06,6.85-0.4,1.58,2.37,0.46
		c1.13-0.37,2.32-0.52,3.5-0.43c9.18,0.11,18.36,0.21,27.53,0.31c1.18-0.13,2.36,0.21,3.28,0.95c0.54,0.72,0.83,1.58,0.85,2.48
		c0.68,5.66,0.88,11.37,0.61,17.06C38.32,23.91,38.66,29.17,35.89,30.3z"
      />
      <path
        className={styles.st0}
        d="M33.56,24.06V5.07c2.9,1.6,5.65,3.3,8.25,5.11c2.3,1.3,6.27,3.5,5.1,5.23
		c-0.6,0.72-1.34,1.32-2.17,1.75C41.01,19.46,37.28,21.76,33.56,24.06z"
      />
    </g>
    <g id="plus_icon">
      <path
        className={styles.st1}
        d="M 11.057 14.558 C 16.897 15.148 22.797 14.978 28.597 14.048"
      />
      <path
        className={styles.st1}
        d="M 19.577 5.528 C 19.277 11.378 19.447 17.248 20.087 23.078"
      />
    </g>
  </svg>
);

export default memo(AddSectionIcon);
