/* eslint no-console: 0 */

import { utilsBaseUrl } from '../apiBaseUrl';
import keycloak from '../../keycloak';

export default async ({
  message,
  stack = '',
}: {
  message?: string;
  stack?: string;
} = {}) => {
  if (['prod', 'staging', 'test', 'edge'].includes(window.PT_ENV)) {
    try {
      const { tokenParsed: { sub: userId } = {} } = keycloak;

      await fetch(`${utilsBaseUrl}/log/error`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          service: 'further-frontend',
          env: window.PT_ENV,
          version: window.PT_VERSION,
          error: {
            message,
            stack,
            details: {
              url: window.location.href,
              userId,
              userAgent: window.navigator.userAgent,
              windowWidth: window.innerWidth,
              windowHeight: window.innerHeight,
            },
          },
        }),
      });
    } catch (e) {
      if (window.PT_ENV !== 'prod') {
        console.warn(e);
      }
    }
  }
};
