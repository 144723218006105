/* eslint-disable react/prop-types */
import React, { MouseEvent, Ref } from 'react';
import cx from 'classnames';

import styles from './Button.module.css';

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  ariaLabel?: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'reset' | 'submit';
  unstyled?: boolean;
  role?: string;
  'aria-label'?: string;
  buttonRef?: Ref<HTMLButtonElement>;
};

const Button: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  ariaLabel,
  children,
  className,
  disabled,
  onClick,
  type = 'button',
  unstyled,
  buttonRef,
  ...props
}) => (
  <button
    aria-label={ariaLabel}
    className={cx(unstyled ? styles.unstyled : styles.styled, className)}
    disabled={disabled}
    onClick={type === 'button' ? onClick : () => {}}
    // eslint-disable-next-line react/button-has-type
    type={type}
    ref={buttonRef}
    {...props}
  >
    {children}
  </button>
);

export default Button;
