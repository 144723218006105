import { ApolloCache } from '@apollo/client';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import localForage from 'localforage';

const BACKEND_TAG_KEY = 'apollo-cache-backend-tag';
export const APOLLO_CACHE_PERSIST_KEY = 'apollo-cache-persist';

export default async (cache: ApolloCache<any>) => {
  // Handle backendTag changes: remove cache and update last backendTag
  const currentBackendTag = window.localStorage.getItem(BACKEND_TAG_KEY);
  if (currentBackendTag && currentBackendTag !== window.PT_BACKEND_TAG) {
    await localForage.removeItem(APOLLO_CACHE_PERSIST_KEY);

    // Delete old and orphan localStorage entry if it exists
    if (window.localStorage.getItem(APOLLO_CACHE_PERSIST_KEY)) {
      window.localStorage.removeItem(APOLLO_CACHE_PERSIST_KEY);
    }
  }

  // Set backend tag key to be up to date with the current package.json tag
  window.localStorage.setItem(BACKEND_TAG_KEY, window.PT_BACKEND_TAG);

  return persistCache({
    storage: new LocalForageWrapper(localForage),
    cache,
    key: APOLLO_CACHE_PERSIST_KEY,
  });
};
