import React, { PureComponent } from 'react';
import cx from 'classnames';
import { shape, arrayOf, string, func, number } from 'prop-types';

import styles from './NotificationFlyout.module.css';

import NotificationCard from '../notificationCard/NotificationCard';
import Underline from '../atoms/underline/Underline';

import { notificationsEmpty } from '../../strings';

class NotificationFlyout extends PureComponent {
  static propTypes = {
    notifications: arrayOf(
      shape({
        createdAt: string.isRequired,
        updatedAt: string.isRequired,
        type: string.isRequired,
        data: shape({}).isRequired,
        id: string.isRequired,
      })
    ),
    className: string,
    handleClose: func.isRequired,
    unreadCount: number.isRequired,
  };

  static defaultProps = {
    notifications: [],
    className: '',
  };

  onClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  render() {
    const { notifications, className, unreadCount } = this.props;

    return (
      <div className={cx(styles.flyoutContainer, className)}>
        <div className={styles.dialog} role="dialog">
          <header className={styles.header}>
            <div className={styles.flexWrapper}>
              <h3 className={styles.title}>Notifications</h3>
              {!!unreadCount && (
                <span className={styles.unreadCount}>{unreadCount} New</span>
              )}
            </div>
            <Underline />
          </header>
          <div className={styles.container}>
            {!notifications.length && (
              <div className={styles.emptyList}>{notificationsEmpty}</div>
            )}
            {notifications.map((notification) => {
              return (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                />
              );
            })}
          </div>
        </div>

        <svg
          className={styles.flyoutBG}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="14 30 297 657"
          preserveAspectRatio="none"
        >
          <path
            fill="#ffffff"
            stroke="#505050"
            strokeWidth="0.35"
            strokeMiterlimit="10"
            d="M309.87143,255.32664c0.1001,0.39961,0.12012,0.86803,0.12012,1.29512
            c0.11987,18.98584,0.18994,37.97156,0.19995,56.9574c0.01001,9.49292,0,18.98584-0.02002,28.49237
            c-0.02002,4.29883,0.03003,8.59732,0.12988,12.86856c0.05005,2.2594,0.19019,5.86935-0.2998,8.56973
            c0.11987,18.26938,0.17993,36.51114,0.18994,54.76669c0.01001,9.49292,0,18.98584-0.02002,28.47858
            c-0.02002,4.29883,0.03003,8.61111,0.12988,12.86859c0.05005,2.25955,0.19019,5.86932-0.29004,8.55594
            c0.11011,18.00766,0.17017,36.01514,0.18018,54.02264c0.01001,9.4931,0,18.98584-0.02002,28.47876
            c-0.02002,4.29865,0.03003,8.61108,0.12988,12.86835c0.05005,2.57666,0.24023,6.9165-0.54004,9.64447
            c0.2002,0.45477,0.23022,1.07471,0.23022,1.62585c0.11987,18.98584,0.18994,37.98529,0.19995,56.97113
            c0.01001,9.49292,0,18.98578-0.02002,28.4787c-0.02002,4.29871,0.03003,8.61115,0.12988,12.86841
            c0.06006,2.86591,0.28003,7.89465-0.82983,10.48486c-0.19019,0.45465-0.36011,1.25385-0.8501,1.25385
            c-13.02002,1.32263-36.06006,1.76361-61.34009,1.76361c-23.59985,0-49.1499-0.3858-70.28979-0.78546h-0.04004
            c-12.77002,0.57874-29.20996,0.78546-46.71997,0.78546c-48.74999,0-105.81005-1.63965-115.17016-1.74982
            c-0.45996-0.01379-0.81982-0.44098-0.81982-0.96436V574.36511c0-0.01379,0-0.02759,0-0.02759V47.61276
            c0-2.30087,4.71997-2.54886,6.82983-2.54886c0.61011,0,1,0.01379,1,0.01379s25.34009,0.08266,56.52002,0.09645h5.73999
            c9.67993,0,19.79004-0.01379,29.78003-0.02758c6.64014,0.01379,13.99999,0.01379,21.77001,0.01379
            c0.8501-0.08266,1.64014-0.09645,2.23999-0.09645c0.61011,0,1,0.01379,1,0.01379s13.15991,0.04129,32.18994,0.06887
            c8.96997,0.01379,19.25,0.02758,30.07007,0.02758h7.6001c14.44995-0.01379,29.51001-0.04137,43.45996-0.09645
            c2.51001-4.78094,5.20996-9.548,8.51001-13.48851c0.17993-0.19282,0.36987-0.30307,0.57983-0.30307
            c0.2002,0,0.42017,0.11025,0.59009,0.33065c2.17017,2.72797,3.96997,5.42845,5.20996,8.432
            c0.69995,1.66714,1.21997,3.41695,1.54004,5.31821c13.20996,0.05516,26.40991,0.15162,39.61011,0.31694
            c0.35986,0,0.75,0.01379,1.04004,0.30307c0.41992,0.4409,0.46997,1.28133,0.46997,2.01159
            c0.11987,18.98574,0.18994,37.97157,0.19995,56.9711c0.01001,9.49292,0,18.98583-0.02002,28.47874
            c-0.02002,4.29866,0.03003,8.61111,0.12988,12.86841c0.05005,2.23199,0.19019,5.75909-0.27002,8.43199
            c0.1001,16.67117,0.15015,33.34225,0.16016,50.01334c0.01001,9.49292,0,18.98582-0.02002,28.49254
            c-0.02002,4.29866,0.03003,8.59732,0.12988,12.86841C310.35141,248.5618,310.52158,252.59868,309.87143,255.32664z"
          />
        </svg>
      </div>
    );
  }
}

export default NotificationFlyout;
