import React, { memo } from 'react';

import styles from './CodeIcon.module.css';

const CodeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.76 52.83">
    <path
      className={styles.st0}
      d="M16.03,18.34c-3.11,2.02-6.08,4.27-8.85,6.74c2.41,2.95,5.29,5.51,8.51,7.56"
    />
    <path
      className={styles.st0}
      d="M38.09,18.73c2.94,2.04,5.87,4.08,8.81,6.13c-2.92,2.83-5.85,5.66-8.77,8.48"
    />
    <path
      className={styles.st0}
      d="M34.7,10.15c-4.55,10.65-8.6,21.51-12.15,32.53"
    />
    <path
      className={styles.st3}
      d="M40.19,18.19c2.94,2.04,5.87,4.08,8.81,6.13c-2.92,2.83-5.85,5.66-8.77,8.48"
    />
    <path
      className={styles.st3}
      d="M17.79,17.61c-3.11,2.02-6.08,4.27-8.85,6.74c2.41,2.95,5.29,5.51,8.51,7.56"
    />
    <path
      className={styles.st3}
      d="M36.95,9.77c-4.55,10.65-8.6,21.51-12.15,32.53"
    />
  </svg>
);

export default memo(CodeIcon);
