import { stringify, parse } from 'query-string';

export const updateQueryString = (
  currentQueryString: string,
  params: Record<string, string | string[] | undefined>
) =>
  stringify(
    Object.entries(params).reduce((encodedParams, [paramKey, param]) => {
      if (Array.isArray(param))
        return { ...encodedParams, [paramKey]: param.map(encodeURIComponent) };

      if (typeof param === 'string')
        return { ...encodedParams, [paramKey]: encodeURIComponent(param) };

      return { ...encodedParams, [paramKey]: null };
    }, parse(currentQueryString))
  );

export const parseQueryString = (queryString: string) => {
  const parsed = parse(queryString);

  return Object.entries(parsed).reduce((decodedParams, [paramKey, param]) => {
    if (Array.isArray(param))
      return { ...decodedParams, [paramKey]: param.map(decodeURIComponent) };

    if (typeof param === 'string')
      return { ...decodedParams, [paramKey]: decodeURIComponent(param) };

    return { ...decodedParams, [paramKey]: null };
  }, parsed);
};
