import React, { memo } from 'react';

import styles from './FilterIcon.module.css';

type props = {
  className?: string;
};

const FilterIcon = ({ className }: props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 58.38 45.83"
  >
    <path
      className={styles.st1}
      d="M29.78,12.56c0.09,0,10,0.05,11.09,0.1c-2.75,4.1-6.65,8.05-9.85,11.72l0.06,10.45
	c-1.49,0.84-3.11,1.46-4.78,1.82c0.45-4.93,0.01-7.02,0.13-12.19c-3.21-3.7-6.19-7.6-8.92-11.67C21.6,12.56,25.69,12.5,29.78,12.56z
	"
    />
  </svg>
);

export default memo(FilterIcon);
