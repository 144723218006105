import React, { memo } from 'react';

import styles from './ResizeIcon.module.css';
import CircleIcon from '../circleIcon/CircleIcon';

const ResizeIcon = () => (
  <CircleIcon>
    <g className={styles.arrows}>
      <line
        className={styles.path}
        x1="13.04215"
        y1="11.22609"
        x2="18.76094"
        y2="16.94488"
      />
      <line
        className={styles.path}
        x1="12.82075"
        y1="15.24733"
        x2="12.82075"
        y2="11.00469"
      />
      <line
        className={styles.path}
        x1="17.06339"
        y1="11.00469"
        x2="12.82075"
        y2="11.00469"
      />
      <line
        className={styles.path}
        x1="29.21118"
        y1="27.39512"
        x2="23.49239"
        y2="21.67633"
      />
      <line
        className={styles.path}
        x1="29.43258"
        y1="23.37388"
        x2="29.43258"
        y2="27.61652"
      />
      <line
        className={styles.path}
        x1="25.18994"
        y1="27.61652"
        x2="29.43258"
        y2="27.61652"
      />
    </g>
  </CircleIcon>
);

export default memo(ResizeIcon);
