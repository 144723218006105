import { gql } from '@apollo/client';

export default gql`
  fragment ProjectFields on Project {
    id
    priority
    isChallenge
    createdAt
    updatedAt
    tags {
      id
      name
    }
    visibility
    inheritedVisibility
    __typename
  }
`;
