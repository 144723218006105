import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/client';

import styles from './ActivateSchoolLicenseDialog.module.css';
import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';
import Input from '../atoms/input/Input';
import {
  schoolActivateLicenseTitle,
  schoolActivateLicenseHint,
  schoolActivateLicenseInputLabel,
  schoolActivateLicenseSubmit,
  schoolActivateLicenseLoading,
} from '../../strings';
import logException from '../../services/logging/exception';
import { ACTIVATE_SCHOOL_LICENSE } from '../../graphql/mutations/school';
import {
  ActivateSchoolLicense,
  ActivateSchoolLicenseVariables,
} from '../../graphql/mutations/school/types/ActivateSchoolLicense';

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'title' | 'handleClose'
> & {
  schoolId: string;
  handleClose: () => void;
};

const ActivateSchoolLicenseDialog = ({
  schoolId,
  handleClose,
  ...props
}: Props) => {
  const [code, setCode] = useState('');

  const [activateSchoolLicense, { loading, error }] = useMutation<
    ActivateSchoolLicense,
    ActivateSchoolLicenseVariables
  >(ACTIVATE_SCHOOL_LICENSE, {
    refetchQueries: ['GetSchoolPage'],
    awaitRefetchQueries: true,
    onCompleted: () => handleClose(),
    onError: (err) => {
      logException(err);
    },
  });

  return (
    <Dialog
      {...props}
      size="medium"
      title={schoolActivateLicenseTitle}
      description={schoolActivateLicenseHint}
      confirmAction={{
        label: schoolActivateLicenseSubmit,
        onClick: () =>
          activateSchoolLicense({
            variables: { id: schoolId, code },
          }),
        disabled: !code || loading,
      }}
      handleClose={handleClose}
      theme={{
        innerContainer: styles.innerContainer,
      }}
      isLoading={loading}
    >
      <Input
        id="activate-license-input"
        value={code}
        onChange={setCode}
        placeholder={schoolActivateLicenseInputLabel}
        bordered
        className={styles.input}
        disabled={loading}
      />

      {loading && (
        <Placeholder
          className={styles.placeholder}
          isLoading={loading}
          message={schoolActivateLicenseLoading}
        />
      )}

      {!!error && <span className={styles.errorMessage}>{error.message}</span>}
    </Dialog>
  );
};

export default memo(ActivateSchoolLicenseDialog);
