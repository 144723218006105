import { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { GetMyAccountForHubspot } from './types/GetMyAccountForHubspot';
import { trackUser } from '../utils/track';
import { AccountFrag } from '../graphql/fragments';
import { useRealm } from '../context/Realm';

const GET_MY_ACCOUNT_FOR_HUBSPOT = gql`
  query GetMyAccountForHubspot {
    myAccount {
      ...AccountFields
      userType
      isStudent
      noMarketing
      unsubscribedFromMarketing
      hardwarePreferences
      membershipType
    }
  }
  ${AccountFrag}
`;

export function useTrackUser() {
  const { isRealmLoading, realmName } = useRealm();

  const { data: { myAccount: user } = {} } = useQuery<GetMyAccountForHubspot>(
    GET_MY_ACCOUNT_FOR_HUBSPOT
  );

  useEffect(() => {
    if (isRealmLoading || !user) {
      return;
    }

    trackUser({ ...user, realm: realmName });
  }, [user, isRealmLoading, realmName]);
}
