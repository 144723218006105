import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import { useTabsContext } from './Tabs';
import styles from './Tabs.module.css';

export type Props = PropsWithChildren<{
  name: string;
  className?: string;
}>;

const TabContent = ({ name, className, children }: Props) => {
  const tabsContext = useTabsContext('TabContent');

  if (tabsContext.activeTab !== name) {
    return null;
  }

  return (
    <div
      data-testid={`${name}-tab-content`}
      className={cx(styles.tabContent, className)}
    >
      {children}
    </div>
  );
};

export default TabContent;
