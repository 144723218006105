import React from 'react';
import { string } from 'prop-types';

import CircleIcon from '../circleIcon/CircleIcon';
import styles from './PinIcon.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const PinIcon = ({ className }) => (
  <CircleIcon className={className}>
    <path
      className={styles.path}
      d="M7.8943,15.45686l6.41643,6.41687L8.317,27.86746c-0.1727,0.1727-0.1727,0.45185,0,0.62455
			c0.08613,0.08613,0.1992,0.12941,0.31227,0.12941c0.11307,0,0.22615-0.04329,0.31227-0.12941l5.99373-5.99373l6.43542,6.43542
			c0.08348,0.08348,0.19611,0.12942,0.31227,0.12942c0.02959,0,0.05963-0.00309,0.08922-0.00928
			c0.14576-0.03004,0.26634-0.13074,0.32111-0.26899c1.41915-3.56311,0.24779-7.1143-0.1356-8.09926l5.61874-6.60459
			c0.60821,0.04947,2.18681,0.06493,3.41249-0.90767c0.0985-0.07818,0.15901-0.19523,0.16607-0.32067
			c0.00751-0.12632-0.03931-0.24911-0.12853-0.33789l-6.73754-6.73753c-0.08834-0.08878-0.21157-0.13162-0.33612-0.12897
			c-0.125,0.00707-0.24116,0.0667-0.32023,0.16431c-0.04859,0.06007-1.13691,1.43991-0.90723,3.45755l-6.5719,5.59091
			c-0.96642-0.42093-4.32282-1.63602-8.10987-0.12721c-0.13781,0.05477-0.23895,0.17579-0.26899,0.32111
			C7.74457,15.20068,7.78962,15.35174,7.8943,15.45686z M9.0131,15.32524c3.75039-1.18285,6.97516,0.43153,7.00918,0.4492
			c0.15768,0.07995,0.35159,0.05919,0.48807-0.05654l6.97207-5.93189c0.11793-0.10026,0.17491-0.2553,0.14929-0.40812
			c-0.1886-1.14309,0.15901-2.07241,0.42579-2.583l5.96281,5.96281c-1.15767,0.63029-2.54325,0.42446-2.55915,0.42181
			c-0.02253-0.00397-0.04549-0.00574-0.06802-0.00574c-0.12853,0-0.25309,0.05609-0.33745,0.15547l-5.94514,6.98841
			c-0.11042,0.12986-0.13604,0.31183-0.06625,0.46775c0.01634,0.03622,1.53134,3.49112,0.45494,7.02684l-5.92924-5.92968
			L9.0131,15.32524z"
    />
    <path
      className={styles.path}
      d="M21.6826,29.35545c-0.19436,0-0.38396-0.07908-0.51925-0.21628l-6.22818-6.22722L9.14812,28.699
			c-0.27535,0.27535-0.76221,0.27725-1.03755,0c-0.14006-0.1391-0.21628-0.32394-0.21628-0.5202
			c0.00095-0.19627,0.07717-0.38015,0.21628-0.51925l5.78705-5.7861l-6.21007-6.21008
			c-0.17435-0.17435-0.24962-0.42398-0.20008-0.66693c0.05145-0.24391,0.21818-0.44303,0.4478-0.5345
			c3.69384-1.47106,7.00753-0.41445,8.16609,0.05907l6.31774-5.37451c-0.17245-2.05986,0.93751-3.45756,0.9861-3.51758
			c0.12958-0.16102,0.32298-0.2601,0.52973-0.27344c0.22485-0.00191,0.42112,0.07336,0.56213,0.21628l6.736,6.736
			c0.14863,0.14672,0.22676,0.35157,0.21437,0.56213c-0.01143,0.20675-0.11147,0.40111-0.2763,0.53164
			c-1.21381,0.96419-2.73727,1.0185-3.46708,0.98134l-5.41166,6.36061c0.44494,1.20905,1.45867,4.67232,0.07241,8.14989
			c-0.09051,0.22962-0.29059,0.3973-0.5345,0.44684C21.7874,29.34974,21.73595,29.35545,21.6826,29.35545z M14.93517,22.20596
			c0.07527,0,0.14958,0.02858,0.20675,0.08575l6.43588,6.43493c0.02858,0.02858,0.06574,0.04383,0.1048,0.04383
			c0.08003-0.01334,0.12005-0.04668,0.13815-0.09242c1.37578-3.4547,0.24391-6.91035-0.13529-7.88693
			c-0.03906-0.10004-0.02001-0.21342,0.04954-0.29535l5.61841-6.60452c0.06098-0.07146,0.15625-0.11242,0.24677-0.10194
			c0.58213,0.04859,2.06462,0.06002,3.20698-0.84605c0.03335-0.02572,0.05335-0.06479,0.05526-0.10576
			c0.00286-0.04478-0.01239-0.08575-0.04192-0.11528l-6.7379-6.7379c-0.03049-0.03049-0.09813-0.04669-0.12481-0.04383
			c-0.03049,0.00191-0.0705,0.02287-0.09718,0.05621c-0.04383,0.05431-1.06137,1.33672-0.8451,3.24033
			c0.01143,0.09718-0.02668,0.19341-0.10099,0.25629l-6.57117,5.59078c-0.08479,0.07336-0.20198,0.08956-0.30679,0.04573
			c-0.91846-0.40016-4.19976-1.59015-7.88407-0.12481c-0.04669,0.01905-0.08098,0.06002-0.09146,0.10957
			c-0.01048,0.04859,0.00476,0.10004,0.04002,0.13529l6.41682,6.41682c0.11433,0.11433,0.11433,0.29917,0,0.4135l-5.9938,5.9938
			c-0.02858,0.02858-0.04383,0.06574-0.04383,0.1048c0,0.04002,0.01524,0.07812,0.04383,0.10671
			c0.05526,0.05526,0.15339,0.05335,0.21056,0l5.9938-5.9938C14.78559,22.23454,14.8599,22.20596,14.93517,22.20596z
			M21.49967,28.10448c-0.07622,0-0.15149-0.02954-0.20675-0.08575L8.80608,15.53189
			c-0.07241-0.07241-0.10195-0.17721-0.07717-0.2763c0.02382-0.09909,0.09813-0.17912,0.19627-0.20961
			c3.86153-1.21667,7.20189,0.45256,7.23334,0.46971c0.04669,0.02382,0.11528,0.01906,0.16197-0.02096l6.97228-5.93187
			c0.04001-0.03335,0.05907-0.08575,0.0505-0.13624c-0.19627-1.19095,0.1391-2.16181,0.45542-2.76681
			c0.04288-0.08289,0.12291-0.14006,0.21437-0.15435c0.09718-0.01048,0.18674,0.01715,0.25153,0.08289l5.96236,5.96236
			c0.06574,0.06479,0.09623,0.1572,0.08289,0.24867c-0.01334,0.09146-0.0686,0.17054-0.14958,0.21532
			c-1.26526,0.68884-2.73632,0.45637-2.74966,0.45351c-0.06574-0.02382-0.10385,0.0181-0.13053,0.04954l-5.94616,6.98943
			c-0.03716,0.04383-0.04573,0.10575-0.02191,0.15911c0.01524,0.03525,1.57491,3.5919,0.4678,7.23048
			c-0.03049,0.09909-0.10957,0.17435-0.20865,0.19913C21.54731,28.10163,21.52349,28.10448,21.49967,28.10448z M9.57114,15.46996
			l11.77799,11.77799c0.77459-3.24319-0.55832-6.31107-0.57261-6.34346c-0.11433-0.2582-0.07051-0.56308,0.11147-0.7765
			l5.94521-6.98848c0.1391-0.16387,0.34299-0.2582,0.56022-0.2582c0.0162,0,0.10004,0.00667,0.11624,0.00953
			c0.00858,0.00191,1.02993,0.14958,2.00651-0.22295l-5.36879-5.36879c-0.20675,0.51639-0.3611,1.21477-0.22676,2.03128
			c0.04287,0.25248-0.0524,0.51258-0.24867,0.67836l-6.97228,5.93187c-0.2239,0.1915-0.54212,0.22866-0.80794,0.09623
			C15.85839,16.0197,12.99631,14.59343,9.57114,15.46996z"
    />
    <path
      className={styles.internal}
      d="M31.44354,12.86538c-0.01001,0.20996-0.10999,0.40997-0.27002,0.53998
			c-1.21997,0.96002-2.73999,1.01001-3.46997,0.97998l-5.41003,6.36005c0.44,1.20996,1.46002,4.66998,0.07001,8.14996
			c-0.09003,0.22998-0.28998,0.40002-0.52997,0.44c-0.05005,0.01001-0.10004,0.02002-0.15002,0.02002
			c-0.20001,0-0.39001-0.08002-0.52002-0.22003l-6.22998-6.21997l-5.78998,5.77997c-0.27002,0.28003-0.76001,0.28003-1.03003,0
			c-0.14001-0.13995-0.21997-0.32001-0.21997-0.51996c0-0.19,0.07996-0.38,0.21997-0.52002l5.78003-5.78003l-6.21002-6.20996
			c-0.16998-0.17999-0.25-0.42999-0.20001-0.66998c0.06-0.24005,0.22003-0.44,0.45001-0.53003
			c3.70001-1.46997,7.01001-0.41998,8.16998,0.06l6.32001-5.38c-0.17999-2.06,0.92999-3.46002,0.97998-3.52002
			c0.13-0.15997,0.32001-0.25995,0.53003-0.26996c0.22998,0,0.41998,0.07001,0.56,0.21997l6.73999,6.73004
			C31.38354,12.45534,31.4635,12.65535,31.44354,12.86538z"
    />
  </CircleIcon>
);

PinIcon.propTypes = propTypes;
PinIcon.defaultProps = defaultProps;
export default PinIcon;
