import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import useResizeObserver from 'use-resize-observer';

// Components
import AddIcon from '../addIcon/AddIcon';
import Button from '../atoms/button/Button';
import CollapseIcon from '../collapseIcon/CollapseIcon';
import { useTooltip } from '../tooltip/Tooltip';

import {
  emptyMultiSelectList,
  draftSubmissonAddMemberInstructionText,
} from '../../strings';

// Locals
import styles from './TagsList.module.css';
import DownloadTag from '../downloadTag/DownloadTag';
import Tag from '../tag/Tag';

export type Props = {
  canExpand?: boolean;
  canManageList?: boolean;
  onAdd?: () => void;
  onRemove?: (tag: { id: string; label: string }) => void;
  options: {
    canRemove?: boolean;
    highlighted?: boolean;
    id: string;
    label: string;
    url?: string;
    title?: string;
    className?: string;
  }[];
  placeholderText?: string;
  useDownloadLink?: boolean;
  isDraftSubmission?: boolean;
  className?: string;
};

const isElementWrapping = (id: string) => {
  const element = document.getElementById(`select_option_${id}`);

  if (!element) return false;
  return element.offsetTop > 0;
};

const isListWrapping = (options: { id: string }[]) =>
  !!options.find(({ id }) => isElementWrapping(id));

const getLastVisibleItemIndex = (options: { id: string }[]) =>
  options.findIndex((option) => isElementWrapping(option.id)) - 1;

const TagsList = ({
  canExpand = true,
  canManageList,
  onAdd,
  onRemove,
  options,
  placeholderText = emptyMultiSelectList,
  useDownloadLink,
  isDraftSubmission,
  className,
}: Props) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [showToolTip, setShowToolTip] = useState(false);

  const { tooltip, setTooltipReferenceElement } = useTooltip({
    active: showToolTip,
    message: !isDraftSubmission
      ? 'Add new'
      : draftSubmissonAddMemberInstructionText,
    placement: 'bottom',
  });

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isWrapping, setIsWrapping] = useState(false);
  const [lastVisibleItemIndex, setLastVisibleItemIndex] = useState<number>(-1);

  const updateWrapping = useCallback(() => {
    setIsWrapping(isListWrapping(options));
  }, [options]);

  const updateLastVisibleItemIndex = useCallback(() => {
    const newLastVisibleItemIndex = getLastVisibleItemIndex(options);
    setLastVisibleItemIndex(newLastVisibleItemIndex);
  }, [options]);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      updateWrapping();
      updateLastVisibleItemIndex();
    },
  });

  useLayoutEffect(updateWrapping, [updateWrapping]);

  useEffect(updateLastVisibleItemIndex, [updateLastVisibleItemIndex]);

  const addButton = canManageList && (
    <div className={styles.actions}>
      <div
        ref={setTooltipReferenceElement}
        onMouseEnter={() => {
          setShowToolTip(true);
        }}
        onMouseLeave={() => {
          setShowToolTip(false);
        }}
      >
        <Button
          className={cx(styles.button, styles.addButton)}
          onClick={onAdd}
          ariaLabel={
            !isDraftSubmission
              ? 'Add new'
              : draftSubmissonAddMemberInstructionText
          }
          unstyled
          disabled={isDraftSubmission}
        >
          <AddIcon />
        </Button>
      </div>
      {tooltip}
    </div>
  );

  if (_.isEmpty(options)) {
    return (
      <div className={cx(styles.placeholderContent, className)}>
        {addButton}
        <span className={styles.placeholderText}>{placeholderText}</span>
      </div>
    );
  }

  const TagComponent = useDownloadLink ? DownloadTag : Tag;

  return (
    <div
      ref={setContainerRef}
      className={cx(styles.root, className)}
      data-testid="tags-list"
    >
      {addButton}

      <ul className={cx(styles.list, { [styles.collapsed]: isCollapsed })}>
        {options.map(
          ({ canRemove, id, label, url = '', ...passedProps }, i) => {
            const showNumWrapping =
              isWrapping && isCollapsed && lastVisibleItemIndex === i;
            const numWrapping = options.length - lastVisibleItemIndex - 1;

            return (
              <li
                key={`select_option_${id}`}
                id={`select_option_${id}`}
                className={cx(className, styles.item, {
                  [styles.showNumWrapping]: showNumWrapping,
                })}
              >
                <TagComponent
                  {...passedProps}
                  label={label}
                  url={url}
                  canRemove={!isDraftSubmission && (canRemove || canManageList)}
                  onRemove={onRemove && (() => onRemove({ id, label }))}
                  className={cx(styles.inner, passedProps.className)}
                />

                {showNumWrapping && (
                  <Button
                    unstyled
                    title="more"
                    id="more"
                    onClick={() => setIsCollapsed(false)}
                    disabled={!canExpand}
                    className={styles.numWrapping}
                  >
                    +{numWrapping}
                  </Button>
                )}
              </li>
            );
          }
        )}
      </ul>

      <div className={styles.actions}>
        {isWrapping && canExpand && (
          <Button
            className={styles.button}
            onClick={() => setIsCollapsed(!isCollapsed)}
            title="Toggle wrapping"
            unstyled
          >
            <CollapseIcon isCollapsed={isCollapsed} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(TagsList);
