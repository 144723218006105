import React, { ReactChild, useState } from 'react';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import createClient from './client';
import useIsMounted from '../utils/useIsMounted';

export type Props = {
  useAuthLink?: boolean;
  children: ReactChild;
};

type Context = {
  isCacheReady: boolean;
};

const CacheContext = React.createContext<Context | undefined>(undefined);

const PersistedCacheApolloProvider = ({
  useAuthLink = true,
  children,
}: Props) => {
  const [isCacheReady, setIsCacheReady] = useState(false);
  const isMounted = useIsMounted();
  const [client] = useState(() =>
    createClient({
      persistCache: true,
      onCacheReady: () => isMounted.current && setIsCacheReady(true),
      useAuthLink,
    })
  );

  return (
    <ApolloProvider client={client as ApolloClient<any>}>
      <CacheContext.Provider value={{ isCacheReady }}>
        {children}
      </CacheContext.Provider>
    </ApolloProvider>
  );
};

function usePersistedCacheContext() {
  const context = React.useContext(CacheContext);
  if (context === undefined) {
    throw new Error(
      'usePersistedCacheContext must be used within a PersistedCacheApolloProvider'
    );
  }
  return context;
}

export { PersistedCacheApolloProvider, usePersistedCacheContext, CacheContext };
