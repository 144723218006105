import { gql } from '@apollo/client';

export default gql`
  fragment BlockFields on Block {
    id
    blockType
    content
    number
    pinningId
    reported
    createdAt
    updatedAt
    __typename
  }
`;
