import React, { memo } from 'react';
import cx from 'classnames';

import styles from './BigLockIcon.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  className?: string;
  theme?: Partial<Theme>;
};

const BigLockIcon = ({ className, theme: customTheme = {} }: Props) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(className, theme.icon)}
      viewBox="0 0 175.99 124.74"
      data-testid="bigLockIcon"
    >
      <path
        className={styles.lightGreenFill}
        d="M0,103.09c.54,8.74,9.06,14.79,17.32,17.7,41,14.42,86.6-16.11,128.68-5.35,5.54,1.42,11,3.55,16.74,3.39s11.9-3.46,13-9.07c.84-4.15-1.34-8.45-4.5-11.28s-7.19-4.45-11.12-6L121.46,76.92c-9.3-3.73-18.79-7.51-28.78-8.26-12.72-1-23.57-1.86-36-4.91-7-1.72-14.08-3.06-21-.42C22.71,68.23-.89,88.37,0,103.09Z"
      />
      <path
        className={styles.piTopGreenFill}
        d="M69.23,20.62c-3-1.9-2.61-11.29,3.42-16.57,6.61-5.77,17-4,18.37-.72C93.18,8.65,74.26,23.8,69.23,20.62Z"
      />
      <path
        className={cx(styles.lineStyle, styles.noFill)}
        d="M108.36,47.66a49.78,49.78,0,0,1,5.17,19.76"
      />
      <path
        className={cx(styles.lineStyle, styles.noFill)}
        d="M115,66.57a5,5,0,0,1,1.7,2.1,2.67,2.67,0,0,1,.14,2,2.93,2.93,0,0,1-1.69,1.42,4.31,4.31,0,0,1-1.9.38,1.49,1.49,0,0,1-.68-.2,1.58,1.58,0,0,1-.54-.92,10.43,10.43,0,0,1,.38-5.05"
      />
      <path
        className={styles.piTopGreenFill}
        d="M108,76.58a24.74,24.74,0,0,1-5,7.28,11.61,11.61,0,0,1-4.11,2.54l-.31.09a3.22,3.22,0,0,1-.44.14,11.59,11.59,0,0,1-1.17.25l-.17,0c-.58.09-1.17.14-1.73.17a131,131,0,0,1-34.2-2.72,4,4,0,0,1-3.22-2.21,10.34,10.34,0,0,1-.6-3.9c0-.55,0-1.22-.1-2-.58-10.14-3.18-37.24-.14-45.78a11,11,0,0,1,.79-3,13.17,13.17,0,0,1,6.86-6.64c1.13.67,2.33,1.35,3.61,2,1.47.77,2.9,1.44,4.26,2.05a6.44,6.44,0,0,1-.29,2.5l26,.47c1.5,0,3.19.17,4.21,1.92a12.42,12.42,0,0,1,1.09,5A325.18,325.18,0,0,1,104,74.44c.75-.12,1.51-.22,2.27-.3s1.78,0,2.07.81A2.22,2.22,0,0,1,108,76.58Z"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M60.21,72.6c0,.78.08,1.46.1,2a10.37,10.37,0,0,0,.62,3.94,4.14,4.14,0,0,0,3.34,2.22,130.25,130.25,0,0,0,34.39,2.69,9.73,9.73,0,0,0,8.11-4.29,12.87,12.87,0,0,0,2-7.18,326.65,326.65,0,0,0-.56-41.27,12,12,0,0,0-1.13-5.07c-1.06-1.76-2.8-1.91-4.35-1.94l-35.48-4a14.14,14.14,0,0,0-1.39.5,15.27,15.27,0,0,0-4.4,3.09C56.65,28.31,60,69.12,60.21,72.6Z"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M94.47,93.81A6.28,6.28,0,0,0,91.73,93a2.26,2.26,0,0,0-2.21,1.5c-.28,1.12.64,2.15,1.56,2.84A14,14,0,0,0,98,100a2,2,0,0,0,1.56-.3,1.75,1.75,0,0,0,.61-.9,2.14,2.14,0,0,0-1.06-2.29c-.64-.45-4-2.27-4.61-2.7"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M70.73,97.9a6.43,6.43,0,0,0-.86-2.73,2.25,2.25,0,0,0-2.48-1c-1.08.4-1.43,1.74-1.48,2.89a14,14,0,0,0,1.64,7.2,2,2,0,0,0,1.13,1.12A1.87,1.87,0,0,0,70,105.2a2.51,2.51,0,0,0,1.12-2c.07-.79-.33-4.55-.34-5.34"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M52.24,66.35a4.91,4.91,0,0,0-1.91,1.9,2.64,2.64,0,0,0-.36,2,2.89,2.89,0,0,0,1.53,1.6,4.22,4.22,0,0,0,1.85.58,1.35,1.35,0,0,0,.69-.13,1.53,1.53,0,0,0,.64-.86,10.41,10.41,0,0,0,.16-5.05"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M69.73,17.41h0s0,.11,0,.19a1.41,1.41,0,0,1,0,.18.85.85,0,0,0,.39.75,4.91,4.91,0,0,0,2.1.43c2.39,0,4.77,0,7.15,0,3.57.09,9.61-.14,15.16.48,4,.45,4.9.86,5.77.11,2.38-2.07.75-9.64-3.53-13.93C91.28.12,79.8-1.44,73.75,5,69.34,9.71,69.72,17.36,69.73,17.41Z"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M73.82,19.61v0a1.25,1.25,0,0,1,0,.19l0,.18a.87.87,0,0,0,.39.75,5.05,5.05,0,0,0,2.1.42c2.38,0,4.77.16,7.15,0,1.79-.09,2-.07,3.31-.08,4,0,4.69.06,5.82-.15a5.34,5.34,0,0,0,2.42-.75c2.17-1.81,1.37-8.41-2.32-12a11.74,11.74,0,0,0-14.92-1A11.73,11.73,0,0,0,73.82,19.61Z"
      />
      <path
        className={styles.piTopGreenFill}
        d="M94.24,19.36c-.27-.14-.1-.86,0-1.53a12.33,12.33,0,0,0-.09-3.32,14.29,14.29,0,0,0-.92-2.71,11.92,11.92,0,0,0-1-2.16A14.09,14.09,0,0,0,90,7.21a6.46,6.46,0,0,1,2,1.35A9.85,9.85,0,0,1,93.3,9.93a22.16,22.16,0,0,1,1.36,2.77.53.53,0,0,0,.06.13,10.36,10.36,0,0,1,.73,3.69,9.64,9.64,0,0,1-.23,2.18,1.09,1.09,0,0,1-.35.45S94.45,19.47,94.24,19.36Z"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M65.71,67.21c0,.76.07,1.42.09,2A11.07,11.07,0,0,0,66.36,73a3.78,3.78,0,0,0,3,2.16,117.23,117.23,0,0,0,32.12,2.68c2.68-.15,5.77-.75,7.26-4.46a21.47,21.47,0,0,0,1-6.76,338.61,338.61,0,0,0-.5-40.17,12.71,12.71,0,0,0-1-4.94c-1-1.71-2.54-1.85-4-1.88L71.21,19a6.86,6.86,0,0,0-4.22.85C62.05,23.2,65.1,55.92,65.71,67.21Z"
      />
      <path
        className={cx(styles.lineStyle, styles.whiteFill)}
        d="M83.47,48.46a15.42,15.42,0,0,1-2.54-1.77,4.78,4.78,0,0,1-1.38-2,5.2,5.2,0,0,1,.94-4.36,6.7,6.7,0,0,1,4.57-2.59A6.49,6.49,0,0,1,90.54,39a5.81,5.81,0,0,1,1.8,3.71,5.59,5.59,0,0,1-.82,3.68c-1,1.51-2.16,1.08-3,2.17-1.89,2.39,1.74,6.53,0,8.66a3.45,3.45,0,0,1-4.31.55C82.29,56.07,85.79,50.5,83.47,48.46Z"
      />
      <path
        className={cx(styles.lineStyle, styles.noFill)}
        d="M61.83,49a46.58,46.58,0,0,0-8.21,18.33"
      />
      <path
        className={cx(styles.lineStyle, styles.noFill)}
        d="M74.66,80.65q-3.27,8.82-6.17,17.77"
      />
      <path
        className={cx(styles.lineStyle, styles.noFill)}
        d="M93,81.74c.9,1.91,2.08,4.54,1.89,6.6a29.9,29.9,0,0,1-2,6.6"
      />
    </svg>
  );
};

export default memo(BigLockIcon);
