import { gql } from '@apollo/client';

export default gql`
  fragment SectionFields on Section {
    id
    title
    description
    createdAt
    updatedAt
    crossVersionId
    number
    __typename
  }
`;
