import { gql } from '@apollo/client';

export default gql`
  mutation UpdateHardwarePreferences(
    $hardwarePreferences: [HardwarePreference!]!
  ) {
    updateHardwarePreferences(hardwarePreferences: $hardwarePreferences) {
      __typename
      id
      hardwarePreferences
    }
  }
`;
