import React from 'react';

import SignUpHandler from './SignUpHandler';
import { useAuth } from '../../context/Auth';

export type Props = {
  className?: string;
};

const SignUpHandlerContainer = ({ className }: Props) => {
  const { login, register } = useAuth();

  return (
    <SignUpHandler login={login} register={register} className={className} />
  );
};

export default SignUpHandlerContainer;
