function getFullErrorStack(err) {
  let ret = err.stack || err.toString();
  if (err.cause && typeof err.cause === 'function') {
    const cerr = err.cause();
    if (cerr) {
      ret += `\nCaused by: ${getFullErrorStack(cerr)}`;
    }
  }
  return ret;
}

export default (err) => {
  if (!err) {
    return err;
  }

  return {
    message: err.message,
    stack: getFullErrorStack(err),
    name: err.name,
    code: err.code,
    signal: err.signal,
    details: err.details,
    statusCode: err.statusCode,
  };
};

export const errorHasStatusCode = (err, statusCode) => {
  if (!err || !statusCode) {
    return false;
  }
  if (Array.isArray(err.graphQLErrors)) {
    return (
      err.graphQLErrors.findIndex(
        (errorItem) =>
          errorItem.extensions &&
          errorItem.extensions.exception &&
          errorItem.extensions.exception.statusCode === statusCode
      ) > -1
    );
  }
  return false;
};
