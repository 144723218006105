import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { distanceInWordsStrict } from 'date-fns';

import styles from './NotificationCard.module.css';
import { notificationMessages } from '../../strings';
import Underline from '../atoms/underline/Underline';
import ProjectLink from './ProjectLink';
import SubmissionLink from './SubmissionLink';
import { GetNotifications_getNotifications } from '../../graphql/queries/notifications/types/GetNotifications';

import {
  ADD_COMMENT_ON_PROJECT,
  ADD_COMMENT_ON_SUBMISSION,
  ADD_COMMENT_REPLY,
  EDIT_PROJECT,
  EDIT_SUBMISSION,
} from '../../utils/notificationTypes';
import {
  GetNotificationCard,
  GetNotificationCardVariables,
} from './types/GetNotificationCard';

const GET_NOTIFICATION_CARD = gql`
  query GetNotificationCard($id: String!) {
    account(id: $id) {
      id
      username
    }
  }
`;

interface AnnotatedNotification extends GetNotifications_getNotifications {
  isUnread: boolean;
  count: number;
}

type Props = {
  notification: AnnotatedNotification;
};

const typeContainer = (notification: AnnotatedNotification) => {
  switch (notification.type) {
    case ADD_COMMENT_ON_PROJECT:
    case EDIT_PROJECT:
      if (notification.data.projectId) {
        return <ProjectLink projectId={notification.data.projectId} />;
      }
      return null;
    case ADD_COMMENT_ON_SUBMISSION:
    case EDIT_SUBMISSION:
      if (notification.data.submissionId) {
        return <SubmissionLink submissionId={notification.data.submissionId} />;
      }
      return null;
    case ADD_COMMENT_REPLY:
      if (notification.data.projectId) {
        return <ProjectLink projectId={notification.data.projectId} />;
      }
      if (notification.data.submissionId) {
        return <SubmissionLink submissionId={notification.data.submissionId} />;
      }
      return null;
    default:
      return null;
  }
};

const NotificationCard = ({ notification }: Props) => {
  const { data: { account } = { account: null } } = useQuery<
    GetNotificationCard,
    GetNotificationCardVariables
  >(GET_NOTIFICATION_CARD, {
    variables: { id: notification.data.creatorId },
  });

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <div className={styles.messageBody}>
          {/* <span className={styles.icon}>
            <NotificationIcon />
          </span> */}
          <div className={styles.message}>
            {account && account.username ? (
              <strong className={styles.userName}>{account.username}</strong>
            ) : (
              <strong className={styles.unknownValue}>user</strong>
            )}
            <span>{notificationMessages[notification.type](notification)}</span>
            {typeContainer(notification)}
          </div>
        </div>
        <p className={styles.cardFooter}>
          <small>
            {distanceInWordsStrict(new Date(), notification.updatedAt, {
              addSuffix: true,
            })}
          </small>
        </p>
        {notification.isUnread && (
          <svg
            className={styles.unreadBG}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="14.77286148071289 11.159027099609375 1076.6959228515625 370.30438232421875"
          >
            <path d="M15.6 11.16s-4.625 351.528 7.998 362.32c12.623 10.793 1033.776 8.092 1057.38 6.359 23.604-1.733 0-368.68 0-368.68H15.6z" />
          </svg>
        )}
      </div>
      <Underline />
    </div>
  );
};

export default NotificationCard;
