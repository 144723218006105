import React, { memo } from 'react';

import styles from './CommentsIcon.module.css';

const CommentsIcon = () => (
  <span className={styles.icon}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.76 52.83">
      <path
        className={styles.st1}
        d="M40.98,31.35c-1.04,1.83-2.64,3.28-4.56,4.14c-3.28,1.54-6.86,2.34-10.49,2.34
	c-0.55,0.01-1.71-0.08-2.25-0.13c-1.75,1.57-4.85,4.42-5.21,4.81c-1.75,1.94-1.72,0.64-1.68,0.34c0.38-2.26,0.62-4.54,0.73-6.83l0,0
	c-2.93-1.54-4.15-4.14-4.96-6.97c-0.67-2.57-0.36-5.3,0.87-7.65c0.81-1.65,1.9-3.14,3.22-4.41c1.13-1.19,2.53-2.09,4.08-2.63
	c1.04-0.3,2.12-0.43,3.21-0.41c1.14,0,2.3,0.09,3.37,0.17c2.36,0.12,4.7,0.51,6.98,1.15c1.9,0.45,3.64,1.41,5.03,2.79
	c1.19,1.56,1.91,3.43,2.09,5.38C41.86,26.09,42.23,28.9,40.98,31.35z"
      />
      <g transform="translate(-4285.224 -2591.394)">
        <g>
          <path
            className={styles.st2}
            d="M4309.27,2614.07c-0.09,0-0.17,0.01-0.25,0.04c-0.13,0.05-0.24,0.13-0.33,0.24
			c-0.11,0.12-0.19,0.25-0.26,0.4c-0.1,0.22-0.12,0.46-0.07,0.69c0.08,0.32,0.3,0.58,0.6,0.71c0.15,0.05,0.31,0.08,0.47,0.08
			c0.29,0,0.58-0.07,0.83-0.21c0.16-0.08,0.29-0.21,0.36-0.37c0.08-0.23,0.09-0.48,0.03-0.71c-0.01-0.17-0.07-0.34-0.17-0.49
			c-0.11-0.12-0.25-0.21-0.4-0.25c-0.18-0.06-0.37-0.09-0.56-0.1C4309.45,2614.09,4309.36,2614.08,4309.27,2614.07"
          />
        </g>
        <g>
          <path
            className={styles.st2}
            d="M4314.57,2614.07c-0.09,0-0.17,0.01-0.25,0.04c-0.13,0.05-0.24,0.13-0.32,0.24
			c-0.11,0.12-0.19,0.25-0.26,0.4c-0.1,0.22-0.12,0.46-0.07,0.69c0.08,0.32,0.3,0.58,0.6,0.71c0.15,0.05,0.31,0.08,0.47,0.08
			c0.29,0,0.58-0.07,0.83-0.21c0.16-0.08,0.29-0.21,0.36-0.37c0.08-0.23,0.09-0.48,0.03-0.71c-0.01-0.17-0.07-0.34-0.17-0.49
			c-0.11-0.12-0.25-0.21-0.4-0.25c-0.18-0.06-0.37-0.09-0.56-0.1C4314.76,2614.09,4314.67,2614.08,4314.57,2614.07"
          />
        </g>
        <g>
          <path
            className={styles.st2}
            d="M4319.88,2614.07c-0.09,0-0.17,0.01-0.25,0.04c-0.13,0.05-0.24,0.13-0.32,0.24
			c-0.11,0.12-0.19,0.25-0.26,0.4c-0.1,0.22-0.12,0.46-0.07,0.69c0.08,0.32,0.3,0.58,0.6,0.71c0.15,0.05,0.31,0.08,0.47,0.08
			c0.29,0,0.58-0.07,0.83-0.21c0.16-0.08,0.29-0.21,0.36-0.37c0.08-0.23,0.09-0.48,0.03-0.71c-0.01-0.17-0.07-0.34-0.17-0.49
			c-0.11-0.12-0.25-0.21-0.4-0.25c-0.18-0.06-0.37-0.09-0.56-0.1C4320.06,2614.09,4319.97,2614.08,4319.88,2614.07"
          />
        </g>
      </g>
      <path
        className={styles.st3}
        d="M43.33,29.74c-1.04,1.83-2.64,3.28-4.56,4.14c-3.28,1.54-6.86,2.34-10.49,2.34
	c-0.55,0.01-1.71-0.08-2.25-0.12c-1.75,1.57-4.85,4.42-5.21,4.81c-1.76,1.94-1.72,0.64-1.68,0.34c0.38-2.26,0.62-4.54,0.73-6.83l0,0
	c-2.93-1.54-4.15-4.14-4.96-6.97c-0.67-2.57-0.36-5.3,0.88-7.65c0.81-1.65,1.9-3.14,3.22-4.42c1.13-1.19,2.53-2.09,4.08-2.63
	c1.04-0.29,2.12-0.43,3.21-0.4c1.14,0,2.3,0.09,3.37,0.17c2.36,0.12,4.7,0.51,6.98,1.15c1.9,0.45,3.64,1.41,5.03,2.79
	c1.19,1.56,1.91,3.43,2.09,5.38C44.22,24.48,44.58,27.3,43.33,29.74z"
      />
    </svg>
  </span>
);

export default memo(CommentsIcon);
