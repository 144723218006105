import React, { useState } from 'react';
import { HeaderContext } from '@tanstack/react-table';
import cx from 'classnames';

import { useTooltip } from '../tooltip/Tooltip';
import SearchBar from '../searchBar/SearchBar';
import TagsSelect from '../tagsSelect/TagsSelect';
import Button from '../atoms/button/Button';
import { FilterIcon, SearchIcon } from '../icons';
import styles from './MultiSelectTable.module.css';
import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';

export type Props<RowData> = {
  name: string;
  header: HeaderContext<RowData, unknown>;
  filterType?: 'search' | 'filter';
  filterOptions?: string[];
  className?: string;
};

export const Header = <RowData extends Record<string, any>>({
  name,
  header,
  filterType,
  filterOptions = [],
  className,
}: Props<RowData>) => {
  const [isShowingFilterTooltip, setIsShowingFilterTooltip] = useState(false);
  const filterValue = header.column.getFilterValue();
  const tagsFilterValue = Array.isArray(filterValue) ? filterValue : [];
  const textSearch = typeof filterValue === 'string' ? filterValue : '';

  const { tooltip, setTooltipReferenceElement } = useTooltip({
    active: isShowingFilterTooltip,
    message: (
      <>
        {filterType === 'search' && (
          <SearchBar
            aria-label="Search input"
            value={textSearch}
            placeholder={`Search for a ${name.toLowerCase()}...`}
            onChange={({ target }) => {
              const nextTextSearch = (target as HTMLInputElement).value;
              header.column.setFilterValue(nextTextSearch);
            }}
            className={styles.searchBar}
          />
        )}

        {filterType === 'filter' && (
          <TagsSelect
            aria-label={`${name.toLowerCase()} filter select`}
            placeholder={`Filter ${name.toLowerCase()}`}
            onSelectedTagsChange={(tags) => {
              header.column.setFilterValue(tags);
            }}
            tags={filterOptions}
            initialSelectedTags={header.column.getFilterValue() as string[]}
            className={styles.tagsFilter}
          />
        )}
      </>
    ),
    allowHover: true,
    placement: 'bottom',
    theme: {
      tooltip: styles.filterTooltip,
    },
  });

  const outsideClickContainerRef = useOutsideClickHandler<HTMLTableCellElement>(
    () => {
      if (isShowingFilterTooltip) {
        setIsShowingFilterTooltip(false);
      }
    },
    { active: isShowingFilterTooltip }
  );

  return (
    <th className={cx(styles.header, className)} ref={outsideClickContainerRef}>
      <div className={styles.headerContent}>
        {name}

        <div className={styles.headerActions}>
          {header.column.getCanSort() && (
            <Button
              unstyled
              aria-label="sort toggle"
              className={cx(styles.sort, {
                [styles.sortAsc]: header.column.getIsSorted() === 'asc',
                [styles.sortDesc]: header.column.getIsSorted() === 'desc',
              })}
              onClick={header.column.getToggleSortingHandler()}
            />
          )}

          {header.column.getCanFilter() && !!filterType && (
            <Button
              unstyled
              buttonRef={setTooltipReferenceElement}
              aria-label={`${filterType} toggle`}
              className={cx(styles.filterToggle, {
                [styles.filtering]:
                  isShowingFilterTooltip ||
                  tagsFilterValue.length ||
                  textSearch,
              })}
              onClick={() => setIsShowingFilterTooltip(!isShowingFilterTooltip)}
            >
              {filterType === 'filter' ? (
                <FilterIcon />
              ) : (
                <SearchIcon noCircle />
              )}
            </Button>
          )}
        </div>

        {!isShowingFilterTooltip && (
          <span className={styles.textSearchPreviewWrapper}>
            <div className={styles.textSearchPreview}>{textSearch}</div>
          </span>
        )}
      </div>

      {tooltip}
    </th>
  );
};
