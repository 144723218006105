import React, { memo } from 'react';
import cx from 'classnames';
import { shape, string } from 'prop-types';

import styles from './PdfExportIcon.module.css';
import useTheme from '../../hooks/useTheme';

const propTypes = {
  className: string,
  theme: shape({}),
};

const defaultProps = {
  className: '',
  theme: {},
};

const PdfExportIcon = ({ className, theme: customTheme }) => {
  const theme = useTheme(styles, customTheme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx(theme.root, className)}
      viewBox="6 0 50.28 34.29"
    >
      <path
        className={theme.background}
        d="M32.45,13.54c1.18,0.01,2.36,0.04,3.55,0.12c1.76,0.11,3.79,0.2,4.69,2
	c0.68,1.72,0.93,3.58,0.72,5.41c-0.01,0.21-0.01,0.41-0.01,0.61c-0.04,2.03,0.07,4.06-0.06,6.09c-0.12,1.86-0.33,4.19-1.54,5.7
	c-1.32,1.64-3.32,1.94-5.31,2.08c-2.77,0.19-5.54-0.05-8.31-0.05c-2.91,0-5.83,0.11-8.73-0.16c-0.93-0.02-1.84-0.29-2.63-0.79
	c-0.58-0.47-1.01-1.1-1.23-1.81c-0.41-1.61-0.53-3.28-0.35-4.94c0.1-4.32-1.31-12.17,4.15-13.92c1.91-0.49,3.89-0.62,5.85-0.41"
      />
      <path
        className={theme.path}
        d="M34.28,11.01c1.18,0.01,2.36,0.04,3.55,0.12c1.76,0.11,3.79,0.2,4.69,2
	c0.68,1.72,0.93,3.58,0.72,5.41c-0.01,0.21-0.01,0.41-0.01,0.61c-0.04,2.03,0.07,4.06-0.06,6.09c-0.12,1.86-0.33,4.19-1.54,5.7
	c-1.32,1.64-3.32,1.94-5.31,2.08c-2.77,0.19-5.54-0.05-8.31-0.05c-2.91,0-5.83,0.11-8.73-0.16c-0.93-0.02-1.84-0.29-2.63-0.79
	c-0.58-0.47-1.01-1.1-1.23-1.81c-0.41-1.61-0.53-3.28-0.35-4.94c0.1-4.32-1.31-12.17,4.15-13.92c1.91-0.49,3.89-0.62,5.85-0.41"
      />
      <g className={theme.downArrow}>
        <g transform="translate(-3269.68 -2368.759)">
          <path
            className={theme.path}
            d="M3298.58,2370.65c-0.56,6.67,0.43,16.27,0.13,22.6"
          />
          <path
            className={theme.path}
            d="M3298.8,2393.9c-1.8-1.67-3.35-3.58-4.61-5.69"
          />
          <path
            className={theme.path}
            d="M3298.8,2393.9c1.8-1.67,3.35-3.58,4.61-5.69"
          />
        </g>
      </g>
    </svg>
  );
};
PdfExportIcon.propTypes = propTypes;
PdfExportIcon.defaultProps = defaultProps;

export default memo(PdfExportIcon);
