import { ApolloLink } from '@apollo/client';
import { print } from 'graphql/language/printer';

import { IObject } from '../../types/index.types';
import log from '../../services/logging/info';

const jsonSize = (json: any) => {
  const stringJson = JSON.stringify(json);
  // eslint-disable-next-line no-bitwise
  return ~-encodeURI(stringJson).split(/%..|./).length;
};

export default new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime();
  const { operationName, variables } = operation;
  const query = print(operation.query);
  const { headers: reqHeaders } = operation.getContext();

  const req = {
    method: 'POST',
    url: '/graphql',
    body: {
      operationName,
      query,
      variables,
    },
    headers: reqHeaders,
  };

  return forward(operation).map((result) => {
    const responseTime = new Date().getTime() - startTime;
    const { response } = operation.getContext();
    const { status, statusText, type, url, redirected, ok } = response;

    const resHeaders: IObject = {};
    response.headers.forEach((value: any, key: string) => {
      resHeaders[key] = value;
    });

    const res: any = {
      status,
      statusText,
      type,
      url,
      redirected,
      ok,
      headers: resHeaders,
      body: result,
    };

    const logPayload = {
      type: 'clientRequestLog',
      responseTime,
      'pt-request-id': reqHeaders['pt-request-id'],
      req,
      res,
    };

    const payloadSize = jsonSize(logPayload);
    if (payloadSize < 0 || payloadSize > 50000) {
      delete logPayload.res.body;
    }

    log(logPayload);

    return result;
  });
});
