import React, { useEffect, useCallback, MouseEvent, ReactNode } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import useOutsideClickHandler from '../../hooks/useOutsideClickHandler';
import Button from '../atoms/button/Button';
import MaskedDiv from '../maskedDiv/MaskedDiv';

import ModalMask from '../../assets/images/dialog-mask.svg';

import styles from './Modal.module.css';
import useTheme from '../../hooks/useTheme';

export type Theme = typeof styles;

export type Props = {
  active?: boolean;
  preserveChildState?: boolean;
  children?: ReactNode;
  className?: string;
  clickBackdrop?: boolean;
  handleClose?: (e?: MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  hasBlurBg?: boolean;
  ariaLabel?: string;
  size?: 'large' | 'medium' | 'small';
  style?: any;
  theme?: Partial<Theme>;
};

const Modal = ({
  active,
  className,
  clickBackdrop = true,
  isLoading,
  preserveChildState,
  children,
  handleClose,
  hasBlurBg,
  ariaLabel,
  size = 'large',
  style,
  theme: customTheme = {},
}: Props) => {
  const theme = useTheme(styles, customTheme);

  const escapeKeyListener = useCallback(
    ({ keyCode }: any) => {
      if (
        handleClose &&
        active &&
        clickBackdrop &&
        !isLoading &&
        keyCode === 27
      ) {
        handleClose();
      }
    },
    [active, isLoading, clickBackdrop, handleClose]
  );

  useEffect(() => {
    if (!active) {
      return;
    }

    document.documentElement.style.overflow = 'hidden';
    document.body.addEventListener('keyup', escapeKeyListener);
    if (hasBlurBg) {
      document.documentElement.classList.add('mask');
    }
    return () => {
      document.documentElement.style.overflow = '';
      document.documentElement.classList.remove('mask');
      document.body.removeEventListener('keyup', escapeKeyListener);
    };
  }, [active, hasBlurBg, escapeKeyListener]);

  const dialogRef = useOutsideClickHandler(() => handleClose && handleClose(), {
    active: handleClose && active && clickBackdrop && !isLoading,
  });

  if (!active && !preserveChildState) {
    return null;
  }

  return createPortal(
    <div
      role="button"
      aria-pressed="false"
      tabIndex={0}
      onKeyUp={escapeKeyListener}
      className={cx(theme.backdrop, {
        [theme.hidden]: !active,
        [theme.large]: size === 'large',
        [theme.medium]: size === 'medium',
        [theme.small]: size === 'small',
      })}
      style={style}
    >
      <MaskedDiv className={cx(theme.mask)} mask={`url(${ModalMask})`}>
        <div
          aria-hidden={!active}
          aria-label={ariaLabel || ''}
          aria-modal
          className={theme.dialog}
          role="dialog"
          ref={dialogRef}
        >
          {handleClose && (
            <Button
              className={theme.close}
              onClick={handleClose}
              title="Close"
              unstyled
              disabled={isLoading}
              aria-label="close"
              role="button"
            />
          )}
          <div className={cx(theme.container, className)}>{children}</div>
        </div>
      </MaskedDiv>
    </div>,
    document.body
  );
};

export default Modal;
