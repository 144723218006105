import React from 'react';
import HourglassIcon from '@material-ui/icons/HourglassEmptyRounded';
import styles from './LoggingInPlaceHolder.module.css';

const LoggingInPlaceHolder = () => {
  return (
    <>
      <div className={styles.container} data-testid="loggingIn">
        <span>
          <HourglassIcon className={styles.hourglassIcon} />
          &nbsp;Logging in...
        </span>
      </div>
      ;
    </>
  );
};

export default LoggingInPlaceHolder;
