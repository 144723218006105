const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null || typeof serializedState === 'undefined') {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const { privacyPolicy, ...rest } = state;
    const serializedState = JSON.stringify(rest);

    localStorage.setItem('state', serializedState);
  } catch (error) {
    throw error;
  }
};

export { loadState, saveState };
