import React from 'react';
import { Snackbar } from 'react-toolbox/lib/snackbar';
import snackbarStyle from '../../globalStyles/Snackbar.module.css';

class NetworkStatus extends React.Component {
  state = {
    snackbarValue: '',
    active: false,
  };

  timeoutFlag = false;

  componentDidMount() {
    this.registerNetworkEvents();
  }

  componentWillUnmount() {
    this.unregisterNetworkEvents();
  }

  openSnackbar = (snackbarValue) => {
    this.setState({ snackbarValue, active: true });
  };

  closeSnackbar = () => this.setState({ active: false });

  handleOnline = () => {
    this.openSnackbar('Online');
    this.timeoutFlag = !this.timeoutFlag
      ? setTimeout(() => this.closeSnackbar(), 1000)
      : this.timeoutFlag;
  };

  handleOffline = () => {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag);
      this.timeoutFlag = null;
    }
    this.openSnackbar('Offline');
  };

  registerNetworkEvents() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  unregisterNetworkEvents() {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag);
    }
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  render() {
    const { active, snackbarValue } = this.state;

    return (
      <Snackbar
        className={snackbarStyle.snackbar}
        label={snackbarValue}
        active={active}
      />
    );
  }
}

export default NetworkStatus;
