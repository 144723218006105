import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './Tabs.module.css';
import Separator from '../separator/Separator';

export type Props = PropsWithChildren<{
  className?: string;
}>;

const TabBar = ({ className, children }: Props) => {
  return (
    <div className={cx(styles.tabBar, className)}>
      <div className={styles.tabBarTabs}>{children}</div>
      <Separator className={styles.tabBarSeparator} />
    </div>
  );
};

export default TabBar;
