import React, { memo } from 'react';

import styles from './TabsPageLayout.module.css';
import StickyBar from '../../stickyBar/StickyBar';
import Tabs from '../../atoms/tabs/Tabs';
import Meta from '../../meta/Meta';

export type Props = {
  pageName: string;
  stickyBarSlot: React.ReactNode;
  bannerSlot: React.ReactNode;
  tabBarSlot: React.ReactNode;
  tabsSlot: React.ReactNode;
  initialActiveTab: string;
};

const TabsPageLayout = ({
  pageName,
  stickyBarSlot,
  bannerSlot,
  tabBarSlot,
  tabsSlot,
  initialActiveTab,
}: Props) => {
  return (
    <div className={styles.page}>
      <Meta title={pageName} />

      <StickyBar
        backButton
        className={styles.stickyBar}
        theme={{ container: styles.stickyBarContainer }}
      >
        {stickyBarSlot}
      </StickyBar>

      <div className={styles.banner}>{bannerSlot}</div>

      <Tabs initialActiveTab={initialActiveTab} className={styles.tabs}>
        <Tabs.TabBar className={styles.tabBar}>{tabBarSlot}</Tabs.TabBar>
        {tabsSlot}
      </Tabs>
    </div>
  );
};

export default memo(TabsPageLayout);
