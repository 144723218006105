import React from 'react';
import cx from 'classnames';

import { string } from 'prop-types';
import styles from './TrashIcon.module.css';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};
const TrashIcon = ({ className }) => (
  <svg
    className={cx(className, styles.root)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.67 40.85"
    width="100%"
  >
    <g>
      <path
        className={styles.st0}
        d="M17.29,0c-1.51,0-3.01,0.23-4.45,0.7
		c-2.39,0.97-4.5,2.52-6.15,4.5c-2.02,2.24-3.66,4.79-4.85,7.55c-1.87,4.1-2.33,8.7-1.32,13.09c1.44,5.67,5.73,11.4,11.29,13.51
		c2.75,1,5.65,1.51,8.58,1.51c0.1,0,0.2,0,0.31,0c5.52-0.01,10.96-1.39,15.82-4c2.99-1.57,5.4-4.05,6.87-7.08
		c1.88-4.18,1.33-9,0.62-13.52c-0.5-3.24-1.13-6.63-3.15-9.21C39,4.64,36.16,3.22,33.29,2.26c-3.41-1.09-6.95-1.75-10.52-1.97
		c-1.61-0.12-3.36-0.29-5.08-0.29l0,0C17.55,0,17.42,0,17.29,0"
      />
      <g transform="translate(0.4)">
        <path className={styles.st1} d="M12.61,12.08l18.48-3.17" />
        <path
          className={styles.st1}
          d="M19.69,10.85l-0.64-2.32l4.48-0.66c0.22,0.67,0.4,1.35,0.55,2.04"
        />
        <path
          className={styles.st2}
          d="M28.75,16.59c-6.08-0.37-12.18-0.44-18.27-0.22v1.15c0.35,4.91,0.99,9.79,1.94,14.63
			c0.05,0.53,0.24,1.04,0.53,1.48c0.74,0.67,1.73,0.99,2.73,0.87l4,0.08l4-0.08c0.99,0.12,1.99-0.2,2.73-0.87
			c0.3-0.44,0.48-0.95,0.54-1.48C27.84,27,28.44,21.81,28.75,16.59"
        />
        <path
          className={styles.st3}
          d="M30.75,14.59c-6.08-0.37-12.18-0.44-18.27-0.22v1.15c0.35,4.91,0.99,9.79,1.94,14.62
			c0.05,0.53,0.24,1.04,0.53,1.48c0.74,0.67,1.73,0.99,2.73,0.87l4,0.08l4-0.08c0.99,0.12,1.99-0.2,2.73-0.87
			c0.3-0.44,0.48-0.95,0.54-1.48C29.84,25,30.44,19.81,30.75,14.59"
        />
        <g
          className={styles.openTrash}
          transform="translate(1.646 2.933) rotate(-9)"
        >
          <path className={styles.st3} d="M9.46,9.59l18.75,0.09" />
          <path
            className={styles.st3}
            d="M16.65,9.62l-0.23-2.4l4.53,0.13c0.1,0.7,0.16,1.4,0.19,2.11"
          />
        </g>

        <g className={styles.closeTrash}>
          <path className={styles.st3} d="M12.36,10.68l18.75,0.09" />
          <path
            className={styles.st3}
            d="M19.54,10.7l-0.23-2.4l4.53,0.13
				c0.1,0.7,0.16,1.4,0.19,2.11"
          />
        </g>
        <g transform="translate(6.169 11.645)">
          <path
            className={styles.st3}
            d="M10.48,6c0.55,3.75,0.97,7.51,1.26,11.29"
          />
          <path
            className={styles.st3}
            d="M19.87,17.32c0.05-3.79,0.23-7.57,0.54-11.35"
          />
          <path
            className={styles.st3}
            d="M15.51,5.65c-0.08,3.93,0.01,7.87,0.26,11.8"
          />
        </g>
      </g>
    </g>
  </svg>
);

TrashIcon.propTypes = propTypes;
TrashIcon.defaultProps = defaultProps;

export default TrashIcon;
