import React, { memo } from 'react';

import styles from './PlusIcon.module.css';

const PlusIcon = () => (
  <svg viewBox="0 0 57.89 53.33">
    <g transform="rotate(45)">
      <path
        className={styles.path}
        transform="scale(-1 -1)"
        d="M-48.33,11.08c7.3-5.81,13.81-12.56,19.36-20.05"
      />
      <path
        className={styles.path}
        d="M29.01,-11.37c6.16,6.98,12.86,13.46,20.05,19.37"
      />
    </g>
  </svg>
);

export default memo(PlusIcon);
