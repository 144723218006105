import React from 'react';
import { gql, useMutation } from '@apollo/client';

import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';

import { errorDialogMessage } from '../../strings';
import styles from './UpdateSchoolMembersRoleDialog.module.css';
import {
  UpdateSchoolMembersRole,
  UpdateSchoolMembersRoleVariables,
} from './types/UpdateSchoolMembersRole';
import logException from '../../services/logging/exception';
import { SCHOOL_MEMBER_ROLE } from '../../types/graphql-types';
import Select from '../atoms/select/Select';

export const UPDATE_SCHOOL_MEMBERS_ROLE = gql`
  mutation UpdateSchoolMembersRole(
    $schoolId: String!
    $schoolMemberIds: [String!]!
    $role: SCHOOL_MEMBER_ROLE!
  ) {
    updateSchoolMembersRole(
      id: $schoolId
      schoolMemberIds: $schoolMemberIds
      role: $role
    ) {
      __typename
      id
      schoolMembers {
        __typename
        id
        role
      }
    }
  }
`;

const roleToOption = (role: SCHOOL_MEMBER_ROLE) => ({
  label: role,
  value: role,
});

const optionToRole = (option: { label: string; value: string }) =>
  option.value as SCHOOL_MEMBER_ROLE;

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'size' | 'title' | 'description' | 'handleClose'
> & {
  schoolId: string;
  selectedMembers: { id: string; role: 'admin' | 'teacher' | 'student' }[];
  userIsSchoolAdmin: boolean;
  handleClose: () => void;
};

const UpdateSchoolMembersRoleDialog = ({
  schoolId,
  selectedMembers,
  userIsSchoolAdmin,
  ...dialogProps
}: Props) => {
  const [role, setRole] = React.useState<SCHOOL_MEMBER_ROLE | null>(null);

  const [
    updateSchoolMembersRole,
    { loading: updatingSchoolMembers, error: updateSchoolMembersRoleError },
  ] = useMutation<UpdateSchoolMembersRole, UpdateSchoolMembersRoleVariables>(
    UPDATE_SCHOOL_MEMBERS_ROLE,
    {
      variables: {
        schoolId,
        schoolMemberIds: selectedMembers.map((member) => member.id),
        role: role || SCHOOL_MEMBER_ROLE.student,
      },
      onError: (err) => logException(err),
      onCompleted: () => dialogProps.handleClose(),
    }
  );

  const includesAdmins = selectedMembers.some(
    (member) => member.role === SCHOOL_MEMBER_ROLE.admin
  );

  const notPermittedToUpdateSelectedMembers =
    includesAdmins && !userIsSchoolAdmin;

  return (
    <Dialog
      {...dialogProps}
      title="Update School Members Role"
      description={`Choose a role to update <strong>${selectedMembers.length} school members</strong> to:`}
      isLoading={updatingSchoolMembers}
      confirmAction={{
        label: 'Confirm',
        disabled: updatingSchoolMembers || notPermittedToUpdateSelectedMembers,
        type: 'submit',
        form: 'update-school-member-roles-form',
      }}
      size="medium"
      theme={{
        innerContainer: styles.dialogInnerContainer,
      }}
    >
      <form
        id="update-school-member-roles-form"
        onSubmit={(e) => {
          e.preventDefault();
          updateSchoolMembersRole();
        }}
      >
        <Select
          required
          name="school-member-role-select"
          value={role && roleToOption(role)}
          options={[
            ...(userIsSchoolAdmin ? [SCHOOL_MEMBER_ROLE.admin] : []),
            SCHOOL_MEMBER_ROLE.teacher,
            SCHOOL_MEMBER_ROLE.student,
          ].map(roleToOption)}
          onChange={(option) => option && setRole(optionToRole(option))}
          placeholder="Select a role"
          aria-label="Role Select"
          className={styles.select}
        />
      </form>

      {updatingSchoolMembers && (
        <Placeholder
          isLoading={updatingSchoolMembers}
          message="Updating school members..."
          className={styles.placeholder}
        />
      )}
      {!!(
        updateSchoolMembersRoleError || notPermittedToUpdateSelectedMembers
      ) && (
        <span className={styles.errorMessage}>
          {updateSchoolMembersRoleError
            ? errorDialogMessage(
                `updating school members: ${updateSchoolMembersRoleError.message}`
              )
            : 'You do not have permission to change the role of admin members, please remove admins from your selected rows first'}
        </span>
      )}
    </Dialog>
  );
};

export default UpdateSchoolMembersRoleDialog;
