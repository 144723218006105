import React from 'react';
import { node, string, bool } from 'prop-types';
import cx from 'classnames';

import listItemMask from '../../assets/images/list-item-mask.svg';
import listItemBorder from '../../assets/images/list-item-border.svg';
import styles from './MaskedDiv.module.css';

const propTypes = {
  mask: string,
  children: node,
  border: string,
  showBorder: bool,
  className: string,
  testId: string,
};

const defaultProps = {
  mask: `url(${listItemMask})`,
  border: listItemBorder,
  showBorder: false,
  children: null,
  className: '',
  testId: '',
};

const MaskedDiv = ({
  mask,
  children,
  border,
  showBorder,
  className,
  testId,
}) => (
  <div
    className={cx(styles.root, className)}
    style={{
      maskImage: mask,
      WebkitMaskImage: mask,
    }}
    data-testid={testId || 'masked div'}
  >
    {children}

    <img
      src={border}
      alt="border"
      className={cx(styles.border, { [styles.active]: showBorder })}
    />
  </div>
);

MaskedDiv.propTypes = propTypes;
MaskedDiv.defaultProps = defaultProps;

export default MaskedDiv;
