export default function getAccountName<
  Member extends {
    firstName?: string | null;
    lastName?: string | null;
  }
>(member: Member): string {
  if (member.firstName && member.lastName) {
    return `${member.firstName} ${member.lastName}`;
  }
  if (member.firstName) {
    return member.firstName;
  }
  if (member.lastName) {
    return member.lastName;
  }
  return '';
}
