import React from 'react';
import { CellContext } from '@tanstack/react-table';

export type Props<RowData> = {
  info: CellContext<RowData, unknown>;
  className?: string;
};

export const TextCell = <RowData extends Record<string, any>>({
  info,
  className,
}: Props<RowData>) => {
  const value = info.getValue();

  if (typeof value !== 'string') {
    return <td className={className} />;
  }

  return <td className={className}>{value}</td>;
};
