import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '../dialog/Dialog';

import { errorDialogMessage, groupsPath, schoolsPath } from '../../strings';
import styles from './AddSchoolClassDialog.module.css';
import logException from '../../services/logging/exception';
import Select from '../atoms/select/Select';
import PtProgress from '../ptProgress/PtProgress';
import Input from '../atoms/input/Input';
import {
  AddSchoolClass,
  AddSchoolClassVariables,
} from './types/AddSchoolClass';

const ADD_SCHOOL_CLASS = gql`
  mutation AddSchoolClass($schoolId: String!, $name: String!) {
    addSchoolClass(id: $schoolId, name: $name) {
      __typename
      id
      schoolClasses {
        __typename
        id
        name
        createdAt
      }
    }
  }
`;

const schoolToOption = (school: { id: string; name: string }) => ({
  value: school.id,
  label: school.name,
});

const optionToSchool = (option: { value: string; label: string }) => ({
  id: option.value,
  name: option.label,
});

const AddSchoolClassDialog = ({
  schools,
  handleClose,
}: {
  schools: { id: string; name: string }[];
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [school, setSchool] = useState<{ id: string; name: string } | null>(
    schools.length === 1 ? schools[0] : null
  );

  const [addClass, { loading, error }] = useMutation<
    AddSchoolClass,
    AddSchoolClassVariables
  >(ADD_SCHOOL_CLASS, {
    onError: (err) => logException(err),
    onCompleted: (data) => {
      const addedClass = data.addSchoolClass.schoolClasses
        .filter((schoolClass) => schoolClass.name === name)
        .sort((a, b) => (b.createdAt < a.createdAt ? -1 : 1))[0];

      navigate(
        `/${schoolsPath}/${data.addSchoolClass.id}/${groupsPath}/${addedClass.id}`
      );
    },
  });

  return (
    <Dialog
      active
      handleClose={handleClose}
      title="Create Class"
      confirmAction={{
        label: 'Confirm',
        disabled: !school || loading,
        type: 'submit',
        form: 'add-class-by-name-form',
      }}
      size="medium"
    >
      <div id="school-select-label" className={styles.schoolSelectLabel}>
        School
      </div>

      <Select
        value={school && schoolToOption(school)}
        isMulti={false}
        options={schools.map(schoolToOption)}
        onChange={(option) => setSchool(option && optionToSchool(option))}
        aria-labelledby="school-select-label"
        placeholder="Select school to add class to..."
        isDisabled={loading || schools.length === 1}
        aria-invalid={!school}
      />

      {!schools.length && (
        <span className={styles.errorMessage}>
          You must have a school to add the class to, ask your school admin to
          add you or create your own school
        </span>
      )}

      <form
        id="add-class-by-name-form"
        onSubmit={(event) => {
          event.preventDefault();
          if (school) {
            addClass({ variables: { name, schoolId: school?.id } });
          }
        }}
      >
        <Input
          id="add-school-class-name-input"
          minLength={1}
          maxLength={128}
          required
          autoFocus
          bordered
          controlled
          label="Class Name"
          placeholder="Enter class name here..."
          controlledValue={name}
          onChange={setName}
          className={styles.classNameInput}
          disabled={!school || loading}
        />

        {loading && <PtProgress />}

        {!!error && (
          <span className={styles.errorMessage}>
            {errorDialogMessage(`creating class: ${error.message}`)}
          </span>
        )}
      </form>
    </Dialog>
  );
};

export default AddSchoolClassDialog;
