import React, { memo } from 'react';
import { useQuery, useMutation, useSubscription, gql } from '@apollo/client';

import Notifications from './Notifications';

import { UPDATE_LAST_READ } from '../../graphql/mutations/accounts';
import { GET_NOTIFICATIONS } from '../../graphql/queries/notifications';

import { ON_NOTIFICATION } from '../../graphql/subscriptions/notifications';
import { OnNotification } from '../../graphql/subscriptions/notifications/types/OnNotification';
import { onSubscriptionData } from '../../graphql/subscriptions/notifications/onNotification';
import logException from '../../services/logging/exception';
import { GetNotifications } from '../../graphql/queries/notifications/types/GetNotifications';
import { UpdateLastRead } from '../../graphql/mutations/accounts/types/UpdateLastRead';
import { GetMyAccount } from '../../graphql/queries/accounts/types/GetMyAccount';
import { GET_MY_ACCOUNT } from '../../graphql/queries/accounts';
import { AccountLastRead } from './types/AccountLastRead';

export type Props = {
  className?: string;
};

const NotificationsContainer = ({ className }: Props) => {
  useSubscription<OnNotification>(ON_NOTIFICATION, {
    onSubscriptionData,
  });

  const { data: { myAccount } = {} } = useQuery<GetMyAccount>(GET_MY_ACCOUNT);
  const { data: { getNotifications = [] } = {} } = useQuery<GetNotifications>(
    GET_NOTIFICATIONS,
    {
      onError: (err) => logException(err),
    }
  );

  const [updateLastRead] = useMutation<UpdateLastRead>(UPDATE_LAST_READ, {
    update: (cache, { data }) => {
      if (!data || !data.updateLastRead || !myAccount) {
        return;
      }
      cache.writeFragment<AccountLastRead>({
        data: { ...myAccount, lastRead: data.updateLastRead.lastRead },
        fragment: gql`
          fragment AccountLastRead on Account {
            __typename
            id
            lastRead
          }
        `,
      });
    },
    onError: (err) => logException(err),
  });

  return (
    <Notifications
      lastRead={myAccount?.lastRead}
      notifications={getNotifications}
      readNotifications={() => updateLastRead()}
      className={className}
    />
  );
};

export default memo(NotificationsContainer);
