import { gql } from '@apollo/client';

export default gql`
  mutation UpdateUserType($userType: UserType!) {
    updateAccount(userType: $userType) {
      __typename
      id
      userType
    }
  }
`;
