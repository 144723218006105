export const isOverAllocated = (school: {
  numLicenses: number;
  hasSufficientSeats: boolean;
}): boolean => !school.hasSufficientSeats && school.numLicenses > 0;

export const hasNoLicenses = (school: {
  numLicenses: number;
  hasSufficientSeats: boolean;
}): boolean => !school.hasSufficientSeats && school.numLicenses <= 0;

export const hasUnpaidLicenses = (school: {
  licenses: { status: string }[] | null;
}): boolean =>
  !!school.licenses &&
  school.licenses.some(({ status }) => status === 'past_due');
