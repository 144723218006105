import { gql } from '@apollo/client';
import { AccountFrag } from '../../fragments';

export default gql`
  mutation UpgradeMembership($inviteCode: String!) {
    upgradeMembership(inviteCode: $inviteCode) {
      ...AccountFields
      membershipType
      membershipExpiry
      hasActivatedTrial
      restricted
      tutorialsCompleted
      lastRead
      invites {
        __typename
        id
        code
        contentLicenses {
          __typename
          id
          maxUsers
          durationDays
          activationDate
          accounts {
            __typename
            id
          }
          course {
            __typename
            id
            title
          }
          realm {
            __typename
            id
            name
          }
        }
      }
    }
  }

  ${AccountFrag}
`;
