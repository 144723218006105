const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

const int24ToBytes = (int: number) => {
  // convert int to array of 3 bytes
  return [int & 255, (int >> 8) & 255, (int >> 16) & 255]; // eslint-disable-line no-bitwise
};

const int16ToBytes = (int: number) => {
  // convert int to array of 2 bytes
  return [int & 255, (int >> 8) & 255]; // eslint-disable-line no-bitwise
};

export const stringToUint8Array = (str: string): Uint8Array => {
  return new Uint8Array(new TextEncoder().encode(str));
};

export class Chunk {
  id: number;

  finalIndex: number;

  index: number;

  data: Uint8Array;

  constructor(id: number, finalIndex: number, index: number, data: Uint8Array) {
    this.id = id;
    this.finalIndex = finalIndex;
    this.index = index;
    this.data = data;
  }

  asBytes() {
    const bytes = new Uint8Array(8 + this.data.length);
    bytes.set(int16ToBytes(this.id));
    bytes.set(int24ToBytes(this.finalIndex), 2);
    bytes.set(int24ToBytes(this.index), 5);
    bytes.set(this.data, 8);
    return bytes;
  }

  decode() {
    const decoded = new TextDecoder().decode(this.data);
    return decoded;
  }

  static parse(message: DataView | Uint8Array) {
    let bytes: DataView | Uint8Array;
    if (message instanceof DataView) {
      bytes = new Uint8Array(message.buffer);
    } else if (message instanceof Uint8Array) {
      bytes = message;
    } else {
      throw new Error(
        `Invalid Bluetooth message type: ${typeof message}, ${message}`
      );
    }

    const idBytes = bytes.slice(0, 2);
    const idInt = idBytes[0] + (idBytes[1] << 8); // eslint-disable-line no-bitwise

    const finalIndex = bytes.slice(2, 5);
    const finalIndexInt =
      finalIndex[0] + (finalIndex[1] << 8) + (finalIndex[2] << 16); // eslint-disable-line no-bitwise

    const indexBytes = bytes.slice(5, 8);
    const indexInt =
      indexBytes[0] + (indexBytes[1] << 8) + (indexBytes[2] << 16); // eslint-disable-line no-bitwise

    const dataBytes = bytes.slice(8, bytes.length);

    return new Chunk(idInt, finalIndexInt, indexInt, dataBytes);
  }
}

export class ChunkedMessage {
  static chunkDataSize = 504; // payload size

  chunks: Record<number, Chunk>;

  totalChunks: number;

  bytes: Uint8Array;

  maxChunkSize: number;

  constructor(totalChunks: number, maxChunkSize: number) {
    this.chunks = {};
    this.totalChunks = totalChunks;
    // this assumes that all chunks have the same data size, though final chunk may be smaller
    this.maxChunkSize = maxChunkSize;
    this.bytes = new Uint8Array(this.totalChunks * this.maxChunkSize);
  }

  static async fromBytes(bytes: Uint8Array, mtu: number) {
    const payloadSize = mtu - 8;

    const chunkCount = Math.ceil(bytes.length / payloadSize);

    const chunked = new ChunkedMessage(chunkCount, payloadSize);

    const id = getRandomInt(65535);
    const finalIndex = chunkCount - 1;

    for (let index = 0; index < chunkCount; index += 1) {
      const data = bytes.slice(index * payloadSize, (index + 1) * payloadSize);
      chunked.append(new Chunk(id, finalIndex, index, data));
    }
    return chunked;
  }

  static fromString(message: string, mtu: number) {
    return ChunkedMessage.fromBytes(stringToUint8Array(message), mtu);
  }

  append(chunk: Chunk) {
    this.chunks[chunk.index] = chunk;
    this.bytes.set(chunk.data, chunk.index * this.maxChunkSize);

    // if appending the last chunk, trim down the bytes array
    if (chunk.index === chunk.finalIndex) {
      this.bytes = this.bytes.slice(
        0,
        chunk.index * this.maxChunkSize + chunk.data.length
      );
    }
  }

  chunk(index: number) {
    return this.chunks[index];
  }

  isComplete() {
    return Object.keys(this.chunks).length === this.totalChunks;
  }

  decode() {
    const decoded = new TextDecoder().decode(this.bytes);
    return decoded;
  }
}
