import { gql } from '@apollo/client';

export default gql`
  fragment CodeRunEventFields on CodeRunEvent {
    __typename
    process
    type
    timestamp
    data {
      path
      code
      exitCode
      output
      input
      message
      codeRunId
      key
      event
    }
  }
`;
