import { gql } from '@apollo/client';
import { AccountFrag } from '../../fragments';

export default gql`
  mutation CancelMembership {
    cancelMembership {
      ...AccountFields
      membershipType
      membershipExpiry
      hasActivatedTrial
      restricted
      tutorialsCompleted
      lastRead
    }
  }

  ${AccountFrag}
`;
