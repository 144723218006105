import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './Tabs.module.css';
import { useTabsContext } from './Tabs';
import Button from '../button/Button';

export type Props = PropsWithChildren<{
  name: string;
  className?: string;
}>;

const Tab = ({ name, className, children }: Props) => {
  const tabsContext = useTabsContext('Tab');

  return (
    <Button
      unstyled
      onClick={() => tabsContext.setActiveTab(name)}
      className={cx(styles.tab, styles.tabFont, className, {
        [styles.active]: tabsContext.activeTab === name,
      })}
    >
      {children}
    </Button>
  );
};

export default Tab;
