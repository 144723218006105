import React, { useContext } from 'react';
import FourOhFourImage from '../../assets/images/404.png';
import {
  fourOhFourTitle,
  fourOhFourLoggedInMessage,
  fourOhFourGuestMessage,
  logInLabel,
} from '../../strings';
import { AuthContext } from '../../context/Auth';
import styles from './FourOhFour.module.css';
import Button from '../atoms/button/Button';

export type Props = {
  resourceName?: string;
};

const FourOhFour = ({ resourceName }: Props) => {
  const { authenticated, login } = useContext(AuthContext) || {}

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h3 data-testid="fourohfour-title">{fourOhFourTitle(resourceName)}</h3>
        <img src={FourOhFourImage} alt="404" data-testid="fourohfour-image" />
        <div className={styles.message}>
          {authenticated || !login ? (
            <span data-testid="fourohfour-message">
              {fourOhFourLoggedInMessage(resourceName)}
            </span>
          ) : (
            <div data-testid="fourohfour-message">
              <span>{fourOhFourGuestMessage(resourceName, 0)}</span>
              <Button onClick={() => login()} unstyled>
                {logInLabel}
              </Button>
              <span>{fourOhFourGuestMessage(resourceName, 1)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FourOhFour;
