import React from 'react';
import cx from 'classnames';
import { bool, shape, string } from 'prop-types';
import addTooltip from 'react-toolbox/lib/tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import styles from './StatusIcon.module.css';

const propTypes = {
  className: string,
  hasError: bool.isRequired,
  isSaving: bool.isRequired,
  messages: shape({}),
};

const defaultProps = {
  className: null,
  messages: {
    error: 'Error',
    saving: 'Saving',
    success: 'Saved',
  },
};

const TooltipIcon = addTooltip((props) => <div {...props} />);

const StatusIcon = ({
  className,
  hasError,
  isSaving,
  messages: { error, saving, success },
}) => {
  if (hasError) {
    return (
      <TooltipIcon
        className={cx(styles.icon, styles.error, className)}
        title={error || defaultProps.messages.error}
        tooltip={error || defaultProps.messages.error}
      >
        <ErrorOutlineIcon />
      </TooltipIcon>
    );
  }

  if (isSaving) {
    return (
      <TooltipIcon
        className={cx(styles.icon, styles.saving, className)}
        title={saving || defaultProps.messages.saving}
        tooltip={saving || defaultProps.messages.saving}
      />
    );
  }

  return (
    <TooltipIcon
      className={cx(styles.icon, styles.success, className)}
      title={success || defaultProps.messages.success}
      tooltip={success || defaultProps.messages.success}
    />
  );
};

StatusIcon.propTypes = propTypes;
StatusIcon.defaultProps = defaultProps;
export default StatusIcon;
