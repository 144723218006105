import React, { memo } from 'react';

import styles from './ReplaceIcon.module.css';
import CircleIcon from '../circleIcon/CircleIcon';

const ReplaceIcon = () => (
  <CircleIcon>
    <path
      className={styles.path}
      d="M31.122 14.266H20.606a1 1 0 0 1-1-1v-5.68a1 1 0 0 1 1-1h10.516a1 1 0 0 1 1 1v5.68a1 1 0 0 1-1 1zM18.606 28.695H8.09a1 1 0 0 1-1-1v-5.68a1 1 0 0 1 1-1h10.516a1 1 0 0 1 1 1v5.68a1 1 0 0 1-1 1zM16.822 10.426h-3.474v7.45M22.39 24.855h3.474v-7.45"
    />
  </CircleIcon>
);

export default memo(ReplaceIcon);
