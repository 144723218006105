import React, { MouseEvent, ReactNode } from 'react';
import cx from 'classnames';

import PrimaryButton, {
  Props as PrimaryButtonProps,
} from '../primaryButton/PrimaryButton';

import Modal, { Theme as ModalTheme } from '../modal/Modal';
import styles from './Dialog.module.css';
import useTheme, { ParentTheme } from '../../hooks/useTheme';
import Button from '../atoms/button/Button';

type Action = PrimaryButtonProps & {
  label: string;
};

export type Theme = ParentTheme<typeof styles, { modal: ModalTheme }>;

export type Props = {
  title: string;
  description?: string;
  headerIcon?: string;
  confirmAction?: Action | null;
  backAction?: Action | null;
  active: boolean;
  preserveChildState?: boolean;
  children?: ReactNode;
  className?: string;
  clickBackdrop?: boolean;
  handleClose?: (e?: MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  hasBlurBg?: boolean;
  ariaLabel?: string;
  size?: 'large' | 'medium' | 'small';
  style?: any;
  theme?: Partial<Theme>;
};

const Dialog = ({
  title,
  description,
  headerIcon,
  confirmAction,
  backAction,
  size,
  ariaLabel,
  theme: customTheme = {},
  children,
  className,
  ...props
}: Props) => {
  const theme = useTheme(styles, customTheme);
  const { label: _, ...confirmActionButtonProps } = confirmAction || {};
  const { label: __, ...backActionButtonProps } = backAction || {};
  return (
    <Modal
      {...props}
      theme={theme.children?.modal}
      size={size}
      ariaLabel={ariaLabel || title}
    >
      <div
        className={cx(theme.dialog, className, {
          [theme.medium]: size === 'medium',
          [theme.small]: size === 'small',
        })}
      >
        <div className={cx(theme.container, theme.headerContainer)}>
          <header className={theme.innerContainer}>
            {headerIcon && (
              <div className={theme.headerIcon}>
                <img src={headerIcon} alt={title} />
              </div>
            )}
            {title && <h3 className={theme.title}>{title}</h3>}
          </header>
        </div>
        <div className={cx(theme.container, theme.bodyContainer)}>
          <div className={theme.innerContainer}>
            {description && (
              <p
                className={theme.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {children}
          </div>
        </div>
        <div className={cx(theme.container, theme.footerContainer)}>
          {!!(confirmAction || backAction) && (
            <footer className={theme.footer}>
              {backAction && (
                <Button
                  unstyled
                  key={backAction.label}
                  aria-label={backAction.label}
                  {...backActionButtonProps}
                  className={cx(theme.backAction, backAction.className)}
                >
                  {backAction.label}
                </Button>
              )}

              {confirmAction && (
                <PrimaryButton
                  key={confirmAction.label}
                  aria-label={confirmAction.label}
                  {...confirmActionButtonProps}
                >
                  {confirmAction.label}
                </PrimaryButton>
              )}
            </footer>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;
