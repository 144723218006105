import React from 'react';

import styles from './LockIcon.module.css';

type Props = {
  className?: string;
};

const LockIcon = ({ className }: Props) => (
  <svg
    className={className}
    viewBox="12 5 18 22"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="lock-icon"
  >
    <g id="Group_9464" transform="translate(-3008.784 -3139.971)">
      <g id="Group_9398" transform="translate(3759.159 3169.337)">
        <path
          id="Path_70351"
          className={styles.path}
          d="M-737.68-5.88c0.01,0.18,0.03,0.33,0.03,0.46c-0.01,0.31,0.06,0.62,0.21,0.89
			c0.29,0.31,0.69,0.5,1.12,0.5c3.94,0.55,7.91,0.76,11.89,0.62c1.01,0.11,2.01-0.27,2.68-1.03c0.26-0.48,0.39-1.02,0.39-1.57
			c0.23-3.1,0.17-6.22-0.19-9.31c-0.01-0.41-0.14-0.81-0.38-1.14c-0.41-0.34-0.94-0.5-1.46-0.44l-12.26-0.15
			c-0.53-0.04-1.06,0.03-1.56,0.2C-739.04-16.07-737.91-8.49-737.68-5.88z"
        />
        <path
          id="Path_70352"
          className={styles.path}
          d="M-733.48-17.36c-0.3-1.7,0.08-3.83,1.53-4.89c1.53-1.05,3.59-0.89,4.94,0.38
			c0.27,0.26,0.49,0.58,0.63,0.93c0.12,0.39,0.19,0.79,0.2,1.2l0.17,2.38"
        />
        <path
          id="Path_70353"
          className={styles.path}
          d="M-729.78-13.81c0.52-0.02,1.03,0.19,1.38,0.58c0.46,0.41,0.73,0.99,0.76,1.6
			c0.03,0.27-0.04,0.53-0.18,0.76c-0.15,0.2-0.38,0.32-0.55,0.51c-0.28,0.42-0.39,0.92-0.31,1.41l0.04,1.11
			c0.02,0.2,0,0.4-0.06,0.59c-0.2,0.4-0.63,0.64-1.08,0.6h-0.01c-0.45,0.04-0.87-0.2-1.08-0.6c-0.06-0.19-0.08-0.39-0.06-0.59
			l0.04-1.11c0.08-0.49-0.03-1-0.31-1.41c-0.17-0.19-0.4-0.31-0.55-0.51c-0.14-0.23-0.21-0.49-0.18-0.76
			c0.03-0.61,0.31-1.19,0.76-1.6c0.35-0.39,0.86-0.6,1.38-0.58"
        />
      </g>
    </g>
  </svg>
);

export default LockIcon;
