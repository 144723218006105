import React, { DetailedHTMLProps, memo } from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.css';

export type Props = DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { indeterminate?: boolean };

const Checkbox = ({ indeterminate, ...rest }: Props) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, rest.checked, indeterminate]);

  return (
    <input
      className={cx(rest.className, styles.root)}
      type="checkbox"
      ref={ref}
      {...rest}
    />
  );
};

export default memo(Checkbox);
