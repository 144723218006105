const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lowerLetters = 'abcdefghijklmnopqrstuvwxyz';
const letters = upperLetters + lowerLetters;
const numbers = '0123456789';
const alphaNumeric = letters + numbers;
const confusingCharacters = 'ILOijlo01';

function randomString(characters, length) {
  let string = '';

  for (let i = 0; i < length; i += 1) {
    const randomCharacter =
      characters[Math.floor(Math.random() * characters.length)];
    string += randomCharacter;
  }

  return string;
}

function removeCharactersFromString(string, characterString) {
  return string
    .split('')
    .reduce(
      (str, char) => (characterString.includes(char) ? str : str + char),
      ''
    );
}

const nonConfusingAlphaNumeric = removeCharactersFromString(
  alphaNumeric,
  confusingCharacters
);

const makeReadableCode = (length = 8) => `\
${randomString(nonConfusingAlphaNumeric, length / 2)}-\
${randomString(nonConfusingAlphaNumeric, length / 2)}\
`;

export const makePasswordPin = (length = 8) => {
  const min = 10 ** (length - 1);
  const range = 9 * min;
  return Math.floor(min + Math.random() * range);
};

export default makeReadableCode;
