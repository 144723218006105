import { gql } from '@apollo/client';
import { CommentFrag } from '../../fragments';

export default gql`
  query GetSubmissionComments($id: String!) {
    submission(id: $id) {
      id
      comments {
        ...CommentFields
        pinningId
        owner {
          id
          username
        }
        replies {
          ...CommentFields
          owner {
            id
            username
          }
          repliedTo {
            id
          }
        }
      }
      __typename
    }
  }

  ${CommentFrag}
`;
