import { gql } from '@apollo/client';

export default gql`
  fragment AssignmentFields on Assignment {
    id
    createdAt
    updatedAt
    number
    __typename
  }
`;
