import { HardwarePreference } from '../types/graphql-types';
import { isHardwarePreference } from './typeGuards';

export const TUTORIALS_KEY = 'tutorials';
export const DEVICES_KEY = 'device';
export const UNAUTHENTICATED = 'unauthenticated';

// this used to store completedTutorials for accounts also, so the structure
// is an object with key UNAUTHENTICATED, but now that's the only key used

export const getUnauthenticatedTutorialsCompleted = (): string[] => {
  const tutorialObj = JSON.parse(localStorage.getItem(TUTORIALS_KEY) || '{}');
  return tutorialObj[UNAUTHENTICATED] || [];
};

export const setUnauthenticatedTutorialsCompleted = (
  completedTutorials: string[]
) => {
  localStorage.setItem(
    TUTORIALS_KEY,
    JSON.stringify({
      [UNAUTHENTICATED]: completedTutorials,
    })
  );
};

export const getDevices = () => {
  const devices = localStorage.getItem(DEVICES_KEY) || '';
  return devices.split(',').filter(isHardwarePreference);
};

export const setDevices = (devices: HardwarePreference[]) => {
  localStorage.setItem(DEVICES_KEY, devices.join(','));
};
