/* eslint @typescript-eslint/no-explicit-any: 0 */

import { IObject } from '../types/index.types';
import { HardwarePreference } from '../types/graphql-types';

export const isKeyOfObject = <T extends IObject>(
  keyInput: any,
  obj: T
): keyInput is keyof T => {
  return Object.keys(obj).includes(keyInput);
};

export const isHardwarePreference = (
  value: any
): value is HardwarePreference => {
  return Object.values(HardwarePreference).includes(value);
};
