import parseCsv from 'csv-parse';

import { SCHOOL_MEMBER_ROLE, SchoolMemberInput } from '../types/graphql-types';

const parseRole = (role?: string): SCHOOL_MEMBER_ROLE => {
  const normalisedRole = role?.trim().toLowerCase();
  if (
    ![
      SCHOOL_MEMBER_ROLE.student,
      SCHOOL_MEMBER_ROLE.teacher,
      SCHOOL_MEMBER_ROLE.admin,
    ].includes(normalisedRole as SCHOOL_MEMBER_ROLE)
  ) {
    return SCHOOL_MEMBER_ROLE.student;
  }

  return normalisedRole as SCHOOL_MEMBER_ROLE;
};

const readMembersData = (parsedCsv: string[][]): SchoolMemberInput[] => {
  // column names are optional, if not provided defaults are used
  // 'email', 'username' or 'firstname' columns must have values

  // accept when starts with main part of name, case insensitive (eg 'First Name' matches 'first')
  const matchColumnName = (value: string, name: string) =>
    value.trim().toLowerCase().startsWith(name);

  const emailIndex = parsedCsv[0].findIndex((value) =>
    matchColumnName(value, 'email')
  );
  const usernameIndex = parsedCsv[0].findIndex((value) =>
    matchColumnName(value, 'username')
  );
  const firstNameIndex = parsedCsv[0].findIndex((value) =>
    matchColumnName(value, 'first')
  );
  const lastNameIndex = parsedCsv[0].findIndex((value) =>
    matchColumnName(value, 'last')
  );
  const roleIndex = parsedCsv[0].findIndex((value) =>
    matchColumnName(value, 'role')
  );

  // no valid column names, assume all data with default indexes
  const columnNameFound =
    emailIndex !== -1 ||
    usernameIndex !== -1 ||
    firstNameIndex !== -1 ||
    lastNameIndex !== -1;

  const membersData: SchoolMemberInput[] = [];

  // if column names found, set them and skip first row, otherwise use parsedCsv
  const rows = columnNameFound ? parsedCsv.slice(1) : parsedCsv;
  rows.forEach((row) => {
    if (columnNameFound) {
      const role = parseRole(row[roleIndex]);

      const email = row[emailIndex];
      if (email) {
        membersData.push({ role, email });
        return;
      }

      const username = row[usernameIndex];
      if (username) {
        membersData.push({ role, username });
        return;
      }

      const firstName = row[firstNameIndex];
      const lastName = row[lastNameIndex];
      if (firstName || lastName) {
        membersData.push({ role, firstName, lastName });
        return;
      }

      membersData.push({ role });
      return;
    }

    // no column names found, use default indexes
    const data: SchoolMemberInput = {
      role: parseRole(row[4]),
    };
    [
      'email' as const,
      'username' as const,
      'firstName' as const,
      'lastName' as const,
    ].forEach((name, index) => {
      const value = row[index];
      if (value) {
        data[name] = row[index];
      }
    });
    membersData.push(data);
  });

  return membersData;
};

export default async (file: File) => {
  const badFile = new Error('That file could not be read');
  const emptyFile = new Error('No valid data was found in that file');

  return new Promise<SchoolMemberInput[]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(badFile);

    reader.onload = () => {
      const text = reader.result;
      if (typeof text !== 'string') {
        return reject(badFile);
      }

      parseCsv(
        text,
        { trim: true, skip_empty_lines: true },
        (parseError, parsed) => {
          if (parseError) {
            return reject(badFile);
          }

          try {
            const membersData = readMembersData(parsed);
            if (!membersData.length) {
              return reject(emptyFile);
            }

            return resolve(membersData);
          } catch (e) {
            return reject(badFile);
          }
        }
      );
    };

    reader.readAsText(file);
  });
};
