import { gql } from '@apollo/client';

export default gql`
  fragment ProjectVersionFields on ProjectVersion {
    id
    title
    number
    thumbnail
    description
    standards
    teacherResources {
      id
      url
      name
      mimeType
    }
    createdAt
    updatedAt
    __typename
  }
`;
