import * as types from '../../actions/commonStandards/actionTypes';

export default (state = 0, action) => {
  if (action.type === types.FETCH_STANDARDS_SUCCESS) {
    const { totalPages } = action.payload;
    return totalPages;
  }

  return state;
};
