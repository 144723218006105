import React, { memo } from 'react';
import { bool, string } from 'prop-types';
import cx from 'classnames';

import CircleIcon from '../circleIcon/CircleIcon';
import styles from './CompletedIcon.module.css';

const propTypes = {
  className: string,
  noCircle: bool,
};

const defaultProps = {
  className: '',
  noCircle: false,
};

const CompletedIcon = ({ className, noCircle, ...restProps }) => {
  const checkIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      className={cx({ className: noCircle, [styles.withCircle]: !noCircle })}
      data-testid="completed-icon"
    >
      <g>
        <path
          className={styles.path}
          d="M24.49,46.16c-1.36-0.04-2.58-0.84-3.15-2.08L15,32.86c-0.7-1.23-0.26-2.79,0.97-3.49
		c1.23-0.7,2.79-0.26,3.49,0.97l0,0l5.33,9.44c3.21-3.83,5.64-8.47,7.99-12.97l8.07-15.5c0.67-1.25,2.22-1.72,3.47-1.05
		c1.23,0.66,1.71,2.18,1.08,3.42l-8.08,15.51c-2.86,5.47-5.81,11.12-10.27,15.75c-0.48,0.55-1.11,0.95-1.82,1.15
		c-0.1,0.03-0.2,0.05-0.3,0.06C24.79,46.16,24.64,46.17,24.49,46.16z"
        />
      </g>
    </svg>
  );

  if (noCircle) return checkIcon;

  return (
    <div className={cx(className, styles.wrapper)}>
      <CircleIcon {...restProps} />
      {checkIcon}
    </div>
  );
};

CompletedIcon.propTypes = propTypes;
CompletedIcon.defaultProps = defaultProps;

export default memo(CompletedIcon);
