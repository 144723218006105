import React, { Fragment, HTMLProps, useState, useCallback } from 'react';
import cx from 'classnames';

import styles from './Input.module.css';
import MaskedDiv from '../../maskedDiv/MaskedDiv';
import InputMask from '../../../assets/images/input-mask.svg';

export type Props = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'onPaste'
> & {
  id?: string;
  ariaLabel?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  controlled?: boolean;
  controlledValue?: string;
  defaultValue?: string;
  helpText?: string;
  innerRef?: (e: HTMLInputElement) => void;
  inputClassName?: string;
  maskPath?: string;
  masked?: boolean;
  onPaste?: ((e: string) => string) | null;
  onChange?: (s: string) => void;
};

const Input = ({
  id,
  ariaLabel = '',
  bordered = false,
  className = '',
  controlled = false,
  controlledValue = '',
  helpText = '',
  innerRef,
  inputClassName = '',
  label = '',
  maskPath = InputMask,
  masked = false,
  defaultValue = '',
  onKeyDown = () => {},
  onChange = () => {},
  onPaste = null,
  type = 'text',
  ...restProps
}: Props) => {
  const [value, setValue] = useState(controlled ? undefined : defaultValue);

  const handleChange = useCallback(
    (
      ev: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }
    ) => {
      const {
        target: { value: newValue },
      } = ev;

      if (!controlled) {
        setValue(newValue);
      }

      onChange(newValue);
    },
    [onChange, controlled]
  );

  const handlePaste = useCallback(
    (ev: React.ClipboardEvent<HTMLInputElement>) => {
      if (onPaste) {
        ev.preventDefault();
        const text = ev.clipboardData ? ev.clipboardData.getData('Text') : '';
        const returnedValue = onPaste(text);
        handleChange({ target: { value: returnedValue } });
      }
    },
    [onPaste, handleChange]
  );

  const WrapperEl = masked ? MaskedDiv : Fragment;
  const wrapperProps = {
    ...(masked && { mask: `url(${maskPath})` }),
  };

  return (
    <div className={cx(className, { [styles.bordered]: bordered })}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <WrapperEl {...wrapperProps}>
        <input
          aria-label={ariaLabel}
          // overwrite aria-label if it is passed in restProps
          {...restProps}
          type={type}
          className={cx(styles.input, inputClassName)}
          defaultValue={undefined}
          id={id}
          onChange={handleChange}
          onPaste={handlePaste}
          onKeyDown={onKeyDown}
          ref={innerRef}
          value={controlled ? controlledValue : value}
        />
      </WrapperEl>
      {helpText && <span className={styles.helpText}>{helpText}</span>}
    </div>
  );
};

export default Input;
