if (window && (window.PT_ENV === 'staging' || window.PT_ENV === 'prod')) {
  (() => {
    const s = document.getElementsByTagName('script')[0];
    const p = document.createElement('script');
    p.type = 'text/javascript';
    p.id = 'hs-script-loader';
    p.async = 'async';
    p.defer = 'defer';
    p.src = '//js.hs-scripts.com/4368942.js';
    s.parentNode.insertBefore(p, s);
  })();
}
