import { gql } from '@apollo/client';
import {
  GroupFrag,
  GroupAdminFrag,
  GroupMemberFrag,
  ProjectFrag,
  ProjectCollaboratorGroupFrag,
  ProjectVersionFrag,
  ProjectReaderGroupFrag,
} from '../../fragments';

export default gql`
  query GetProject($id: String!) {
    project(id: $id) {
      ...ProjectFields
      hasUnpublishedChanges
      versions {
        ...ProjectVersionFields
        isCollaboratorProject
      }
      collaboratorGroups {
        ...ProjectCollaboratorGroupFields
        group {
          ...GroupFields
          groupAdmins {
            ...GroupAdminFields
            admin {
              id
              username
            }
          }
          groupMembers {
            ...GroupMemberFields
            member {
              id
              username
            }
          }
        }
      }
      readerGroups {
        ...ProjectReaderGroupFields
        group {
          id
        }
      }
      assignments {
        id
        group {
          id
        }
      }
      submissions {
        id
        isSubmissionCollaborator
        assignment {
          id
        }
      }
    }
  }

  ${GroupFrag}
  ${GroupAdminFrag}
  ${GroupMemberFrag}
  ${ProjectFrag}
  ${ProjectCollaboratorGroupFrag}
  ${ProjectVersionFrag}
  ${ProjectReaderGroupFrag}
`;
