import React, { memo, ReactNode } from 'react';
import { shadowFilterId } from '../svgFilterDefinitions/SvgFilterDefinitions';

import styles from './CircleIcon.module.css';

type Props = {
  dropshadow?: true;
  children?: ReactNode;
  className?: string;
};

const CircleIcon = ({ dropshadow, className = '', children = null }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.92053 37"
    overflow="visible"
    width="100%"
  >
    <path
      className={styles.path}
      filter={dropshadow && `url(#${shadowFilterId})`}
      d="M21.50416,35.5749c1.46256,0,2.90716-0.14038,4.20928-0.60248c2.1076-0.74745,3.85802-2.26212,5.35409-3.92406
        c1.73919-1.93051,3.20098-4.17657,4.2289-6.5796c1.5497-3.62176,2.11259-7.60127,1.15011-11.39767
        c-1.25109-4.93196-4.99441-9.9259-9.83047-11.76159C26.24825,0.41054,23.59265,0,20.88017,0
        c-4.84555,0-9.87388,1.30967-13.77642,3.48615c-2.54584,1.4195-4.79183,3.50964-5.98807,6.16835
        c-1.63695,3.63536-1.15633,7.83506-0.54419,11.77537c0.43822,2.82505,0.98243,5.77106,2.74032,8.02647
        c1.62325,2.08131,4.09925,3.32247,6.60269,4.15661c2.96035,0.986,6.05786,1.49031,9.16409,1.7135
        C20.48274,35.42693,22.00291,35.5749,23.50416,35.5749"
    />

    {children}
  </svg>
);

export default memo(CircleIcon);
