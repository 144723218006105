import React from 'react';
import { gql, useMutation } from '@apollo/client';

import Dialog, { Props as DialogProps } from '../dialog/Dialog';
import Placeholder from '../placeholder/Placeholder';

import { errorDialogMessage } from '../../strings';
import styles from './RemoveSchoolMembersDialog.module.css';
import {
  RemoveSchoolMembers,
  RemoveSchoolMembersVariables,
} from './types/RemoveSchoolMembers';
import logException from '../../services/logging/exception';
import { SCHOOL_MEMBER_ROLE } from '../../types/graphql-types';

export const REMOVE_SCHOOL_MEMBERS = gql`
  mutation RemoveSchoolMembers(
    $schoolId: String!
    $schoolMemberIds: [String!]!
  ) {
    removeSchoolMembers(id: $schoolId, schoolMemberIds: $schoolMemberIds) {
      __typename
      id
      schoolMembers {
        id
        __typename
      }
      schoolContentAccess {
        __typename
        id
        schoolMembers {
          __typename
          id
        }
      }
    }
  }
`;

export type Props = Omit<
  DialogProps,
  'confirmAction' | 'size' | 'title' | 'description' | 'handleClose'
> & {
  schoolId: string;
  selectedMembers: { id: string; role: 'admin' | 'teacher' | 'student' }[];
  userIsSchoolAdmin: boolean;
  onCompleted: () => void;
  handleClose: () => void;
};

const RemoveSchoolMembersDialog = ({
  schoolId,
  selectedMembers,
  userIsSchoolAdmin,
  onCompleted,
  ...dialogProps
}: Props) => {
  const [
    removeSchoolMembers,
    { loading: removingSchoolMembers, error: removeSchoolMembersError },
  ] = useMutation<RemoveSchoolMembers, RemoveSchoolMembersVariables>(
    REMOVE_SCHOOL_MEMBERS,
    {
      variables: {
        schoolId,
        schoolMemberIds: selectedMembers.map((member) => member.id),
      },
      onError: (err) => logException(err),
      onCompleted: () => {
        onCompleted();
        dialogProps.handleClose();
      },
    }
  );

  const includesAdmins = selectedMembers.some(
    (member) => member.role === SCHOOL_MEMBER_ROLE.admin
  );

  const unableToRemoveSelectedStudents = includesAdmins && !userIsSchoolAdmin;

  return (
    <Dialog
      {...dialogProps}
      title="Remove School Members"
      description={`Are you sure you want to <strong>remove ${selectedMembers.length} school members</strong>? They will also be removed from all classes and assignments.`}
      isLoading={removingSchoolMembers}
      confirmAction={{
        label: 'Confirm',
        onClick: () => removeSchoolMembers(),
        disabled: removingSchoolMembers || unableToRemoveSelectedStudents,
      }}
      size="medium"
      theme={{
        innerContainer: styles.dialogInnerContainer,
      }}
    >
      {removingSchoolMembers && (
        <Placeholder
          isLoading={removingSchoolMembers}
          message="Removing school members..."
          className={styles.placeholder}
        />
      )}

      {!!(removeSchoolMembersError || unableToRemoveSelectedStudents) && (
        <span className={styles.errorMessage}>
          {removeSchoolMembersError
            ? errorDialogMessage(
                `removing school members: ${removeSchoolMembersError.message}`
              )
            : 'You do not have permission to remove admin members, please remove admins from your selected rows first'}
        </span>
      )}
    </Dialog>
  );
};

export default RemoveSchoolMembersDialog;
